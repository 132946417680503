import React, { useContext } from 'react'
import { UrlContext } from '../../../../hooks/UrlContext'
import { Movie } from '../../../../types/Movie'
import dayjs from 'dayjs'

interface Schedule {
  start_day: string
  end_day: string
}

interface MovieExtended extends Movie {
  startDay: string
  endDay: string
}

interface Props {
  commingSoonMovies: MovieExtended[]
  schedules: Schedule[]
}

export const CommingSoon = (props: Props) => {
  const { imageURL } = useContext(UrlContext)

  return (
    <>
      <div className="title-red-2 text-center">
        <div>近日上映</div>
      </div>
      <div className="grid grid-cols-2 gap-2 cursor-pointer">
        {props.commingSoonMovies.map((movie) => (
          <div key={movie.id}>
            <a>
              <div
                className="square"
                style={{
                  backgroundImage: `url(${
                    imageURL + encodeURIComponent(movie.image)
                  })`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                }}
              >
                <div className="mov_bg">
                  <div className="monthly-title-blue">
                    <p className="movie-title tri">
                      {movie.title}
                      <br />
                      {dayjs(movie.startDay).format('M/D(dd)')}~
                      {dayjs(movie.endDay).format('M/D(dd)')}
                    </p>
                  </div>
                </div>
              </div>
            </a>
          </div>
        ))}
      </div>
    </>
  )
}
