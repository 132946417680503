import React from 'react'

type UrlContextProps = {
  imageURL: string
}

type Children = {
  children: React.ReactNode
}
export const UrlContext = React.createContext<UrlContextProps>(
  {} as UrlContextProps
)

export const UrlProvider = (props: Children) => {
  const { children } = props

  const imageURL = 'https://backend.perpage.jp/'
  // const imageURL = 'http://localhost:8000/'

  return (
    <UrlContext.Provider value={{ imageURL }}>{children}</UrlContext.Provider>
  )
}
