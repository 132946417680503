import axios from 'axios'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'

import { UpdateEventNotificationContainer } from '../Container/UpdateEventNotification.container'
import { CreateEventNotificationContainer } from '../Container/CreateEventNotification.container'

interface EventList {
  id: number
  title: string
  startDay: string
  endDay: string
}

interface Event {
  id: number
  movie_id: number
  content: string
  expiration: string
}
// interface Schedule {
//   id: number
//   movie_id: number
//   start_day: string
//   end_day: string
//   start_hour: string
//   start_minutes: string
//   end_hour: string
//   end_minutes: string
// }
export const EventNotification = () => {
  const [eventList, setEventList] = useState<EventList[]>([])
  // const [schedule, setSchedule] = useState<Schedule[]>([])
  const [notificationEvent, setNotificationEvent] = useState<Event[]>([])
  const [activeEditorId, setActiveEditorId] = useState<number>(0)
  const [activePreviewId, setActivePreviewId] = useState<number>(0)
  const [baseDate, setBaseDate] = useState<string>(dayjs().format('YYYY-MM-DD'))

  useEffect(() => {
    getEventList()
  }, [baseDate])

  const getEventList = () => {
    axios
      .get('get_event_list', {
        params: { date: baseDate },
      })
      .then((response) => {
        setEventList(response.data.event_list)
        // setSchedule(response.data.schedules)
        setNotificationEvent(response.data.event_notifications)
      })
  }

  return (
    <>
      <div className="w-full text-center text-white mt-5 mb-5">
        基準日：
        <input
          type="date"
          className="w-1/6 h-10 rounded-xl border-2 border-yt-atom text-center text-white bg-yt-component cursor-pointer hover:bg-yt-atom hover:text-yt-component"
          value={baseDate}
          onChange={(e) => setBaseDate(e.target.value)}
        />
      </div>
      {eventList.length === 0 && (
        <div className="flex flex-row justify-center text-white mb-5">
          <div className="inline-block w-2/4 bg-yt-atom rounded-xl py-3 px-4  mt-5 text-left shadow-lg">
            &nbsp;
            基準日以降にスケジュール登録されている映画・イベントはありません。
          </div>
        </div>
      )}
      {eventList.length > 0 &&
        eventList.map((event) => (
          <div key={event.id} className="flex flex-row mb-5 justify-center">
            <div className="basis-full sm:basis-3/4 rounded-xl py-2 px-2 text-cinekoya-sub-a font-bo  bg-yt-component border-2 border-yt-atom shadow-lg">
              <b>{event.title}</b>
              &nbsp;
              <span className="text-base text-yt-atom-hover">
                （&nbsp;{dayjs(event.startDay).format('MM/DD')} ~
                {dayjs(event.endDay).format('MM/DD')}&nbsp;）
              </span>
              {!notificationEvent.find(
                (item) => item.movie_id === event.id
              ) && (
                <CreateEventNotificationContainer
                  event={event}
                  activeEditorId={activeEditorId}
                  setActiveEditorId={setActiveEditorId}
                  getEventList={getEventList}
                />
              )}
              {notificationEvent.find((item) => item.movie_id === event.id) &&
                notificationEvent.map((notification) => (
                  <React.Fragment key={notification.id}>
                    {notification.movie_id === event.id ? (
                      <UpdateEventNotificationContainer
                        event={event}
                        notification={notification}
                        activeEditorId={activeEditorId}
                        setActiveEditorId={setActiveEditorId}
                        activePreviewId={activePreviewId}
                        setActivePreviewId={setActivePreviewId}
                        getEventList={getEventList}
                      />
                    ) : null}
                  </React.Fragment>
                ))}
            </div>
          </div>
        ))}
    </>
  )
}
