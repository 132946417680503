import React from 'react'

import { Movie } from '../../types/Movie'
import { HomeHeader } from '../Molecules/HomeHeader'
import { Access } from '../Organism/Top/Presentational/Access'
import { NotificationTop } from '../Organism/Top/Presentational/NotificationTop'
import { BookShelf } from '../Organism/Top/Presentational/BookShelf'
import { CommingSoon } from '../Organism/Top/Presentational/CommingSoon'
import { Fanclub } from '../Organism/Top/Presentational/Fanclub'
import { Food } from '../Organism/Top/Presentational/Food'
import { NonCinemaEvent } from '../Organism/Top/Presentational/NonCinemaEvent'
import { NowShowing } from '../Organism/Top/Presentational/NowShowing'
import { RentalSpace } from '../Organism/Top/Presentational/RentalSpace'
import { SnsIcons } from '../Organism/Top/Presentational/SnsIcons'
import { MailMagazineContainer } from '../Organism/Top/Container/MailMagazine.container'
import { FooterSnsIcon } from '../Molecules/FooterSnsIcon'
import useWindowSize from '../../hooks/useWindowSize'
import { CampaignParams } from '../../types/CampaignPrams'

interface Schedule {
  start_day: string
  end_day: string
}

interface MovieExtended extends Movie {
  startDay: string
  endDay: string
}

interface Props {
  nowShowingMovies: MovieExtended[]
  forcedNowShowingMovies: MovieExtended[]
  schedules: Schedule[]
  commingSoonMovies: MovieExtended[]
  movieCampaigns: CampaignParams[]
  eventCampaigns: CampaignParams[]
  nonCinemaEvents: MovieExtended[]
  notificationTop: string
  isBlankNotificationTop: boolean
}

export const Top = (props: Props) => {
  const { width } = useWindowSize()

  const isMobile = width <= 700 // 768pxをスマホとPCの切り替えポイントとする

  return (
    <div className="flex flex-row justify-center ">
      <div className="curtain-area"></div>
      <div className="basis-full max-w-[960px] ">
        <HomeHeader />
        <div className="flex flex-col md:flex-row mt-4">
          <div className="md:basis-3/4 md:pr-4">
            <NotificationTop
              notificationTop={props.notificationTop}
              isBlankNotificationTop={props.isBlankNotificationTop}
            />

            <NowShowing
              nowShowingMovies={props.nowShowingMovies}
              forcedNowShowingMovies={props.forcedNowShowingMovies}
              movieCampaigns={props.movieCampaigns}
              schedules={props.schedules}
            />
            <CommingSoon
              commingSoonMovies={props.commingSoonMovies}
              schedules={props.schedules}
            />
            <BookShelf />
            <Food />
            <div id="access">
              <Access />
            </div>
          </div>
          <div className="md:basis-1/4 md:pl-3  border-l-[1px] border-cinekoya">
            <div className="grid grid-cols-8">
              <div className="col-span-8 md:col-span-7">
                <NonCinemaEvent
                  nonCinemaEvents={props.nonCinemaEvents}
                  eventCampaigns={props.eventCampaigns}
                />
                <RentalSpace />
                <Fanclub />
                <MailMagazineContainer />
              </div>
              {!isMobile && (
                <div className="col-span-1 pl-0.5">
                  <SnsIcons />
                </div>
              )}

              {isMobile && (
                <div className="col-span-8">
                  <FooterSnsIcon />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
