import DeleteIcon from '@mui/icons-material/Delete'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import TextField from '@mui/material/TextField'
import axios from 'axios'
import dayjs, { Dayjs } from 'dayjs'
import { useState } from 'react'
import React from 'react'

interface dateType {
  id: number
  date: string
}

type Props = {
  isOpendayMode: boolean
  openDays: dateType[]
  closeDays: dateType[]
  setOpenDays: (e: dateType[]) => void
  setCloseDays: (e: dateType[]) => void
}

export const ClosingDateSetting = (props: Props) => {
  const today = dayjs()

  const [newOpenday, setNewOpenday] = useState<Dayjs | null>(dayjs())
  const [newCloseday, setNewCloseday] = useState<Dayjs | null>(dayjs())
  const [validateOpenDay, setValidateOpenDay] = useState<boolean>(false)
  const [validateCloseDay, setValidateCloseDay] = useState<boolean>(false)

  const saveOpenday = () => {
    axios
      .post('common_setting_newOpenday', {
        date: newOpenday?.format('YYYY-MM-DD'),
        today: today.format('YYYY-MM-DD'),
      })
      .then((response) => {
        props.setOpenDays(response.data)
      })
      .catch((error) => console.log(error))
  }

  const saveCloseday = () => {
    axios
      .post('common_setting_newCloseday', {
        date: newCloseday?.format('YYYY-MM-DD'),
        today: today.format('YYYY-MM-DD'),
      })
      .then((response) => {
        props.setCloseDays(response.data)
      })
      .catch((error) => console.log(error))
  }

  const deleteOpenday = (e: number) => {
    axios
      .delete('common_setting_deleteOpenday', {
        params: {
          id: e,
          today: today.format('YYYY-MM-DD'),
        },
      })
      .then((response) => {
        props.setOpenDays(response.data)
      })
      .catch((error) => console.log(error))
  }

  const deleteCloseday = (e: number) => {
    axios
      .delete('common_setting_deleteCloseday', {
        params: {
          id: e,
          today: today.format('YYYY-MM-DD'),
        },
      })
      .then((response) => {
        props.setCloseDays(response.data)
      })
      .catch((error) => console.log(error))
  }

  const validateDuplication = (e: Dayjs | null) => {
    if (props.isOpendayMode) {
      setNewOpenday(e)
    } else if (!props.isOpendayMode) {
      setNewCloseday(e)
    }
    axios
      .get('common_setting_validateDuplication', {
        params: {
          date: e?.format('YYYY-MM-DD'),
          isOpendayMode: props.isOpendayMode,
        },
      })
      .then((response) => {
        const isDuplicated = response.data
        if (isDuplicated && props.isOpendayMode) {
          setValidateOpenDay(true)
          setValidateCloseDay(false)
          return
        }

        if (isDuplicated && !props.isOpendayMode) {
          setValidateOpenDay(false)
          setValidateCloseDay(true)

          return
        }

        setValidateCloseDay(false)
        setValidateOpenDay(false)
      })
      .catch((error) => console.log(error))
  }

  return (
    <>
      <div className="flex flex-row justify-start mb-3">
        <div className="basis-3/4 flex flex-row bg-yt-bg mt-5 px-3 text-white font-body rounded-xl mb-2 items-center justify-center">
          <div className="inline-block text-yt-text-gray">
            <span className="text-cinekoya-sub-a text-opacity-90 font-bold">
              {props.isOpendayMode ? '営業日' : '休館日'}
            </span>
            &nbsp;新規登録&emsp;
          </div>
          <div>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              sx={{
                width: 500,
              }}
            >
              <MobileDatePicker
                label="選択"
                inputFormat="MM/DD"
                value={
                  (props.isOpendayMode && newOpenday) ||
                  (!props.isOpendayMode && newCloseday)
                }
                onChange={validateDuplication}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      input: { width: '70px', background: '#282828' },
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </div>

          <div className="inline-block">
            <button
              className="text-center inline  rounded-xl ml-3 px-2 py-1 text-sm bg-cinekoya-sub-c enabled:hover:bg-opacity-80 cursor-pointer disabled:text-yt-atom-hover disabled:cursor-not-allowed"
              onClick={props.isOpendayMode ? saveOpenday : saveCloseday}
              disabled={
                props.isOpendayMode ? validateOpenDay : validateCloseDay
              }
            >
              保存
            </button>
          </div>
        </div>
      </div>
      {props.isOpendayMode && validateOpenDay && (
        <div className="w-full rounded-xl px-2 py-1 bg-yt-atom">
          選択された日はすでに休館日に登録されているため、営業日登録ができません。登録したい場合は、休館日登録されている同一日を削除して下さい。
        </div>
      )}
      {!props.isOpendayMode && validateCloseDay && (
        <div className="w-full rounded-xl px-2 py-1 bg-yt-atom">
          選択された日はすでに営業日に登録されているため、休館日登録ができません。登録したい場合は、営業日登録されている同一日を削除してください。
        </div>
      )}

      {props.isOpendayMode && (
        <>
          {props.openDays.length !== 0 && (
            <>
              <div className="w-full text-right text-sm text-yt-text-gray pr-3">
                ※&nbsp;本日以降に登録されている営業日が表示されます
              </div>
            </>
          )}
          {props.openDays.map((openday) => (
            <React.Fragment key={openday.id}>
              <div className="mt-2 py-1 px-2 rounded-3xl flex flex-row items-center bg-yt-component">
                <div className="basis-3/4 text-center text-cinekoya-sub-a text-opacity-90 font-bold">
                  {' '}
                  {openday.date}
                </div>
                <div className="basis-1/4 text-right">
                  <DeleteIcon
                    sx={{ cursor: 'pointer' }}
                    onClick={() => deleteOpenday(openday.id)}
                  />
                </div>
              </div>
            </React.Fragment>
          ))}
        </>
      )}
      {!props.isOpendayMode && (
        <>
          {props.closeDays.length !== 0 && (
            <>
              <div className="w-full text-right text-sm text-yt-text-gray pr-3">
                ※&nbsp;本日以降に登録されている休館日が表示されます
              </div>
            </>
          )}
          {props.closeDays.map((closeday) => (
            <React.Fragment key={closeday.id}>
              <div className="mt-2 py-1 px-2 rounded-3xl flex  flex-row items-center bg-yt-component">
                <div className="basis-3/4 text-center text-cinekoya-sub-a text-opacity-90 font-bold">
                  {' '}
                  {closeday.date}
                </div>

                <div className="basis-1/4 text-right">
                  <DeleteIcon
                    sx={{ cursor: 'pointer' }}
                    onClick={() => deleteCloseday(closeday.id)}
                  />
                </div>
              </div>
            </React.Fragment>
          ))}
        </>
      )}
    </>
  )
}
