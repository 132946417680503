export const modalStyle_visitorManagement = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: '800px',
    height: '99vh',
    borderRadius: '1%',
    background: 'rgba(28,28,28,0.9)',
    color: 'white',
  },
  overlay: {
    top: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0,0.85)',
    zIndex: 10,
  },
}
