import axios from 'axios'
import dayjs from 'dayjs'
import { useContext, useEffect, useState } from 'react'
import ja from 'dayjs/locale/ja'
import { ReserveBaseParamsContext } from '../../../hooks/ReserveBaseParamsContext'
import { useNavigate } from 'react-router-dom'
import { Link as Scroll } from 'react-scroll'

dayjs.locale(ja)

interface ArrayDateAttribute {
  date: string
  isClose: boolean
}

interface Props {
  dateAttribute: ArrayDateAttribute
}

export const DateButton = (props: Props) => {
  const navigate = useNavigate()
  const { reserveBaseParams, setReserveBaseParams } = useContext(
    ReserveBaseParamsContext
  )
  const defaultStyle =
    'px-1.5 py-0.5  bg-zinc-50  text-cinekoya hover:text-white  hover:bg-cinekoya-sub-d'

  const selectedStyle = 'px-1.5 py-0.5  bg-cinekoya-sub-a text-white '

  const closedayStyle =
    ' px-1.5 py-0.5  bg-yt-atom text-yt-atom-hover cursor-not-allowed'

  return (
    <Scroll
      to={props.dateAttribute.date}
      smooth={true}
      duration={300}
      offset={-100}
    >
      <div
        className={`${
          props.dateAttribute.date === reserveBaseParams.date
            ? selectedStyle
            : props.dateAttribute.isClose
            ? closedayStyle
            : defaultStyle
        }  shrink-0 sm:flex-1 w-20 md:w-auto mx-0.5 my-2 rounded-lg text-center h-12 block  duration-300 ease-in-out `}
        onClick={() =>
          setReserveBaseParams({
            ...reserveBaseParams,
            date: props.dateAttribute.date,
          })
        }
      >
        {props.dateAttribute.isClose ? (
          <>
            <div>
              <span className="text-sm align-middle font-number">
                {dayjs(props.dateAttribute.date).format('M')}
              </span>
              <span className="text-base align-middle font-number">
                {'/' + dayjs(props.dateAttribute.date).format('D')}
              </span>
              <span className="text-base align-middle">
                {'(' + dayjs(props.dateAttribute.date).format('dd') + ')'}
              </span>
            </div>

            <div className="text-sm text-stone-300">休館日</div>
          </>
        ) : (
          <>
            <span className="text-sm align-middle font-number">
              {dayjs(props.dateAttribute.date).format('M')}
            </span>
            <span className="text-base md:text-lg align-middle font-number">
              {'/' + dayjs(props.dateAttribute.date).format('D')}
            </span>
            <span className="text-base md:text-lg align-middle">
              {'(' + dayjs(props.dateAttribute.date).format('dd') + ')'}
            </span>
          </>
        )}
      </div>
    </Scroll>
  )
}
