import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { StripeCardElementChangeEvent } from '@stripe/stripe-js'
import axios from 'axios'
import { useContext, useEffect, useState } from 'react'
import { ReserveBaseParamsContext } from '../../../../hooks/ReserveBaseParamsContext'
import { cardStyle } from '../../../../style/cardStyle'
import { PayCreditcard } from '../Presentational/PayCreditcard'
import { useNavigate } from 'react-router-dom'

export const PayCreditcardContainer = () => {
  const { reserveBaseParams } = useContext(ReserveBaseParamsContext)
  const navigate = useNavigate()
  const [error, setError] = useState<string | null>(null)
  const [processing, setProcessing] = useState<boolean>(false)
  const [disabled, setDisabled] = useState<boolean>(true)
  const [clientSecret, setClientSecret] = useState('')

  const stripe = useStripe()
  const elements = useElements()

  let ignore = false

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    //useEffect２回実行にあわせてAPI2回通信を防ぐためignore使用
    if (!ignore) {
      const formData = new FormData()
      const reserveBaseParamsJson = JSON.stringify(reserveBaseParams)
      formData.append('params', reserveBaseParamsJson)

      axios.post('payment_stripe', formData).then((response) => {
        setClientSecret(response.data.clientSecret)
      })
    }
    return () => {
      ignore = true
    }
  }, [])

  const confirmReservation = async () => {
    const { start_hour, start_minutes } = reserveBaseParams.movie || {}
    const clientId = clientSecret.split('_secret_')[0]
    const formData = new FormData()
    formData.append('params', JSON.stringify(reserveBaseParams))
    formData.append('clientId', clientId)
    formData.append('start_time', `${start_hour}:${start_minutes}`)

    const response = await axios.post('confirm_payment', formData)
    formData.set('management_id', response.data)
    formData.set(
      'sold_ticket_types',
      JSON.stringify(reserveBaseParams.sold_ticket_types)
    )
    await axios.post('delete_tentative_reservation')
    if (reserveBaseParams.is_cinema) {
      //映画ならば「売上票」への登録も行う
      await axios.post('register_sales_web', formData)
    }
    const hasGeneralTicket =
      reserveBaseParams.generalNum > 0 ? 'has_general' : 'no_general'
    navigate('/reservation/payment_success/' + hasGeneralTicket)
  }

  const handleChange = async (event: StripeCardElementChangeEvent) => {
    setDisabled(event.empty || event.error ? true : false)
    setError(event.error ? event.error.message : '')
  }

  const handleSubmit = async () => {
    setProcessing(true)

    if (!elements || !stripe) {
      return
    }

    const cardElement = elements.getElement(CardElement)

    if (cardElement) {
      const payload = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement,
        },
      })

      if (payload?.error) {
        setError(` ${payload.error.message}`)
        setProcessing(false)
      } else {
        setError(null)
        setProcessing(false)
        confirmReservation()
      }
    }
  }

  return (
    <>
      <PayCreditcard
        error={error}
        processing={processing}
        disabled={disabled}
        handleSubmit={handleSubmit}
      >
        <CardElement
          id="card-element"
          options={cardStyle}
          onChange={handleChange}
        />
      </PayCreditcard>
    </>
  )
}
