import React, { ReactNode } from 'react'
import { ThreeDots } from 'react-loader-spinner'

interface Props {
  onClickHandler: () => void
  isLoading: boolean
  children: ReactNode
  buttonColor: string
  ThreeDotsColor: string
}
export const SubmitButton = (props: Props) => {
  return (
    <div className="flex justify-center text-center mt-2 mb-5 ">
      <button
        className={`w-1/2 rounded-2xl  text-center text-xl py-2 hover:opacity-75 font-bold ${props.buttonColor}`}
        onClick={props.onClickHandler}
      >
        {props.isLoading ? (
          <ThreeDots
            height="30"
            width="70"
            radius="9"
            color={props.ThreeDotsColor}
            ariaLabel="three-dots-loading"
            wrapperStyle={{ justifyContent: 'center' }}
            visible={true}
          />
        ) : (
          props.children
        )}
      </button>
    </div>
  )
}
