import { useContext, useState } from 'react'
import { ImagePreview } from './ImagePreview'
import imageCompression from 'browser-image-compression'
import { UrlContext } from '../../../../hooks/UrlContext'
import { ImageFile } from '../../../../types/ImageFile'
import { PostParamsContext } from '../../../../hooks/postParamsContext'

type Props = {
  imageFile: ImageFile
  setImageFile: (e: ImageFile) => void
  isEditable: boolean
  image: string
}

export const Upload = (props: Props) => {
  const { postParams, setPostParams } = useContext(PostParamsContext)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isFileTypeError, setIsFileTypeError] = useState<boolean>(false)
  const { imageURL } = useContext(UrlContext)

  async function changeFileHandler(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.currentTarget?.files && event.currentTarget.files[0]) {
      const imageFile = event.currentTarget.files[0]

      //browser-image-compressionによる圧縮
      const options = {
        maxSizeMB: 512,
        // maxWidthOrHeight: 500,
        useWebWorker: true,
      }

      if (
        ![
          'image/gif',
          'image/jpeg',
          'image/jpg',
          'image/png',
          'image/bmp',
          'image/svg+xml',
        ].includes(imageFile.type)
      ) {
        //画像ファイルでなければエラー
        setIsFileTypeError(true)
      } else {
        const compressedFile = await imageCompression(imageFile, options)
        props.setImageFile({ file: compressedFile, fileName: imageFile.name })
        //今のところファイル名が英数字でない場合に表示エラーが生じる場合がある。
      }
    }
  }

  return (
    <>
      <div className="flex flex-row mb-3">
        <div className="basis-3/5 font-body text-lg ">
          <span className="text-cinekoya-yellow-2">ポスター画像</span>&emsp;
          <label className="rounded-full px-3  bg-yt-atom hover:bg-yt-atom-hover border-solid border-2 cursor-pointer">
            <input
              className="hidden"
              type="file"
              onChange={changeFileHandler}
            />
            画像を
            {props.isEditable || props.imageFile.file !== null
              ? '変更'
              : '選択'}
          </label>
        </div>
        <div className="basis-2/5 flex justify-end">
          {props.imageFile.file && <ImagePreview file={props.imageFile.file} />}

          {props.isEditable && !props.imageFile.file && (
            <img
              src={imageURL + props.image}
              className="w-1/2 inline-block ring-4 ring-cinekoya-sub-c"
            />
          )}
        </div>
      </div>
    </>
  )
}
