import dayjs from 'dayjs'
import React, { ChangeEvent, useState } from 'react'
import Modal from 'react-modal'
import CloseIcon from '@mui/icons-material/Close'
interface MovieInfo {
  movie_id: number
  is_cinema: boolean
  title: string
}

interface Reservations {
  date: string
  order: number
  movie_id: number
  seats: number
  name: string
  phone: string
  memo: string
}

interface Props {
  isAddModalOpen: boolean
  closeAddModal: () => void
  closeModal: () => void
  date: string
  order: number
  movieInfo: MovieInfo
  handleSubmit: () => void
  reservations: Reservations
  setReservations: (e: Reservations) => void
  isEmptyName: boolean
}
export const AddTelReservation = (props: Props) => {
  const countDown = () => {
    if (props.reservations.seats - 1 < 1) {
      return
    }
    props.setReservations({
      ...props.reservations,
      seats: props.reservations.seats - 1,
    })
  }

  const countUp = () => {
    props.setReservations({
      ...props.reservations,
      seats: props.reservations.seats + 1,
    })
  }

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '100%',
      maxWidth: '700px',
      height: '80vh',
      border: '1px solid #3090A0 ',
      borderRadius: '1%',
      background: 'rgba(28,28,28,0.7)',
      color: 'white',
      fontFamily: 'Roboto, sans-serif',
    },
    overlay: {
      top: 0,
      left: 0,
      backgroundColor: 'rgba(0,0,0,0.85)',
    },
  }

  return (
    <>
      <Modal
        isOpen={props.isAddModalOpen}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
        onRequestClose={props.closeAddModal}
      >
        <div className="flex flex-col items-center text-2xl font-body">
          <div className="w-full text-right pr-2">
            <CloseIcon
              sx={{ cursor: 'pointer' }}
              onClick={props.closeAddModal}
            />
          </div>
          <div className="text-cinekoya-yellow-2 font-bold mb-3">
            電話予約登録
          </div>
          <div>{dayjs(props.date).format('MM/DD(dd)')}</div>

          <div>{props.movieInfo.title}</div>
        </div>
        <div className="w-full text-center mb-3 mt-3">
          <input
            placeholder="氏名（必須）"
            type="text"
            autoComplete="off"
            className="m-auto w-4/5 py-1 px-2  rounded-lg bg-yt-component border-1 border-yt-atom-hover focus:outline-cinekoya-yellow-2"
            value={props.reservations.name}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              props.setReservations({
                ...props.reservations,
                name: e.target.value,
              })
            }
          />
        </div>
        <div className="w-full text-center mb-3">
          <input
            type="tel"
            placeholder="電話番号（空欄可）"
            className="m-auto w-4/5  py-1 px-2  rounded-lg bg-yt-component border-1 border-yt-atom-hover
                focus:outline-cinekoya-yellow-2"
            value={props.reservations.phone}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              props.setReservations({
                ...props.reservations,
                phone: String(e.target.value),
              })
            }
          />
        </div>
        <div className="w-full text-center mb-3">
          <textarea
            placeholder="備考（空欄可）"
            className="m-auto w-4/5 h-[180px] py-1 px-2 rounded-lg bg-yt-component border-1 border-yt-atom-hover
                focus:outline-cinekoya-yellow-2"
            value={props.reservations.memo}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
              props.setReservations({
                ...props.reservations,
                memo: e.target.value,
              })
            }
          />
        </div>
        <div className="flex flex-row h-10 justify-center ">
          <div className="basis-full sm:basis-96   text-center">
            <button
              className="h-16 w-16 rounded-l-2xl bg-cinekoya-yellow-2 hover:opacity-80  text-yt-component font-bold text-3xl"
              onClick={countDown}
            >
              －
            </button>
            &emsp;
            <div className="inline-block h-16 w-36 border-1 border-yt-atom-hover align-bottom rounded-2xl pt-3 text-white bg-yt-component ">
              <span className="align-bottom text-xl font-bold">
                {props.reservations.seats}
              </span>
            </div>
            &emsp;
            <button
              className="h-16 w-16 rounded-r-2xl bg-cinekoya-yellow-2 hover:opacity-80  text-yt-component font-bold text-3xl"
              onClick={countUp}
            >
              +
            </button>
          </div>
        </div>

        {props.isEmptyName && (
          <div className="w-full text-center mt-16 mb-1 text-cinekoya-yellow-1 font-bold text-xl">
            氏名の入力は必須です。
          </div>
        )}

        <div className="w-full text-center mt-5">
          <button
            className="w-2/3 bg-cinekoya-yellow-2 hover:opacity-80 text-yt-component rounded-2xl py-1 text-xl font-bold"
            onClick={props.handleSubmit}
          >
            追加する
          </button>
        </div>
      </Modal>
    </>
  )
}
