import React, { useContext, useEffect, useState } from 'react'
import { EventTemplate } from '../Templates/EventTemplate'
import { CinemaDetail } from '../Organism/CinemaDetail/CinemaDetail'
import { ImagePreviewDetail } from '../Organism/Create/Presentational/ImagePreviewDetail'
import { UrlContext } from '../../hooks/UrlContext'

interface Detail {
  title: string
  detail: string
  image: string
  trailer_url: string
}

export const PreviewCinemaDetailPage = () => {
  const { imageURL } = useContext(UrlContext)
  const [detail, setDetail] = useState<Detail>({} as Detail)
  const [file, setFile] = useState<File | null>(null)

  useEffect(() => {
    fetchPreview()
    console.log(file)
  }, [])

  const fetchPreview = () => {
    const storedParams = localStorage.getItem('preview_cinema_detail_params')
    if (storedParams) {
      setDetail(JSON.parse(storedParams).detail)
      setFile(JSON.parse(storedParams).file)
    }
  }

  const handleUpdate = () => {
    fetchPreview()
  }

  return (
    <>
      <div className="w-full text-right pt-9 px-4 mb-[-45px] md:mb-[-80px] z-60">
        <button
          className="inline-block bg-cinekoya-sub-c px-3 py-1 rounded-xl shadow-md text-white font-bold text-sm md:text-xl hover:bg-opacity-80 cursor-auto"
          onClick={() => handleUpdate()}
        >
          プレビュー更新
        </button>
      </div>
      <EventTemplate>
        <CinemaDetail
          detail={detail}
          schedule={null}
          notification={null}
          is_cinema={true}
        >
          {file && <ImagePreviewDetail file={file} />}

          {!file && detail.image && <img src={imageURL + detail.image} />}

          {!file && !detail.image && null}
        </CinemaDetail>
      </EventTemplate>
    </>
  )
}
