import dayjs from 'dayjs'
import ja from 'dayjs/locale/ja'
import React, { useRef } from 'react'
import ScrollContainer from 'react-indiana-drag-scroll'
import { DateButton } from './DateButton'
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded'

dayjs.locale(ja)

interface ArrayDate {
  date: string
  isClose: boolean
}

type Props = {
  arrayDateAttribute: ArrayDate[]
  closeDays: string[]
  difference: number
}
export const DateBar = (props: Props) => {
  const slider = useRef<Slider>(null)

  const next = () => {
    if (slider?.current) {
      slider.current.slickNext()
    }
  }

  const prev = () => {
    if (slider?.current) {
      slider.current.slickPrev()
    }
  }

  const SlickNext = () => {
    return (
      <div onClick={next} className="h-10">
        <ArrowForwardIosRoundedIcon sx={{ cursor: 'pointer' }} />
      </div>
    )
  }

  const SlickBack = () => {
    return (
      <div onClick={prev} className="h-10">
        <ArrowBackIosRoundedIcon sx={{ cursor: 'pointer' }} />
      </div>
    )
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 3,
  }

  return (
    <>
      {/* スマホ画面の日付バー */}
      <div className="sticky md:hidden bg-white  top-11 overflow-hidden">
        <ScrollContainer>
          <div className="flex flex-row flex-nowrap cursor-pointer title-red  w-max">
            {props.arrayDateAttribute?.map((dateAttribute, index) => (
              <DateButton key={index} dateAttribute={dateAttribute} />
            ))}
          </div>
        </ScrollContainer>
      </div>

      {/* PC画面の日付バー */}
      <div className="hidden md:flex sticky w-full  justify-center bg-white  top-11 overflow-hidden">
        <div
          className="pt-4 z-10 bg-cinekoya rounded-l-xl text-white hover:bg-cinekoya-hover cursor-pointer transition-colors"
          style={{ height: '4.7rem' }}
        >
          <SlickBack />
        </div>
        <div
          style={{ width: ' calc(100% - 50px)' }}
          className="cursor-pointer title-red w-full"
        >
          <Slider ref={slider} {...settings}>
            {props.arrayDateAttribute?.map((dateAttribute, index) => (
              <DateButton key={index} dateAttribute={dateAttribute} />
            ))}
          </Slider>
        </div>
        <div
          className="pt-4 z-10 bg-cinekoya rounded-r-xl text-white hover:bg-cinekoya-hover cursor-pointer transition-colors"
          style={{ height: '4.7rem' }}
        >
          <SlickNext />
        </div>
      </div>

      <div className="w-full text-center"></div>
      {props.closeDays.length !== 0 && (
        <div className="w-full px-2 my-2 mb-3">
          <div className="py-3 pl-2 text-center bg-cinekoya-hover text-stone-200 font-bold rounded-lg text-md md:text-xl font-body border-1  shadow-md">
            <ErrorOutlineRoundedIcon /> &nbsp;
            {props.closeDays.map((closeday, index) => (
              <span key={index} className="align-middle pt-2 ">
                {dayjs(closeday).format('M/D(dd)')}
                {props.closeDays[props.difference - 1] !== closeday && '、'}
              </span>
            ))}
            <span className="align-middle">&nbsp;は休館日です</span>
          </div>
        </div>
      )}
    </>
  )
}
