import React, { Children, ReactNode } from 'react'
import Tooltip from '@mui/material/Tooltip'

interface Props {
  onClick: () => void
  children?: ReactNode
  isPreDelete: boolean
}

export const DeleteScheduleButton = (props: Props) => {
  return (
    <div className="text-center mt-4 mb-4 ">
      <Tooltip title={'作品情報は削除されません'} placement="bottom-end">
        <span>
          <button
            className={`${
              props.isPreDelete
                ? 'text-stone-300 hover:text-white'
                : 'text-cinekoya-yellow-2 border-1 border-cinekoya-yellow-2'
            } bg-yt-component  hover:bg-yt-atom-hover    w-64 rounded-full py-1 shadow-md`}
            onClick={props.onClick}
          >
            {props.children}
          </button>
        </span>
      </Tooltip>
    </div>
  )
}
