import dayjs from 'dayjs'

export const DefaultReserveBaseParams = {
  date: dayjs().format('YYYY-MM-DD'),
  order: -1,
  seat: 1,
  breakdown: '',
  fanclub_code: '',
  members_code: '',
  price: 0,
  movie_id: -1,
  name: '',
  kana: '',
  email: '',
  phone: '',
  fanclubNum: 0,
  membersNum: 0,
  generalNum: 0,
  sold_ticket_types: [],
  is_cinema: true,
  movie: null,
}
