import React from 'react'
import { useContext } from 'react'
import { ReserveBaseParamsContext } from '../../../../hooks/ReserveBaseParamsContext'
import { InputBoxRounded } from '../../../Atoms/InputBoxRounded'
import { NextStepButton } from '../../../Atoms/NextStepButton'
import { PrevStepButton } from '../../../Atoms/PrevStepButton'
import { useNavigate } from 'react-router-dom'
import { InputMembersCode } from './InputMembersCode'

interface MembersCode {
  index: number
  code: string
}

interface ErrorPersonalInfo {
  emailConfirmation: boolean
  empty: boolean
  emailCharacter: boolean
}

interface Props {
  arrayFanclubCode: MembersCode[]
  setArrayFanclubCode: (e: MembersCode[]) => void
  arrayMembersCode: MembersCode[]
  setArrayMembersCode: (e: MembersCode[]) => void
  errorFanclubCode: boolean
  errorMembersCode: boolean
  errorDigit: boolean
  errorPersonalInfo: ErrorPersonalInfo
  emailConfirmation: string
  setEmailConfirmation: (e: string) => void
  handleSubmit: () => void
}
export const PersonalInfo = (props: Props) => {
  const navigate = useNavigate()
  const { reserveBaseParams, setReserveBaseParams } = useContext(
    ReserveBaseParamsContext
  )

  const inputStyle =
    'h-8 md:h-10 pl-3 basis-52 sm:basis-80 text-sm sm:text-md rounded-r-2xl border-1 border-yt-atom-hover bg-stone-100 hover:bg-stone-50 text-base text-yt-text-gray align-middle focus:outline-none focus:ring-1 focus:ring-cinekoya-hover focus:border-transparent'

  const handleBackButton = () => {
    if (
      !reserveBaseParams.is_cinema &&
      !reserveBaseParams.breakdown.includes('/')
    ) {
      navigate('../reservation/select_seat')
      return
      //非映画かつ券種が一種類の場合はarrange_ticketページはskipする。「/」の文字が1つしかない場合は券種が一種類ということ。
    }
    navigate('../reservation/arrange_ticket')
  }

  return (
    <>
      <div className="w-full text-center text-cinekoya font-body text-xl mt-10 font-bold">
        個人情報入力
      </div>
      <InputBoxRounded label={'お名前'} mt={2} bgColor={'cinekoya-sub-b'}>
        <input
          className={inputStyle}
          placeholder="お名前"
          maxLength={50}
          value={reserveBaseParams.name}
          autoComplete="name"
          onChange={(e) => {
            setReserveBaseParams({
              ...reserveBaseParams,
              name: e.target.value,
            })
          }}
        />
      </InputBoxRounded>

      <InputBoxRounded label={'フリガナ'} mt={3} bgColor={'cinekoya-sub-b'}>
        <input
          className={inputStyle}
          placeholder="フリガナ"
          maxLength={50}
          value={reserveBaseParams.kana}
          onChange={(e) =>
            setReserveBaseParams({
              ...reserveBaseParams,
              kana: e.target.value,
            })
          }
        />
      </InputBoxRounded>

      <InputBoxRounded label={'電話番号'} mt={3} bgColor={'cinekoya-sub-b'}>
        <input
          className={inputStyle}
          maxLength={11}
          placeholder="電話番号"
          value={reserveBaseParams.phone}
          onChange={(e) =>
            setReserveBaseParams({
              ...reserveBaseParams,
              phone: e.target.value,
            })
          }
        />
      </InputBoxRounded>

      <InputBoxRounded label={'Eメール'} mt={3} bgColor={'cinekoya-sub-b'}>
        <input
          className={inputStyle}
          maxLength={50}
          placeholder="メールアドレス"
          value={reserveBaseParams.email}
          onChange={(e) =>
            setReserveBaseParams({
              ...reserveBaseParams,
              email: e.target.value,
            })
          }
        />
      </InputBoxRounded>

      <InputBoxRounded
        label={'Eメール (確認）'}
        mt={3}
        bgColor={'cinekoya-sub-b'}
      >
        <input
          className={inputStyle}
          maxLength={50}
          placeholder="メールアドレス（確認）"
          value={props.emailConfirmation}
          onChange={(e) => props.setEmailConfirmation(e.target.value)}
        />{' '}
      </InputBoxRounded>

      <InputMembersCode
        arrayFanclubCode={props.arrayFanclubCode}
        setArrayFanclubCode={props.setArrayFanclubCode}
        arrayMembersCode={props.arrayMembersCode}
        setArrayMembersCode={props.setArrayMembersCode}
        errorFanclubCode={props.errorFanclubCode}
        errorMembersCode={props.errorMembersCode}
        errorDigit={props.errorDigit}
      />

      {props.errorPersonalInfo.empty && (
        <div className="w-full h-9 mt-3 text-center text-cinekoya-sub-a font-body font-bold text-xl">
          未入力の項目があります
        </div>
      )}
      {props.errorPersonalInfo.emailCharacter ? (
        <div className="w-full h-9 mt-3 text-center text-cinekoya-sub-a font-body font-bold text-xl">
          Eメールが適切な形式ではありません。
        </div>
      ) : null}
      {props.errorPersonalInfo.emailConfirmation ? (
        <div className="w-full h-9 mt-3 text-center text-cinekoya-sub-a font-body font-bold text-xl">
          Eメールが一致していません
        </div>
      ) : null}

      <div className="w-full text-center mb-3 mt-5">
        <NextStepButton
          onClick={props.handleSubmit}
          bgColor={'cinekoya'}
          disabled={false}
        >
          次へ
        </NextStepButton>
      </div>
      <div className="w-full text-center pb-11">
        <PrevStepButton onClick={handleBackButton}>戻る</PrevStepButton>
      </div>
    </>
  )
}
