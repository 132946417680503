import React from 'react'
import MDEditor from '@uiw/react-md-editor'
interface Props {
  notificationTop: string
  isBlankNotificationTop: boolean
}
export const NotificationTop = (props: Props) => {
  return (
    <>
      {!props.isBlankNotificationTop && (
        <>
          <div className=" flex flex-col font-hp text-cinekoya mb-3">
            <div className="text-base">【シネコヤからのお知らせ】</div>

            <div className="mt-3 pl-2 text-lg">
              <MDEditor.Markdown
                source={props.notificationTop}
                style={{
                  backgroundColor: 'transparent',
                  color: '#A94337',
                  whiteSpace: 'pre-wrap',
                }}
              />
            </div>
          </div>
        </>
      )}
    </>
  )
}
