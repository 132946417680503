import React from 'react'

interface Props {
  label: string
  unitPrice: number
  num: number
  totalPrice: number
}

export const RowBreakdown = (props: Props) => {
  return (
    <div className="flex flex-row justify-center md:text-lg py-1">
      <div className="basis-3/4 text-center ">
        {props.label}&nbsp;¥&nbsp;{props.unitPrice.toLocaleString()}&nbsp;×
        <span className="  text-cinekoya ">{props.num}枚</span>
      </div>

      <div className="basis-1/4 text-center text-cinekoya font-bold">
        ¥&nbsp;{props.totalPrice.toLocaleString()}
      </div>
    </div>
  )
}
