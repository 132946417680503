import React, { ChangeEvent, useContext, useState } from 'react'
import Modal from 'react-modal'
import WarningRoundedIcon from '@mui/icons-material/WarningRounded'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { ReflectMovieinfo } from './ReflectMovieinfo'
import { InputStartDay } from './InputStartDay'
import { InputEndDay } from './InputEndDay'
import { InputOrder } from './InputOrder'
import FmdBadRoundedIcon from '@mui/icons-material/FmdBadRounded'
import { useSaveDisabled } from '../../../../hooks/Create/useSaveDisabled'
import { PostParamsContext } from '../../../../hooks/postParamsContext'
import { modalStyle_create } from '../../../../style/modalStyle_create'
import { ImageFile } from '../../../../types/ImageFile'
import { Post } from '../../../../types/Post.'
import { DeleteSchedule } from '../Container/DeleteSchedule'
import { SaveSchedule } from '../Container/SaveSchedule'
import { UpdateSchedule } from '../Container/UpdateSchedule'
import { SetLimit } from './SetLimit'
import { ScreeningTime } from './ScreeningTime'
import { AlternativeName } from './AlternativeName'
import { UrlContext } from '../../../../hooks/UrlContext'
import { EditEvent } from './EditEvent'
import EditIcon from '@mui/icons-material/Edit'
import FmdBadIcon from '@mui/icons-material/FmdBad'
// import { DisplayCinemaDetailPreview } from './DisplayCinemaDetailPreview'
// import { useSetPreviewDetail } from '../../../../hooks/useSetPreviewDetail'
import { ButtonBorderBlue } from '../../../Atoms/ButtonBorderBlue'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'

interface Props {
  modalIsOpen: boolean
  closeModal: () => void
  selectedCinemaSchedule: Post | null
  selectedOrder: number
  cntReservation: number
  isReflectMovieinfo: boolean
  setIsReflectMovieinfo: (e: boolean) => void
  imageFile: ImageFile
  setImageFile: (e: ImageFile) => void
  getLatest: () => void
  schedules: Post[]
  movieList: Post[]
}

export const Modal_Create = (props: Props) => {
  const [isEditableReflected, setIsEditableReflected] = useState<boolean>(false)
  const { postParams, setPostParams } = useContext(PostParamsContext)
  const { imageURL } = useContext(UrlContext)

  // const previewDetail = useSetPreviewDetail({ imageFile: props.imageFile })

  const { saveDisabled, isDuplicatedTitle } = useSaveDisabled({
    file: props.imageFile.file,
    isReflectMovieinfo: props.isReflectMovieinfo,
    selectedCinemaSchedule: props.selectedCinemaSchedule,
    movieList: props.movieList,
  })

  const openPreview = () => {
    const params = { detail: postParams, file: props.imageFile.file }
    localStorage.setItem('preview_cinema_detail_params', JSON.stringify(params))
    window.open('/dashboard/preview_cinema_detail', '_blank')
    //ストレージの削除はcloseModal上に記述
  }

  return (
    <Modal
      isOpen={props.modalIsOpen}
      style={modalStyle_create}
      contentLabel="Example Modal"
      ariaHideApp={false}
    >
      <div className="flex flex-row">
        <div className="basis-1/2 text- text-xl text-cinekoya-sub-a mb-3">
          スケジュール登録
        </div>
        <div className="basis-1/2 text-right">
          <HighlightOffIcon
            fontSize="large"
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              props.closeModal()
              setIsEditableReflected(false)
            }}
          />
        </div>
      </div>
      {props.selectedCinemaSchedule && props.cntReservation > 0 && (
        <div className="flex justify-center mb-2">
          <div className="w-full bg-cinekoya-hover rounded-2xl px-2 py-2">
            <WarningRoundedIcon />
            <span className=" align-middle">
              この作品には本日以降すでに予約が入っています。編集・非公開化・削除をする場合は十分に注意して下さい。
            </span>
          </div>
        </div>
      )}
      <ReflectMovieinfo
        setIsReflectMovieinfo={props.setIsReflectMovieinfo}
        setIsEditableReflected={setIsEditableReflected}
        movieList={props.movieList}
      />

      {isEditableReflected && (
        <div className="w-full flex text-cinekoya-yellow-1 font-bold  text-lg mt-3 mb-3">
          <FmdBadIcon fontSize="small" />
          <span className="">
            &nbsp;この{postParams.is_cinema ? '作品' : 'イベント'}
            の内容変更は、この{postParams.is_cinema ? '作品' : 'イベント'}
            を利用する他のスケジュールにも影響します
          </span>
        </div>
      )}
      {!isEditableReflected && postParams.cinema_id !== -1 && (
        <>
          <div className="grid grid-cols-6 grid-rows-2 border-2 border-yt-atom-hover bg-yt-atom bg-opacity-10 rounded-xl mt-4 mb-3">
            <div className="col-span-1 row-span-2 py-2 pl-2">
              <img src={imageURL + postParams.image} width={70} />
            </div>

            <div className="col-span-5 row-span-1 text-xl py-3 pl-1 font-title">
              {postParams.title}
            </div>
            <div className="col-span-5 row-span-1 text-right pr-2 pb-2">
              <div
                className="w-full inline-block py-2 px-2 rounded-xl bg-cinekoya-sub-c hover:bg-cinekoya-sub-d  cursor-pointer ext-right shadow-md text-left text-stone-300 hover:text-white"
                onClick={() => setIsEditableReflected(true)}
              >
                <EditIcon />
                &nbsp;
                <span className="align-middle text-white">編集する</span>
                <br />
                &emsp;
                <span className="text-sm ">
                  ※内容の変更は、この
                  {postParams.is_cinema ? '作品' : 'イベント'}
                  の他のスケジュールにも影響します
                </span>
              </div>
            </div>
          </div>
        </>
      )}
      {(postParams.cinema_id === -1 ||
        (postParams.cinema_id !== -1 && isEditableReflected)) && (
        <>
          <EditEvent
            imageFile={props.imageFile}
            setImageFile={props.setImageFile}
            selectedCinemaSchedule={props.selectedCinemaSchedule}
            isReflectMovieinfo={props.isReflectMovieinfo}
          />
          <hr
            className="mb-5 mt-3 bg-"
            style={{ height: 5, backgroundColor: '#00B8CC', opacity: '80%' }}
          />
        </>
      )}
      <div className="text-right mb-2">
        <ButtonBorderBlue onClick={openPreview}>
          作品情報プレビュー
          <OpenInNewIcon />
        </ButtonBorderBlue>
      </div>
      {/* <DisplayCinemaDetailPreview
        imageFile={props.imageFile}
        detail={previewDetail}
      /> */}
      <AlternativeName schedules={props.schedules} />

      <div className=" font-body text-lg mb-3 text-cinekoya-yellow-2">
        上映期間
      </div>
      <div className="flex text-center items-center font-body mb-5">
        <InputStartDay
          selectedOrder={props.selectedOrder}
          selectedCinemaSchedule={props.selectedCinemaSchedule}
        />
        <div className="inline-block m-2">&nbsp;～&nbsp;</div>
        <InputEndDay
          selectedOrder={props.selectedOrder}
          selectedCinemaSchedule={props.selectedCinemaSchedule}
        />
      </div>

      <div className="w-full mb-5">
        <InputOrder selectedOrder={props.selectedOrder} />
      </div>

      <ScreeningTime selectedCinemaSchedule={props.selectedCinemaSchedule} />

      <hr className="mt-3 mb-5" />
      <div className=" mt-3">
        <SetLimit />
      </div>

      {postParams.seat_limits !== null && (
        <>
          <div className="mt-1 mb-3 text-center overflow-hidden">
            <input
              type="number"
              min="0"
              className=" w-32  mt-1 ml-1 text-center text-xl font-bold text-cinekoya border-solid  border-1 border-yt-atom focus:outline-none focus:ring-2 focus:ring-yt-atom-hover focus:border-transparent "
              value={String(postParams.seat_limits)}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setPostParams({
                  ...postParams,
                  seat_limits: Number(e.target.value),
                })
              }}
            />
            &nbsp;
            <span className="font-body  text-lg">席</span>
          </div>
          <div className="mt-2 flex justify-center">
            <div className="w-4/5 py-1 px-2 rounded-xl bg-yt-atom text-yt-text-gray text-base">
              コマ単位で予約数上限を設定しているコマにはこの予約数上限は適用されず、コマ単位で設定している上限が適用されます。適用の優先順位は「コマ設定&nbsp;＞作品設定&nbsp;＞共通設定」となります。
            </div>
          </div>
        </>
      )}

      <hr className="mt-2" />

      {saveDisabled && !isDuplicatedTitle && (
        <div className="flex justify-center text-center mt-2">
          <div className="w-3/4 rounded-3xl bg-cinekoya-hover px-2 py-1">
            <div className="text-xl font-bold mb-1">
              <FmdBadRoundedIcon />
              以下の項目が未入力です
            </div>
            <div className="px-4">
              {postParams.title === '' && 'タイトル　'}
              {postParams.detail === '' && '作品詳細　'}
              {!postParams.is_cinema &&
                Boolean(postParams.non_cinema_reservable) &&
                postParams.event_price_1 === 0 &&
                '料金　'}
              {!props.isReflectMovieinfo &&
                !props.selectedCinemaSchedule &&
                props.imageFile.file === null &&
                'ポスター画像　'}
              {Boolean(postParams.is_cinema) &&
                postParams.trailer_url === '' &&
                '予告編URL　'}
              {postParams.order === 0 && 'コマ順序　'}
              {!postParams.time_unsettled &&
                postParams.start_hour === '' &&
                '開始時間（時）　'}
              {!postParams.time_unsettled &&
                postParams.start_minutes === '' &&
                '開始時間（分）　'}
              {!postParams.time_unsettled &&
                postParams.end_hour === '' &&
                '終了時間（時）　'}
              {!postParams.time_unsettled &&
                postParams.end_minutes === '' &&
                '終了時間（分）　'}
            </div>
          </div>
        </div>
      )}
      {saveDisabled && isDuplicatedTitle && (
        <div className="flex justify-center text-center mt-2">
          <div className="w-3/4 rounded-3xl bg-cinekoya-hover px-2 py-1">
            <>
              <div className="text-xl font-bold mb-1">
                <FmdBadRoundedIcon />
                他の作品とタイトルが重複しています。
              </div>
              <div className="px-4 text-sm text-left pb-1">
                作品情重複による混乱を避けるため、登録済の作品と同一作品であれば「登録済の作品を利用する」から情報を反映させて下さい。再上映に伴う告知を入れたい場合は「お知らせ」を利用して下さい。
              </div>
            </>
          </div>
        </div>
      )}

      {postParams.cinema_id === -1 || postParams.id === -1 ? (
        <SaveSchedule
          imageFile={props.imageFile}
          saveDisabled={saveDisabled}
          closeModal={props.closeModal}
          getLatest={props.getLatest}
        />
      ) : (
        <>
          <UpdateSchedule
            imageFile={props.imageFile}
            saveDisabled={saveDisabled}
            closeModal={props.closeModal}
            getLatest={props.getLatest}
            cntReservation={props.cntReservation}
            selectedCinemaSchedule={props.selectedCinemaSchedule}
          />

          <hr className="mt-2" />
          <DeleteSchedule
            saveDisabled={saveDisabled}
            closeModal={props.closeModal}
            getLatest={props.getLatest}
            cntReservation={props.cntReservation}
            selectedCinemaSchedule={props.selectedCinemaSchedule}
          />
        </>
      )}
    </Modal>
  )
}
