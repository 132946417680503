import { useState } from 'react'
import { DiscountInfo } from '../../../../types/Reservation/DiscountInfo'
import { Modal_ResetRepeatDiscount } from './Modal_ResetRepeatDiscount'
interface Props {
  amount: number
  setAmount: (e: number) => void
  unit: string
  countRemainder: number
  countMinLimit: number
  height: number
  widthInput: number
  paddingTop: number
  prevGeneralNumber: number
  setPrevGeneralNumber: (e: number) => void
  discountInfo: DiscountInfo
  setDiscountInfo: (e: DiscountInfo) => void
  setSwitchChecked: (e: boolean) => void
}
export const CountDiscount = (props: Props) => {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false)

  const countDown = () => {
    if (props.discountInfo.repeat_general_number > 0) {
      setModalIsOpen(true)
      return
    }
    if (props.amount - 1 < props.countMinLimit) {
      props.setAmount(props.amount)
    } else {
      props.setAmount(props.amount - 1)
    }
  }

  const countUp = () => {
    if (props.discountInfo.repeat_general_number > 0) {
      setModalIsOpen(true)
      return
    }

    if (props.countRemainder <= 0) {
      props.setAmount(props.amount)
    } else {
      props.setAmount(props.amount + 1)
    }
  }

  return (
    <>
      <div className="flex flex-row h-10 justify-center ">
        <div className="basis-full sm:basis-96   text-center">
          <button
            className={`h-${props.height} w-${16}  pb-1
          } rounded-l-2xl bg-cinekoya hover:bg-cinekoya-hover  text-white font-bold text-3xl`}
            onClick={countDown}
          >
            －
          </button>
          &emsp;
          <div
            className={`inline-block h-${props.height} w-${props.widthInput} border-1 border-yt-atom-hover align-bottom rounded-2xl pt-${props.paddingTop} `}
          >
            <span className="align-bottom text-xl font-bold">
              {props.amount}
              {props.unit}
            </span>
          </div>
          &emsp;
          <button
            className={`h-${
              props.height
            } w-${16} pb-1 rounded-r-2xl bg-cinekoya hover:bg-cinekoya-hover text-white font-bold text-3xl`}
            onClick={countUp}
          >
            +
          </button>
        </div>
      </div>

      <Modal_ResetRepeatDiscount
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        setPrevGeneralNumber={props.setPrevGeneralNumber}
        setDiscountInfo={props.setDiscountInfo}
        setSwitchChecked={props.setSwitchChecked}
      />
    </>
  )
}
