import React from 'react'
import Modal from 'react-modal'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import IconButton from '@mui/material/IconButton'

import CloseIcon from '@mui/icons-material/Close'
import SouthRoundedIcon from '@mui/icons-material/SouthRounded'
import { CounterVisitedNumber } from './CounterVisitedNumber'
import { CustomerDetail } from './CustomerDetail'
import { SubmitButton } from './SubmitButton'
import { NotEditableVisitedNumber } from './NotEditableVisitedNumber'
import { ReservationKindsBlock } from './ReservationKindsBlock'
import { Reservation } from '../../../../types/Reservation/Reservation'
import { modalStyle_visitorManagement } from '../../../../style/modalStylev_visitorManagement'
import { CustomerDetailWeb } from './CustomerDetailWeb'

interface Props {
  areaStyle: string
  buttonStyle: string
  isModalOpen: boolean
  setIsModalOpen: (e: boolean) => void
  totalReservedSeats: number
  totalVisited: number
  visitedReservations: Reservation[]
  notVisitedReservations: Reservation[]
  selectedReservationId: number | null
  setSelectedReservationId: (e: number | null) => void
  visitedNum: number
  setVisitedNum: (e: number) => void
  registerHasVisited: () => void
  isLoading: boolean
  isEditableVisitedNum: boolean
  setIsEditableVisitedNum: (e: boolean) => void
  updateVisitedNum: () => void
  cancelHasVisited: () => void
}

export const CountWebReservedVisitor = (props: Props) => {
  const openModal = () => {
    props.setIsModalOpen(true)
  }

  const closeModal = () => {
    props.setIsModalOpen(false)
    props.setSelectedReservationId(null)
  }

  return (
    <>
      <ReservationKindsBlock
        areaStyle={props.areaStyle}
        buttonStyle={props.buttonStyle}
        openModal={openModal}
        totalVisited={props.totalVisited}
        totalReservedSeats={props.totalReservedSeats}
        borderColor="border-cinekoya-sub-c"
        buttonColor="bg-cinekoya-sub-c text-white"
      >
        Web予約
      </ReservationKindsBlock>

      <Modal
        isOpen={props.isModalOpen}
        onRequestClose={closeModal}
        style={modalStyle_visitorManagement}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <div className="flex flex-row mb-2">
          <div className="basis-1/3" />{' '}
          <div className="basis-1/3 text-2xl font-body font-bold text-cinekoya-sub-a text-center">
            Web予約
          </div>
          <div className="basis-1/3 text-right pr-3 text-yt-atom hover:text-yt-atom-hover ">
            <CloseIcon
              fontSize="large"
              sx={{ cursor: 'pointer' }}
              onClick={closeModal}
            />
          </div>
        </div>

        <hr />
        <div className=" mt-3">
          {props.notVisitedReservations.length > 0 &&
            props.notVisitedReservations.map((reservation) => (
              <React.Fragment key={reservation.id}>
                <div className="flex flex-row bg-yt-component rounded-xl px-1 py-2 cursor-pointer text-lg items-center mb-3 border-4 border-cinekoya-sub-c">
                  <div className="basis-1/3 pl-2 ">{reservation.name}様</div>
                  <div className="basis-1/3">{reservation.seats}席</div>
                  <div className="basis-1/3 text-right pr-3 text-cinekoya-sub-a">
                    <IconButton>
                      {props.selectedReservationId === reservation.id ? (
                        <ExpandLessIcon
                          className="text-cinekoya-sub-a"
                          fontSize="large"
                          onClick={() => {
                            props.setSelectedReservationId(null)
                            props.setVisitedNum(0)
                          }}
                        />
                      ) : (
                        <ExpandMoreIcon
                          className="text-cinekoya-sub-a"
                          fontSize="large"
                          onClick={() => {
                            props.setSelectedReservationId(reservation.id)
                            props.setVisitedNum(
                              reservation.has_visited
                                ? reservation.visited_number
                                : reservation.seats
                            )
                          }}
                        />
                      )}
                    </IconButton>
                  </div>
                </div>
                {props.selectedReservationId === reservation.id && (
                  <div className="pb-5">
                    <CustomerDetailWeb reservation={reservation} />
                    {!reservation.has_visited && (
                      <div className="w-full mt-5 mb-3 text-xl text-center font-body">
                        来店済にしますか？
                      </div>
                    )}
                    <CounterVisitedNumber
                      visitedNum={props.visitedNum}
                      setVisitedNum={props.setVisitedNum}
                      reservation={reservation}
                      borderColor="border-cinekoya-sub-a"
                    />
                    {!reservation.has_visited && (
                      <SubmitButton
                        onClickHandler={props.registerHasVisited}
                        isLoading={props.isLoading}
                        buttonColor="bg-cinekoya-sub-a text-yt-component"
                        ThreeDotsColor="#282828"
                      >
                        来店済にする
                      </SubmitButton>
                    )}
                  </div>
                )}
              </React.Fragment>
            ))}
        </div>

        <hr className="mt-5" />

        <div className="w-full text-center text-2xl font-body font-bold my-3">
          <SouthRoundedIcon fontSize="large" sx={{ color: '#3090A0' }} />
          &nbsp;<span className="align-bottom">来店済</span>
        </div>

        {props.visitedReservations.length > 0 &&
          props.visitedReservations.map((reservation) => (
            <React.Fragment key={reservation.id}>
              <>
                <div className="flex flex-row bg-yt-component rounded-xl px-1 py-2 cursor-pointer text-lg items-center mb-3">
                  <div className="basis-1/3 pl-2 ">{reservation.name}様</div>
                  <div className="basis-1/3">
                    {reservation.visited_number}/{reservation.seats}席
                  </div>
                  <div className="basis-1/3 text-right pr-3 text-cinekoya-sub-c">
                    <IconButton>
                      {props.selectedReservationId === reservation.id ? (
                        <ExpandLessIcon
                          className="text-cinekoya-sub-a"
                          fontSize="large"
                          onClick={() => {
                            props.setSelectedReservationId(null)
                            props.setVisitedNum(0)
                            props.setIsEditableVisitedNum(false)
                          }}
                        />
                      ) : (
                        <ExpandMoreIcon
                          className="text-cinekoya-sub-a"
                          fontSize="large"
                          onClick={() => {
                            props.setSelectedReservationId(reservation.id)
                            props.setVisitedNum(
                              reservation.has_visited
                                ? reservation.visited_number
                                : reservation.seats
                            )
                          }}
                        />
                      )}
                    </IconButton>
                  </div>
                </div>
                {props.selectedReservationId === reservation.id && (
                  <>
                    <CustomerDetail reservation={reservation} />

                    {!props.isEditableVisitedNum && (
                      <NotEditableVisitedNumber
                        visitedNum={props.visitedNum}
                        reservation={reservation}
                        setIsEditableVisitedNum={props.setIsEditableVisitedNum}
                        color="#00B8CC"
                      />
                    )}

                    {props.isEditableVisitedNum && (
                      <CounterVisitedNumber
                        visitedNum={props.visitedNum}
                        setVisitedNum={props.setVisitedNum}
                        reservation={reservation}
                        borderColor="border-cinekoya-sub-a"
                      />
                    )}

                    {props.isEditableVisitedNum && (
                      <>
                        <SubmitButton
                          onClickHandler={props.updateVisitedNum}
                          isLoading={props.isLoading}
                          buttonColor="bg-cinekoya-sub-a text-yt-component"
                          ThreeDotsColor="#282828"
                        >
                          来店済人数を修正する
                        </SubmitButton>

                        <div className="w-full mt-3 mb-[100px] text-center">
                          <button
                            className="w-1/2 rounded-2xl bg-yt-component text-yt-atom text-center py-2 hover:opacity-75 font-bold"
                            onClick={() => props.setIsEditableVisitedNum(false)}
                          >
                            キャンセル
                          </button>
                        </div>
                      </>
                    )}
                    {!props.isEditableVisitedNum && (
                      <div className="pb-5">
                        <SubmitButton
                          onClickHandler={props.cancelHasVisited}
                          isLoading={props.isLoading}
                          buttonColor="bg-yt-atom text-yt-text-gray"
                          ThreeDotsColor="#00B8CC"
                        >
                          来店済を取り消す
                        </SubmitButton>
                      </div>
                    )}
                  </>
                )}
              </>
            </React.Fragment>
          ))}
      </Modal>
    </>
  )
}
