import React, { useContext } from 'react'
import { CampaignParams } from '../../types/CampaignPrams'
import dayjs from 'dayjs'
import { UrlContext } from '../../hooks/UrlContext'
import { Movie } from '../../types/Movie'
import KeyboardDoubleArrowRightRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowRightRounded'

interface Props {
  event_id: number
  campaign_id: number
  nonCinemaEvents: Movie[]
  campaigns: CampaignParams[]
}

export const OtherEventList = (props: Props) => {
  const { imageURL } = useContext(UrlContext)
  const { nonCinemaEvents, campaigns } = props
  return (
    <>
      {(nonCinemaEvents || campaigns) && (
        <div className="flex flex-col items-center">
          <div className="w-full md:w-4/5">
            <div className="title-red-2 text-center mt-5">
              <div>開催予定のイベント</div>
            </div>

            <div
              className={`mb-3
            ${'grid grid-cols-1 md:grid-cols-4 gap-x-1 gap-y-3'}`}
            >
              {campaigns?.map((campaign) => (
                <React.Fragment key={campaign.id}>
                  {campaign.id !== props.campaign_id && (
                    <div
                      className={`${
                        campaigns?.length + nonCinemaEvents?.length > 2
                          ? 'col-span-1'
                          : 'col-span-2 md:col-span-2'
                      }`}
                    >
                      <a href={`/campaign/${campaign.id}`}>
                        <div
                          className="relative pb-[70%] md:pb-[140%] w-[100%] h-0 hover:opacity-80 rounded-2xl shadow-md"
                          style={{
                            backgroundImage: `url(${
                              imageURL + encodeURIComponent(campaign.main_image)
                            })`,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                          }}
                        >
                          <div className="absolute w-[100%] h-[35%] md:h-[30%] bottom-0 bg-cinekoya bg-opacity-80 text-white px-2 py-1 rounded-b-2xl">
                            <p className="font-body text-sm text-center text-stone-200">
                              {dayjs(campaign.start_date).format('M/D(dd)')}
                              {campaign.start_date !== campaign.end_date &&
                                `〜${dayjs(campaign.end_date).format(
                                  'M/D(dd)'
                                )}`}
                            </p>
                            <p className="font-title text-base md:text-lg">
                              {' '}
                              {campaign.title}
                            </p>
                          </div>
                        </div>
                      </a>
                    </div>
                  )}
                </React.Fragment>
              ))}

              {nonCinemaEvents?.map((event) => (
                <React.Fragment key={event.id}>
                  {event.id !== props.event_id && (
                    <div
                      className={`${
                        campaigns?.length + nonCinemaEvents?.length > 2
                          ? 'col-span-1'
                          : 'col-span-1 md:col-span-2'
                      }`}
                    >
                      <a href={`/non_cinema/${event.id}`}>
                        <div
                          className="relative pb-[70%] md:pb-[140%] w-[100%] h-0 hover:opacity-80 rounded-2xl shadow-md"
                          style={{
                            backgroundImage: `url(${
                              imageURL + encodeURIComponent(event.image)
                            })`,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                          }}
                        >
                          <div className="absolute w-[100%] h-[35%] md:h-[30%] bottom-0 bg-cinekoya bg-opacity-80 text-white px-2 py-1 rounded-b-2xl">
                            <p className="font-body text-sm text-center text-stone-200">
                              {dayjs(event.start_day).format('M/D(dd)')}
                              {event.start_day !== event.end_day &&
                                `~${dayjs(event.end_day).format('M/D(dd)')}`}
                            </p>
                            <p className="font-title text-base md:text-lg">
                              {event.title}
                            </p>
                          </div>
                        </div>
                      </a>
                    </div>
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>

          <div className="w-full md:w-1/2 text-zinc-200 font-bold shadow-md sm:shadow-md border-4 border-zinc-50 bg-zinc-700 rounded-full text-lg hover:opacity-80 mb-3 mt-5 py-1 ">
            <a
              href="/past_events"
              target="_blank"
              rel="noreferrer"
              className="hover:no-underline"
            >
              <div className="w-full hover:text-white  flex items-center justify-center">
                <div>過去のイベント</div>
                <div>
                  <KeyboardDoubleArrowRightRoundedIcon fontSize="large" />
                </div>
              </div>
            </a>
          </div>
        </div>
      )}
    </>
  )
}
