import React, { ReactNode } from 'react'
import { createContext, useState, useContext } from 'react'

type AuthContextProps = {
  isAuth: boolean
  setIsAuth: (e: boolean) => void
}

type Children = {
  children: React.ReactNode
}
export const AuthContext = React.createContext<AuthContextProps>(
  {} as AuthContextProps
)

export const AuthProvider = (props: Children) => {
  const { children } = props

  const [isAuth, setIsAuth] = useState(false)

  return (
    <AuthContext.Provider value={{ isAuth, setIsAuth }}>
      {children}
    </AuthContext.Provider>
  )
}
