import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Reservation } from '../../../../types/Reservation/Reservation'
import { CountWebReservedVisitor } from '../Presentational/CountWebReservedVisitor'

interface Props {
  areaStyle: string
  buttonStyle: string
  date: string
  order: number
  getSeatsInfo: () => void
}
export const CountWebReservedVisitorContainer = (props: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isEditableVisitedNum, setIsEditableVIsitedNum] =
    useState<boolean>(false)

  const [totalReservedSeats, setTotalReservedSeats] = useState<number>(0)

  const [totalVisited, setTotalVisited] = useState<number>(0)
  const [visitedReservations, setVisitedReservations] = useState<Reservation[]>(
    []
  )
  const [notVisitedReservations, setNotVisitedReservations] = useState<
    Reservation[]
  >([])

  const [visitedNum, setVisitedNum] = useState<number>(0)

  const [selectedReservationId, setSelectedReservationId] = useState<
    number | null
  >(null)

  useEffect(() => {
    getSeats()
  }, [props.date, props.order])

  const getSeats = () => {
    axios
      .get('get_reserved_visitor_web', {
        params: {
          date: props.date,
          order: props.order,
        },
      })
      .then((response) => {
        setTotalReservedSeats(response.data.total_reserved_seats)
        setTotalVisited(response.data.total_visited_number)
        setVisitedReservations(response.data.visited_reservations)
        setNotVisitedReservations(response.data.not_visited_reservations)
      })
  }

  const registerHasVisited = () => {
    setIsLoading(true)
    axios
      .put('register_has_visited_web', {
        reservation_id: selectedReservationId,
        visited_number: visitedNum,
      })
      .then(() => {
        setIsLoading(false)
        setSelectedReservationId(null)
        getSeats()
        props.getSeatsInfo()
      })
  }

  const updateVisitedNum = () => {
    setIsLoading(true)
    axios
      .put('update_visited_number_web', {
        reservation_id: selectedReservationId,
        visited_number: visitedNum,
      })
      .then(() => {
        setIsLoading(false)
        setSelectedReservationId(null)
        getSeats()
        setIsEditableVIsitedNum(false)
        props.getSeatsInfo()
      })
  }

  const cancelHasVisited = () => {
    setIsLoading(true)
    axios
      .put('cancel_has_visited_web', {
        reservation_id: selectedReservationId,
        visited_number: visitedNum,
      })
      .then(() => {
        setIsLoading(false)
        setSelectedReservationId(null)
        getSeats()
        setIsEditableVIsitedNum(false)
        props.getSeatsInfo()
      })
  }

  return (
    <>
      <CountWebReservedVisitor
        areaStyle={props.areaStyle}
        buttonStyle={props.buttonStyle}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        totalReservedSeats={totalReservedSeats}
        totalVisited={totalVisited}
        visitedReservations={visitedReservations}
        notVisitedReservations={notVisitedReservations}
        selectedReservationId={selectedReservationId}
        setSelectedReservationId={setSelectedReservationId}
        visitedNum={visitedNum}
        setVisitedNum={setVisitedNum}
        registerHasVisited={registerHasVisited}
        isLoading={isLoading}
        isEditableVisitedNum={isEditableVisitedNum}
        setIsEditableVisitedNum={setIsEditableVIsitedNum}
        updateVisitedNum={updateVisitedNum}
        cancelHasVisited={cancelHasVisited}
      />
    </>
  )
}
