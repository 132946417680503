import React, { ChangeEvent, useContext, useEffect } from 'react'
import { InputBoxRounded } from '../../../Atoms/InputBoxRounded'
import WarningIcon from '@mui/icons-material/Warning'
import { ReserveBaseParamsContext } from '../../../../hooks/ReserveBaseParamsContext'

interface MembersCode {
  index: number
  code: string
}

interface Props {
  arrayFanclubCode: MembersCode[]
  setArrayFanclubCode: (e: MembersCode[]) => void
  arrayMembersCode: MembersCode[]
  setArrayMembersCode: (e: MembersCode[]) => void
  errorFanclubCode: boolean
  errorMembersCode: boolean
  errorDigit: boolean
}
export const InputMembersCode = (props: Props) => {
  const { reserveBaseParams } = useContext(ReserveBaseParamsContext)

  const inputStyle =
    'h-8 md:h-10 pl-3 basis-52 sm:basis-80 text-sm sm:text-md rounded-r-2xl border-1 border-yt-atom-hover bg-stone-100 hover:bg-stone-50 text-base text-yt-text-gray align-middle focus:outline-none focus:ring-1 focus:ring-cinekoya-hover focus:border-transparent'

  const changeFanclubCodeHandler = (index: number, code: string) => {
    //日本語が含まれる場合は空文字置換
    const filteredCode = code.replace(/[ぁ-んァ-ヶ亜-熙]+/g, '')
    props.setArrayFanclubCode(
      props.arrayFanclubCode.map((fanclubCode) =>
        fanclubCode.index === index
          ? { ...fanclubCode, code: filteredCode }
          : { ...fanclubCode }
      )
    )
  }

  const changeMembersCodeHandler = (index: number, code: string) => {
    const filteredCode = code.replace(/[ぁ-んァ-ヶ亜-熙]+/g, '')

    props.setArrayMembersCode(
      props.arrayMembersCode.map((membersCode) =>
        membersCode.index === index
          ? { ...membersCode, code: filteredCode }
          : { ...membersCode }
      )
    )
  }

  useEffect(() => {
    const arrayFanclubCodeIndex: number[] = []
    const arrayMembersCodeIndex: number[] = []

    for (let i = 0; i < reserveBaseParams.fanclubNum; i++) {
      arrayFanclubCodeIndex.push(i + 1)
    }
    for (let i = 0; i < reserveBaseParams.membersNum; i++) {
      arrayMembersCodeIndex.push(i + 1)
    }

    props.setArrayFanclubCode(
      arrayFanclubCodeIndex.map((fanclubCode) => ({
        index: fanclubCode,
        code: '',
      }))
    )

    props.setArrayMembersCode(
      arrayMembersCodeIndex.map((membersCode) => ({
        index: membersCode,
        code: '',
      }))
    )
  }, [])

  return (
    <>
      {props.arrayFanclubCode && props.arrayFanclubCode.length > 0 && (
        <>
          <div className="flex flex-col text-center  font-body  mt-10 font-bold">
            <div className="text-cinekoya-sub-c text-xl ">
              ファンクラブ会員番号入力
            </div>

            <div className="text-stone-500 text-sm">
              ※&nbsp;ファンクラブ会員{props.arrayFanclubCode.length}
              名様の会員番号をご入力ください。
            </div>
          </div>

          {props.arrayFanclubCode.map((fanclubCode) => (
            <React.Fragment key={fanclubCode.index}>
              <InputBoxRounded
                label={
                  props.arrayFanclubCode.length === 1
                    ? '会員番号'
                    : `お${fanclubCode.index}人目`
                }
                mt={2}
                bgColor={'cinekoya-sub-c'}
              >
                <input
                  type="text"
                  maxLength={4}
                  className={inputStyle}
                  value={fanclubCode.code}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    changeFanclubCodeHandler(fanclubCode.index, e.target.value)
                  }
                />
              </InputBoxRounded>
            </React.Fragment>
          ))}
        </>
      )}

      {props.arrayMembersCode && props.arrayMembersCode.length > 0 && (
        <>
          <div className="flex flex-col text-center  font-body  mt-10 font-bold">
            <div className="text-cinekoya-sub-c text-xl ">
              メンバーズ会員番号入力
            </div>

            <div className="text-stone-500 text-sm">
              ※&nbsp;メンバーズ会員{props.arrayMembersCode.length}
              名様の会員番号をご入力ください。
            </div>
          </div>
          {props.arrayMembersCode.map((membersCode) => (
            <React.Fragment key={membersCode.index}>
              <InputBoxRounded
                label={
                  props.arrayMembersCode.length === 1
                    ? '会員番号'
                    : `お${membersCode.index}人目`
                }
                mt={2}
                bgColor={'cinekoya-sub-c'}
              >
                <input
                  type="text"
                  maxLength={4}
                  value={membersCode.code}
                  className={inputStyle}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    changeMembersCodeHandler(membersCode.index, e.target.value)
                  }
                />
              </InputBoxRounded>
            </React.Fragment>
          ))}
        </>
      )}

      {(props.errorFanclubCode ||
        props.errorMembersCode ||
        props.errorDigit) && (
        <div className="flex  justify-center mt-5">
          <div className="basis-full md:basis-1/3 bg-cinekoya-sub-c text-white rounded-xl px-3 py-2 shadow-md ">
            {props.errorFanclubCode && (
              <div className="text-left">
                <WarningIcon fontSize="small" sx={{ align: 'bottom' }} />
                &nbsp;
                <span className="align-middle">
                  入力された会員番号の中に、ファンクラブ会員ではなくメンバーズ会員の番号が含まれています。
                </span>
              </div>
            )}

            {props.errorMembersCode && (
              <div className="text-left">
                <WarningIcon fontSize="small" sx={{ align: 'bottom' }} />
                &nbsp;
                <span className="align-middle">
                  入力された会員番号の中に、メンバーズ会員ではなくファンクラブ会員の番号が含まれています。
                </span>
              </div>
            )}
            {props.errorDigit && (
              <div className="text-left">
                <WarningIcon fontSize="small" sx={{ align: 'bottom' }} />
                &nbsp;
                <span className="align-middle">
                  会員番号は【英字1字+数字3字】の4桁です
                </span>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  )
}
