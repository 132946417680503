import axios from 'axios'
import EditIcon from '@mui/icons-material/Edit'
import CloseIcon from '@mui/icons-material/Close'
import { ChangeEvent, useState } from 'react'

type Props = {
  capacity: number
  setCapacity: (e: number) => void
}

export const CapacitySetting = (props: Props) => {
  const [isEditable, setIsEditable] = useState<boolean>(false)

  const saveCapacity = () => {
    axios
      .put('update_capacity', {
        capacity: props.capacity,
      })
      .then((response) => {
        props.setCapacity(response.data)
        setIsEditable(false)
      })
      .catch((error) => console.log(error))
  }

  const changeCapacity = (e: ChangeEvent<HTMLInputElement>) => {
    const capacity = Number(e.target.value)

    if (capacity < 1) props.setCapacity(1)
    else {
      props.setCapacity(Number(e.target.value))
    }
  }

  return (
    <>
      <div className="flex flex-row pt-2 text-xl">
        <div className="basis-3/4 text-center">
          {isEditable ? (
            <>
              <input
                type="number"
                className=" w-20  ml-1 text-center  font-bold text-cinekoya border-solid  border-1 border-yt-atom focus:outline-none focus:ring-2 focus:ring-cinekoya-sub-c focus:border-transparent"
                value={props.capacity}
                onChange={changeCapacity}
              />
              <span>&nbsp;席</span>
              <button
                className="text-center inline  rounded-2xl ml-3 px-2 py-1 text-sm bg-yt-atom hover:bg-yt-atom-hover cursor-pointer "
                onClick={saveCapacity}
              >
                保存
              </button>
            </>
          ) : (
            <>
              <span className="font-bold text-cinekoya-sub-a">
                {props.capacity}
              </span>
              &nbsp;席
            </>
          )}
        </div>
        <div className="basis-1/4 text-right pr-3 cursor-pointer">
          {isEditable ? (
            <CloseIcon
              fontSize="large"
              onClick={() => setIsEditable(!isEditable)}
            />
          ) : (
            <EditIcon
              fontSize="large"
              onClick={() => setIsEditable(!isEditable)}
            />
          )}
        </div>
      </div>
    </>
  )
}
