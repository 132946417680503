import React, { useContext, useEffect } from 'react'
import { EventTemplate } from '../Templates/EventTemplate'
import axios from 'axios'
import { UrlContext } from '../../hooks/UrlContext'
import dayjs from 'dayjs'

interface PastEvent {
  id: number
  title: string
  detail: string
  image: string
  start_date: string
  end_date: string
  is_campaign: boolean
}
export const PastEventsPage = () => {
  const { imageURL } = useContext(UrlContext)
  const [pastEvents, setPastEvents] = React.useState<PastEvent[]>([])

  useEffect(() => {
    axios.get('past_events').then((res) => {
      setPastEvents(res.data)
    })
  }, [])

  return (
    <EventTemplate>
      <div className="flex justify-center">
        <div className="w-full">
          <div className="title-red-2 text-center mt-5">過去のイベント</div>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-4  gap-3 px-3 mb-5">
        {pastEvents.map((pastEvent) => (
          <div className="col-span-1" key={pastEvent.id}>
            <a
              href={`${pastEvent.is_campaign ? '/campaign/' : '/non_cinema/'}${
                pastEvent.id
              }`}
            >
              <div
                className="relative pb-[70%] md:pb-[140%] w-[100%] h-0 hover:opacity-80 rounded-xl shadow-md"
                style={{
                  backgroundImage: `url(${
                    imageURL + encodeURIComponent(pastEvent.image)
                  })`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                }}
              >
                <div className="absolute w-[100%] h-[35%] md:h-[30%] bottom-0 bg-zinc-600 bg-opacity-80 text-white px-2 py-1 rounded-b-xl">
                  <p className="font-body text-sm text-center text-stone-200">
                    {dayjs(pastEvent.start_date).format('YYYY/M/D')}
                    {pastEvent.start_date !== pastEvent.end_date &&
                      '〜' + dayjs(pastEvent.end_date).format('YYYY/M/D')}
                  </p>{' '}
                  <p className="font-title text-base">{pastEvent.title}</p>
                </div>
              </div>
            </a>
          </div>
        ))}
      </div>
    </EventTemplate>
  )
}
