import React, { ChangeEvent, useContext, useEffect, useState } from 'react'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import { Radio } from '@mui/material'
import { Upload } from './upload'
import { PostParamsContext } from '../../../../hooks/postParamsContext'
import { ImageFile } from '../../../../types/ImageFile'
import { Post } from '../../../../types/Post.'
import { UploadImageContainer } from '../Container/UploadImage.container'
import QuillEditor from '../../../Molecules/QuillEditor'
import { InputCustomPrice } from './InputCustomPrice'

interface Props {
  imageFile: ImageFile
  setImageFile: (e: ImageFile) => void
  selectedCinemaSchedule: Post | undefined | null
  isReflectMovieinfo: boolean
}

export const EditEvent = (props: Props) => {
  const { postParams, setPostParams } = useContext(PostParamsContext)

  const [radioValue, setRadioValue] = useState<string>(
    postParams.is_cinema ? 'true' : 'false'
  )

  const [radioValueReservable, setRadioValueReservable] =
    useState<string>('false')

  const [quillValue, setQuillValue] = useState<string>(postParams.detail)

  const handleQuillChange = (value: string) => {
    setQuillValue(value)
  }
  useEffect(() => {
    setPostParams({ ...postParams, detail: quillValue })
  }, [quillValue])

  const radioIsCinema = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === 'false') {
      setPostParams({
        ...postParams,
        is_cinema: false,
        event_price_1: 0,
        event_price_2: 0,
        event_price_3: 0,
        event_price_4: 0,
        ep_1_label: '',
        ep_2_label: '',
        ep_3_label: '',
        ep_4_label: '',
      })
      setRadioValue('false')
    } else {
      setPostParams({
        ...postParams,
        is_cinema: true,
        event_price_1: 0,
        event_price_2: 0,
        event_price_3: 0,
        event_price_4: 0,
        ep_1_label: '',
        ep_2_label: '',
        ep_3_label: '',
        ep_4_label: '',
      })
      setRadioValue('true')
    }
  }

  const radioNonCinemaReservable = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === 'false') {
      setPostParams({
        ...postParams,
        non_cinema_reservable: false,
      })
      setRadioValueReservable('false')
    } else {
      setPostParams({
        ...postParams,
        non_cinema_reservable: false,
      })
      setRadioValueReservable('false')
    }
  }

  const [isEditable, setIsEditable] = useState<boolean>(false)

  useEffect(() => {
    if (postParams.cinema_id !== -1 || props.isReflectMovieinfo) {
      setIsEditable(true)
    }
  }, [postParams.cinema_id, props.isReflectMovieinfo])

  return (
    <div>
      <div className="font-body text-lg text-cinekoya-yellow-2">
        イベント種別
      </div>
      <FormControl>
        <RadioGroup
          row
          aria-labelledby="radio-buttons-group-isCinema"
          value={radioValue}
          name="radio-buttons-group-isCinema"
          onChange={radioIsCinema}
        >
          <FormControlLabel value={'true'} control={<Radio />} label="映画" />
          <FormControlLabel
            value={'false'}
            control={<Radio />}
            label="映画以外"
          />
        </RadioGroup>
      </FormControl>

      <div className=" font-body text-lg text-cinekoya-yellow-2">タイトル</div>

      <div className="mb-3 ">
        <input
          className="w-full text-black border-solid  border-1 border-yt-atom focus:outline-none focus:ring-2 focus:ring-yt-atom-hover focus:border-transparent"
          value={postParams.title}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setPostParams({
              ...postParams,
              title: e.target.value,
            })
          }}
        />
      </div>

      <div className="mt-3 flex flex-row font-body text-lg text-cinekoya-yellow-2 mb-2">
        <div className="basis-1/2">
          {postParams.is_cinema ? '作品詳細' : 'イベント詳細'}
        </div>
        <div className="basis-1/2 text-right">
          {/* <UploadImageContainer /> */}
          {/* ↑画像をエディタと別口でアップロードできるボタン。作ったものの、今は使わないのでコメントアウト */}
        </div>
      </div>
      <div className="bg-white mb-3 cinema_detail">
        <QuillEditor
          allowImageInsertion={true}
          value={quillValue}
          onChange={handleQuillChange}
          onImageUploadComplete={() => {
            return
          }}
        />
      </div>

      {/* <EditEventDetail /> */}
      {!postParams.is_cinema && (
        <div className="mb-5">
          <div className=" font-body text-lg  text-cinekoya-yellow-2">
            WEB予約の許可
            <span className="text-sm text-yt-text-gray">
              &nbsp;※「許可しない」にするとスケジュール上のボタンには「詳細参照」と表示されます。
            </span>
          </div>

          <FormControl>
            <RadioGroup
              row
              aria-labelledby="radio-buttons-group-nonCinemaReservable"
              value={radioValueReservable}
              name="radio-buttons-group-nonCinemaReservable"
              onChange={radioNonCinemaReservable}
            >
              <FormControlLabel
                value={'true'}
                control={<Radio />}
                label="許可する"
              />
              <FormControlLabel
                value={'false'}
                control={<Radio />}
                label="許可しない"
              />
            </RadioGroup>
          </FormControl>
          <div className=" pl-2 text-sm text-cinekoya-yellow-2">
            （↑現在「許可する」機能は準備中です！）
          </div>
        </div>
      )}
      {!postParams.is_cinema && postParams.non_cinema_reservable && (
        <>
          <div className=" font-body text-lg  text-cinekoya-yellow-2">
            {' '}
            料金
          </div>
          <div className="w-full mb-5">
            <InputCustomPrice />
          </div>
        </>
      )}

      <Upload
        imageFile={props.imageFile}
        setImageFile={props.setImageFile}
        isEditable={isEditable}
        image={postParams.image}
      />
      {Boolean(postParams.is_cinema) && (
        <>
          <div className="text-lg text-cinekoya-yellow-2">
            予告編URL
            <span className="text-sm text-yt-text-gray">
              &nbsp;※Youtubeの再生画面下の「共有」ボタンで表示されるURL
            </span>
          </div>
          <div className="mb-3">
            <input
              className="w-4/5 border-solid text-sky-900 border-1 border-yt-atom focus:outline-none focus:ring-2 focus:ring-yt-atom-hover focus:border-transparent px-1 py-1"
              value={postParams.trailer_url}
              placeholder="例:https://youtu.be/WnA3wge9Kuc"
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setPostParams({
                  ...postParams,
                  trailer_url: e.target.value,
                })
              }}
            />
          </div>
        </>
      )}
    </div>
  )
}
