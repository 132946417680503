import React, { ReactNode } from 'react'

interface Props {
  onClick: () => void
  disabled: boolean
  children: ReactNode
  bgColor: string
}

export const NextStepButton = (props: Props) => {
  return (
    <button
      className={`h-16 w-5/6 sm:w-96 rounded-2xl shadow-sm bg-${
        props.bgColor
      } hover:bg-cinekoya-hover text-white font-bold text-xl ${
        props.disabled ? 'bg-opacity-80 cursor-not-allowed' : ''
      }}`}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.children}
    </button>
  )
}
