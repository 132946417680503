import axios from 'axios'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { CinemaOnly } from '../../types/CInemaOnly'
import { TicketSales } from '../../types/Reservation/TicketSales'
import { AggregateSales } from '../Organism/AggregateSales/Presentational/AggregateSales'
import { DashboardTop } from '../Templates/DashboardTop'

interface Aggregate {
  dayNonPrepaid: TicketSales[]
  dayPrepaid: TicketSales[]
  dayAll: TicketSales[]
  ticketNonPrepaid: TicketSales
  ticketPrepaid: TicketSales
  ticketAll: TicketSales
}

type PaidType = 'prepaid' | 'non_prepaid' | 'all'

export const AggregateSalesPage = () => {
  const [startDay, setStartDay] = useState<string>(dayjs().format('YYYY-MM-DD'))
  const [endDay, setEndDay] = useState<string>(dayjs().format('YYYY-MM-DD'))
  const [movieId, setMovieId] = useState<number>(-1)

  const [cinemaList, setCinemaList] = useState<CinemaOnly[]>([])

  const [isMovieUnit, setIsMovieUnit] = useState<boolean>(true)

  const [aggregateSales, setAggregateSales] = useState<Aggregate>()

  const [paidType, setPaidType] = useState<PaidType>('all')

  const [displayDailySales, setDisplayDailySales] = useState<TicketSales[]>([])
  const [displayTicketSales, setDisplayTicketSales] = useState<TicketSales>()
  const [arrayDate, setArrayDate] = useState<string[]>([])

  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    setIsLoading(true)
    if (!isMovieUnit) {
      setIsLoading(false)
      return
    }
    fetchCinemaList()
    generateDateArray()
    setIsLoading(false)
  }, [startDay, endDay, isMovieUnit])

  useEffect(() => {
    fetchSalesData()
  }, [startDay, endDay, movieId])

  const fetchCinemaList = async () => {
    const response = await axios.get('get_cinemalist_for_aggregate_sales', {
      params: {
        start_day: startDay,
        end_day: endDay,
      },
    })
    const movie_id = response.data.length > 0 ? response.data[0].id : -1
    setCinemaList(response.data)

    const cinema_list: CinemaOnly[] = response.data

    const isIncluded: boolean =
      cinema_list.length > 0
        ? cinema_list.some((item) => item.id === movieId)
        : false

    setMovieId(isMovieUnit && isIncluded ? movieId : movie_id)
  }

  const fetchSalesData = async () => {
    setIsLoading(true)
    const endpoints = [
      'non_prepaid_daily_sales',
      'prepaid_daily_sales',
      'all_daily_sales',
    ]
    const responses = await Promise.all(
      endpoints.map((endpoint) => {
        return axios.get(endpoint, {
          params: {
            start_day: startDay,
            end_day: endDay,
            movie_id: movieId,
          },
        })
      })
    )

    setAggregateSales({
      dayNonPrepaid: responses[0].data.dailySales,
      dayPrepaid: responses[1].data.dailySales,
      dayAll: responses[2].data.dailySales,
      ticketNonPrepaid: responses[0].data.totalPerTicket,
      ticketPrepaid: responses[1].data.totalPerTicket,
      ticketAll: responses[2].data.totalPerTicket,
    })

    setDisplayDailySales(responses[2].data.dailySales)
    setDisplayTicketSales(responses[2].data.totalPerTicket)

    setIsLoading(false)
  }

  useEffect(() => {
    if (paidType === 'all' && aggregateSales) {
      setDisplayDailySales(aggregateSales?.dayAll)
      setDisplayTicketSales(aggregateSales?.ticketAll)
    }

    if (paidType === 'non_prepaid' && aggregateSales) {
      setDisplayDailySales(aggregateSales?.dayNonPrepaid)
      setDisplayTicketSales(aggregateSales?.ticketNonPrepaid)
    }

    if (paidType === 'prepaid' && aggregateSales) {
      setDisplayDailySales(aggregateSales?.dayPrepaid)
      setDisplayTicketSales(aggregateSales?.ticketPrepaid)
    }
  }, [paidType])

  const generateDateArray = () => {
    const dateDifference = dayjs(endDay).diff(dayjs(startDay), 'day')
    const array_date = Array.from({ length: dateDifference + 1 }, (_, i) =>
      dayjs(startDay).add(i, 'day').format('YYYY-MM-DD')
    )
    setArrayDate(array_date)
  }

  return (
    <DashboardTop>
      <AggregateSales
        startDay={startDay}
        setStartDay={setStartDay}
        endDay={endDay}
        setEndDay={setEndDay}
        cinemaList={cinemaList}
        setMovieId={setMovieId}
        isMovieUnit={isMovieUnit}
        setIsMovieUnit={setIsMovieUnit}
        displayDailySales={displayDailySales}
        displayTicketSales={displayTicketSales}
        arrayDate={arrayDate}
        paidType={paidType}
        setPaidType={setPaidType}
        isLoading={isLoading}
      />
    </DashboardTop>
  )
}
