import React, { useContext, useEffect, useState } from 'react'
import { useCountRemainder } from '../../../../hooks/useCountRemainder'
import { useTicketNum } from '../../../../hooks/useTicketNum'
import { useAggregateResults } from '../../../../hooks/useAggregateResults'
import { useGrandTotalPrice } from '../../../../hooks/useGrandTotalPrice'
import { ReserveBaseParamsContext } from '../../../../hooks/ReserveBaseParamsContext'
import { TicketBreakdown } from '../Presentational/TicketBreakdown'
import { DiscountInfo } from '../../../../types/Reservation/DiscountInfo'

export const TicketBreakdownContainer = () => {
  const { reserveBaseParams, setReserveBaseParams } = useContext(
    ReserveBaseParamsContext
  )
  const [fanclubNum, setfanclubNum] = useState<number>(0)
  const [membersNum, setMembersNum] = useState<number>(0)
  const [youthNum, setYouthNum] = useState<number>(0)
  const [underYouthNum, setUnderYouthNum] = useState<number>(0)
  const [infantNum, setInfantNum] = useState<number>(0)

  const [discountInfo, setDiscountInfo] = useState<DiscountInfo>({
    email: '',
    coupon_code: '',
    repeat_general_number: 0,
  })

  const UNIT_PRICE = {
    fanclub: 1100,
    fanclub_companion: 1300,
    members: 1300,
    members_companion: 1300,
    youth: 1300,
    under_youth: 1100,
    infant: 800,
    general: 1800,
    second: 1100,
  }

  const ticketNum = useTicketNum({
    seatNum: reserveBaseParams.seat,
    fanclubNum: fanclubNum,
    membersNum: membersNum,
    youthNum: youthNum,
    underYouthNum: underYouthNum,
    infantNum: infantNum,
  })

  //集計結果のオブジェクト配列
  const aggregateResults = useAggregateResults({
    ticketNum: ticketNum,
    price: UNIT_PRICE,
    repeatGeneralNum: discountInfo.repeat_general_number,
  })

  //入力ボックスの入力可能残数
  //fanclubだけ異なる
  const countRemainder = useCountRemainder({
    seatNum: reserveBaseParams.seat,
    fanclubNum: fanclubNum,
    membersNum: membersNum,
    youthNum: youthNum,
    underYouthNum: underYouthNum,
    infantNum: infantNum,
  })

  const grandTotalPrice = useGrandTotalPrice(aggregateResults)

  useEffect(() => {
    setReserveBaseParams({
      ...reserveBaseParams,
      fanclubNum: ticketNum.fanclub,
      membersNum: ticketNum.members,
    })
  }, [ticketNum])

  return (
    <TicketBreakdown
      fanclubNum={fanclubNum}
      setfanclubNum={setfanclubNum}
      membersNum={membersNum}
      setMembersNum={setMembersNum}
      youthNum={youthNum}
      setYouthNum={setYouthNum}
      underYouthNum={underYouthNum}
      setUnderYouthNum={setUnderYouthNum}
      infantNum={infantNum}
      setInfantNum={setInfantNum}
      countRemainder={countRemainder}
      aggregateResults={aggregateResults}
      grandTotalPrice={grandTotalPrice}
      discountInfo={discountInfo}
      setDiscountInfo={setDiscountInfo}
    />
  )
}
