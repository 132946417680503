import React from 'react'
import { Reservation } from '../../../../types/Reservation/Reservation'

interface Props {
  visitedNum: number
  setVisitedNum: (e: number) => void
  reservation: Reservation
  borderColor: string
}
export const CounterVisitedNumber = (props: Props) => {
  const countDown = () => {
    if (props.visitedNum - 1 < 0) {
      props.setVisitedNum(props.visitedNum)
    } else {
      props.setVisitedNum(props.visitedNum - 1)
    }
  }

  const countUp = (seats: number) => {
    if (props.visitedNum + 1 > seats) {
      props.setVisitedNum(props.visitedNum)
    } else {
      props.setVisitedNum(props.visitedNum + 1)
    }
  }

  return (
    <div className="flex flex-row justify-center text-center mt-3 mb-3">
      <div className="basis-1/3 text-center">
        <button
          className={`border-2 ${props.borderColor} text-3xl px-2 py-2 rounded-2xl w-16 hover:bg-yt-atom`}
          onClick={countDown}
        >
          -
        </button>
      </div>
      <div className="basis-1/3 text-3xl">
        <span className="text-5xl">{props.visitedNum}</span>

        <span className="text-5xl">&nbsp;/&nbsp;</span>
        <span>{props.reservation.seats}</span>
        <span className="text-sm text-yt-text-gray">名様</span>
      </div>
      <div className="basis-1/3 text-center">
        <button
          className={`border-2 ${props.borderColor} text-3xl px-2 py-2 rounded-xl w-16 hover:bg-yt-atom`}
          onClick={() => countUp(props.reservation.seats)}
        >
          +
        </button>
      </div>
    </div>
  )
}
