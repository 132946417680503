import React from 'react'

import { DashboardTop } from '../Templates/DashboardTop'
import { CommonSetting } from '../Organism/CommonSetting/CommonSetting'

export const CommonSettingPage = () => {
  return (
    <DashboardTop>
      <CommonSetting />
    </DashboardTop>
  )
}
