import React, { ReactNode } from 'react'
import { createContext, useState, useContext } from 'react'

type MailVerifiedContextProps = {
  mailVerified: boolean
  setMailVerified: (e: boolean) => void
}

type Children = {
  children: React.ReactNode
}
export const MailVerifiedContext =
  React.createContext<MailVerifiedContextProps>({} as MailVerifiedContextProps)

export const MailVerifiedProvider = (props: Children) => {
  const { children } = props

  const [mailVerified, setMailVerified] = useState(false)

  return (
    <MailVerifiedContext.Provider value={{ mailVerified, setMailVerified }}>
      {children}
    </MailVerifiedContext.Provider>
  )
}
