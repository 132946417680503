import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { CampaignParams } from '../../types/CampaignPrams'
import { EventTemplate } from '../Templates/EventTemplate'
import { Campaign } from '../Organism/Campaign/Campaign'
import { OtherEventList } from '../Molecules/OtherEventList'
import { Movie } from '../../types/Movie'

export const CampaignPage = () => {
  const { campaign_id } = useParams<{ campaign_id: string }>()

  const [campaign, setCampaign] = React.useState<CampaignParams>(
    {} as CampaignParams
  )

  const [nonCinemaEvents, setNonCinemaEvents] = useState<Movie[]>([])

  const [campaigns, setCampaigns] = useState<CampaignParams[]>([])

  useEffect(() => {
    getContent()
  }, [])

  const getContent = () => {
    axios
      .get('get_campaign', {
        params: {
          id: campaign_id,
        },
      })
      .then((response) => {
        setCampaign(response.data)
      })

    axios.get('event_campaign').then((response) => {
      setCampaigns(response.data)
    })

    axios.get('get_non_cinema_event').then((response) => {
      setNonCinemaEvents(response.data)
    })
  }

  return (
    <EventTemplate>
      <Campaign campaign={campaign} />
      <OtherEventList
        event_id={-1}
        campaign_id={Number(campaign_id)}
        nonCinemaEvents={nonCinemaEvents}
        campaigns={campaigns}
      />
    </EventTemplate>
  )
}
