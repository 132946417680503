import axios from 'axios'
import React, { useState } from 'react'
import { UpdateNotification } from '../Presentational/UpateNotification'

interface Event {
  id: number
  movie_id: number
  content: string
  expiration: string
}

interface EventList {
  id: number
  title: string
  endDay: string
}

interface Props {
  event: EventList
  notification: Event
  activeEditorId: number
  setActiveEditorId: (e: number) => void
  activePreviewId: number
  setActivePreviewId: (e: number) => void
  getEventList: () => void
}

export const UpdateEventNotificationContainer = (props: Props) => {
  const [editingValue, setEditingValue] = useState<string>(
    props.notification.content
  )
  const [editorMode, setEditorMode] = useState<'live' | 'edit' | 'preview'>(
    'edit'
  )

  const [expiration, setExpiration] = useState<string>(
    props.notification.expiration
  )

  const [isEdit, setIsEdit] = useState<boolean>(false)

  const updateNotificationEvent = async () => {
    if (!editingValue) {
      return
    }
    // const valueEscaped = editingValue
    //   ?.replace(/&/g, '&lt;')
    //   .replace(/</g, '&lt;')
    //   .replace(/>/g, '&gt;')
    //   .replace(/"/g, '&quot;')
    //   .replace(/'/g, '&#x27;')

    await axios
      .put('update_notification_event', {
        id: props.notification.id,
        content: editingValue,
        expiration: expiration,
      })
      .then(() => {
        props.setActiveEditorId(0)
        setEditorMode('edit')
        setIsEdit(false)
        props.getEventList()
      })
  }

  const deleteNotificationEvent = async () => {
    await axios
      .delete('delete_notification_event', {
        params: {
          id: props.notification.id,
        },
      })
      .then(() => {
        props.setActiveEditorId(0)
        setEditorMode('edit')
        setIsEdit(false)
        props.getEventList()
      })
  }

  return (
    <UpdateNotification
      isEdit={isEdit}
      setIsEdit={setIsEdit}
      editorMode={editorMode}
      setEditorMode={setEditorMode}
      updateNotificationEvent={updateNotificationEvent}
      deleteNotificationEvent={deleteNotificationEvent}
      editingValue={editingValue}
      setEditingValue={setEditingValue}
      expiration={expiration}
      setExpiration={setExpiration}
      event={props.event}
      notification={props.notification}
      activeEditorId={props.activeEditorId}
      setActiveEditorId={props.setActiveEditorId}
      activePreviewId={props.activePreviewId}
      setActivePreviewId={props.setActivePreviewId}
    />
  )
}
