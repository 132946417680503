import React, { ChangeEvent, useContext } from 'react'
import { PostParamsContext } from '../../../../hooks/postParamsContext'

export const InputCustomPrice = () => {
  const { postParams, setPostParams } = useContext(PostParamsContext)

  const inputPriceClass =
    'w-[100px] text-black border-solid  border-1 border-yt-atom focus:outline-none focus:ring-2 focus:ring-yt-atom-hover focus:border-transparent py-1 px-1 rounded-lg'

  const inputLabelClass =
    'w-[310px] text-black border-solid  border-1 border-yt-atom focus:outline-none focus:ring-2 focus:ring-yt-atom-hover focus:border-transparent py-1 px-1 rounded-lg text-sm'

  const placeholder = '(例）大学生、高校生、+ 1drink order '

  const indexLabel = 'ラベル（任意）'
  const indexLabelClass = 'ml-3 mr-1 text-yt-text-gray'

  return (
    <>
      <div className="w-full pl-3 mb-1">
        <b>料金１（必須）</b> &nbsp;
        <input
          type="number"
          className={inputPriceClass}
          value={postParams.event_price_1}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setPostParams({
              ...postParams,
              event_price_1: Number(e.target.value),
            })
          }}
        />
        &nbsp;円
        <span className={indexLabelClass}>{indexLabel}</span>
        <input
          type="text"
          className={inputLabelClass}
          value={postParams.ep_1_label}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setPostParams({
              ...postParams,
              ep_1_label: e.target.value,
            })
          }}
          placeholder={placeholder}
          maxLength={20}
        />
      </div>
      <div className="w-full pl-3 mb-1">
        料金２（任意） &nbsp;
        <input
          type="number"
          className={inputPriceClass}
          value={postParams.event_price_2}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setPostParams({
              ...postParams,
              event_price_2: Number(e.target.value),
            })
          }}
        />
        &nbsp;円
        <span className={indexLabelClass}>{indexLabel}</span>
        <input
          type="text"
          className={inputLabelClass}
          value={postParams.ep_2_label}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setPostParams({
              ...postParams,
              ep_2_label: e.target.value,
            })
          }}
          placeholder={placeholder}
          maxLength={20}
        />
      </div>
      <div className="w-full pl-3 mb-1">
        料金３（任意） &nbsp;
        <input
          type="number"
          className={inputPriceClass}
          value={postParams.event_price_3}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setPostParams({
              ...postParams,
              event_price_3: Number(e.target.value),
            })
          }}
        />
        &nbsp;円
        <span className={indexLabelClass}>{indexLabel}</span>
        <input
          type="text"
          className={inputLabelClass}
          value={postParams.ep_3_label}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setPostParams({
              ...postParams,
              ep_3_label: e.target.value,
            })
          }}
          placeholder={placeholder}
          maxLength={20}
        />
      </div>
      <div className="w-full pl-3 mb-1">
        料金４（任意） &nbsp;
        <input
          type="number"
          className={inputPriceClass}
          value={postParams.event_price_4}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setPostParams({
              ...postParams,
              event_price_4: Number(e.target.value),
            })
          }}
        />
        &nbsp;円
        <span className={indexLabelClass}>{indexLabel}</span>
        <input
          type="text"
          className={inputLabelClass}
          value={postParams.ep_4_label}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setPostParams({
              ...postParams,
              ep_4_label: e.target.value,
            })
          }}
          placeholder={placeholder}
          maxLength={20}
        />
      </div>
    </>
  )
}
