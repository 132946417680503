import React, { ChangeEvent, useEffect } from 'react'

interface TicketType {
  id: number
  name: string
  unit_price: number
}

interface Props {
  isLoading: boolean
  setIsLoading: (e: boolean) => void
  soldTicketTypes: TicketType[]
  setSoldTicketTypes: (e: TicketType[]) => void
  ticketTypes: TicketType[]
  setIsTicketTypeEmpty: (e: boolean) => void
  newSeats: number
}

export const SelectSoldTicketType = (props: Props) => {
  useEffect(() => {
    props.setIsLoading(true)
    props.setIsTicketTypeEmpty(false)
    const array_ticket_type: number[] = []

    let newSeatNum = props.newSeats
    if (props.newSeats < 0) {
      newSeatNum = -props.newSeats
    }

    for (let i = 1; i <= newSeatNum; i++) {
      array_ticket_type.push(i)
    }
    props.setSoldTicketTypes(
      array_ticket_type.map((item) => ({
        id: item,
        name: '券種を選択',
        unit_price: 0,
      }))
    )
    setTimeout(() => {
      props.setIsLoading(false)
    }, 500)
    window.scrollBy(0, -100)
  }, [props.newSeats])

  const handleChange = (
    e: ChangeEvent<HTMLSelectElement>,
    soldTicketTypeId: number
  ) => {
    const selectedTicketTypeName = e.target.value
    const selectedTicketType = props.ticketTypes.find(
      (item) => item.name === selectedTicketTypeName
    )

    if (selectedTicketType) {
      props.setSoldTicketTypes(
        props.soldTicketTypes.map((soldTicketType) =>
          soldTicketType.id === soldTicketTypeId
            ? {
                ...soldTicketType,
                name: selectedTicketType.name,
                unit_price: selectedTicketType.unit_price,
              }
            : soldTicketType
        )
      )
    }
  }

  return (
    <>
      {!props.isLoading && (
        <div className="flex flex-col text-2xl justify-center text-center mt-3 font-body">
          {props.soldTicketTypes.map((inputTicketType) => (
            <div key={inputTicketType.id} className={`mt-3`}>
              <select
                className="w-1/2 py-3 px-3 rounded-lg text-white bg-yt-atom text-center cursor-pointer font-bold"
                onChange={(event) => handleChange(event, inputTicketType.id)}
                value={inputTicketType.name}
              >
                {props.ticketTypes.map((type) => (
                  <option key={type.id} value={type.name}>
                    {type.name}
                  </option>
                ))}
              </select>
            </div>
          ))}
        </div>
      )}
    </>
  )
}
