import React from 'react'
import { DashboardTop } from '../Templates/DashboardTop'
import { Notification } from '../Templates/Notification'
import { EventNotification } from '../Organism/NotificationEvent/Presentational/EventNotification'

export const NotificationEventPage = () => {
  return (
    <DashboardTop>
      <Notification>
        <EventNotification />
      </Notification>
    </DashboardTop>
  )
}
