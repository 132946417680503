import React, { useState } from 'react'
import { ButtonBorderBlue } from '../../../Atoms/ButtonBorderBlue'
import EditIcon from '@mui/icons-material/Edit'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import CloseIcon from '@mui/icons-material/Close'
import QuillEditor from '../../../Molecules/QuillEditor'

interface Props {
  isEdit: boolean
  setIsEdit: (e: boolean) => void
  editorMode: 'live' | 'edit' | 'preview'
  setEditorMode: (e: 'live' | 'edit' | 'preview') => void
  updateNotificationTop: () => void
  deleteNotificationTop: () => void
  editingValue: string
  setEditingValue: (e: string) => void
  notification: string | null
}

export const TopNotification = (props: Props) => {
  const [previewHeight, setPreviewHeight] = useState<string | number>(50)

  return (
    <>
      <hr
        className="mt-5"
        style={{ height: '3px', backgroundColor: '#606060' }}
      />
      <div className="flex flex-col items-center">
        <div className="w-2/3  mt-3 text-right text-cinekoya-sub-a hover:text-cinekoya-sub-d mb-2 pr-2">
          {!props.isEdit && props.notification !== null && (
            <EditIcon
              fontSize="large"
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                props.setIsEdit(true)
                setPreviewHeight('auto')
              }}
            />
          )}
          {!props.isEdit && props.notification === null && (
            <AddCircleIcon
              fontSize="large"
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                props.setIsEdit(true)
                setPreviewHeight('auto')
              }}
            />
          )}
          {props.isEdit && (
            <CloseIcon
              fontSize="large"
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                props.setEditingValue(
                  props.notification !== null ? props.notification : ''
                )
                props.setEditorMode('edit')
                props.setIsEdit(false)
              }}
            />
          )}
        </div>
        <div
          className="w-2/3  text-white bg-yt-component rounded-2xl p-2 border-2 border-yt-atom px-3 py-4"
          style={{
            height:
              props.notification === null || props.notification.length <= 100
                ? 'auto'
                : previewHeight,
          }}
        >
          {!props.isEdit && props.notification !== null && (
            <div dangerouslySetInnerHTML={{ __html: props.notification }}></div>
          )}
          {!props.isEdit && props.notification === null && (
            <div className="text-center text-cinekoya-sub-a">
              通知はありません
            </div>
          )}

          {props.isEdit && (
            <>
              <div className="bg-white mb-3 event_notification_editor">
                <QuillEditor
                  allowImageInsertion={false}
                  value={props.editingValue}
                  onChange={(contetn: string) => {
                    props.setEditingValue(contetn)
                  }}
                  onImageUploadComplete={() => {
                    return
                  }}
                />
              </div>

              <div className="flex flex-row text-center mt-3">
                <div className="basis-1/3" />
                <div className="basis-1/3">
                  <ButtonBorderBlue onClick={props.updateNotificationTop}>
                    更新する
                  </ButtonBorderBlue>
                </div>

                <div className="basis-1/3 text-right pr-2">
                  <button
                    className="border-1 border-yt-atom py-1 px-2 rounded-xl hover:bg-yt-atom cursor-pointer"
                    onClick={props.deleteNotificationTop}
                  >
                    通知の削除
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}
