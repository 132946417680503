import axios from 'axios'
import React, { useContext } from 'react'
import { AuthContext } from '../../../../hooks/AuthContext'
import { Logout } from '../Presentational/Logout'

interface Props {
  isMenuShrinked: boolean
}
export const LogoutContainer = (props: Props) => {
  const { setIsAuth } = useContext(AuthContext)

  const logout = async () => {
    await axios
      .post('logout')
      .then(() => {
        setIsAuth(false)
      })
      .catch((error) => console.log(error))
  }

  return <Logout logout={logout} isMenuShrinked={props.isMenuShrinked} />
}
