import { useEffect, useState } from 'react'

interface Result {
  id: number
  name: string
  ticketNum: number
  unitPrice: number
  totalPrice: number
}

export const useGrandTotalPrice = (aggregateResults: Result[]) => {
  const [totalPrice, setTotalPrice] = useState(0)

  useEffect(() => {
    const total_price = aggregateResults.reduce(function (sum, element) {
      return sum + element.totalPrice
    }, 0)

    setTotalPrice(total_price)
  }, [aggregateResults])

  return totalPrice
}
