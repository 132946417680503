import { useContext } from 'react'
import { ReserveBaseParamsContext } from '../../../../hooks/ReserveBaseParamsContext'
import { ConfirmSessionContainer } from '../Container/ConfirmSession.container'
import { Terms } from '../../Schedule/terms'
import { CountSeat } from './CountSeat'

type Props = {
  reservableNum: number
  setReservableNum: (e: number) => void
  getReservableNumber: () => void
}

export const SelectSeatNumber = (props: Props) => {
  const { reserveBaseParams } = useContext(ReserveBaseParamsContext)
  return (
    <>
      <div className="w-100 text-center text-xl mt-16 mb-1 ">席数選択</div>
      <CountSeat
        countRemainder={props.reservableNum - reserveBaseParams.seat}
        countMinLimit={1}
        unit={''}
        widthInput={40}
        height={16}
        paddingTop={3}
      />
      <div className="w-100 text-center text-base mt-9 mb-1 text-cinekoya ">
        {props.reservableNum !== -1 && (
          <>現在のWEB予約可能残数：{props.reservableNum}席</>
        )}
        {props.reservableNum === -1 && <>予約可能時間を過ぎています。</>}
      </div>

      <>
        <div className="w-100 text-center text-sm mt-20 mb-1 ">利用規約</div>
        <div className="flex justify-center font-body ">
          <div className="w-full md:w-3/5 pl-2">
            <Terms />
          </div>
        </div>
      </>

      <ConfirmSessionContainer
        reservableNum={props.reservableNum}
        setReservableNum={props.setReservableNum}
        getReservableNumber={props.getReservableNumber}
      />
    </>
  )
}
