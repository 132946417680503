import { useEffect, useState } from 'react'
import { Post } from '../../types/Post.'

interface Props {
  date: string
  orderNum: number
  schedules: Post[]
}

export const useJudgeOccupied = (props: Props) => {
  const [isOccupied, setIsOccupied] = useState<boolean>(false)

  useEffect(() => {
    const arraySelectedSchedule = props.schedules?.map((schedule) => {
      if (
        schedule.order === props.orderNum &&
        schedule.start_day <= props.date &&
        schedule.end_day >= props.date
      ) {
        return schedule
      } else {
        return null
      }
    })

    const selected = arraySelectedSchedule.find((items) => items !== null)
    //作品登録が非該当である場合はundefinedが返る
    if (selected !== undefined) {
      setIsOccupied(true)
    } else {
      setIsOccupied(false)
    }
  }, [props])

  return isOccupied
}
