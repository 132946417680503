import React from 'react'
import EditIcon from '@mui/icons-material/Edit'
import { Reservation } from '../../../../types/Reservation/Reservation'

interface Props {
  visitedNum: number
  reservation: Reservation
  setIsEditableVisitedNum: (e: boolean) => void
  color: string
}
export const NotEditableVisitedNumber = (props: Props) => {
  return (
    <div className="flex flex-row justify-center text-center mt-3 mb-5">
      <div className="basis-1/3 text-center" />

      <div className="basis-1/3 text-3xl">
        <span className="text-5xl">{props.visitedNum}</span>

        <span className="text-5xl">&nbsp;/&nbsp;</span>
        <span>{props.reservation.seats}</span>
        <span className="text-sm text-yt-text-gray">名様</span>
      </div>
      <div className="basis-1/3 text-center ">
        <EditIcon
          sx={{
            color: props.color,
            cursor: 'pointer',
            marginTop: '3px',
          }}
          fontSize="large"
          onClick={() => props.setIsEditableVisitedNum(true)}
        />
      </div>
    </div>
  )
}
