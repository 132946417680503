import { ChangeEvent } from 'react'
import axios from 'axios'

type Props = {
  order: number
  targetDay: string
  newLimit: number
  setNewLimit: (e: number) => void
  getReservationLimit: () => void
  closeModalForLimit: () => void
}

export const NoParticleLimit = (props: Props) => {
  const register = () => {
    axios
      .post('limit_particle', {
        date: props.targetDay,
        order: props.order,
        limit: props.newLimit,
      })
      .then(() => {
        props.getReservationLimit()
        props.closeModalForLimit()
      })
      .catch((error) => console.log(error))
  }

  return (
    <>
      <div className="w-100 text-center text-base  text-stone-400 ">
        コマ単位の上限設定はありません
      </div>

      <div className=" w-100 flex items-center justify-center mt-1 font-body text-lg">
        <div className="font-body font-bold text-lg">上限の設定&nbsp;</div>
        <input
          type="number"
          className=" w-32 h-10  ml-1 text-center text-xl font-bold border-solid  border-2 border-stone-400 focus:outline-none focus:ring-2 focus:ring-cinekoya-sub-c focus:border-transparent text-black"
          value={props.newLimit}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            props.setNewLimit(Number(e.target.value))
          }
        />
        &nbsp;
        <div className="font-body font-bol text-lg">席</div>
      </div>

      <div className="text-center">
        <button
          className="mt-3 w-40 py-0.5 text-xl text-center text-white inline-block bg-cinekoya-sub-c font-body rounded-full shadow-md cursor-pointer hover:bg-cinekoya-sub-a "
          onClick={() => {
            register()
          }}
        >
          登録
        </button>
      </div>
    </>
  )
}
