import React, { useState } from 'react'
import Modal from 'react-modal'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import SouthRoundedIcon from '@mui/icons-material/SouthRounded'
import { CounterVisitedNumber } from './CounterVisitedNumber'
import { CustomerDetail } from './CustomerDetail'
import { SubmitButton } from './SubmitButton'
import { NotEditableVisitedNumber } from './NotEditableVisitedNumber'
import { ReservationKindsBlock } from './ReservationKindsBlock'
import { Reservation } from '../../../../types/Reservation/Reservation'
import { SelectSoldTicketType } from './SelectSoldTicketType'
import { modalStyle_visitorManagement } from '../../../../style/modalStylev_visitorManagement'
import { VisitedCustomerDetail } from './VisitedCustomerDetail'
import { AddTelReservationContainer } from '../Container/AddTelReservation.container'
import AddIcon from '@mui/icons-material/Add'

interface TicketType {
  id: number
  name: string
  unit_price: number
}
interface MovieInfo {
  movie_id: number
  is_cinema: boolean
  title: string
}

interface Props {
  areaStyle: string
  buttonStyle: string
  isModalOpen: boolean
  setIsModalOpen: (e: boolean) => void
  totalReservedSeats: number
  totalVisited: number
  visitedReservations: Reservation[]
  notVisitedReservations: Reservation[]
  selectedReservationId: number | null
  setSelectedReservationId: (e: number | null) => void
  visitedNum: number
  setVisitedNum: (e: number) => void
  registerHasVisited: () => void
  isLoading: boolean
  setIsLoading: (e: boolean) => void
  isEditableVisitedNum: boolean
  setIsEditableVisitedNum: (e: boolean) => void
  updateVisitedNum: () => void
  cancelHasVisited: () => void
  ticketTypes: TicketType[]
  soldTicketTypes: TicketType[]
  setSoldTicketTypes: (e: TicketType[]) => void
  isTicketTypeEmpty: boolean
  setIsTicketTypeEmpty: (e: boolean) => void
  movieInfo: MovieInfo
  date: string
  order: number
  getSeats: () => void
  getSeatsInfo: () => void
}

export const CountTelReservedVisitor = (props: Props) => {
  const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false)

  const openModal = () => {
    props.setIsModalOpen(true)
  }

  const closeModal = () => {
    props.setIsModalOpen(false)
    props.setSelectedReservationId(null)
  }

  const openAddModal = () => {
    closeModal()
    setIsAddModalOpen(true)
  }

  const closeAddModal = () => {
    setIsAddModalOpen(false)
  }

  return (
    <>
      <ReservationKindsBlock
        areaStyle={props.areaStyle}
        buttonStyle={props.buttonStyle}
        openModal={openModal}
        totalVisited={props.totalVisited}
        totalReservedSeats={props.totalReservedSeats}
        borderColor="border-cinekoya-yellow-2"
        buttonColor="bg-cinekoya-yellow-2 text-yt-component"
      >
        電話予約
      </ReservationKindsBlock>

      <AddTelReservationContainer
        isAddModalOpen={isAddModalOpen}
        closeAddModal={closeAddModal}
        closeModal={closeModal}
        date={props.date}
        order={props.order}
        movieInfo={props.movieInfo}
        getSeats={props.getSeats}
        getSeatsInfo={props.getSeatsInfo}
      />

      <Modal
        isOpen={props.isModalOpen}
        onRequestClose={closeModal}
        style={modalStyle_visitorManagement}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <div className="flex flex-row mb-2 font-body ">
          <div className="basis-1/3">
            <button
              className="bg-cinekoya-yellow-2 text-yt-component py-1 px-3 rounded-2xl bg-opacity-90 hover:bg-opacity-100 border-white border-2 cursor-pointer"
              onClick={openAddModal}
            >
              <AddIcon />
              <span className="align-middle">予約追加</span>
            </button>
          </div>
          <div className="basis-1/3 text-2xl font-bold text-cinekoya-yellow-2 text-center">
            電話予約
          </div>
          <div className="basis-1/3 text-right pr-3 text-yt-atom hover:text-yt-atom-hover ">
            <CloseIcon
              fontSize="large"
              sx={{ cursor: 'pointer' }}
              onClick={closeModal}
            />
          </div>
        </div>

        <hr />
        <div className=" mt-3">
          {props.notVisitedReservations.length > 0 &&
            props.notVisitedReservations.map((reservation) => (
              <React.Fragment key={reservation.id}>
                <div className="flex flex-row bg-yt-component rounded-xl px-1 py-2 cursor-pointer text-lg items-center mb-3 border-4 border-cinekoya-yellow-2">
                  <div className="basis-1/3 pl-2 ">{reservation.name}様</div>
                  <div className="basis-1/3">{reservation.seats}席</div>
                  <div className="basis-1/3 text-right pr-3 text-cinekoya-yellow-2">
                    <IconButton>
                      {props.selectedReservationId === reservation.id ? (
                        <ExpandLessIcon
                          className="text-cinekoya-yellow-2"
                          fontSize="large"
                          onClick={() => {
                            props.setSelectedReservationId(null)
                            props.setVisitedNum(0)
                          }}
                        />
                      ) : (
                        <ExpandMoreIcon
                          className="text-cinekoya-yellow-2"
                          fontSize="large"
                          onClick={() => {
                            props.setSelectedReservationId(reservation.id)
                            props.setVisitedNum(
                              reservation.has_visited
                                ? reservation.visited_number
                                : reservation.seats
                            )
                          }}
                        />
                      )}
                    </IconButton>
                  </div>
                </div>
                {props.selectedReservationId === reservation.id && (
                  <>
                    <CustomerDetail reservation={reservation} />
                    {!reservation.has_visited && (
                      <div className="w-full mt-5 mb-3 text-xl text-center font-body">
                        来店済にしますか？
                      </div>
                    )}
                    <CounterVisitedNumber
                      visitedNum={props.visitedNum}
                      setVisitedNum={props.setVisitedNum}
                      reservation={reservation}
                      borderColor="border-cinekoya-yellow-2"
                    />
                    {Boolean(props.movieInfo.is_cinema) && (
                      <>
                        <SelectSoldTicketType
                          isLoading={props.isLoading}
                          setIsLoading={props.setIsLoading}
                          soldTicketTypes={props.soldTicketTypes}
                          setSoldTicketTypes={props.setSoldTicketTypes}
                          ticketTypes={props.ticketTypes}
                          setIsTicketTypeEmpty={props.setIsTicketTypeEmpty}
                          newSeats={props.visitedNum}
                        />
                        <div className="w-full text-center text-2xl py-2 h-[50px] text-cinekoya-yellow-1 font-bold">
                          {props.isTicketTypeEmpty && (
                            <span>すべての券種を選択して下さい</span>
                          )}
                        </div>
                      </>
                    )}

                    {!reservation.has_visited && (
                      <>
                        <SubmitButton
                          onClickHandler={props.registerHasVisited}
                          isLoading={props.isLoading}
                          buttonColor="bg-cinekoya-yellow-2 text-yt-component"
                          ThreeDotsColor="#282828"
                        >
                          来店済にする
                        </SubmitButton>
                        <div className="w-full text-center text-yt-text-gray mt-[-30px]">
                          ※キャンセルの場合は人数を0名として「来店済」として下さい
                        </div>
                      </>
                    )}
                  </>
                )}
              </React.Fragment>
            ))}
        </div>

        <hr className="mt-5" />

        <div className="w-full text-center text-2xl font-body font-bold my-3">
          <SouthRoundedIcon fontSize="large" sx={{ color: '#E4F551' }} />
          &nbsp;<span className="align-bottom">来店済</span>
        </div>

        {props.visitedReservations.length > 0 &&
          props.visitedReservations.map((reservation) => (
            <React.Fragment key={reservation.id}>
              <>
                <div className="flex flex-row bg-yt-component rounded-xl px-1 py-2 cursor-pointer text-lg items-center mb-3">
                  <div className="basis-1/3 pl-2 ">{reservation.name}様</div>
                  <div className="basis-1/3">
                    {reservation.visited_number}/{reservation.seats}席
                  </div>
                  <div className="basis-1/3 text-right pr-3 text-cinekoya-yellow-2">
                    <IconButton>
                      {props.selectedReservationId === reservation.id ? (
                        <ExpandLessIcon
                          className="text-cinekoya-yellow-2"
                          fontSize="large"
                          onClick={() => {
                            props.setSelectedReservationId(null)
                            props.setVisitedNum(0)
                            props.setIsEditableVisitedNum(false)
                          }}
                        />
                      ) : (
                        <ExpandMoreIcon
                          className="text-cinekoya-yellow-2"
                          fontSize="large"
                          onClick={() => {
                            props.setSelectedReservationId(reservation.id)
                            props.setVisitedNum(
                              reservation.has_visited
                                ? reservation.visited_number
                                : reservation.seats
                            )
                          }}
                        />
                      )}
                    </IconButton>
                  </div>
                </div>
                {props.selectedReservationId === reservation.id && (
                  <>
                    <VisitedCustomerDetail reservation={reservation} />

                    {!props.isEditableVisitedNum && (
                      <NotEditableVisitedNumber
                        visitedNum={props.visitedNum}
                        reservation={reservation}
                        setIsEditableVisitedNum={props.setIsEditableVisitedNum}
                        color="#E4F551"
                      />
                    )}

                    {props.isEditableVisitedNum && (
                      <CounterVisitedNumber
                        visitedNum={props.visitedNum}
                        setVisitedNum={props.setVisitedNum}
                        reservation={reservation}
                        borderColor="border-cinekoya-yellow-2"
                      />
                    )}

                    {props.isEditableVisitedNum && (
                      <>
                        <SelectSoldTicketType
                          isLoading={props.isLoading}
                          setIsLoading={props.setIsLoading}
                          soldTicketTypes={props.soldTicketTypes}
                          setSoldTicketTypes={props.setSoldTicketTypes}
                          ticketTypes={props.ticketTypes}
                          setIsTicketTypeEmpty={props.setIsTicketTypeEmpty}
                          newSeats={props.visitedNum}
                        />
                        <div className="w-full text-center text-2xl py-2 h-[50px] text-cinekoya-yellow-1 font-bold">
                          {props.isTicketTypeEmpty && (
                            <span>すべての券種を選択して下さい</span>
                          )}
                        </div>
                      </>
                    )}
                    {props.isEditableVisitedNum && (
                      <>
                        <SubmitButton
                          onClickHandler={props.updateVisitedNum}
                          isLoading={props.isLoading}
                          buttonColor="bg-cinekoya-yellow-2 text-yt-component"
                          ThreeDotsColor="#282828"
                        >
                          来店済人数を修正する
                        </SubmitButton>

                        <div className="w-full mt-3 mb-[100px] text-center">
                          <button
                            className="w-1/2 rounded-2xl bg-yt-component text-yt-atom text-center py-2 hover:opacity-75 font-bold"
                            onClick={() => {
                              props.setIsEditableVisitedNum(false)
                              props.setVisitedNum(reservation.seats)
                            }}
                          >
                            キャンセル
                          </button>
                        </div>
                      </>
                    )}
                    {!props.isEditableVisitedNum && (
                      <div className="pb-5">
                        <SubmitButton
                          onClickHandler={props.cancelHasVisited}
                          isLoading={props.isLoading}
                          buttonColor="bg-yt-atom text-yt-text-gray"
                          ThreeDotsColor="#E4F551"
                        >
                          来店済を取り消す
                        </SubmitButton>
                      </div>
                    )}
                  </>
                )}
              </>
            </React.Fragment>
          ))}
      </Modal>
    </>
  )
}
