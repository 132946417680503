import React from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import DoneOutlineIcon from '@mui/icons-material/DoneOutline'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import CampaignIcon from '@mui/icons-material/Campaign'
import { Link } from 'react-router-dom'
interface Props {
  hasGeneralTicket: boolean
}
export const PaymentSuccess = (props: Props) => {
  const { hasGeneralTicket } = props
  return (
    <>
      <div className="w-full text-center texl-lg md:text-xl text-cinekoya-hover font-bold mt-11 font-body ">
        決済が確定しました。
        <br />
        ありがとうございました。
      </div>
      <div className="flex flex-col items-center font-body mt-3 px-2">
        <div className="grid grid-cols-1 grid-rows-3 gap-3 w-full md:w-[650px] bg-cinekoya-sub-d bg-opacity-50 text-zinc-600 rounded-xl py-4 px-3 shadow-sm">
          <div>
            <CheckCircleIcon />
            &emsp;
            <span className="align-middle">
              <b>予約完了メール</b>をお送りしましたので、ご確認ください。
            </span>
          </div>
          <div>
            <CheckCircleIcon />
            &emsp;<b>来店時</b>
            には、予約完了メールを<b>ご提示</b>いただく必要があります。
          </div>
          <div>
            <CheckCircleIcon />
            &emsp;しばらくたっても予約完了メールが<b>届かない場合</b>
            は、お電話でお問い合わせください。（<b>0466-33-5393</b>）
          </div>
        </div>

        {hasGeneralTicket && (
          <div className=" w-full md:w-[650px] mt-10 border-2 border-cinekoya-sub-d rounded-t-3xl rounded-br-3xl py-2 px-3 text-zinc-500 bg-stone-100 ">
            <div className="">
              <CampaignIcon fontSize="large" sx={{ color: '#A3C8CC' }} />
              「同日の別作品」をご予約される場合、一般料金が1,800円から1,100円に割引となります！同日の別作品を新たにweb予約される際には、予約完了メールに記載のクーポンコードをご利用下さい。
            </div>
          </div>
        )}
        <div className="text-center mt-5 text-sm text-zinc-500 font-bold">
          <Link to="/schedule">
            <u>スケジュールに戻る</u>
          </Link>
        </div>
      </div>
    </>
  )
}
