import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { CountNonReservedVisitor } from '../Presentational/CountNonReservedVisitor'

interface History {
  id: number
  seats: number
  memo: string
  created_at: string
}

interface TicketType {
  id: number
  name: string
  unit_price: number
}

interface MovieInfo {
  movie_id: number
  is_cinema: boolean
}

interface SeatsInfo {
  visited_non_reserved: number
  visited_web: number
  not_visited_web: number
  visited_tel: number
  not_visited_tel: number
  total_visited_reserved_number: number
  total_not_visited_reserved_seats: number
  capacity: number
  seats_left: number
}

interface Props {
  areaStyle: string
  buttonStyle: string
  date: string
  order: number
  movieInfo: MovieInfo
  getSeatsInfo: () => void
  seatsInfo: SeatsInfo
}
export const CountNonReservedVisitorContainer = (props: Props) => {
  const [newSeats, setNewSeats] = useState<number>(1)
  const [ticketTypes, setTicketTypes] = useState<TicketType[]>([])
  const [soldTicketTypes, setSoldTicketTypes] = useState<TicketType[]>([])

  const [totalSeats, setTotalSeats] = useState<number>(0)
  const [history, setHistory] = useState<History[]>([])

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [isTicketTypeEmpty, setIsTicketTypeEmpty] = useState<boolean>(false)

  const [memo, setMemo] = useState<string>('')

  useEffect(() => {
    getSeats()
  }, [props.date, props.order])

  const getSeats = () => {
    axios
      .get('get_non_reserved_visitor', {
        params: {
          date: props.date,
          order: props.order,
        },
      })
      .then((response) => {
        setTotalSeats(response.data.seats)
        setHistory(response.data.history)
      })
  }

  useEffect(() => {
    axios.get('ticket_types').then((response) => {
      setTicketTypes(response.data)
    })
  }, [])

  const saveHandler = async () => {
    setIsLoading(true)

    if (
      props.movieInfo.is_cinema &&
      soldTicketTypes.find((item) => item.name === '券種を選択')
    ) {
      setIsTicketTypeEmpty(true)
      setIsLoading(false)
      return
    }

    const formData = new FormData()

    formData.append('sold_ticket_types', JSON.stringify(soldTicketTypes))
    formData.append('date', props.date)
    formData.append('order', String(props.order))
    formData.append('seats', String(newSeats))
    formData.append('memo', memo)

    const response = await axios.post('add_non_reserved_visitor', formData)
    if (props.movieInfo.is_cinema) {
      formData.append('movie_id', String(props.movieInfo.movie_id))
      formData.append('management_id', response.data)
      await axios.post('register_sales_non_web', formData)
    }
    setNewSeats(1)
    setMemo('')
    getSeats()
    props.getSeatsInfo()
    setIsTicketTypeEmpty(false)
    setTimeout(() => setIsLoading(false), 700)
  }

  return (
    <CountNonReservedVisitor
      areaStyle={props.areaStyle}
      buttonStyle={props.buttonStyle}
      saveHandler={saveHandler}
      newSeats={newSeats}
      setNewSeats={setNewSeats}
      memo={memo}
      setMemo={setMemo}
      totalSeats={totalSeats}
      history={history}
      isLoading={isLoading}
      setIsLoading={setIsLoading}
      ticketTypes={ticketTypes}
      soldTicketTypes={soldTicketTypes}
      setSoldTicketTypes={setSoldTicketTypes}
      isTicketTypeEmpty={isTicketTypeEmpty}
      setIsTicketTypeEmpty={setIsTicketTypeEmpty}
      seatsInfo={props.seatsInfo}
      movieInfo={props.movieInfo}
    />
  )
}
