import React, { useEffect, useState } from 'react'
import { EventTemplate } from '../Templates/EventTemplate'
import { Campaign } from '../Organism/Campaign/Campaign'
import { CampaignParams } from '../../types/CampaignPrams'

export const PreviewCampaignPage = () => {
  const [campaign, setCampaign] = useState<CampaignParams>({} as CampaignParams)

  useEffect(() => {
    fetchPreview()
  }, [])

  const fetchPreview = () => {
    const storedParams = localStorage.getItem('preview_campaign_params')
    if (storedParams) {
      setCampaign(JSON.parse(storedParams) as CampaignParams)
      //  ストレージの削除はcloseModal上に記述
    }
  }

  const handleUpdate = () => {
    fetchPreview()
  }
  return (
    <>
      <div className="w-full text-right pt-9 px-4 mb-[-45px] md:mb-[-80px] z-60">
        <button
          className="inline-block bg-cinekoya-sub-c px-3 py-1 rounded-xl shadow-md text-white font-bold text-sm md:text-xl hover:bg-opacity-80 cursor-auto"
          onClick={() => handleUpdate()}
        >
          プレビュー更新
        </button>
      </div>
      <EventTemplate>
        <Campaign campaign={campaign} />
      </EventTemplate>
    </>
  )
}
