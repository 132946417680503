import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Movie } from '../../types/Movie'
import { Top } from '../Templates/Top'
import { CampaignParams } from '../../types/CampaignPrams'

interface Schedule {
  start_day: string
  end_day: string
}

interface MovieExtended extends Movie {
  startDay: string
  endDay: string
}

export const TopPage = () => {
  const [nowShowingMovies, setNowShowingMovies] = useState<MovieExtended[]>([])

  const [forcedNowShowingMovies, setForcedNowShowingMovies] = useState<
    MovieExtended[]
  >([])

  const [commingSoonMovies, setCommingSoonMovies] = useState<MovieExtended[]>(
    []
  )

  const [movieCampaigns, setMovieCampaigns] = useState<CampaignParams[]>([])

  const [eventCampaigns, setEventCampaigns] = useState<CampaignParams[]>([])

  const [nonCinemaEvents, setNonCinemaEvents] = useState<MovieExtended[]>([])
  const [schedules, setSchedules] = useState<Schedule[]>([])

  const [notificationTop, setNotificationTop] = useState<string>('')

  const [isBlankNotificationTop, setIsBlankNotificationTop] =
    useState<boolean>(false)
  useEffect(() => {
    axios.get('get_now_showing').then((response) => {
      setNowShowingMovies(response.data.now_showing_movies)
      setSchedules(response.data.schedule)
    })

    axios
      .get('get_forced_now_showing')
      .then((response) => setForcedNowShowingMovies(response.data))

    axios.get('get_comming_soon').then((response) => {
      setCommingSoonMovies(response.data)
    })

    axios.get('get_non_cinema_event').then((response) => {
      setNonCinemaEvents(response.data)
    })

    axios.get('get_notification_top').then((response) => {
      setNotificationTop(response.data.content)
      if (response.data.content === null) {
        setIsBlankNotificationTop(true)
      }

      axios.get('movie_campaign').then((response) => {
        setMovieCampaigns(response.data)
      })

      axios.get('event_campaign').then((response) => {
        setEventCampaigns(response.data)
      })
    })
  }, [])
  return (
    <>
      <Top
        nowShowingMovies={nowShowingMovies}
        forcedNowShowingMovies={forcedNowShowingMovies}
        commingSoonMovies={commingSoonMovies}
        movieCampaigns={movieCampaigns}
        eventCampaigns={eventCampaigns}
        nonCinemaEvents={nonCinemaEvents}
        schedules={schedules}
        notificationTop={notificationTop}
        isBlankNotificationTop={isBlankNotificationTop}
      />
    </>
  )
}
