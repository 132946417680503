import axios from 'axios'
import EditIcon from '@mui/icons-material/Edit'
import CloseIcon from '@mui/icons-material/Close'
import { ChangeEvent, useState } from 'react'

type Props = {
  sessionTime: number
  setSessionTime: (e: number) => void
}

export const SessionTimeSetting = (props: Props) => {
  const [editSessionTime, setEditSessionTime] = useState<boolean>(false)

  const saveSessionTime = () => {
    axios
      .put('common_setting_session', {
        session_time: props.sessionTime,
      })
      .then((response) => {
        props.setSessionTime(response.data)
        setEditSessionTime(false)
      })
      .catch((error) => console.log(error))
  }

  const changeSessionTime = (e: ChangeEvent<HTMLInputElement>) => {
    const display_limit = Number(e.target.value)

    if (display_limit < 1) props.setSessionTime(1)
    else {
      props.setSessionTime(Number(e.target.value))
    }
  }

  return (
    <>
      <div className="flex flex-row pt-2  text-xl">
        <div className="basis-3/4 text-center">
          {editSessionTime ? (
            <>
              <input
                type="number"
                className=" w-20  ml-1 text-center font-bold text-cinekoya border-solid  border-1 border-yt-atom focus:outline-none focus:ring-2 focus:ring-cinekoya-sub-c focus:border-transparent"
                value={props.sessionTime}
                onChange={changeSessionTime}
              />
              <span>分間</span>
              <button
                className="text-center inline  rounded-2xl ml-3 px-2 py-1 text-sm bg-yt-atom hover:bg-yt-atom-hover cursor-pointer "
                onClick={saveSessionTime}
              >
                保存
              </button>
            </>
          ) : (
            <>
              <span className="font-bold text-cinekoya-sub-a">
                {props.sessionTime}
              </span>
              &nbsp;分間
            </>
          )}
        </div>

        <div className="basis-1/4 text-right pr-3 cursor-pointer">
          {editSessionTime ? (
            <CloseIcon
              fontSize="large"
              onClick={() => setEditSessionTime(!editSessionTime)}
            />
          ) : (
            <EditIcon
              fontSize="large"
              onClick={() => setEditSessionTime(!editSessionTime)}
            />
          )}
        </div>
      </div>
    </>
  )
}
