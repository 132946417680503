import axios from 'axios'
import { useContext, useEffect, useState } from 'react'
import { SelectSeatNumber } from '../../Organism/Reservation/Presentational/SelectSeatNumber'
import { ReserveBaseParamsContext } from '../../../hooks/ReserveBaseParamsContext'
import { Reservation } from '../../Templates/Reservation'
import { useNavigate } from 'react-router-dom'

export const SelectSeatPage = () => {
  const { reserveBaseParams, setReserveBaseParams } = useContext(
    ReserveBaseParamsContext
  )
  const [reservableNum, setReservableNum] = useState<number>(0)

  const navigate = useNavigate()

  const getReservableNumber = () => {
    axios
      .get('get_reservable_number', {
        params: {
          date: reserveBaseParams.date,
          order: reserveBaseParams.order,
        },
      })
      .then((response) => {
        setReservableNum(response.data.reservable_num)
      })
  }

  useEffect(() => {
    if (reserveBaseParams.order === -1) {
      navigate('/schedule')
      return
    }
    axios
      .get('get_movie', {
        params: {
          order: reserveBaseParams.order,
          date: reserveBaseParams.date,
        },
      })
      .then((response) => {
        setReserveBaseParams({
          ...reserveBaseParams,
          movie_id: response.data.movie.movie_id,
          is_cinema: response.data.movie.is_cinema,
          movie: response.data.movie,
        })
      })

    getReservableNumber()
  }, [])

  return (
    <>
      <Reservation>
        <SelectSeatNumber
          reservableNum={reservableNum}
          setReservableNum={setReservableNum}
          getReservableNumber={getReservableNumber}
        />
      </Reservation>
    </>
  )
}
