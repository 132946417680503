import React from 'react'
import ContentLoader from 'react-content-loader'

const MyLoader = () => (
  <ContentLoader
    speed={1}
    width={'95%'}
    height={'1000px'}
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="3" y="10" rx="3" ry="3" width="100%" height="180" />
    <rect x="0" y="200" rx="3" ry="3" width="100%" height="180" />
    <rect x="0" y="400" rx="3" ry="3" width="100%" height="180" />
  </ContentLoader>
)

export default MyLoader
