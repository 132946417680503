import { ChangeEvent } from 'react'
import EditIcon from '@mui/icons-material/Edit'
import CloseIcon from '@mui/icons-material/Close'
import HelpRoundedIcon from '@mui/icons-material/HelpRounded'
import { styled } from '@mui/material/styles'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'

interface Props {
  editLimit: boolean
  commonLimit: number
  isForcedLimit: boolean
  setEditLimit: (e: boolean) => void
  setCommonLimit: (e: number) => void
  saveLimit: () => void
  saveForcedLimit: () => void
  cancelForcedLimit: () => void
}
export const SetCommonLimit = (props: Props) => {
  const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 500,
    },
  })

  const Text = () => {
    return (
      <div className=" py-2 px-2 rounded-xl  text-stone-100 text-sm">
        コマ単位／作品単位で予約数上限を設定しているコマ／作品にはこの予約数上限は適用されず、個々に設定している上限が適用されます。適用の優先順位は「コマ設定&nbsp;＞作品設定&nbsp;＞共通設定」となります。ただし「強制適用」を実施した場合は共通設定の上限がコマ設定／作品設定が優先されます。
      </div>
    )
  }
  return (
    <>
      {!props.editLimit && (
        <>
          <div className="flex flex-row justify-center">
            <div className="w-3/4 rounded-xl bg-yt-component text-center text-white">
              <div className="flex flex-row">
                <div className="basis-1/5"></div>
                <div className="basis-3/5">
                  <span className="text-lg align-middle">
                    共通予約数上限：&emsp;
                  </span>
                  <span className="text-xl font-bold align-middle text-cinekoya-sub-a">
                    {props.commonLimit} &nbsp;席
                  </span>
                </div>
                <div className="basis-1/5 text-right pr-4">
                  <EditIcon
                    fontSize="medium"
                    sx={{ cursor: 'pointer' }}
                    onClick={() => props.setEditLimit(!props.editLimit)}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {props.editLimit && (
        <>
          <div className="flex justify-center">
            <div className="w-3/4 rounded-2xl bg-yt-component py-2 px-3 text-center">
              <div className="flex flex-row text-white">
                <div className="basis-1/6">
                  <input
                    type="number"
                    className="  w-2/3  mt-1 text-center text-xl font-bold text-cinekoya border-solid  border-1 border-yt-atom focus:outline-none focus:ring-2 focus:ring-cinekoya-sub-c focus:border-transparent "
                    value={props.commonLimit}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      props.setCommonLimit(Number(e.target.value))
                    }
                  />
                  &nbsp;
                  <div className="inline-block pt-1 mr-2">
                    <span className="text-sm ">席</span>
                  </div>
                </div>
                <div className="basis-4/6 pt-1">
                  <button
                    className="w-1/3 text-center  rounded-2xl  px-3 py-1 text-base bg-cinekoya-sub-c hover:bg-yt-atom-hover cursor-pointer "
                    onClick={props.saveLimit}
                  >
                    保存
                  </button>
                  &emsp;
                  {!props.isForcedLimit ? (
                    <button
                      className="w-1/2 text-center  rounded-2xl px-1.5 py-1 text-sm bg-yt-atom hover:bg-yt-atom-hover cursor-pointer "
                      onClick={props.saveForcedLimit}
                    >
                      強制適用&nbsp;
                      <CustomWidthTooltip
                        title={
                          <>
                            <Text />
                          </>
                        }
                      >
                        <HelpRoundedIcon
                          fontSize="small"
                          sx={{ color: 'white', verticalAlign: '-3px' }}
                        />
                      </CustomWidthTooltip>
                    </button>
                  ) : (
                    <button
                      className="w-1/2 text-center  rounded-2xl text-white-gray pl-2 py-1 text-sm bg-cinekoya hover:bg-cinekoya-hover cursor-pointer "
                      onClick={props.cancelForcedLimit}
                    >
                      強制適用解除 &nbsp;
                      <CustomWidthTooltip
                        title={
                          <>
                            <Text />
                          </>
                        }
                      >
                        <HelpRoundedIcon
                          fontSize="small"
                          sx={{ color: 'white', verticalAlign: '-3px' }}
                        />
                      </CustomWidthTooltip>
                    </button>
                  )}
                </div>
                <div className="basis-1/6 text-right pr-2">
                  <CloseIcon
                    fontSize="medium"
                    sx={{
                      color: 'white',
                      verticalAlign: 'middle',
                      cursor: 'pointer',
                    }}
                    onClick={() => props.setEditLimit(!props.editLimit)}
                  />
                </div>
              </div>{' '}
              {props.isForcedLimit && (
                <div className="flex flex-row">
                  <div className="basis-1/5 text-left ">
                    <span className="text-cinekoya-yellow-1 font-bold font-body">
                      強制適用中
                    </span>
                  </div>
                  <div className="basis-4/5"></div>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  )
}
