import axios from 'axios'
import dayjs from 'dayjs'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { UrlContext } from '../../hooks/UrlContext'
import { CinemaDetail } from '../Organism/CinemaDetail/CinemaDetail'
import { EventTemplate } from '../Templates/EventTemplate'
import { CampaignParams } from '../../types/CampaignPrams'
import { OtherEventList } from '../Molecules/OtherEventList'
import { Movie } from '../../types/Movie'

interface Notification {
  movie_id: number
  content: string
}

interface Detail {
  title: string
  detail: string
  image: string
  trailer_url: string
}

interface Schedule {
  id: number
  movie_id: number
  start_day: string
  end_day: string
  start_hour: string
  start_minutes: string
  end_hour: string
  end_minutes: string
}

export const NonCinemaDetailPage = () => {
  const { event_id } = useParams<{ event_id: string }>()

  const { imageURL } = useContext(UrlContext)

  const defaultDetail = {
    title: '',
    detail: '',
    image: '',
    trailer_url: '',
  }

  const [detail, setDetail] = useState<Detail>(defaultDetail)

  const [notification, setNotification] = useState<Notification | null>(null)

  const [schedule, setSchedule] = useState<Schedule[] | null>([])

  const [nonCinemaEvents, setNonCinemaEvents] = useState<Movie[]>([])

  const [campaigns, setCampaigns] = useState<CampaignParams[]>([])
  //MDEditor.Markdownのaタグのcolorを変更(直接指定できないため)
  // const aTag = document.getElementsByTagName('a')
  // for (let i = 0; i < aTag.length; ++i) {
  //   aTag[i].style.color = '#A94337'
  // }

  const now = dayjs()
  useEffect(() => {
    axios
      .get('non_cinema_detail', {
        params: {
          date: now?.format('YYYY-MM-DD'),
          event_id: event_id,
        },
      })
      .then((response) => {
        setDetail(response.data.non_cinema_detail)
        setNotification(response.data.notification)
        setSchedule(response.data.schedules)
        setCampaigns(response.data.campaigns)
      })

    axios.get('event_campaign').then((response) => {
      setCampaigns(response.data)
    })

    axios.get('get_non_cinema_event').then((response) => {
      setNonCinemaEvents(response.data)
    })
  }, [])

  return (
    <EventTemplate>
      <CinemaDetail
        detail={detail}
        schedule={schedule}
        notification={notification}
        is_cinema={false}
      >
        <img src={imageURL + detail?.image} className="m-auto" />
      </CinemaDetail>
      <OtherEventList
        event_id={Number(event_id)}
        campaign_id={-1}
        nonCinemaEvents={nonCinemaEvents}
        campaigns={campaigns}
      />
    </EventTemplate>
  )
}
