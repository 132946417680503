import React, { ChangeEvent, ReactNode } from 'react'

interface Props {
  label: string
  bgColor: string
  mt: number
  children: ReactNode
}

export const InputBoxRounded = (props: Props) => {
  return (
    <div className={`flex flex-row mt-${props.mt} justify-center items-center`}>
      <div
        className={`h-8 md:h-10 basis-32 sm:basis-48  rounded-l-2xl bg-${props.bgColor}  text-white  text-sm sm:text-base text-center pt-2`}
      >
        {props.label}
      </div>

      {props.children}
    </div>
  )
}
