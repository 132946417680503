import React, { useContext, useEffect, useState } from 'react'
import { ReserveBaseParamsContext } from '../../../../hooks/ReserveBaseParamsContext'
import { NextStepButton } from '../../../Atoms/NextStepButton'
import { PrevStepButton } from '../../../Atoms/PrevStepButton'

import { RowBreakdown } from './RowBreakdown'
import { CountNonCinemaTicket } from './CountNonCinemaTicket'

interface CustomPrice {
  event_price_1: number
  event_price_2: number
  event_price_3: number
  event_price_4: number
  ep_1_label: string
  ep_2_label: string
  ep_3_label: string
  ep_4_label: string
}

interface CustomTicketNum {
  price_1_num: number
  price_2_num: number
  price_3_num: number
  price_4_num: number
}
interface Props {
  totalPrice: number
  setTotalPrice: React.Dispatch<React.SetStateAction<number>>
  setBreakDown: React.Dispatch<React.SetStateAction<string>>
  backToSeat: () => void
  nextToCreditinfo: () => void
  customPrice: CustomPrice
  countNotEnough: boolean
  totalTicketNum: number
  setTotalTicketNum: React.Dispatch<React.SetStateAction<number>>
}

export const NonMovieFeeChecker = (props: Props) => {
  const customPrice = props.customPrice
  const height = 9
  const paddingTop = 1
  const widthInput = 32
  const labelClass = ' text-lg  text-zinc-700 pb-1'
  const { reserveBaseParams } = useContext(ReserveBaseParamsContext)

  const [ticketNum, setTicketNum] = useState<CustomTicketNum>({
    price_1_num: 0,
    price_2_num: 0,
    price_3_num: 0,
    price_4_num: 0,
  })

  useEffect(() => {
    const totalNum =
      ticketNum.price_1_num +
      ticketNum.price_2_num +
      ticketNum.price_3_num +
      ticketNum.price_4_num
    props.setTotalTicketNum(totalNum)
    props.setTotalPrice(
      totalNum !== 0
        ? ticketNum.price_1_num * customPrice.event_price_1 +
            ticketNum.price_2_num * customPrice.event_price_2 +
            ticketNum.price_3_num * customPrice.event_price_3 +
            ticketNum.price_4_num * customPrice.event_price_4
        : 0
    )

    const ep2_set =
      customPrice.event_price_2 !== 0
        ? `/${customPrice.ep_2_label}:${customPrice.event_price_2}:${ticketNum.price_2_num}枚`
        : null

    const ep3_set =
      customPrice.event_price_3 !== 0
        ? `/${customPrice.ep_3_label}:${customPrice.event_price_3}:${ticketNum.price_3_num}枚`
        : null

    const ep4_set =
      customPrice.event_price_4 !== 0
        ? `/${customPrice.ep_4_label}:${customPrice.event_price_4}:${ticketNum.price_4_num}枚`
        : null

    props.setBreakDown(
      totalNum !== 0
        ? `${customPrice.ep_1_label}:${customPrice.event_price_1}:${ticketNum.price_1_num}枚${ep2_set}${ep3_set}${ep4_set}`
        : ''
    )
  }, [ticketNum])

  const countUp = (e: number) => {
    if (
      reserveBaseParams.seat - props.totalTicketNum - 1 >= 0 &&
      e >= 1 &&
      e <= 4
    ) {
      const key = `price_${e}_num` as keyof CustomTicketNum
      setTicketNum({ ...ticketNum, [key]: ticketNum[key] + 1 })
    }
  }

  const countDown = (e: number) => {
    if (e >= 1 && e <= 4) {
      const key = `price_${e}_num` as keyof CustomTicketNum
      setTicketNum({
        ...ticketNum,
        [key]: ticketNum[key] - 1 < 0 ? 0 : ticketNum[key] - 1,
      })
    }
  }
  return (
    <>
      <div className="w-full text-center  sm:px-0 mt-4 mb-4 font-body">
        {[1, 2, 3, 4].map((i) => {
          const eventPrice =
            customPrice[`event_price_${i}` as keyof CustomPrice]
          const epLabel = customPrice[`ep_${i}_label` as keyof CustomPrice]
          const ticketValue =
            ticketNum[`price_${i}_num` as keyof CustomTicketNum]

          return Number(eventPrice) > 0 ? (
            <div key={i} className="mb-4">
              <div className={labelClass}>
                {epLabel}({eventPrice.toLocaleString()}円)
              </div>
              <CountNonCinemaTicket
                value={ticketValue}
                countDown={() => countDown(i)}
                countUp={() => countUp(i)}
                unit={'枚'}
                widthInput={widthInput}
                height={height}
                paddingTop={paddingTop}
              />
            </div>
          ) : null
        })}

        <div className="block w-full md:w-1/2 h-auto border-x-4 border-t-4 border-stone-200 border-b-0 rounded-t-lg  py-2 m-auto transition duration-300 ease-in-out ">
          {[1, 2, 3, 4].map((i) => {
            const eventPrice =
              customPrice[`event_price_${i}` as keyof CustomPrice]
            const epLabel = customPrice[`ep_${i}_label` as keyof CustomPrice]
            const ticketValue =
              ticketNum[`price_${i}_num` as keyof CustomTicketNum]

            return Number(eventPrice) > 0 ? (
              <RowBreakdown
                key={i}
                label={String(epLabel)}
                unitPrice={Number(eventPrice)}
                num={ticketValue}
                totalPrice={Number(eventPrice) * ticketValue}
              />
            ) : null
          })}
        </div>
        <div className="block w-full md:w-1/2 border-4 border-stone-200 rounded-b-lg py-2 px-3 m-auto bg-stone-100">
          <div className=" inline-block w-1/2 text-left text-xl">
            チケット合計:
          </div>
          <div className=" inline-block w-1/2 text-right text-3xl text-cinekoya font-bold">
            ¥&nbsp;{props.totalPrice.toLocaleString()}
          </div>
        </div>
      </div>

      {props.countNotEnough && (
        <div className="w-full flex justify-center  mb-3">
          <div className="w-1/2 font-bold bg-cinekoya-yellow-2 text-zinc-700 opacity-90 px-2 py-2 rounded-xl ">
            選択されたチケット枚数は{reserveBaseParams.seat}枚ですが、
            {props.totalTicketNum}枚分しか券種が選択されていません。
          </div>
        </div>
      )}

      <div className="w-full text-center mb-3">
        <NextStepButton
          onClick={props.nextToCreditinfo}
          bgColor={'cinekoya'}
          disabled={false}
        >
          次へ
        </NextStepButton>
      </div>
      <div className="w-full text-center">
        <PrevStepButton onClick={props.backToSeat}>戻る</PrevStepButton>
      </div>
    </>
  )
}
