import axios from 'axios'
import React, { useState } from 'react'
import { CreateEventNotification } from '../Presentational/CreateEventNotification'

interface EventList {
  id: number
  title: string
  endDay: string
}

interface Props {
  event: EventList
  activeEditorId: number
  setActiveEditorId: (e: number) => void
  getEventList: () => void
}

export const CreateEventNotificationContainer = (props: Props) => {
  const [editingValue, setEditingValue] = useState<string>('')
  const [editorMode, setEditorMode] = useState<'live' | 'edit' | 'preview'>(
    'edit'
  )

  const [expiration, setExpiration] = useState<string>(props.event.endDay)
  const createNotificationEvent = async () => {
    if (!editingValue) {
      return
    }
    // const valueEscaped = editingValue
    //   ?.replace(/&/g, '&lt;')
    //   .replace(/</g, '&lt;')
    //   .replace(/>/g, '&gt;')
    //   .replace(/"/g, '&quot;')
    //   .replace(/'/g, '&#x27;')

    await axios
      .post('create_notification_event', {
        movie_id: props.event.id,
        content: editingValue,
        expiration: expiration,
      })
      .then(() => {
        props.setActiveEditorId(0)
        setEditorMode('preview')
        props.getEventList()
      })
  }

  return (
    <CreateEventNotification
      editorMode={editorMode}
      setEditorMode={setEditorMode}
      editingValue={editingValue}
      setEditingValue={setEditingValue}
      expiration={expiration}
      setExpiration={setExpiration}
      createNotificationEvent={createNotificationEvent}
      event={props.event}
      activeEditorId={props.activeEditorId}
      setActiveEditorId={props.setActiveEditorId}
    />
  )
}
