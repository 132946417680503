import { Link } from 'react-router-dom'
import { ReservationHeader } from '../../Molecules/ReservationHeader'
import { useEffect } from 'react'

export const SessionExpiredPage = () => {
  useEffect(() => {
    localStorage.removeItem('reserveBaseParams')
  }, [])

  return (
    <>
      <div className="flex justify-center">
        <div className="w-full md:w-5/6">
          <ReservationHeader />
        </div>
      </div>
      <div className="w-full text-center texl-lg md:text-xl text-cinekoya-hover font-bold mt-11 font-body ">
        予約手続きの有効期限が切れました。
        <br />
        最初から予約をやり直してください。
      </div>
      <div className="text-center mt-5 text-sm text-zinc-500 font-bold">
        <Link to="/schedule">
          <u>スケジュールに戻る</u>
        </Link>
      </div>
    </>
  )
}
