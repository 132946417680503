import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { StaticPageParams } from '../../../../types/StaticPageParams'
import { MainImageCandidates } from '../../../../types/MainImageCandidates'
import { StaticPageCreator } from '../Presentational/StaticPageCreator'

export const StaticPageCreatorContainer = () => {
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false)

  const [isEditmode, setIsEditmode] = useState<boolean>(false)
  const [saveDisabled, setSaveDisabled] = useState<boolean>(false)
  const [mainImageCandidates, setMainImageCandidates] = useState<
    MainImageCandidates[]
  >([])
  const defaultObject: StaticPageParams = {
    id: -1,
    page_name: '',
    path_name: '',
    content: '',
    display_public: true,
    main_image: '',
  }
  const [staticPages, setStaticPages] = useState<StaticPageParams[]>([])

  const [postParams, setPostParams] = useState<StaticPageParams>(defaultObject)

  useEffect(() => {
    getStaticPages()
  }, [])

  useEffect(() => {
    if (postParams.id !== -1) {
      getMainImageCandidates()
    }
  }, [postParams.id])

  useEffect(() => {
    //blank check
    if (
      postParams.page_name === '' ||
      postParams.path_name === '' ||
      postParams.content === ''
    ) {
      setSaveDisabled(true)
    } else {
      setSaveDisabled(false)
    }

    //previewのためにローカルストレージに保存
    localStorage.setItem(
      'preview_staticpage_params',
      JSON.stringify(postParams)
    )
  }, [postParams.page_name, postParams.path_name, postParams.content])

  const handleEditorChange = (content: string) => {
    setPostParams({ ...postParams, content: content })
  }

  const getMainImageCandidates = async () => {
    const response = await axios.get('staticpage_main_image_candidates', {
      params: { staticpage_id: postParams.id },
    })

    setMainImageCandidates(response.data)
  }

  const getStaticPages = () => {
    axios.get('get_staticpage_list').then((response) => {
      setStaticPages(response.data)
      setPostParams(defaultObject)
    })
  }

  const createStaticPage = (isPublic: boolean) => {
    const params = {
      ...postParams,
      display_public: isPublic,
    }

    const paramsJson = JSON.stringify(params)
    const formData = new FormData()
    formData.append('params', paramsJson)
    axios.post('create_staticpage', formData).then(() => {
      closeModal()
      getStaticPages()
    })
  }

  const updateStaticPage = (isPublic: boolean) => {
    const params = {
      ...postParams,
      display_public: isPublic,
    }

    const paramsJson = JSON.stringify(params)
    const formData = new FormData()
    formData.append('params', paramsJson)
    axios.post('update_staticpage', formData).then(() => {
      closeModal()
      getStaticPages()
    })
  }

  const deleteStaticPage = () => {
    const paramsJson = JSON.stringify(postParams)
    const formData = new FormData()
    formData.append('params', paramsJson)
    axios.post('delete_staticpage', formData).then(() => {
      closeModal()
      getStaticPages()
    })
  }

  const deleteUnuseImages = () => {
    axios.delete('delete_unused_images').then(() => {
      setMainImageCandidates([])
    })
  }

  const closeModal = () => {
    setIsModalOpen(false)
    setSaveDisabled(true)
    setIsEditmode(false)
    deleteUnuseImages()
    setPostParams(defaultObject)
    //previewのためにローカルストレージも削除
    localStorage.removeItem('preview_staticpage_params')
  }

  return (
    <>
      <StaticPageCreator
        createStaticPage={createStaticPage}
        updateStaticPage={updateStaticPage}
        deleteStaticPage={deleteStaticPage}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        defaultObject={defaultObject}
        postParams={postParams}
        setPostParams={setPostParams}
        handleEditorChange={handleEditorChange}
        getMainImageCandidates={getMainImageCandidates}
        saveDisabled={saveDisabled}
        staticPages={staticPages}
        isEditmode={isEditmode}
        setIsEditmode={setIsEditmode}
        mainImageCandidates={mainImageCandidates}
        setMainImageCandidates={setMainImageCandidates}
        closeModal={closeModal}
      />
    </>
  )
}
