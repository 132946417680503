import { useContext, useEffect, useState } from 'react'
import { Post } from '../../types/Post.'
import { PostParamsContext } from '../postParamsContext'

interface Props {
  file: File | null
  isReflectMovieinfo: boolean
  selectedCinemaSchedule: Post | undefined | null
  movieList: Post[]
}

export const useSaveDisabled = (props: Props) => {
  const { postParams } = useContext(PostParamsContext)

  const [saveDisabled, setSaveDisabled] = useState<boolean>(true)

  const [isDuplicatedTitle, setIsDuplicatedTitle] = useState<boolean>(false)

  useEffect(() => {
    //未入力バリデーション（作品基本情報は省略可としている）

    let is_duplicated_title = false
    if (
      postParams.cinema_id === -1 &&
      props.movieList.find((item) => item.title === postParams.title)
    ) {
      is_duplicated_title = true
      setIsDuplicatedTitle(true)
    } else if (
      postParams.cinema_id !== -1 &&
      props.movieList.find(
        (item) =>
          item.id !== postParams.cinema_id && item.title === postParams.title
      )
    ) {
      is_duplicated_title = true
      setIsDuplicatedTitle(true)
    } else {
      is_duplicated_title = false
      setIsDuplicatedTitle(false)
    }

    if (
      postParams.title === '' ||
      postParams.detail === '' ||
      (postParams.is_cinema && postParams.trailer_url === '') ||
      (!postParams.is_cinema &&
        postParams.non_cinema_reservable &&
        postParams.event_price_1 === 0) ||
      postParams.order === 0 ||
      (!postParams.time_unsettled && postParams.start_hour === '') ||
      (!postParams.time_unsettled && postParams.start_minutes === '') ||
      (!postParams.time_unsettled && postParams.end_hour === '') ||
      (!postParams.time_unsettled && postParams.end_minutes === '') ||
      (!props.isReflectMovieinfo &&
        props.selectedCinemaSchedule?.cinema_id === -1 &&
        props.file === null) ||
      (props.isReflectMovieinfo &&
        props.file === null &&
        postParams.image === '') ||
      is_duplicated_title
    ) {
      setSaveDisabled(true)
    } else {
      setSaveDisabled(false)
    }
  }, [
    postParams.is_cinema,
    postParams.title,
    postParams.detail,
    postParams.event_price_1,
    postParams.trailer_url,
    postParams.order,
    postParams.start_hour,
    postParams.start_minutes,
    postParams.end_hour,
    postParams.end_minutes,
    props.file,
  ])

  return { saveDisabled, isDuplicatedTitle }
}
