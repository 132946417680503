import React from 'react'
import { HomeHeader } from '../Molecules/HomeHeader'
import { FooterSnsIcon } from '../Molecules/FooterSnsIcon'
import LogoSmall from '../../assets/logo_small.svg'

interface Props {
  children: React.ReactNode
}
export const EventTemplate = (props: Props) => {
  return (
    <div className="flex flex-row justify-center pb-20 ">
      <div className="curtain-area"></div>
      <div className="basis-full max-w-[960px] ">
        <HomeHeader />
        {props.children}
        <FooterSnsIcon />
        <div className="w-full mt-1 text-center text-cinekoya">
          <img src={LogoSmall} alt="シネコヤ" className="m-auto" />
          <p className="txt-m">&copy; cinekoya</p>
        </div>
      </div>
    </div>
  )
}
