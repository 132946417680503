import dayjs from 'dayjs'
import React, { useContext } from 'react'
import Modal from 'react-modal'
import CloseIcon from '@mui/icons-material/Close'
import { useNavigate } from 'react-router-dom'
import { ReserveBaseParamsContext } from '../../../../hooks/ReserveBaseParamsContext'
import { NextStepButton } from '../../../Atoms/NextStepButton'
import { PrevStepButton } from '../../../Atoms/PrevStepButton'
import ReportProblemIcon from '@mui/icons-material/ReportProblem'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'

interface Props {
  reservableNum: number
  confirmModalIsOpen: boolean
  setConfirmModalIsOpen: (e: boolean) => void
  soldoutModalIsOpen: boolean
  setSoldoutModalIsOpen: (e: boolean) => void
  isPastTime: boolean
  limitTime: number
  createTentativeReservation: () => void
  updateTentativeReservation: () => void
  returnPreviousProcess: () => void
  previousSeat: number
}
export const ConfirmSession = (props: Props) => {
  const navigate = useNavigate()
  const { reserveBaseParams } = useContext(ReserveBaseParamsContext)
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '100%',
      maxWidth: '500px',
      height: '65vh',
      maxHeight: '450px',
      border: '1px solid #3090A0 ',
      borderRadius: '1%',
      background: 'rgba(28,28,28,0.7)',
      color: 'white',
      fontFamily: 'Roboto, sans-serif',
    },
    overlay: {
      top: 0,
      left: 0,
      backgroundColor: 'rgba(0,0,0,0.85)',
      zIndex: 5,
    },
  }

  const closeConfirmModal = () => {
    props.setConfirmModalIsOpen(false)
  }

  const closeSoldoutModal = () => {
    props.setSoldoutModalIsOpen(false)
  }

  return (
    <>
      <div className="w-full text-center mt-10 mb-2">
        {' '}
        <NextStepButton
          onClick={props.createTentativeReservation}
          bgColor={'cinekoya'}
          disabled={false}
        >
          利用規約に同意して次へ
        </NextStepButton>
      </div>

      <div className="w-full text-center">
        <PrevStepButton onClick={() => navigate('/schedule')}>
          戻る
        </PrevStepButton>
      </div>

      <Modal
        isOpen={props.confirmModalIsOpen}
        style={customStyles}
        onRequestClose={closeConfirmModal}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <div className="w-full text-right pr-1 text-stone-400">
          <CloseIcon onClick={closeConfirmModal} sx={{ cursor: 'pointer' }} />
        </div>
        <div className="text-lg font-bold text-center mb-4">
          予約手続き中の座席があります。
        </div>
        <div className=" text-center mb-1 text-cinekoya-yellow-2">
          {reserveBaseParams.movie?.title}
        </div>
        <div className="text-center mb-5 text-cinekoya-yellow-2">
          {dayjs(reserveBaseParams.date).format('M/D(dd)')}&emsp;
          {reserveBaseParams.movie?.start_hour}:
          {reserveBaseParams.movie?.start_minutes}～
          {reserveBaseParams.movie?.end_hour}:
          {reserveBaseParams.movie?.end_minutes}
          &emsp;{props.previousSeat}席
        </div>

        <div className="text-center mb-4">
          キャンセルして新たな
          <br />
          予約手続きに進みますか？
        </div>
        <div className="w-full text-center mb-3">
          <NextStepButton
            onClick={props.returnPreviousProcess}
            bgColor={'cinekoya'}
            disabled={false}
          >
            手続き中の予約に戻る
          </NextStepButton>
        </div>
        <div className="w-full text-center">
          <NextStepButton
            onClick={props.updateTentativeReservation}
            bgColor={'cinekoya-sub-b'}
            disabled={false}
          >
            新たな予約手続きに進む
          </NextStepButton>
        </div>
      </Modal>

      <Modal
        isOpen={props.soldoutModalIsOpen}
        style={customStyles}
        onRequestClose={closeSoldoutModal}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <div className="w-full text-right pr-1 text-stone-400">
          <CloseIcon onClick={closeSoldoutModal} sx={{ cursor: 'pointer' }} />
        </div>
        <div className="w-full text-center pr-1 text-cinekoya-yellow-2">
          <ReportProblemIcon fontSize="large" />
        </div>
        <div className="text-lg  text-left mt-4 mb-4 px-4 ">
          申し訳こざいません。
          {props.reservableNum !== -1 && (
            <>
              この手続き中に予約可能席数が
              <span className="text-cinekoya-yellow-2 font-bold">
                残り{props.reservableNum}席
              </span>
              に更新されました。
              {props.reservableNum > 0 &&
                'そのため、選択された席数では予約手続きを進めることができません.予約手続きを進めるためには席数を減らしていただく必要がございます。'}
              {props.reservableNum === 0 &&
                '恐れ入りますが、この回についてはWEB予約の手続きを進めることができません。'}
            </>
          )}
          {props.reservableNum === -1 && (
            <>
              WEB予約は上映開始の{props.limitTime}分前までとなっております。
              <br />
              <br />
              恐れ入りますが、この回についてはWEB予約の手続きを進めることができません。
            </>
          )}
        </div>{' '}
        {props.reservableNum <= 0 && (
          <div className="w-full text-center">
            <PrevStepButton onClick={() => navigate('/schedule')}>
              <ArrowBackIosNewIcon />
              &emsp; スケジュール画面に戻る
            </PrevStepButton>
          </div>
        )}
      </Modal>
    </>
  )
}
