import React from 'react'
import { Reservation } from '../../../../types/Reservation/Reservation'

interface Props {
  reservation: Reservation
}
export const CustomerDetail = (props: Props) => {
  return (
    <div className="grid grid-cols-4 grid-rows-2 px-3">
      <div className="col-span-1 row-span-1 border-1 border-yt-atom pl-2 py-1">
        電話番号
      </div>
      <div className="col-span-3 row-span-1  border-1 border-yt-atom px-1 py-1">
        {props.reservation.phone}
      </div>
      <div className="col-span-1 row-span-1  border-1 border-yt-atom pl-2 py-1">
        備考
      </div>
      <div className="col-span-3 row-span-1  border-1 border-yt-atom px-1 py-1">
        {props.reservation.memo}
      </div>
    </div>
  )
}
