import './App.css'
import { BrowserRouter } from 'react-router-dom'
import { AuthProvider } from './hooks/AuthContext'
import AppRouter from './AppRouter'
import { MailVerifiedProvider } from './hooks/MailVerifiedContext'
import { UrlProvider } from './hooks/UrlContext'
import { PostParamsProvider } from './hooks/postParamsContext'
import { ReserveBaseParamsProvider } from './hooks/ReserveBaseParamsContext'

function App() {
  // const [login,setLogin] = useState(false);

  return (
    <div className="App">
      <AuthProvider>
        <MailVerifiedProvider>
          <UrlProvider>
            <PostParamsProvider>
              <ReserveBaseParamsProvider>
                <BrowserRouter>
                  {/* <Nav/> */}
                  {/* <Logo/>
          <SearchBar/> */}

                  <AppRouter />
                </BrowserRouter>
              </ReserveBaseParamsProvider>
            </PostParamsProvider>
          </UrlProvider>
        </MailVerifiedProvider>
      </AuthProvider>
    </div>
  )
}

export default App
