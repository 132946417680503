import React, { useContext, useEffect, useState } from 'react'
import { ReserveBaseParamsContext } from '../../../../hooks/ReserveBaseParamsContext'
import { PrevStepButton } from '../../../Atoms/PrevStepButton'
import { InputPeopleBreakdown } from './InputPeopleBreakdown'
import { RowBreakdown } from './RowBreakdown'
import ExpandCircleDownRoundedIcon from '@mui/icons-material/ExpandCircleDownRounded'
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded'
import ChargeList from '../../../../assets/ChargeList.png'
import { useNavigate } from 'react-router-dom'
import { SubmitTicketBreakdownContainer } from '../Container/SubmitTicketBreakdown.container'
import { Link as Scroll } from 'react-scroll'
import { Oval } from 'react-loader-spinner'
import { CheckDuplicatedContainer } from '../Container/CheckDuplicated.container'
import { DiscountInfo } from '../../../../types/Reservation/DiscountInfo'

interface Result {
  id: number
  name: string
  ticketNum: number
  unitPrice: number
  totalPrice: number
}

interface Props {
  fanclubNum: number
  setfanclubNum: (e: number) => void
  membersNum: number
  setMembersNum: (e: number) => void
  youthNum: number
  setYouthNum: (e: number) => void
  underYouthNum: number
  setUnderYouthNum: (e: number) => void
  infantNum: number
  setInfantNum: (e: number) => void
  countRemainder: number
  aggregateResults: Result[]
  grandTotalPrice: number
  discountInfo: DiscountInfo
  setDiscountInfo: (e: DiscountInfo) => void
}

export const TicketBreakdown = (props: Props) => {
  const { reserveBaseParams, setReserveBaseParams } = useContext(
    ReserveBaseParamsContext
  )
  const [prevGeneralNumber, setPrevGeneralNumber] = useState<number>(-1)

  const [rowCount, setRowCount] = useState<number>(0)

  const [isLoadingArrange, setIsLoadingArrange] = useState<boolean>(false)

  const [switcChecked, setSwitchChecked] = useState<boolean>(false)

  useEffect(() => {
    setIsLoadingArrange(true)
    const general = props.aggregateResults.find((obj) => obj.name === '一般')
    if (general) {
      setReserveBaseParams({
        ...reserveBaseParams,
        generalNum: general.ticketNum,
      })
    }

    const results = props.aggregateResults.filter(
      (result) => result.ticketNum > 0
    )

    setRowCount(results.length)
    setTimeout(() => {
      setIsLoadingArrange(false)
    }, 800)
  }, [props.aggregateResults])

  const navigate = useNavigate()

  const [isExpanded, setIsExpanded] = useState<boolean>(false)

  return (
    <>
      <div
        id="here"
        className="w-full text-center  sm:px-0 mt-3 height-transition-ticketBreakdown"
        style={{ height: 120 + (rowCount - 1) * 48 }}
      >
        <div className="block w-full  md:w-[700px] h-auto border-x-2 border-t-2 border-stone-200 border-b-0 rounded-t-lg  py-2 m-auto  font-body ">
          {props.aggregateResults.map(
            (result) =>
              result.ticketNum > 0 && (
                <RowBreakdown
                  key={result.id}
                  label={result.name}
                  unitPrice={result.unitPrice}
                  num={result.ticketNum}
                  totalPrice={result.totalPrice}
                />
              )
          )}
        </div>
        <div className="flex w-full  md:w-[700px] border-4 border-stone-200 rounded-b-lg py-2 px-3 m-auto bg-stone-100 font-body  ">
          <div className="  w-1/2 text-center text-sm md:text-xl font-bold">
            チケット合計&emsp;
            <span className="text-cinekoya">{reserveBaseParams.seat}枚</span>
          </div>
          <div className="  w-1/2 text-right text-3xl text-cinekoya font-bold">
            ¥&nbsp;{props.grandTotalPrice.toLocaleString()}
          </div>
        </div>
      </div>
      <div className="w-full text-center text-4xl text-cinekoya-sub-d mt-2 mb-3">
        ▼
      </div>
      <div className="flex flex-row justify-center mb-3 text-lg font-body">
        <div className="basis-full sm:basis-2/3 text-center ">
          <span className="text-2xl font-bold text-cinekoya ">
            &emsp;{reserveBaseParams.seat}名様
          </span>
          のうち
          <br />
          以下にあてはまる方がいれば
          <br />
          人数を入力して下さい。
        </div>
      </div>
      <Scroll to={'here'} smooth={false} duration={500} offset={-20}>
        {' '}
        <InputPeopleBreakdown
          fanclubNum={props.fanclubNum}
          setfanclubNum={props.setfanclubNum}
          membersNum={props.membersNum}
          setMembersNum={props.setMembersNum}
          youthNum={props.youthNum}
          setYouthNum={props.setYouthNum}
          underYouthNum={props.underYouthNum}
          setUnderYouthNum={props.setUnderYouthNum}
          infantNum={props.infantNum}
          setInfantNum={props.setInfantNum}
          countRemainder={props.countRemainder}
          prevGeneralNumber={prevGeneralNumber}
          setPrevGeneralNumber={setPrevGeneralNumber}
          discountInfo={props.discountInfo}
          setDiscountInfo={props.setDiscountInfo}
          setSwitchChecked={setSwitchChecked}
        />
      </Scroll>

      <div className="w-full text-center text-4xl text-cinekoya-sub-d mt-2 mb-3">
        ▼
      </div>
      {isLoadingArrange && (
        <Oval
          height={120}
          width={120}
          color="#3090A0"
          wrapperStyle={{ justifyContent: 'center' }}
          wrapperClass=""
          visible={true}
          ariaLabel="oval-loading"
          secondaryColor="#A3C8CC"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
      )}
      {!isLoadingArrange && (
        <>
          {(reserveBaseParams.generalNum > 0 ||
            props.discountInfo.repeat_general_number > 0) && (
            <>
              <CheckDuplicatedContainer
                discountInfo={props.discountInfo}
                setDiscountInfo={props.setDiscountInfo}
                switchChecked={switcChecked}
                setSwitchChecked={setSwitchChecked}
                prevGeneralNumber={prevGeneralNumber}
                setPrevGeneralNumber={setPrevGeneralNumber}
              />
              <div className="w-full text-center text-4xl text-cinekoya-sub-d mt-3 mb-3">
                ▼
              </div>
            </>
          )}

          <div className="w-full text-center  sm:px-0">
            <div className="block w-full  md:w-[700px] h-auto border-x-4 border-t-4 border-cinekoya-sub-d border-b-0 rounded-t-lg  py-2 m-auto transition duration-300 ease-in-out font-body ">
              {props.aggregateResults.map(
                (result) =>
                  result.ticketNum > 0 && (
                    <RowBreakdown
                      key={result.id}
                      label={result.name}
                      unitPrice={result.unitPrice}
                      num={result.ticketNum}
                      totalPrice={result.totalPrice}
                    />
                  )
              )}
            </div>
            <div className="flex w-full  md:w-[700px] border-x-4 border-b-4 border-t-2  border-cinekoya-sub-d rounded-b-lg py-2 px-3 m-auto bg-stone-100 font-body  ">
              <div className="  w-1/2 text-center text-sm md:text-xl font-bold">
                チケット合計&emsp;
                <span className="text-cinekoya">
                  {reserveBaseParams.seat}枚
                </span>
              </div>
              <div className="  w-1/2 text-right text-3xl text-cinekoya font-bold">
                ¥&nbsp;{props.grandTotalPrice.toLocaleString()}
              </div>
            </div>
          </div>
        </>
      )}

      <div className="flex justify-center mt-3 mb-1">
        <div className="w-full md:w-96 text-center  text-stone-600 text-base">
          ※ 上の入力にもとづき、最も価格の低い
          <br />
          券種の組み合わせが自動で選択されます。
        </div>
      </div>
      <div className="flex justify-center mt-2">
        <div
          className="inline-block cursor-pointer font-bold  text-cinekoya-sub-c hover:text-cinekoya-sub-d text-center  "
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {isExpanded ? (
            <ExpandLessRoundedIcon />
          ) : (
            <ExpandCircleDownRoundedIcon />
          )}
          &nbsp;
          <span className="align-middle">シネコヤの料金</span>
        </div>
      </div>
      <div
        className={`flex text-center overflow-hidden height-transition-chargelist`}
        style={{ height: isExpanded ? 405 : 0 }}
      >
        <img src={ChargeList} className="m-auto" />
      </div>

      <div
        id="submit_ticket_breakdown"
        className="w-full text-center mt-4 mb-3"
      >
        <SubmitTicketBreakdownContainer
          aggregateResults={props.aggregateResults}
          grandTotalPrice={props.grandTotalPrice}
        />
      </div>
      <div className="w-full text-center pb-5">
        <PrevStepButton
          onClick={() => {
            navigate('../reservation/select_seat')
            setReserveBaseParams({ ...reserveBaseParams, seat: 1 })
          }}
        >
          戻る
        </PrevStepButton>
      </div>
    </>
  )
}
