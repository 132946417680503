import React from 'react'
import Book from '../../../../assets/kaguya.jpg'

export const BookShelf = () => {
  return (
    <>
      <div className="title-red-2 text-center">
        <div>シネコヤの本棚</div>
      </div>
      <div className="pb-3 hover:bg-[rgba()]">
        <a
          href="https://cinekoya.com/category/book/"
          className="hover:opacity-75"
        >
          {' '}
          <img src={Book} className="w-full" />
        </a>
      </div>
    </>
  )
}
