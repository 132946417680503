import { useContext, useEffect } from 'react'
import { ReserveBaseParamsContext } from '../../../hooks/ReserveBaseParamsContext'
import { Reservation } from '../../Templates/Reservation'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { PayCreditcardContainer } from '../../Organism/Reservation/Container/PayCreditcard.container'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

const promise = loadStripe(
  'pk_test_51MaBo9JT6LXIOoiJCn51PimmkTvtRxg1A9WlTQuYNdh38bkIaaNFA58YA4vfjwobuohFEpl22jUlmGT85j3p4oio00gBTbaMH1',
  { locale: 'ja' }
)

export const ConfirmPaymentPage = () => {
  const { reserveBaseParams } = useContext(ReserveBaseParamsContext)

  const navigate = useNavigate()
  useEffect(() => {
    if (reserveBaseParams.order === -1) {
      navigate('/schedule')
      return
    }
    axios.get('check_tentative_reservation').then((result) => {
      if (!result.data.is_reserved) {
        navigate('/reservation/session_expired')
      }
    })
  }, [])

  return (
    <>
      <Reservation>
        <Elements stripe={promise}>
          <PayCreditcardContainer />
        </Elements>
      </Reservation>
    </>
  )
}
