import React, { useContext, useState } from 'react'
import { CheckDuplicated } from '../Presentational/CheckDuplicated'
import axios from 'axios'
import { ReserveBaseParamsContext } from '../../../../hooks/ReserveBaseParamsContext'
import { useEscape } from '../../../../helpers/useEscape'
import { DiscountInfo } from '../../../../types/Reservation/DiscountInfo'

interface Props {
  discountInfo: DiscountInfo
  setDiscountInfo: (e: DiscountInfo) => void
  switchChecked: boolean
  setSwitchChecked: (e: boolean) => void
  prevGeneralNumber: number
  setPrevGeneralNumber: (e: number) => void
}

export const CheckDuplicatedContainer = (props: Props) => {
  const { reserveBaseParams } = useContext(ReserveBaseParamsContext)

  const [errorVerification, setErrorVerification] = useState<boolean>(false)

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false)

  const verifyCoupon = async () => {
    setIsLoading(true)
    //前回予約の存在確認
    const response = await axios.get('check_duplicated', {
      params: {
        date: reserveBaseParams.date,
        order: reserveBaseParams.order,
        email: useEscape(props.discountInfo.email),
        coupon_code: useEscape(props.discountInfo.coupon_code),
      },
    })

    const PrevGeneralNumber = response.data
    if (PrevGeneralNumber === 0) {
      setErrorVerification(true)
      setIsLoading(false)
      return
    }
    if (PrevGeneralNumber === 1 || reserveBaseParams.generalNum === 1) {
      setIsLoading(false)
      props.setPrevGeneralNumber(PrevGeneralNumber)
      setTimeout(() => {
        setModalIsOpen(false)
        props.setDiscountInfo({
          ...props.discountInfo,
          repeat_general_number: 1,
        })
        scrollToBottom
        return
      }, 1300)
    }
    if (PrevGeneralNumber > 1 && reserveBaseParams.generalNum > 1) {
      props.setPrevGeneralNumber(PrevGeneralNumber)
      setIsLoading(false)
    }
  }

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth',
    })
  }

  return (
    <CheckDuplicated
      prevGeneralNumber={props.prevGeneralNumber}
      setPrevGeneralNumber={props.setPrevGeneralNumber}
      verifyCoupon={verifyCoupon}
      discountInfo={props.discountInfo}
      setDiscountInfo={props.setDiscountInfo}
      errorVerification={errorVerification}
      setErrorVerification={setErrorVerification}
      isLoading={isLoading}
      modalIsOpen={modalIsOpen}
      setModalIsOpen={setModalIsOpen}
      switchChecked={props.switchChecked}
      setSwitchChecked={props.setSwitchChecked}
    />
  )
}
