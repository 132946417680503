import React from 'react'
import { DashboardTop } from '../Templates/DashboardTop'
import { Notification } from '../Templates/Notification'
import { TopNotificationContainer } from '../Organism/NotificationTop/Container/TopNotification.container'

export const NotificationTopPage = () => {
  return (
    <DashboardTop>
      <Notification>
        <TopNotificationContainer />
      </Notification>
    </DashboardTop>
  )
}
