import { useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { ReserveBaseParamsContext } from '../../../../hooks/ReserveBaseParamsContext'

export const ProgressBar = () => {
  const { reserveBaseParams } = useContext(ReserveBaseParamsContext)
  const location = useLocation()

  const isCinema = reserveBaseParams.is_cinema

  return (
    <div className="flex flex-row justify-center my-3  text-stone-200 font-body text-[7px] sm:text-sm pl-3 sm:pl-4">
      <div
        className={
          location.pathname === '/reservation/select_seat'
            ? 'rectangle-start-red'
            : 'rectangle-start-gray'
        }
      >
        座席
      </div>
      <div
        className={
          location.pathname === '/reservation/select_seat'
            ? 'triangle-red'
            : 'triangle-gray'
        }
      ></div>
      <div className="triangle-white"></div>
      <div
        className={
          location.pathname === '/reservation/arrange_ticket'
            ? 'rectangle-red'
            : 'rectangle-gray'
        }
      >
        {isCinema ? '券種' : '確認'}
      </div>
      <div
        className={
          location.pathname === '/reservation/arrange_ticket'
            ? 'triangle-red'
            : 'triangle-gray'
        }
      ></div>
      <div className="triangle-white"></div>
      <div
        className={
          location.pathname === '/reservation/personal_info'
            ? 'rectangle-red'
            : 'rectangle-gray'
        }
      >
        情報
      </div>
      <div
        className={
          location.pathname === '/reservation/personal_info'
            ? 'triangle-red'
            : 'triangle-gray'
        }
      ></div>
      <div className="triangle-white"></div>
      <div
        className={
          location.pathname === '/reservation/confirm_payment'
            ? 'rectangle-red'
            : 'rectangle-gray'
        }
      >
        決済
      </div>
      <div
        className={
          location.pathname === '/reservation/confirm_payment'
            ? 'triangle-red'
            : 'triangle-gray'
        }
      ></div>
    </div>
  )
}
