import React from 'react'
import { StaticPageParams } from '../../../types/StaticPageParams'

interface Props {
  staticPage: StaticPageParams
}
export const StaticPageBody = (props: Props) => {
  return (
    <>
      <div>
        <div className="title-red-2 text-center mt-5">
          <div>{props.staticPage.page_name}</div>
        </div>
        <div className="flex flex-row justify-center mb-5">
          <div
            className="basis-full sm:basis-4/5 px-2 sm:px-0 font-hp text-cinekoya"
            dangerouslySetInnerHTML={{ __html: props.staticPage.content }}
          ></div>
        </div>
      </div>
    </>
  )
}
