import React, { useContext, useEffect, useState } from 'react'
import { UrlContext } from '../../../../hooks/UrlContext'
import { Movie } from '../../../../types/Movie'
import { CampaignParams } from '../../../../types/CampaignPrams'
import dayjs from 'dayjs'

interface Schedule {
  start_day: string
  end_day: string
}

interface MovieExtended extends Movie {
  startDay: string
  endDay: string
}

interface Props {
  nowShowingMovies: MovieExtended[]
  forcedNowShowingMovies: MovieExtended[]
  movieCampaigns: CampaignParams[]
  schedules: Schedule[]
}

export const NowShowing = (props: Props) => {
  const { imageURL } = useContext(UrlContext)

  const [nowShowings, setNowShowings] = useState<MovieExtended[]>([])

  useEffect(() => {
    const now_showings = props.nowShowingMovies.concat(
      props.forcedNowShowingMovies
    )

    setNowShowings(now_showings)
  }, [props])

  return (
    <>
      <div className="title-red-2 text-center">
        <div>上映中</div>
      </div>
      <div className="grid grid-cols-2 gap-2 cursor-pointer">
        {props.movieCampaigns.length !== 0 &&
          props.movieCampaigns.map((campaign) => (
            <div key={campaign.id}>
              <a href={`/campaign/${campaign.id}`}>
                <div
                  className="square"
                  style={{
                    backgroundImage: `url(${
                      imageURL + encodeURIComponent(campaign.main_image)
                    })`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                  }}
                >
                  <div className="mov_bg">
                    <div className="monthly-title-red">
                      <p className="movie-title tri">
                        {campaign.title}
                        <br />
                        {campaign.start_date !== campaign.end_date
                          ? `${dayjs(campaign.start_date).format(
                              'M/D(dd)'
                            )}〜${dayjs(campaign.end_date).format('M/D(dd)')}`
                          : dayjs(campaign.start_date).format('M/D(dd)')}
                      </p>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          ))}

        {nowShowings.map((movie) => (
          <div key={movie.id}>
            <a href={`/cinema/${movie.id}`}>
              <div
                className="square"
                style={{
                  backgroundImage: `url(${
                    imageURL + encodeURIComponent(movie.image)
                  })`,
                  // encodeURIComponentを入れないと画像ファイル名に日本語が含まれている場合に正しく表示されない。imgタグなら問題ないが。
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                }}
              >
                <div className="mov_bg">
                  <div className="monthly-title-blue">
                    <p className="movie-title tri">
                      {movie.title}
                      <br />
                      {dayjs(movie.startDay).format('M/D(dd)')}〜
                      {dayjs(movie.endDay).format('M/D(dd)')}
                    </p>
                  </div>
                </div>
              </div>
            </a>
          </div>
        ))}
      </div>
    </>
  )
}
