import React, { useEffect, useState } from 'react'
import { EventTemplate } from '../Templates/EventTemplate'
import { StaticPageParams } from '../../types/StaticPageParams'
import { StaticPageBody } from '../Organism/StaticPageBody/StaticPageBody'

export const PreviewStaticPage = () => {
  const [staticPage, setStaticPage] = useState<StaticPageParams>(
    {} as StaticPageParams
  )

  useEffect(() => {
    fetchPreview()
  }, [])

  const fetchPreview = () => {
    const storedParams = localStorage.getItem('preview_staticpage_params')
    if (storedParams) {
      setStaticPage(JSON.parse(storedParams) as StaticPageParams)
      //  ストレージの削除はcloseModal上に記述
    }
  }

  const handleUpdate = () => {
    fetchPreview()
  }
  return (
    <>
      <div className="w-full text-right pt-9 px-4 mb-[-45px] md:mb-[-40px] z-60">
        <button
          className="inline-block bg-cinekoya-sub-c px-3 py-1 rounded-xl shadow-md text-white font-bold text-sm md:text-xl hover:bg-opacity-80 cursor-auto"
          onClick={() => handleUpdate()}
        >
          プレビュー更新
        </button>
      </div>
      <EventTemplate>
        <StaticPageBody staticPage={staticPage} />
      </EventTemplate>
    </>
  )
}
