import React, { ReactNode } from 'react'
import { ReservationHeader } from '../Molecules/ReservationHeader'
import { ReservationSummary } from '../Organism/Reservation/Presentational/ReservationSummary'
import { ProgressBar } from '../Organism/Reservation/Presentational/ProgressBar'

interface Props {
  children: ReactNode
}
export const Reservation = (props: Props) => {
  return (
    <>
      <div className="flex justify-center">
        <div className="w-full md:w-5/6">
          <ReservationHeader />
        </div>
      </div>

      <ProgressBar />

      <ReservationSummary />
      {props.children}
      <div className="w-full mt-5 text-center text-cinekoya">
        <p className="txt-m">&copy; cinekoya</p>
      </div>
    </>
  )
}
