import React, { useEffect } from 'react'
import { useState } from 'react'
import { ReserveBaseParam } from '../types/Reservation/ReserveBaseParam'
import { DefaultReserveBaseParams } from '../const/DefaultReserveBaseParams'

type ReserveBaseParamsContextProps = {
  reserveBaseParams: ReserveBaseParam
  setReserveBaseParams: (e: ReserveBaseParam) => void
}

type Children = {
  children: React.ReactNode
}
export const ReserveBaseParamsContext =
  React.createContext<ReserveBaseParamsContextProps>(
    {} as ReserveBaseParamsContextProps
  )

export const ReserveBaseParamsProvider = (props: Children) => {
  const { children } = props

  const defaultValue = DefaultReserveBaseParams

  const [reserveBaseParams, setReserveBaseParams] = useState<ReserveBaseParam>(
    () => {
      const reserveBaseParamsJson = localStorage.getItem('reserveBaseParams')

      const prevReserveBaseParams = JSON.parse(
        reserveBaseParamsJson ? reserveBaseParamsJson : '{}'
      )
      if (prevReserveBaseParams.order !== -1) {
        return prevReserveBaseParams
      } else {
        return defaultValue
      }
    }
  )

  useEffect(() => {
    localStorage.setItem('reserveBaseParams', JSON.stringify(reserveBaseParams))
  }, [reserveBaseParams])

  return (
    <ReserveBaseParamsContext.Provider
      value={{
        reserveBaseParams,
        setReserveBaseParams,
      }}
    >
      {children}
    </ReserveBaseParamsContext.Provider>
  )
}
