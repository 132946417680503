import React, { Children, ReactNode } from 'react'
import Tooltip from '@mui/material/Tooltip'

interface Props {
  saveDisabled: boolean
  onClick: () => void
  isPublic: boolean
  children?: ReactNode
}
export const SaveButton = (props: Props) => {
  const saveStyle =
    'bg-cinekoya-sub-c hover:bg-cinekoya-sub-a  disabled:bg-cinekoya-sub-d '

  const saveDraftStyle =
    'bg-yt-atom  hover:bg-yt-atom-hover  disabled:bg-yt-atom-hover'

  return (
    <div className="text-center mt-2 ">
      <Tooltip
        title={`${props.isPublic ? '' : '下書きとして保存されます'}`}
        placement="top-end"
      >
        <span>
          <button
            className={`${
              props.isPublic ? saveStyle : saveDraftStyle
            } text-white  w-64 rounded-full py-2 px-5 border-solid border-2 shadow-md   `}
            disabled={props.saveDisabled}
            onClick={props.onClick}
          >
            {props.children}
          </button>
        </span>
      </Tooltip>
    </div>
  )
}
