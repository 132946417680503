import React, { ReactNode } from 'react'

interface Props {
  onClick: () => void
  children: React.ReactNode
}
export const ButtonBorderBlue = (props: Props) => {
  return (
    <button
      className="inline-block px-3 py-1 bg-yt-component border-1 border-cinekoya-sub-c rounded-xl text-stone-200 hover:bg-yt-atom hover:text-white "
      onClick={props.onClick}
    >
      {props.children}
    </button>
  )
}
