import React, { useContext, useState } from 'react'
import Switch from '@mui/material/Switch'
import { alpha, styled } from '@mui/material/styles'
import { ReserveBaseParamsContext } from '../../../../hooks/ReserveBaseParamsContext'
import dayjs from 'dayjs'
import useWindowSize from '../../../../hooks/useWindowSize'
import Modal from 'react-modal'
import { DiscountInfo } from '../../../../types/Reservation/DiscountInfo'
import { InputCouponCode } from './InputCouponCode'
import { InputRepeatGeneral } from './InputRepeatGeneral'
import { Modal_ResetRepeatDiscount } from './Modal_ResetRepeatDiscount'

interface Props {
  prevGeneralNumber: number
  setPrevGeneralNumber: (e: number) => void
  verifyCoupon: () => void
  discountInfo: DiscountInfo
  setDiscountInfo: (e: DiscountInfo) => void
  errorVerification: boolean
  setErrorVerification: (e: boolean) => void
  isLoading: boolean
  modalIsOpen: boolean
  setModalIsOpen: (e: boolean) => void
  switchChecked: boolean
  setSwitchChecked: (e: boolean) => void
}
const switchColor = '#A94337'
const CustomSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: switchColor,
    '&:hover': {
      backgroundColor: alpha(switchColor, theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: switchColor,
  },
}))

export const CheckDuplicated = (props: Props) => {
  const { reserveBaseParams } = useContext(ReserveBaseParamsContext)

  const [modalAlertIsOpen, setModalAlertIsOpen] = useState<boolean>(false)
  const { width } = useWindowSize()

  const isMobile = width <= 700 // 768pxをスマホとPCの切り替えポイントとする

  const handleCancel = () => {
    props.setSwitchChecked(false)
    props.setModalIsOpen(false)
    props.setPrevGeneralNumber(-1)
    props.setDiscountInfo({
      email: '',
      coupon_code: '',
      repeat_general_number: 0,
    })
    props.setErrorVerification(false)
  }

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.checked && props.discountInfo.repeat_general_number > 0) {
      setModalAlertIsOpen(true)
      return
    }

    if (event.target.checked) {
      setTimeout(() => {
        props.setModalIsOpen(true)
        scrollToBottom()
      }, 270)
      props.setSwitchChecked(event.target.checked)
    }
  }

  const scrollToBottom = () => {
    const targetPosition =
      document.documentElement.scrollHeight - window.innerHeight

    window.scrollTo({
      top: targetPosition,
      behavior: 'smooth',
    })
  }

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '100%',
      maxWidth: '610px',
      height: isMobile
        ? props.prevGeneralNumber > 1 && reserveBaseParams.generalNum > 1
          ? '60vh'
          : '95vh'
        : props.prevGeneralNumber > 1 && reserveBaseParams.generalNum > 1
        ? '40vh'
        : '65vh',
      borderRadius: '5%',
      background: 'rgba(255,255,255,0.9)',
      //   color: '#A94337',
      padding: '10px',
      border: 'solid 3px #A94337',
      transition: 'height 0.5s ease-out',
    },
    overlay: {
      top: 0,
      left: 0,
      backgroundColor: 'rgba(0,0,0,0.5)',
      zIndex: 10,
    },
  }

  return (
    <>
      <div className="flex flex-col  items-center  font-body text-lg">
        <div className="inline-block text-center ">
          {dayjs(reserveBaseParams.date).format('M/D')}
          に上映される別の作品も予約していますか？
        </div>

        <div className="inline-block ">
          <span
            className={`mr-[50px] text-lg cursor-pointer ${
              !props.switchChecked
                ? 'text-cinekoya opacity-80 font-bold'
                : 'opacity-60'
            }`}
          >
            いいえ
          </span>
          <CustomSwitch
            checked={props.switchChecked}
            onChange={handleSwitchChange}
            inputProps={{ 'aria-label': 'controlled' }}
          />
          <span
            className={`ml-[50px] text-lg cursor-pointer ${
              props.switchChecked
                ? 'text-cinekoya opacity-80 font-bold'
                : 'opacity-60'
            }`}
          >
            はい
          </span>
        </div>

        <div className="inline-block text-center text-base text-stone-600 bg-stone-100  py-1 px-3 rounded-2xl ">
          ※&nbsp; 同じ日に別の作品を観ると２本目以降の
          <br />
          「一般料金」が1,800円→1,100円になります。
        </div>
      </div>

      <Modal
        isOpen={props.modalIsOpen}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
        // onRequestClose={closeModal}
      >
        {(props.prevGeneralNumber === -1 ||
          props.prevGeneralNumber === 1 ||
          reserveBaseParams.generalNum === 1) && (
          <InputCouponCode
            prevGeneralNumber={props.prevGeneralNumber}
            generalNum={reserveBaseParams.generalNum}
            verifyCoupon={props.verifyCoupon}
            discountInfo={props.discountInfo}
            setDiscountInfo={props.setDiscountInfo}
            errorVerification={props.errorVerification}
            isLoading={props.isLoading}
            handleCancel={handleCancel}
          />
        )}
        {props.prevGeneralNumber > 1 && reserveBaseParams.generalNum > 1 && (
          <InputRepeatGeneral
            prevGeneralNumber={props.prevGeneralNumber}
            discountInfo={props.discountInfo}
            setDiscountInfo={props.setDiscountInfo}
            setModalIsOpen={props.setModalIsOpen}
            handleCancel={handleCancel}
          />
        )}
      </Modal>
      <Modal_ResetRepeatDiscount
        modalIsOpen={modalAlertIsOpen}
        setModalIsOpen={setModalAlertIsOpen}
        setPrevGeneralNumber={props.setPrevGeneralNumber}
        setDiscountInfo={props.setDiscountInfo}
        setSwitchChecked={props.setSwitchChecked}
      />
    </>
  )
}
