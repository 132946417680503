import { useEffect, useState } from 'react'

interface Props {
  seatNum: number
  fanclubNum: number
  membersNum: number
  youthNum: number
  underYouthNum: number
  infantNum: number
}
export const useCountRemainder = (props: Props) => {
  const [countRemainder, setCountRemainder] = useState(0)

  useEffect(() => {
    const substractNum =
      props.fanclubNum +
      props.membersNum +
      props.youthNum +
      props.underYouthNum +
      props.infantNum

    setCountRemainder(props.seatNum - substractNum)
  }, [
    props.seatNum,
    props.fanclubNum,
    props.membersNum,
    props.youthNum,
    props.underYouthNum,
    props.infantNum,
  ])

  return countRemainder
}
