import React, { useContext } from 'react'
import foodLink from '../../../../assets/FoodLink.jpg'
import { UrlContext } from '../../../../hooks/UrlContext'

export const Food = () => {
  const { imageURL } = useContext(UrlContext)

  return (
    <>
      <div className="title-red-2 text-center">
        <div>パン＆ドリンク</div>
      </div>

      <div className="flex justify-center mb-5">
        <div className="w-full md:w-[80%]">
          <a href="https://cinekoya.com/メニュー/814/">
            <div
              className="square"
              style={{
                backgroundImage: `url(${encodeURIComponent(foodLink)})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
              }}
            >
              <div className="bread_bg">
                <div className="monthly-title-blue">
                  <p className="movie-title tri">
                    自家製天然酵母パン Desture―デスチャー
                  </p>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </>
  )
}
