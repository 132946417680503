import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Movie } from '../../../../types/Movie'
import { EventDisplayOption } from '../Presentational/EventDisplayOption'

export const EventDisplayOptionContainer = () => {
  const [nowShowingMovies, setNowShowingMovies] = useState<Movie[]>([])
  const [commingSoonMovies, setCommingSoonMovies] = useState<Movie[]>([])

  const [forcedNowShowingMovies, setForcedNowShowingMovies] = useState<Movie[]>(
    []
  )

  const [pastDisplayPeriod, setPastDisplayPeriod] = useState<number>(0)

  const [newPeriod, setNewPeriod] = useState<number>(0)
  const [isEditMode, setIsEditMode] = useState<boolean>(false)

  const [isLoading, setIsloading] = useState<boolean>(false)

  const getLatest = () => {
    axios.get('get_now_showing').then((response) => {
      setNowShowingMovies(response.data.now_showing_movies)
    })

    axios
      .get('get_comming_soon')
      .then((response) => setCommingSoonMovies(response.data))

    axios
      .get('get_forced_now_showing')
      .then((response) => setForcedNowShowingMovies(response.data))

    axios.get('get_past_display_period').then((response) => {
      setPastDisplayPeriod(response.data)
      setNewPeriod(response.data)
      setIsloading(false)
    })
  }
  useEffect(() => {
    setIsloading(true)
    getLatest()
  }, [])

  const updatePastDisplayPeriod = () => {
    setIsloading(true)
    axios
      .put('update_past_display_period', {
        period: newPeriod,
      })
      .then((response) => {
        setPastDisplayPeriod(response.data)
        setNewPeriod(response.data)
        setIsEditMode(false)
      })
  }

  const createForcedNowShowing = (movie_id: number) => {
    setIsloading(true)
    axios
      .post('create_forced_now_showing', {
        movie_id: movie_id,
      })
      .then(() => {
        getLatest()
      })
  }

  const deleteForcedNowShowing = (movie_id: number) => {
    setIsloading(true)
    axios
      .delete('delete_forced_now_showing', {
        params: {
          movie_id: movie_id,
        },
      })
      .then(() => {
        getLatest()
      })
  }

  return (
    <EventDisplayOption
      nowShowingMovies={nowShowingMovies}
      commingSoonMovies={commingSoonMovies}
      forcedNowShowingMovies={forcedNowShowingMovies}
      pastDisplayPeriod={pastDisplayPeriod}
      newPeriod={newPeriod}
      setNewPeriod={setNewPeriod}
      updatePastDisplayPeriod={updatePastDisplayPeriod}
      deleteForcedNowShowing={deleteForcedNowShowing}
      createForcedNowShowing={createForcedNowShowing}
      isEditMode={isEditMode}
      setIsEditMode={setIsEditMode}
      isLoading={isLoading}
    />
  )
}
