import React, { useEffect, useState } from 'react'
import EditIcon from '@mui/icons-material/Edit'
import CloseIcon from '@mui/icons-material/Close'
// import { EditNotification } from './EditNotification'
// import MDEditor from '@uiw/react-md-editor'
import { ButtonBorderBlue } from '../../../Atoms/ButtonBorderBlue'
import dayjs from 'dayjs'
import QuillEditor from '../../../Molecules/QuillEditor'

interface Event {
  id: number
  movie_id: number
  content: string
  expiration: string
}

interface EventList {
  id: number
  title: string
  endDay: string
}

interface Props {
  isEdit: boolean
  setIsEdit: (e: boolean) => void
  editorMode: 'live' | 'edit' | 'preview'
  setEditorMode: (e: 'live' | 'edit' | 'preview') => void
  updateNotificationEvent: () => void
  deleteNotificationEvent: () => void
  editingValue: string
  setEditingValue: (e: string) => void
  expiration: string
  setExpiration: (e: string) => void
  event: EventList
  notification: Event
  activeEditorId: number
  setActiveEditorId: (e: number) => void
  activePreviewId: number
  setActivePreviewId: (e: number) => void
}
export const UpdateNotification = (props: Props) => {
  const [previewHeight, setPreviewHeight] = useState<string | number>(50)
  const [isExpanded, setIsExpanded] = useState<boolean>(false)

  useEffect(() => {
    if (props.event.id === props.activePreviewId) {
      setPreviewHeight('auto')
    } //展開した後に元に戻す仕組み入れない
  }, [props.activePreviewId])

  return (
    <>
      <div className="w-full  lg:mt-[-20px] text-right text-cinekoya-sub-a hover:text-cinekoya-sub-d mb-2 text-sm">
        {props.activeEditorId !== props.event.id && (
          <>
            表示期限：&nbsp;
            {props.notification.expiration &&
              dayjs(props.notification.expiration).format('YYYY/MM/DD')}
            {!props.notification.expiration && '期限なし'}
            &emsp;
            <EditIcon
              fontSize="large"
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                props.setActiveEditorId(props.event.id)
                props.setIsEdit(true)
                setIsExpanded(true)
                setPreviewHeight('auto')
              }}
            />
          </>
        )}
        {props.activeEditorId === props.event.id && (
          <>
            表示期限：&nbsp;
            <input
              className="pl-2 py-1 rounded-md cursor-pointer text-stone-600 font-bold"
              value={props.expiration}
              onChange={(e) => props.setExpiration(e.target.value)}
              type="date"
            />
            &emsp;
            <CloseIcon
              fontSize="large"
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                props.setActiveEditorId(0)
                props.setEditingValue(props.notification.content)
                props.setEditorMode('edit')
                props.setIsEdit(false)
              }}
            />
          </>
        )}
      </div>
      <hr />
      <div
        className=" text-white overflow-hidden"
        style={{
          height:
            props.notification.content.length <= 100 ? 'auto' : previewHeight,
        }}
      >
        {!props.isEdit && (
          <div
            dangerouslySetInnerHTML={{ __html: props.notification.content }}
          ></div>
          // <MDEditor.Markdown
          //   source={props.notification.content}
          //   style={{
          //     backgroundColor: 'transparent',
          //     color: 'white',
          //     whiteSpace: 'pre-wrap',
          //   }}
          // />
        )}
        {props.isEdit && (
          <>
            {/* <EditNotification
              editorMode={props.editorMode}
              setEditorMode={props.setEditorMode}
              editingValue={props.editingValue}
              setEditingValue={props.setEditingValue}
            /> */}
            <div className="bg-white mb-3 event_notification_editor">
              <QuillEditor
                allowImageInsertion={false}
                value={props.editingValue}
                onChange={(contetn: string) => {
                  props.setEditingValue(contetn)
                }}
                onImageUploadComplete={() => {
                  return
                }}
              />
            </div>

            <div className="flex flex-row text-center mt-3">
              <div className="basis-1/3" />
              <div className="basis-1/3">
                <ButtonBorderBlue onClick={props.updateNotificationEvent}>
                  更新する
                </ButtonBorderBlue>
              </div>
              <div className="basis-1/3 text-right pr-2">
                <button
                  className="border-1 border-yt-atom py-1 px-2 rounded-xl hover:bg-yt-atom cursor-pointer"
                  onClick={props.deleteNotificationEvent}
                >
                  通知の削除
                </button>
              </div>
            </div>
          </>
        )}
      </div>
      {!isExpanded && (
        <div
          className="w-full text-right overflow-hidden"
          style={{
            height: props.notification.content.length <= 100 ? 0 : 'auto',
          }}
        >
          <div
            className="inline-block text-stone-300 hover:text-white bg-yt-component font-bold cursor-pointer"
            onClick={() => {
              props.setActivePreviewId(props.event.id)
              setIsExpanded(true)
            }}
          >
            …続きを読む
          </div>
        </div>
      )}
    </>
  )
}
