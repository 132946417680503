import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import React, { useContext, useState } from 'react'
import axios from 'axios'
import { PostParamsContext } from '../../../../hooks/postParamsContext'
import { Modal_AlertDuplicated } from './Modal_AlertDuplicated'

interface Props {
  selectedOrder: number
}

export const InputOrder = (props: Props) => {
  const { postParams, setPostParams } = useContext(PostParamsContext)
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const beforeValidateOrder = (order: number) => {
    if (order === props.selectedOrder) {
      setPostParams({
        ...postParams,
        order: Number(order),
      })
      console.log(true)
      return
    }
    validateOrder(order)
  }

  const validateOrder = async (order: number) => {
    await axios
      .post('validate_order', {
        start_day: postParams.start_day,
        end_day: postParams.end_day,
        order: order,
      })
      .then((response) => {
        const isDuplicated = response.data
        if (isDuplicated) {
          setPostParams({
            ...postParams,
            order: props.selectedOrder,
          })
          setModalIsOpen(true)
          return
        }

        setPostParams({
          ...postParams,
          order: Number(order),
        })
      })
  }

  const closeModal = () => {
    setModalIsOpen(false)
  }

  return (
    <>
      <FormControl sx={{ m: 0, minWidth: 200, background: '#282828' }}>
        <InputLabel id="demo-simple-select-autowidth-label">
          コマ順序
        </InputLabel>
        <Select
          labelId="demo-simple-select-autowidth-label-1"
          id="demo-simple-select-autowidth-1"
          value={String(postParams.order)}
          onChange={(e: SelectChangeEvent) => {
            beforeValidateOrder(Number(e.target.value))
          }}
          autoWidth
          label="コマ順序"
        >
          <MenuItem value="1">
            <em></em>
          </MenuItem>
          <MenuItem value="1">1</MenuItem>
          <MenuItem value="2">2</MenuItem>
          <MenuItem value="3">3</MenuItem>
          <MenuItem value="4">4</MenuItem>
          <MenuItem value="5">5</MenuItem>
        </Select>
      </FormControl>

      <Modal_AlertDuplicated
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        targetText="順序"
      />
    </>
  )
}
