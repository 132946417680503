import axios from 'axios'
import dayjs from 'dayjs'
import { useEffect } from 'react'

interface dateType {
  id: number
  date: string
}

type Props = {
  isOpendayMode: boolean
  setIsOpendayMode: (e: boolean) => void
  setOpenDays: (e: dateType[]) => void
  setCloseDays: (e: dateType[]) => void
}

export const ClosingDateButton = (props: Props) => {
  const today = dayjs()

  useEffect(() => {
    getOpendays()
    getClosedays()
  }, [])

  const clickOpenday = () => {
    props.setIsOpendayMode(true)
    getOpendays()
  }

  const clickCloseday = () => {
    props.setIsOpendayMode(false)
    getClosedays()
  }

  const getOpendays = () => {
    axios
      .get('common_setting_openday', {
        params: {
          today: today.format('YYYY-MM-DD'),
        },
      })
      .then((response) => {
        props.setOpenDays(response.data)
      })
  }

  const getClosedays = () => {
    axios
      .get('common_setting_closeday', {
        params: {
          today: today.format('YYYY-MM-DD'),
        },
      })
      .then((response) => {
        props.setCloseDays(response.data)
      })
  }

  return (
    <>
      <div className=" flex flex-row text-lg">
        <div className="basis-3/4 flex items-center justify-center">
          <div className="mr-3 text-xl"> 個別設定</div>
          <button
            className="rounded-l-full w-1/4 bg-yt-atom hover:bg-yt-atom-hover py-1 border-1 border-yt-atom-hover text-center mr-1 cursor-pointer"
            style={
              props.isOpendayMode
                ? { background: '#3090A0' }
                : { background: '#606060' }
            }
            onClick={clickOpenday}
          >
            営業日
          </button>

          <button
            className="rounded-r-full w-1/4  bg-yt-atom hover:bg-yt-atom-hover py-1 border-1 border-yt-atom-hover text-center cursor-pointer"
            style={
              !props.isOpendayMode
                ? { background: '#3090A0' }
                : { background: '#606060' }
            }
            onClick={clickCloseday}
          >
            休館日
          </button>
        </div>
        <div className="basis-1/4"></div>
      </div>
    </>
  )
}
