import React from 'react'
import { useState } from 'react'
import { Post } from '../types/Post.'

type PostParamsContextProps = {
  postParams: Post
  setPostParams: (e: Post) => void
}

type Children = {
  children: React.ReactNode
}
export const PostParamsContext = React.createContext<PostParamsContextProps>(
  {} as PostParamsContextProps
)

export const PostParamsProvider = (props: Children) => {
  const { children } = props

  const [postParams, setPostParams] = useState<Post>({
    id: -1,
    cinema_id: -1,
    is_cinema: true,
    title: '',
    detail: '',
    event_price_1: 0,
    event_price_2: 0,
    event_price_3: 0,
    event_price_4: 0,
    ep_1_label: '',
    ep_2_label: '',
    ep_3_label: '',
    ep_4_label: '',
    image: '',
    trailer_url: '',
    order: 1,
    start_day: '',
    end_day: '',
    display_public: true,
    start_hour: '',
    start_minutes: '',
    end_hour: '',
    end_minutes: '',
    time_unsettled: false,
    non_cinema_reservable: null,
    seat_limits: null,
    alternative_name: null,
  })

  return (
    <PostParamsContext.Provider value={{ postParams, setPostParams }}>
      {children}
    </PostParamsContext.Provider>
  )
}
