import dayjs from 'dayjs'
import React, { ChangeEvent, useEffect, useState } from 'react'
import Modal from 'react-modal'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import { Counter } from './Counter'
import CloseIcon from '@mui/icons-material/Close'

import { ThreeDots } from 'react-loader-spinner'
import { SelectSoldTicketType } from './SelectSoldTicketType'
import { SoldOutModal } from './SoldOutModal'
import { modalStyle_visitorManagement } from '../../../../style/modalStylev_visitorManagement'

interface History {
  id: number
  seats: number
  memo: string
  created_at: string
}

interface TicketType {
  id: number
  name: string
  unit_price: number
}

interface MovieInfo {
  movie_id: number
  is_cinema: boolean
}

interface SeatsInfo {
  visited_non_reserved: number
  visited_web: number
  not_visited_web: number
  visited_tel: number
  not_visited_tel: number
  total_visited_reserved_number: number
  total_not_visited_reserved_seats: number
  capacity: number
  seats_left: number
}

interface Props {
  areaStyle: string
  buttonStyle: string
  saveHandler: () => void
  newSeats: number
  setNewSeats: (e: number) => void
  memo: string
  setMemo: (e: string) => void
  totalSeats: number
  history: History[]
  isLoading: boolean
  setIsLoading: (e: boolean) => void
  ticketTypes: TicketType[]
  soldTicketTypes: TicketType[]
  setSoldTicketTypes: (e: TicketType[]) => void
  isTicketTypeEmpty: boolean
  setIsTicketTypeEmpty: (e: boolean) => void
  seatsInfo: SeatsInfo
  movieInfo: MovieInfo
}
export const CountNonReservedVisitor = (props: Props) => {
  const [isOpenHistory, setIsOpenHistory] = useState<boolean>(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false)
  const openModal = () => {
    if (props.seatsInfo.seats_left <= 0) {
      setIsAlertModalOpen(true)
      return
    }
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
    props.setNewSeats(1)
    props.setMemo('')
    props.setIsTicketTypeEmpty(false)
  }

  useEffect(() => {
    if (props.seatsInfo.seats_left <= 0) {
      setIsModalOpen(false)
    }
  }, [props.seatsInfo])
  return (
    <>
      <div className={`${props.areaStyle} border-cinekoya`}>
        <div className="flex flex-col font-body text-white">
          <div>
            <button
              className={`${props.buttonStyle} bg-cinekoya`}
              onClick={openModal}
            >
              当日来客
            </button>
          </div>
          <div className=" mt-3">現在の来店合計：{props.totalSeats}</div>
        </div>
      </div>
      <SoldOutModal
        isAlertModalOpen={isAlertModalOpen}
        setIsAlertModalOpen={setIsAlertModalOpen}
        setIsModalOpen={setIsModalOpen}
        seatsInfo={props.seatsInfo}
      />

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        style={modalStyle_visitorManagement}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <div className="flex flex-row mb-2">
          <div className="basis-1/3" />{' '}
          <div className="basis-1/3 text-2xl font-body font-bold text-cinekoya-hover text-center">
            当日来客
          </div>
          <div className="basis-1/3 text-right pr-3 text-yt-atom hover:text-yt-atom-hover ">
            <CloseIcon
              fontSize="large"
              sx={{ cursor: 'pointer' }}
              onClick={closeModal}
            />
          </div>
        </div>

        <hr />
        <Counter newSeats={props.newSeats} setNewSeats={props.setNewSeats} />
        {Boolean(props.movieInfo.is_cinema) && (
          <SelectSoldTicketType
            isLoading={props.isLoading}
            setIsLoading={props.setIsLoading}
            soldTicketTypes={props.soldTicketTypes}
            setSoldTicketTypes={props.setSoldTicketTypes}
            ticketTypes={props.ticketTypes}
            setIsTicketTypeEmpty={props.setIsTicketTypeEmpty}
            newSeats={props.newSeats}
          />
        )}

        {props.isLoading && (
          <div className="flex h-[200px] justify-center items-center mt-5">
            <ThreeDots
              height="70"
              width="200"
              radius="9"
              color={'#A94337'}
              ariaLabel="three-dots-loading"
              wrapperStyle={{ justifyContent: 'center' }}
              visible={true}
            />
          </div>
        )}

        <div className="flex flex-col text-center mt-5 mb-1 justify-center gap-2">
          <div className=" text-yt-text-gray text-base">備考（任意）</div>
          <div>
            <textarea
              className="w-1/2 h-[150px] rounded-md text-black py-1 px-2"
              value={props.memo}
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                props.setMemo(e.target.value)
              }
            ></textarea>
          </div>
          {!props.isLoading && (
            <div className="flex flex-col text-center  mt-4 font-body">
              <div className="text-2xl">
                現在の合計人数：<b>{props.totalSeats}</b>名
              </div>
              <div className="mt-2 text-xl">
                追加後の合計人数：
                <b>{Number(props.totalSeats) + Number(props.newSeats)}</b>名
              </div>{' '}
            </div>
          )}

          <div className="mt-1 font-body">
            <div className="text-2xl py-2 h-[50px] text-cinekoya-yellow-1 font-bold">
              {props.isTicketTypeEmpty && (
                <span>すべての券種を選択して下さい</span>
              )}
            </div>
            <div>
              <button
                className="w-1/3 bg-cinekoya hover:bg-cinekoya-hover text-white rounded-2xl py-1 text-xl"
                onClick={props.saveHandler}
              >
                追加
              </button>
            </div>
          </div>
          <div className=" text-yt-text-gray mt-1 text-lg">
            ※差し引く場合は人数をマイナスにします
          </div>
          {props.history.length > 0 && (
            <div className="text-center mt-2 mb-3">
              <button
                className="bg-yt-atom hover:opacity-80 cursor-pointer rounded-xl w-4/5 text-center  text-xl py-1"
                onClick={() => setIsOpenHistory(!isOpenHistory)}
              >
                追加履歴&emsp;
                {isOpenHistory ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </button>
            </div>
          )}
          {isOpenHistory &&
            props.history.length > 0 &&
            props.history.map((item) => (
              <div key={item.id} className="grid grid-cols-2 grid-rows-2 gap-2">
                <div className="col-span-1 row-span-1">
                  {dayjs(item.created_at).format('M月D日 hh時mm分')}
                </div>
                <div className="col-span-1 row-span-1 text-center">
                  {item.seats}名
                </div>
                <div className="col-span-2 row-span-1 text-sm text-left pl-3">
                  {item.memo !== null && '【備考】' + item.memo}
                  <hr />
                </div>
              </div>
            ))}
        </div>
      </Modal>
    </>
  )
}
