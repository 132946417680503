import React, { useContext, useState } from 'react'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import axios from 'axios'
import { PostParamsContext } from '../../../../hooks/postParamsContext'
import { Post } from '../../../../types/Post.'
import { Modal_AlertDuplicated } from './Modal_AlertDuplicated'
import { Modal_AlertTImeReversed } from './Modal_AlertTimeReversed'

interface Props {
  selectedCinemaSchedule: Post | null
}
export const InputEndTime = (props: Props) => {
  const { postParams, setPostParams } = useContext(PostParamsContext)
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const [isModalTimeReversedOpen, setIsModalTimeReversedOpen] =
    useState<boolean>(false)

  const validateEndHour = async (endHour: string) => {
    if (
      postParams.start_hour !== '' &&
      (Number(endHour) < Number(postParams.start_hour) ||
        (Number(endHour) === Number(postParams.start_hour) &&
          postParams.start_minutes !== '' &&
          postParams.end_minutes !== '' &&
          Number(postParams.start_minutes) > Number(postParams.end_minutes)))
    ) {
      setIsModalTimeReversedOpen(true)
      return
    }

    const response = await axios.post('validate_endtime', {
      start_day: postParams.start_day,
      end_day: postParams.end_day,
      order: postParams.order,
      end_hour: postParams.end_hour !== '' ? postParams.end_hour : endHour,
      end_minutes:
        postParams.end_minutes !== '' ? postParams.end_minutes : 'none',
      new_end_hour: endHour,
      new_end_minutes:
        postParams.end_minutes !== '' ? postParams.end_minutes : 'none',
    })

    const isDuplicated = response.data
    if (isDuplicated && !props.selectedCinemaSchedule) {
      setPostParams(postParams)
      setModalIsOpen(true)
      return
    }
    if (isDuplicated && props.selectedCinemaSchedule) {
      setPostParams({
        ...postParams,
        end_hour: props.selectedCinemaSchedule.end_hour,
        end_minutes: props.selectedCinemaSchedule.end_minutes,
      })
      setModalIsOpen(true)
      return
    }

    setPostParams({
      ...postParams,
      end_hour: endHour,
    })
  }

  const validateEndMinutes = async (endMinutes: string) => {
    if (
      postParams.start_hour !== '' &&
      postParams.end_hour !== '' &&
      postParams.start_minutes !== '' &&
      Number(postParams.start_hour) === Number(postParams.end_hour) &&
      Number(endMinutes) < Number(postParams.start_minutes)
    ) {
      setIsModalTimeReversedOpen(true)
      return
    }

    const response = await axios.post('validate_endtime', {
      start_day: postParams.start_day,
      end_day: postParams.end_day,
      order: postParams.order,
      end_hour: postParams.end_hour,
      end_minutes:
        postParams.end_minutes !== '' ? postParams.end_minutes : endMinutes,
      new_end_hour: postParams.end_hour,
      new_end_minutes: endMinutes,
    })

    const isDuplicated = response.data
    if (isDuplicated && !props.selectedCinemaSchedule) {
      setPostParams(postParams)
      setModalIsOpen(true)
      return
    }
    if (isDuplicated && props.selectedCinemaSchedule) {
      setPostParams({
        ...postParams,
        end_hour: props.selectedCinemaSchedule.end_hour,
        end_minutes: props.selectedCinemaSchedule.end_minutes,
      })
      setModalIsOpen(true)
      return
    }

    setPostParams({
      ...postParams,
      end_minutes: endMinutes,
    })
  }

  const closeModal = () => {
    setModalIsOpen(false)
  }

  return (
    <>
      <FormControl sx={{ m: 0, minWidth: 100, background: '#282828' }}>
        <InputLabel id="demo-simple-select-autowidth-label">時</InputLabel>
        <Select
          labelId="demo-simple-select-autowidth-label-1"
          id="demo-simple-select-autowidth-1"
          value={postParams.end_hour}
          onChange={(e: SelectChangeEvent) => validateEndHour(e.target.value)}
          autoWidth
          label="Age"
        >
          <MenuItem value="00">
            <em></em>
          </MenuItem>
          <MenuItem value="09">09</MenuItem>
          <MenuItem value="10">10</MenuItem>
          <MenuItem value="11">11</MenuItem>
          <MenuItem value="12">12</MenuItem>
          <MenuItem value="13">13</MenuItem>
          <MenuItem value="14">14</MenuItem>
          <MenuItem value="15">15</MenuItem>
          <MenuItem value="16">16</MenuItem>
          <MenuItem value="17">17</MenuItem>
          <MenuItem value="18">18</MenuItem>
          <MenuItem value="19">19</MenuItem>
          <MenuItem value="20">20</MenuItem>
          <MenuItem value="21">21</MenuItem>
        </Select>
      </FormControl>

      <div className="inline-block mx-2">:</div>

      <FormControl sx={{ minWidth: 100, background: '#282828' }}>
        <InputLabel id="demo-simple-select-autowidth-label">分</InputLabel>
        <Select
          labelId="demo-simple-select-autowidth-label-2"
          id="demo-simple-select-autowidth"
          value={postParams.end_minutes}
          onChange={(e: SelectChangeEvent) =>
            validateEndMinutes(e.target.value)
          }
          autoWidth
          label="Age"
        >
          <MenuItem value="00">
            <em>00</em>
          </MenuItem>
          <MenuItem value="05">05</MenuItem>
          <MenuItem value="10">10</MenuItem>
          <MenuItem value="15">15</MenuItem>
          <MenuItem value="20">20</MenuItem>
          <MenuItem value="25">25</MenuItem>
          <MenuItem value="30">30</MenuItem>
          <MenuItem value="35">35</MenuItem>
          <MenuItem value="40">40</MenuItem>
          <MenuItem value="45">45</MenuItem>
          <MenuItem value="50">50</MenuItem>
          <MenuItem value="55">55</MenuItem>
        </Select>
      </FormControl>

      <Modal_AlertDuplicated
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        targetText="終了時間"
      />

      <Modal_AlertTImeReversed
        isModalTimeReversedOpen={isModalTimeReversedOpen}
        setIsModalTimeReversedOpen={setIsModalTimeReversedOpen}
      />
    </>
  )
}
