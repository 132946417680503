import LogoSmall from '../../assets/logo_small.svg'
import MenuIcon from '@mui/icons-material/Menu'
import CloseIcon from '@mui/icons-material/Close'
import Modal from 'react-modal'
import { useState } from 'react'
import { Link } from 'react-router-dom'

export const ReservationHeader = () => {
  const [modalIsOpen, setIsOpen] = useState(false)

  const customStyles = {
    content: {
      top: '50%',
      left: 'auto',
      right: '0%',
      bottom: 'auto',
      transform: 'translate(0%, -50%)',
      width: '200px',
      height: '100%',
      // background: '#f5f5f4',
      background: '#A94337',
      zIndex: 5,
    },
    overlay: {
      top: '44px',
      left: 0,
      backgroundColor: 'rgba(0,0,0,0.60)',
      zIndex: 5,
    },
  }

  const openModal = () => {
    setIsOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
  }

  return (
    <>
      <header className=" sticky top-0 bg-white h-11">
        <nav className="flex flex-row pt-2  text-cinekoya">
          <div className="basis-1/3"></div>
          <div className="basis-1/3 text-center">
            <Link to="/">
              <img src={LogoSmall} className="m-auto" width="150" />
            </Link>
          </div>

          <div className="basis-1/3 text-right">
            {!modalIsOpen ? (
              <MenuIcon
                fontSize="large"
                onClick={openModal}
                className="cursor-pointer"
              />
            ) : (
              <CloseIcon
                fontSize="large"
                onClick={closeModal}
                className="cursor-pointer"
              />
            )}
          </div>
        </nav>
      </header>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <div className="font-title text-white text-left pr-1 text-base ">
          <div className=" py-3 ">
            <a className="hover:text-cinekoya-sub-a hover:font-bold" href="">
              作品情報
            </a>
          </div>
          <hr />
          <div className="  py-3 ">
            <a className="hover:text-cinekoya-sub-a hover:font-bold" href="">
              シネコヤの過ごし方
            </a>
          </div>
          <hr />
          <div className=" py-3">
            <a className="hover:text-cinekoya-sub-a hover:font-bold" href="">
              料金
            </a>
          </div>
          <hr />
          <div className=" py-3">
            <a className="hover:text-cinekoya-sub-a hover:font-bold" href="">
              アクセス
            </a>
          </div>
        </div>
      </Modal>
    </>
  )
}
