import { useEffect, useState } from 'react'

interface TicketNum {
  fanclub: number
  fanclub_companion: number
  members: number
  members_companion: number
  youth: number
  under_youth: number
  infant: number
  general: number
}

interface UnitPrice extends TicketNum {
  second: number
}

interface Result {
  id: number
  name: string
  ticketNum: number
  unitPrice: number
  totalPrice: number
}
interface Props {
  ticketNum: TicketNum
  price: UnitPrice
  repeatGeneralNum: number
}

export const useAggregateResults = (props: Props) => {
  const [aggregateResults, setAggregateResults] = useState<Result[]>([])

  useEffect(() => {
    let generalNum = props.ticketNum.general
    if (props.repeatGeneralNum > 0) {
      generalNum = generalNum - props.repeatGeneralNum
    }

    setAggregateResults([
      {
        id: 1,
        name: 'ファンクラブ',
        ticketNum: props.ticketNum.fanclub,
        unitPrice: props.price.fanclub,
        totalPrice: props.ticketNum.fanclub * props.price.fanclub,
      },
      {
        id: 2,
        name: 'ファンクラブ同伴',
        ticketNum: props.ticketNum.fanclub_companion,
        unitPrice: props.price.fanclub_companion,
        totalPrice:
          props.ticketNum.fanclub_companion * props.price.fanclub_companion,
      },
      {
        id: 3,
        name: 'メンバーズ',
        ticketNum: props.ticketNum.members,
        unitPrice: props.price.members,
        totalPrice: props.ticketNum.members * props.price.members,
      },
      {
        id: 4,
        name: 'メンバーズ同伴',
        ticketNum: props.ticketNum.members_companion,
        unitPrice: props.price.members_companion,
        totalPrice:
          props.ticketNum.members_companion * props.price.members_companion,
      },
      {
        id: 5,
        name: 'ユース',
        ticketNum: props.ticketNum.youth,
        unitPrice: props.price.youth,
        totalPrice: props.ticketNum.youth * props.price.youth,
      },
      {
        id: 6,
        name: '小中高生',
        ticketNum: props.ticketNum.under_youth,
        unitPrice: props.price.under_youth,
        totalPrice: props.ticketNum.under_youth * props.price.under_youth,
      },
      {
        id: 7,
        name: '幼児',
        ticketNum: props.ticketNum.infant,
        unitPrice: props.price.infant,
        totalPrice: props.ticketNum.infant * props.price.infant,
      },
      {
        id: 8,
        name: '一般',
        ticketNum: generalNum,
        unitPrice: props.price.general,
        totalPrice: generalNum * props.price.general,
      },
      {
        id: 9,
        name: '２本目割引',
        ticketNum: props.repeatGeneralNum,
        unitPrice: props.price.second,
        totalPrice: props.repeatGeneralNum * props.price.second,
      },
    ])
  }, [props.ticketNum, props.repeatGeneralNum])

  return aggregateResults
}
