import axios from 'axios'
import EditIcon from '@mui/icons-material/Edit'
import CloseIcon from '@mui/icons-material/Close'
import { ChangeEvent, useState } from 'react'

type Props = {
  reservableTime: number
  setReservableTime: (e: number) => void
}

export const ReservableTimeSetting = (props: Props) => {
  const [editReservableTime, setEditReservableTime] = useState<boolean>(false)

  const saveReservableTime = () => {
    axios
      .put('common_setting_reservableTime', {
        reservable_time: props.reservableTime,
      })
      .then((response) => {
        props.setReservableTime(response.data)
        setEditReservableTime(false)
      })
      .catch((error) => console.log(error))
  }

  const changeReservableTime = (e: ChangeEvent<HTMLInputElement>) => {
    const display_limit = Number(e.target.value)

    if (display_limit < 1) props.setReservableTime(1)
    else {
      props.setReservableTime(Number(e.target.value))
    }
  }

  return (
    <>
      <div className="flex flex-row pt-2 text-xl">
        <div className="basis-3/4 text-center">
          {editReservableTime ? (
            <>
              <span>上映開始の&emsp;</span>
              <input
                type="number"
                className=" w-20  ml-1 text-center font-bold text-cinekoya border-solid  border-1 border-yt-atom focus:outline-none focus:ring-2 focus:ring-cinekoya-sub-c focus:border-transparent"
                value={props.reservableTime}
                onChange={changeReservableTime}
              />
              <span>&emsp;分前まで予約可能</span>
              <button
                className="text-center inline  rounded-2xl ml-3 px-2 py-1 text-sm bg-yt-atom hover:bg-yt-atom-hover cursor-pointer "
                onClick={saveReservableTime}
              >
                保存
              </button>
            </>
          ) : (
            <>
              <span className="text-yt-text-gray">上映開始の&nbsp;</span>
              <span className=" font-bold  text-cinekoya-sub-a">
                {props.reservableTime}
              </span>
              <span className="text-white">&nbsp;分前</span>
              <span className="text-yt-text-gray">まで予約可能</span>
            </>
          )}
        </div>
        <div className="basis-1/4 text-right pr-3 cursor-pointer">
          {editReservableTime ? (
            <CloseIcon
              fontSize="large"
              onClick={() => setEditReservableTime(!editReservableTime)}
            />
          ) : (
            <EditIcon
              fontSize="large"
              onClick={() => setEditReservableTime(!editReservableTime)}
            />
          )}
        </div>
      </div>
    </>
  )
}
