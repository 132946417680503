import React, { ChangeEvent } from 'react'
import { DiscountInfo } from '../../../../types/Reservation/DiscountInfo'
import { Oval } from 'react-loader-spinner'

interface Props {
  prevGeneralNumber: number
  generalNum: number
  verifyCoupon: () => void
  discountInfo: DiscountInfo
  setDiscountInfo: (e: DiscountInfo) => void
  errorVerification: boolean
  isLoading: boolean
  handleCancel: () => void
}

export const InputCouponCode = (props: Props) => {
  return (
    <>
      <div className="flex justify-center font-body">
        <div className="w-full   text-yt-atom font-bold opacity-90 mb-2 px-2 py-3 text-center text-base sm:text-xl ">
          以下をご入力後「認証」を押して下さい
        </div>
        <hr />
      </div>

      <div className="flex justify-center font-body">
        <div className="w-full rounded-b-2xl py-2 px-3 ">
          <div className=" text-cinekoya text-center">
            <b>メールアドレス</b>
          </div>
          <div className="mb-3 text-center ">
            <input
              className="w-full sm:w-4/5 rounded-lg py-1 px-2 border-zinc-700 border-1 bg-cinekoya-yellow-2 bg-opacity-10"
              type="text"
              autoComplete="email"
              maxLength={50}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                props.setDiscountInfo({
                  ...props.discountInfo,
                  email: e.target.value,
                })
              }
            />
            <br />
            <span className=" text-sm">
              ※同じ日の別作品の予約に使用したアドレスをご入力下さい
            </span>
          </div>

          <div className="  text-cinekoya text-center">
            <b>クーポンコード</b>
          </div>
          <div className="mb-3 text-center">
            <input
              className="w-full sm:w-4/5 rounded-lg py-1 px-2 border-zinc-700 border-1 bg-cinekoya-yellow-2 bg-opacity-10"
              type="text"
              maxLength={7}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                props.setDiscountInfo({
                  ...props.discountInfo,
                  coupon_code: e.target.value,
                })
              }
            />
            <br />
            <span className=" text-sm">
              ※同じ日の別作品の予約完了メールに記載のコードをご入力下さい
            </span>
          </div>
          {props.errorVerification && (
            <div className="w-full text-center text-cinekoya font-bold">
              予約が確認できませんでした。
              <br />
              入力情報に誤りがないかご確認下さい。
            </div>
          )}
          <div className="text-center">
            {props.prevGeneralNumber === -1 && (
              <>
                <button
                  className="w-full md:w-3/4 h-12 py-2 bg-cinekoya bg-opacity-85 text-white shadow-md rounded-2xl hover:bg-opacity-75 text-xl border-white border-4"
                  onClick={props.verifyCoupon}
                >
                  {!props.isLoading && '認証'}

                  {props.isLoading && (
                    <Oval
                      height={22}
                      width={22}
                      color="#ffffff"
                      wrapperStyle={{ justifyContent: 'center' }}
                      wrapperClass=""
                      visible={true}
                      ariaLabel="oval-loading"
                      secondaryColor="#A3C8CC"
                      strokeWidth={5}
                      strokeWidthSecondary={5}
                    />
                  )}
                </button>
              </>
            )}
            {!props.isLoading &&
              (props.prevGeneralNumber === 1 ||
                (props.prevGeneralNumber !== -1 && props.generalNum === 1)) && (
                <div className="w-full inline-block md:w-3/4 h-12 py-2 bg-cinekoya text-white shadow-md rounded-2xl hover:bg-opacity-80 text-xl border-white border-4 font-bold">
                  割引が適用されました！
                </div>
              )}
          </div>

          <div className="flex justify-center">
            <div className="w-full md:w-3/4 bg-cinekoya-hover bg-opacity-20 rounded-lg mt-2 py-2 px-3 text-sm md:text-base text-zinc-700 ">
              ※お客様が<b>同じ日の別作品の予約</b>をしており、その予約内に
              <b>「一般料金」のお客様が含まれている</b>とき、
              <b>予約完了メール内に</b>
              ２本目割引用のクーポンコードが記載されています。
              <br />
              ※別作品の予約に「一般料金」のお客様が <b>含まれていない</b>
              場合、割引は<b>適用されません</b>のでご了承下さい。
            </div>
          </div>
          <div className="w-full text-center">
            <button
              className="w-1/2 py-1 mt-3 bg-yt-atom hover:bg-opacity-80 text-white rounded-xl cursor-pointer"
              onClick={props.handleCancel}
            >
              キャンセル
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
