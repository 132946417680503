import { useEffect, useState } from 'react'

interface Props {
  seatNum: number
  fanclubNum: number
  membersNum: number
  youthNum: number
  underYouthNum: number
  infantNum: number
}

export const useTicketNum = (props: Props) => {
  const [ticketNum, setTicketNum] = useState({
    fanclub: 0,
    fanclub_companion: 0,
    members: 0,
    members_companion: 0,
    youth: 0,
    under_youth: 0,
    infant: 0,
    general: 0,
  })

  useEffect(() => {
    const sNum = props.seatNum
    const fNum = props.fanclubNum
    const mNum = props.membersNum
    const yNum = props.youthNum
    const uyNum = props.underYouthNum
    const iNum = props.infantNum
    const sNum2 = sNum - iNum - uyNum

    let fanclub = 0
    let fanclub_companion = 0
    let members = 0
    let members_companion = 0
    let youth = 0
    let under_youth = 0
    let infant = 0
    let general = 0

    //infantとuyNumは必ず優先
    if (iNum > 0) {
      infant = iNum
    }

    if (uyNum > 0) {
      under_youth = uyNum
    }

    //以下ファンクラ＆メンバーズとその同伴の配分
    //条件はfNum>0など省いて良いものもあるがモレ防止のため明示化。
    if (fNum > 0 && sNum2 === fNum) {
      fanclub = fNum
      fanclub_companion = 0
      members = 0
      members_companion = 0
    }

    if (fNum > 0 && sNum2 > fNum && sNum2 - fNum < fNum) {
      fanclub = fNum
      fanclub_companion = sNum2 - fNum
      members = 0
      members_companion = 0
    }

    if (fNum > 0 && sNum2 > fNum && sNum2 - fNum === fNum) {
      fanclub = fNum
      fanclub_companion = fNum
      members = 0
      members_companion = 0
    }

    if (fNum > 0 && sNum2 > fNum && sNum2 - fNum > fNum && mNum === 0) {
      fanclub = fNum
      fanclub_companion = fNum
      members = 0
      members_companion = 0
    }
    if (
      fNum > 0 &&
      sNum2 > fNum &&
      sNum2 - fNum > fNum &&
      mNum > 0 &&
      sNum2 - fNum * 2 < mNum
    ) {
      fanclub = fNum
      fanclub_companion = fNum
      members = sNum2 - fNum * 2
      members_companion = 0
    }

    if (
      fNum > 0 &&
      sNum2 > fNum &&
      sNum2 - fNum > fNum &&
      mNum > 0 &&
      sNum2 - fNum * 2 === mNum
    ) {
      fanclub = fNum
      fanclub_companion = fNum
      members = mNum
      members_companion = 0
    }

    if (
      fNum > 0 &&
      sNum2 > fNum &&
      sNum2 - fNum > fNum &&
      mNum > 0 &&
      sNum2 - fNum * 2 > mNum &&
      sNum - fNum * 2 - mNum < mNum
    ) {
      fanclub = fNum
      fanclub_companion = fNum
      members = mNum
      members_companion = sNum2 - fNum * 2 - mNum
    }

    if (
      fNum > 0 &&
      sNum2 > fNum &&
      sNum2 - fNum > fNum &&
      mNum > 0 &&
      sNum2 - fNum * 2 > mNum &&
      sNum2 - fNum * 2 - mNum === mNum
    ) {
      fanclub = fNum
      fanclub_companion = fNum
      members = mNum
      members_companion = mNum
    }

    if (fNum === 0 && mNum > 0 && sNum2 === mNum) {
      fanclub = 0
      fanclub_companion = 0
      members = mNum
      members_companion = 0
    }

    if (fNum === 0 && mNum > 0 && sNum2 > mNum && sNum2 - mNum < mNum) {
      fanclub = 0
      fanclub_companion = 0
      members = mNum
      members_companion = sNum2 - mNum
    }

    if (fNum === 0 && mNum > 0 && sNum2 > mNum && sNum2 - mNum >= mNum) {
      fanclub = 0
      fanclub_companion = 0
      members = mNum
      members_companion = mNum
    }

    //ユースと一般の配分
    //幼児・中高生・ファンクラブ・メンバーズを確定させてから
    const sNum3 =
      sNum -
      infant -
      under_youth -
      fanclub -
      fanclub_companion -
      members -
      members_companion

    if (sNum3 > 0 && sNum3 < yNum) {
      youth = yNum - sNum3
      general = 0
    }
    if (sNum3 > 0 && sNum3 === yNum) {
      youth = yNum
      general = 0
    }

    if (sNum3 > 0 && sNum3 > yNum) {
      youth = yNum
      general = sNum3 - yNum
    }

    setTicketNum({
      fanclub: fanclub,
      fanclub_companion: fanclub_companion,
      members: members,
      members_companion: members_companion,
      youth: youth,
      under_youth: under_youth,
      infant: infant,
      general: general,
    })

    /////////////
  }, [
    props.fanclubNum,
    props.membersNum,
    props.youthNum,
    props.underYouthNum,
    props.infantNum,
  ])
  //依存配列を"props"とすると何故か無限レンダーが生じる

  return ticketNum
}
