import { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { UrlContext } from '../../../hooks/UrlContext'
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded'
import { ReserveBaseParamsContext } from '../../../hooks/ReserveBaseParamsContext'
import { useReservable } from '../../../hooks/useReservable'
import { Post } from '../../../types/Post.'
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn'
import { CallToBookButton } from './CallToBookButton'

interface ReservableProperty {
  date: string
  order: number
  isReservable: boolean
  ReservableNumber: number
}

type Props = {
  date: string
  table: Post
  reservableProperty: ReservableProperty[]
}

export const CinemaCard = (props: Props) => {
  const navigate = useNavigate()

  const linkPath = `${props.table.is_cinema ? '/cinema/' : '/non_cinema/'}${
    props.table.cinema_id
  }`

  const { reserveBaseParams, setReserveBaseParams } = useContext(
    ReserveBaseParamsContext
  )

  const [isStartExpired, setIsStartExpired] = useState<boolean>(false)
  const [isTelExpired, setIsTelExpired] = useState<boolean>(false)

  // const [reservable, setReservable] = useState<boolean>(false)

  const { imageURL } = useContext(UrlContext)

  const toReservation = () => {
    setReserveBaseParams({
      ...reserveBaseParams,
      date: props.date,
      order: props.table.order,
      seat: 1,
    })

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })

    navigate('../reservation/select_seat')
  }

  const isReservable = useReservable({
    reservableProperty: props.reservableProperty,
    date: props.date,
    table: props.table,
  })

  const isReservationTimeExpired = () => {
    if (props.table.start_hour === '' || props.table.start_minutes === '') {
      return false
    }

    const currentDateTime = new Date()

    const [year, month, day] = props.date
      .split('-')
      .map((val) => parseInt(val, 10))
    const hour = parseInt(props.table.start_hour, 10)
    const minutes = parseInt(props.table.start_minutes, 10)

    const displayDateTime = new Date(year, month - 1, day, hour, minutes, 0, 0)

    setIsStartExpired(currentDateTime > displayDateTime)

    const displayDateTimeMinus15 = new Date(displayDateTime)
    displayDateTimeMinus15.setMinutes(displayDateTime.getMinutes() - 15)

    setIsTelExpired(currentDateTime > displayDateTimeMinus15)
  }

  useEffect(() => {
    isReservationTimeExpired()
  }, [])

  return (
    <>
      <div className="grid grid-rows-3 grid-cols-7 gap-0 mt-2 p-2 border-solid border-2  shadow-md font-title rounded-lg bg-zinc-50">
        <div className="row-span-3 col-span-2 sm:col-span-1 ">
          <Link to={linkPath} className="hover:no-underline ">
            <img
              src={imageURL + props.table.image}
              width="80"
              className="m-auto block cursor-pointer hover:opacity-80 "
            />

            <button
              className={`hidden sm:block w-auto px-2 py-0.5 mt-1 m-auto text-sm text-center text-white  bg-cinekoya font-body rounded-lg shadow-md cursor-pointer hover:bg-cinekoya-hover
            ${props.table.is_cinema ? 'px-3' : 'px-0.5'}`}
            >
              &nbsp;
              {props.table.is_cinema ? '作品' : 'イベント'}
              詳細
            </button>
          </Link>
        </div>

        <div className="pl-0 sm:pl-1 row-span-1 col-span-5 sm:col-span-6 text-xl sm:text-2xl text-cinekoya-text cursor-pointer">
          <Link
            to={linkPath}
            className="hover:no-underline hover:text-cinekoya"
          >
            <span>
              {props.table.alternative_name === null
                ? props.table.title
                : props.table.alternative_name}
            </span>
          </Link>
        </div>

        <div className=" row-span-1 col-span-5 sm:col-span-6 px-0  sm:px-5 text-center">
          <div className=" pb-1 text-white bg-cinekoya-sub-b sm:rounded-lg font-number">
            {!props.table.time_unsettled && (
              <>
                <AccessTimeRoundedIcon />
                &nbsp;
                <span className="text-lg sm:text-3xl align-middle">
                  {props.table.start_hour}:{props.table.start_minutes}&nbsp;
                </span>
                <span className="align-bottom">
                  ~&nbsp;{props.table.end_hour}:{props.table.end_minutes}
                </span>
              </>
            )}
            {Boolean(props.table.time_unsettled) && (
              <>
                <AccessTimeRoundedIcon />
                &nbsp;
                <span className="text-base sm:text-xl align-middle font-title">
                  上映時間未定
                </span>
              </>
            )}
          </div>
        </div>

        <div className="flex  row-span-1 col-span-5 sm:col-span-6 justify-end pr-1 sm:pr-5  items-center">
          {props.table.non_cinema_reservable !== null &&
          props.table.non_cinema_reservable == false ? (
            <a
              href={`/non_cinema/${props.table.cinema_id}`}
              target="_blank"
              rel="noopener noreferrer"
              className="w-3/4 sm:w-1/3 py-0.5 sm:py-1 text-sm text-center text-white inline-block bg-cinekoya-sub-b font-body rounded-full shadow-md  border-1 bg-opacity-90 hover:no-underline hover:bg-opacity-70"
              role="button"
            >
              詳細を参照
            </a>
          ) : (
            <>
              {isReservable &&
                !props.table.time_unsettled &&
                !isTelExpired &&
                !isStartExpired && (
                  <button
                    className="w-3/4 sm:w-1/3 py-0.5 sm:py-1 text-sm text-center text-white inline-block bg-cinekoya-sub-c font-body rounded-full shadow-md cursor-pointer border-1 hover:bg-cinekoya-sub-a"
                    onClick={toReservation}
                  >
                    ◯&nbsp;WEB予約・購入
                  </button>
                )}

              {isReservable &&
                Boolean(props.table.time_unsettled) &&
                !isTelExpired &&
                !isStartExpired && (
                  <button
                    className="flex justify-center items-center text-center  w-3/4 sm:w-1/3 py-0.5 sm:py-1 text-white  bg-zinc-500 font-body rounded-full shadow-md  border-1 "
                    disabled
                  >
                    <DoNotDisturbOnIcon fontSize="small" className="h-4 w-4" />
                    <span className="text-sm ml-1">準備中</span>
                  </button>
                )}

              {!isReservable && !isTelExpired && !isStartExpired && (
                <CallToBookButton />
              )}

              {isTelExpired && !isStartExpired && (
                <button
                  className="w-3/4 sm:w-1/3 py-0.5 sm:py-1 text-sm text-center text-white inline-block bg-cinekoya-sub-b font-body rounded-full shadow-md cursor-not-allowed border-1 bg-opacity-90 "
                  disabled
                >
                  窓口販売
                </button>
              )}

              {isTelExpired && isStartExpired && (
                <button
                  className="w-3/4 sm:w-1/3 py-0.5 sm:py-1 text-sm text-center text-white inline-block bg-stone-700 font-body rounded-full shadow-md cursor-not-allowed border-1 bg-opacity-50"
                  disabled
                >
                  受付終了
                </button>
              )}
            </>
          )}
        </div>
      </div>
    </>
  )
}
