import { FC, useEffect, useState } from 'react'

type ImagePreviewProps = {
  file: File | null
}

export const ImagePreviewDetail: FC<ImagePreviewProps> = ({
  file,
  ...props
}) => {
  const [url, setUrl] = useState<string>('')

  useEffect(() => {
    if (!file) {
      return
    }

    let reader: FileReader | null = new FileReader()
    reader.onloadend = () => {
      const res = reader!.result
      if (res && typeof res === 'string') {
        setUrl(res)
      }
    }
    reader.readAsDataURL(file)

    return () => {
      reader = null
    }
  }, [file])

  return file ? <img src={url} alt={file.name} {...props} /> : null
}
