import dayjs from 'dayjs'
import React from 'react'

interface ArrayDate {
  date: string
  isClose: boolean
}

interface Props {
  arrayDateAttribute: ArrayDate[]
  onmouseDate: string
}
export const Thead = (props: Props) => {
  return (
    <thead>
      <tr>
        {props.arrayDateAttribute?.map((dateAttribute, index) => (
          <th
            key={index}
            className={`${
              props.onmouseDate === dateAttribute.date && 'bg-cinekoya-sub-c'
            } 

          ${
            props.onmouseDate !== dateAttribute.date &&
            dateAttribute.isClose === false &&
            'bg-yt-bg'
          }
          ${
            props.onmouseDate !== dateAttribute.date &&
            dateAttribute.isClose === true &&
            'bg-yt-atom-hover'
          }
          border-1 border-yt-atom px-1 text-base text-center h-11`}
          >
            <div>{dayjs(dateAttribute.date).format('MM/DD')}</div>
            {dateAttribute.isClose && (
              <div className="text-sm text-stone-300">休館日</div>
            )}
          </th>
        ))}
      </tr>
    </thead>
  )
}
