import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { Post } from '../../types/Post.'

interface Props {
  date: string
  orderNum: number
  schedules: Post[]
  defaultPostParams: Post
}
export const useMatchedSchedule = (props: Props) => {
  const [matchedCinemaSchedule, setMatchedCinemaSchedule] =
    useState<Post | null>(props.defaultPostParams)
  const [isDraft, setIsDraft] = useState<boolean>(false)
  const [dateDiff, setDateDiff] = useState<number>(0)

  useEffect(() => {
    const arraySelectedSchedule = props.schedules?.map((schedule) => {
      if (
        schedule.order === props.orderNum &&
        schedule.start_day <= props.date &&
        schedule.end_day >= props.date
      ) {
        return schedule
      } else {
        return null
      }
    })

    const selected = arraySelectedSchedule.find((items) => items !== null)
    //作品登録が非該当である場合はundefinedが返る

    if (!selected) {
      //レコード削除後には初期化する必要があるのでステート更新したあとreturn
      setMatchedCinemaSchedule(null)
      setIsDraft(false)
      return
    }

    if (selected) {
      setMatchedCinemaSchedule(selected)
      setIsDraft(!selected.display_public)
      setDateDiff(
        dayjs(selected?.end_day).diff(dayjs(selected?.start_day), 'day')
      )
    }
  }, [props.schedules, props.date])

  return { matchedCinemaSchedule, isDraft, dateDiff }
}
