import axios from 'axios'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { WeekSetting } from './WeekSetting'
import { ClosingDateButton } from './ClosingDateButton'
import { ClosingDateSetting } from './ClosingDateSetting'
import { DisplayPeriodSetting } from './DisplayPeriodSetting'
import { SessionTimeSetting } from './SessionTImeSetting'
import { ReservableTimeSetting } from './ReservableTImeSetting'
import { CapacitySetting } from './CapacitySetting'

interface dateType {
  id: number
  date: string
}

export const CommonSetting = () => {
  const today = dayjs()
  const [displayPeriod, setDisplayPeriod] = useState<number>(0)
  const [sessionTime, setSessionTime] = useState<number>(0)

  const [capacity, setCapacity] = useState<number>(0)

  const [openDays, setOpenDays] = useState<dateType[]>([])
  const [closeDays, setCloseDays] = useState<dateType[]>([])
  const [isOpendayMode, setIsOpendayMode] = useState<boolean>(true)
  const [reservableTime, setReservableTime] = useState<number>(0)

  useEffect(() => {
    axios
      .get('common_setting', {
        params: {
          today: today.format('YYYY-MM-DD'),
        },
      })
      .then((response) => {
        setDisplayPeriod(response.data.display_period)
        setCapacity(response.data.capacity)
        setSessionTime(response.data.session_time)
        setReservableTime(response.data.reservable_time)
      })
      .catch((error) => console.log(error))
  }, [])

  return (
    <>
      <div className="flex flex-row justify-center text-white mb-2">
        <div className="basis-1/3 pl-2 text-2xl font-body font-bold pb-2">
          その他設定
        </div>
        <div className="basis-2/3" />
      </div>

      <div className="flex flex-col items-center  ">
        <div className="w-4/5 grid grid-cols-4 gap-2  bg-yt-bg pt-2 pb-5 px-3 text-white font-body border-1 border-yt-atom rounded-xl mb-2">
          <div className="col-span-1  p-2 font-bold font-body bg-yt-component  text-white text-xl rounded-2xl">
            定員数
          </div>
          <div className="col-span-3">
            <CapacitySetting capacity={capacity} setCapacity={setCapacity} />
          </div>
          <hr className="col-span-4 mt-2" />
          <div className="col-span-1 p-2 font-bold font-body bg-yt-component  text-white text-xl rounded-2xl">
            セッション時間
          </div>
          <div className="col-span-3">
            <SessionTimeSetting
              sessionTime={sessionTime}
              setSessionTime={setSessionTime}
            />
          </div>
          <hr className="col-span-4 mt-2" />
          <div className="col-span-1  p-2 font-bold font-body bg-yt-component  text-white text-xl rounded-2xl">
            予約可能時間
          </div>
          <div className="col-span-3">
            <ReservableTimeSetting
              reservableTime={reservableTime}
              setReservableTime={setReservableTime}
            />
          </div>

          <hr className="col-span-4 mt-2" />
          <div className="col-span-1 p-2 font-bold backdrop: bg-yt-component text-white text-xl rounded-2xl justify-center">
            スケジュール期間
          </div>
          <div className="col-span-3">
            {' '}
            <DisplayPeriodSetting
              displayPeriod={displayPeriod}
              setDisplayPeriod={setDisplayPeriod}
            />
          </div>

          <hr className="col-span-4 clear-both mt-2" />
          <div className="col-span-1 mt-2 p-2 font-bold font-body  bg-yt-component text-white text-xl rounded-2xl">
            休館日設定
          </div>
          <div className="col-span-3">
            {' '}
            <WeekSetting />
            <hr className="col-span-4 clear-both mt-3" />
          </div>
          <div className="col-span-1" />
          <div className="col-span-3 mt-3">
            <ClosingDateButton
              isOpendayMode={isOpendayMode}
              setIsOpendayMode={setIsOpendayMode}
              setOpenDays={setOpenDays}
              setCloseDays={setCloseDays}
            />
          </div>
          <div className="col-span-1" />
          <div className="col-span-3">
            <>
              <ClosingDateSetting
                isOpendayMode={isOpendayMode}
                openDays={openDays}
                closeDays={closeDays}
                setOpenDays={setOpenDays}
                setCloseDays={setCloseDays}
              />
            </>
          </div>
        </div>
      </div>
    </>
  )
}
