import React, { useState, useEffect } from 'react'

interface Props {
  isOccupied: boolean
  orderSelectType: string
  dateSelectType: string
  isDraft: boolean
  inRange: boolean
  pastDate: boolean
}
export const useBgColor = (props: Props) => {
  const [bgColor, setBgColor] = useState<string>('')

  useEffect(() => {
    if (
      props.isOccupied &&
      props.orderSelectType !== 'unelected' &&
      !props.isDraft &&
      props.inRange
    ) {
      setBgColor('bg-cinekoya-sub-a cursor-pointer')
    }

    if (
      props.isOccupied &&
      props.orderSelectType !== 'unelected' &&
      !props.isDraft &&
      !props.inRange
    ) {
      setBgColor('bg-cinekoya-sub-c cursor-pointer')
    }

    if (
      props.isOccupied &&
      props.orderSelectType !== 'unelected' &&
      props.isDraft &&
      props.inRange
    ) {
      setBgColor('bg-yt-atom-hover cursor-pointer ')
    }
    if (
      props.isOccupied &&
      props.orderSelectType !== 'unelected' &&
      props.isDraft &&
      !props.inRange
    ) {
      setBgColor('bg-yt-atom cursor-pointer ')
    }

    if (
      !props.isOccupied &&
      !props.pastDate &&
      props.orderSelectType !== 'unselected' &&
      props.dateSelectType !== 'selected'
    ) {
      setBgColor(
        'bg-yt-component hover:border-2 hover:border-cinekoya-sub-c hover:rounded-xl cursor-pointer'
      )
    }

    if (
      !props.isOccupied &&
      !props.pastDate &&
      props.orderSelectType === 'selected' &&
      props.dateSelectType === 'selected'
    ) {
      setBgColor('bg-cinekoya-sub-a rounded-xl cursor-pointer')
    }

    if (
      !props.isOccupied &&
      !props.pastDate &&
      props.orderSelectType === 'unselected'
    ) {
      setBgColor('bg-yt-bg cursor-pointer')
    }

    if (!props.isOccupied && props.pastDate) {
      setBgColor('bg-yt-bg cursor-not-allowed')
    }
  }, [props])

  return bgColor
}
