import axios from 'axios'
import React, { useState } from 'react'
import { SetCommonLimit } from '../Presentational/SetCommonLimit'

interface Props {
  commonLimit: number
  setCommonLimit: (e: number) => void
  isForcedLimit: boolean
  setIsForcedLimit: (e: boolean) => void
}

export const SetCommonLimitContainer = (props: Props) => {
  const [editLimit, setEditLimit] = useState<boolean>(false)

  const saveLimit = () => {
    axios
      .put('common_setting_limit', {
        limit: props.commonLimit,
      })
      .then((response) => {
        props.setCommonLimit(response.data)
        setEditLimit(false)
      })
      .catch((error) => console.log(error))
  }

  const saveForcedLimit = () => {
    axios
      .put('common_setting_forced_limit', {
        forced_limit: true,
      })
      .then((response) => {
        props.setIsForcedLimit(Boolean(response.data))
      })
      .catch((error) => console.log(error))
  }

  const cancelForcedLimit = () => {
    axios
      .put('common_setting_cancel_forced_limit', {
        forced_limit: false,
      })
      .then((response) => {
        props.setIsForcedLimit(Boolean(response.data))
      })
      .catch((error) => console.log(error))
  }

  return (
    <SetCommonLimit
      editLimit={editLimit}
      commonLimit={props.commonLimit}
      isForcedLimit={props.isForcedLimit}
      setEditLimit={setEditLimit}
      saveLimit={saveLimit}
      setCommonLimit={props.setCommonLimit}
      saveForcedLimit={saveForcedLimit}
      cancelForcedLimit={cancelForcedLimit}
    />
  )
}
