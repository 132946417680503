import axios from 'axios'
import { useContext, useState } from 'react'
import { PostParamsContext } from '../../../../hooks/postParamsContext'
import { ImageFile } from '../../../../types/ImageFile'

import { RotatingLines, Vortex } from 'react-loader-spinner'
import { SaveButton } from '../../../Atoms/SaveButton'

interface Props {
  imageFile: ImageFile
  saveDisabled: boolean
  closeModal: () => void
  getLatest: () => void
}

export const SaveSchedule = (props: Props) => {
  const { postParams } = useContext(PostParamsContext)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const registerSchedule = (isPublic: boolean) => {
    setIsLoading(true)
    // const detailEscaped = postParams.detail
    //   ?.replace(/&/g, '&lt;')
    //   .replace(/</g, '&lt;')
    //   .replace(/>/g, '&gt;')
    //   .replace(/"/g, '&quot;')
    //   .replace(/'/g, '&#x27;')

    new Promise((resolve) => {
      const params = {
        ...postParams,
        display_public: isPublic,
      }
      const formData = new FormData()
      if (props.imageFile.file !== null) {
        const image: File = props.imageFile.file

        formData.append('image', image, props.imageFile.fileName)
        //第3引数はファイル名。ここで画像ファイル名を指定しないと、すべて”Blob”という名前で保存される。拡張子も何もないので開くこともできない。また同じ名前なので延々と上書きされる。
      }
      const paramsJson = JSON.stringify(params)
      formData.append('params', paramsJson)
      resolve(formData)
    }).then((formData) => {
      const config = { headers: { 'content-type': 'multipart/form-data' } }

      axios
        .post('create_schedule', formData, config)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .then((response) => {
          props.closeModal()
          props.getLatest()
          setIsLoading(false)
          console.log('success')
        })
    })
  }

  return (
    <>
      <SaveButton
        saveDisabled={props.saveDisabled}
        onClick={() => registerSchedule(true)}
        isPublic={true}
      >
        {isLoading && (
          <div className="flex justify-center">
            <RotatingLines
              strokeColor="white"
              strokeWidth="5"
              animationDuration="0.75"
              width="30"
              visible={true}
            />
          </div>
        )}

        {!isLoading && '登録'}
      </SaveButton>

      <SaveButton
        saveDisabled={props.saveDisabled}
        onClick={() => registerSchedule(false)}
        isPublic={false}
      >
        下書き保存
      </SaveButton>
    </>
  )
}
