import React from 'react'
// import { EditNotification } from './EditNotification'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import CloseIcon from '@mui/icons-material/Close'
import { ButtonBorderBlue } from '../../../Atoms/ButtonBorderBlue'
import QuillEditor from '../../../Molecules/QuillEditor'

interface EventList {
  id: number
  title: string
}

interface Props {
  editorMode: 'live' | 'edit' | 'preview'
  setEditorMode: (e: 'live' | 'edit' | 'preview') => void
  editingValue: string
  setEditingValue: (e: string) => void
  expiration: string
  setExpiration: (e: string) => void
  createNotificationEvent: () => void
  event: EventList
  activeEditorId: number
  setActiveEditorId: (e: number) => void
}
export const CreateEventNotification = (props: Props) => {
  return (
    <>
      <div className="w-full  lg:mt-[-20px] text-right text-cinekoya-sub-a hover:text-cinekoya-sub-d mb-2">
        {props.activeEditorId !== props.event.id && (
          <AddCircleIcon
            fontSize="large"
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              props.setActiveEditorId(props.event.id)
            }}
          />
        )}
        {props.activeEditorId === props.event.id && (
          <>
            表示期限：&nbsp;
            <input
              className="pl-2 py-1 rounded-md cursor-pointer text-stone-600 font-bold"
              value={props.expiration}
              onChange={(e) => props.setExpiration(e.target.value)}
              type="date"
            />
            &emsp;
            <CloseIcon
              fontSize="large"
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                props.setActiveEditorId(0)
                props.setEditingValue('')
                props.setEditorMode('preview')
              }}
            />
          </>
        )}
      </div>

      <div
        className="w-full overflow-hidden height-transition"
        style={{ height: props.activeEditorId === props.event.id ? 350 : 0 }}
      >
        <hr />
        <div className="bg-white mb-3 event_notification_editor">
          <QuillEditor
            allowImageInsertion={false}
            value={props.editingValue}
            onChange={(contetn: string) => {
              props.setEditingValue(contetn)
            }}
            onImageUploadComplete={() => {
              return
            }}
          />
        </div>

        {/* <EditNotification
          editorMode={props.editorMode}
          setEditorMode={props.setEditorMode}
          editingValue={props.editingValue}
          setEditingValue={props.setEditingValue}
        /> */}

        <div className="w-full text-center mt-3">
          <ButtonBorderBlue onClick={props.createNotificationEvent}>
            保存する
          </ButtonBorderBlue>
        </div>
      </div>
    </>
  )
}
