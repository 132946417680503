import React, { useState } from 'react'
import TextField from '@mui/material/TextField'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import dayjs from 'dayjs'

interface Props {
  baseDate: string
  setBaseDate: (e: string) => void
}
export const SelectBaseDate = (props: Props) => {
  const [openDatePicker, setOpenDatePicker] = useState<boolean>(false)

  const openfunc = () => {
    setOpenDatePicker(true)
  }

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      sx={{
        width: 500,
      }}
    >
      <MobileDatePicker
        label="表示の基準日"
        inputFormat="YYYY/MM/DD"
        value={props.baseDate}
        onOpen={openfunc}
        onClose={() => setOpenDatePicker(false)}
        open={openDatePicker}
        onChange={(e) => props.setBaseDate(dayjs(e).format('YYYY-MM-DD'))}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{
              input: {
                width: '100px',
                background: '#282828',
                cursor: 'pointer',
              },
            }}
          />
        )}
      />
    </LocalizationProvider>
  )
}
