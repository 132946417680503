import React, { useState } from 'react'
import { TextField } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import Modal from 'react-modal'
interface Props {
  date: string
  setDate: (e: string) => void
}
export const DatePicker = (props: Props) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [openDatePicker, setOpenDatePicker] = useState<boolean>(false)

  const openfunc = () => {
    setOpenDatePicker(true)
  }

  const handleChange = (e: string | null) => {
    props.setDate(dayjs(e).format('YYYY-MM-DD'))
    const now = dayjs()
    if (now.isSame(dayjs(e), 'day')) {
      return
    }
    setOpenDatePicker(false)
    setIsModalOpen(true)
  }

  const customStyles = {
    content: {
      top: '40%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '100%',
      maxWidth: '500px',
      height: '30vh',
      border: '1px solid #3090A0 ',
      borderRadius: '3%',
      background: 'rgba(28,28,28,0.7)',
      color: 'white',
      fontFamily: 'Roboto, sans-serif',
    },
    overlay: {
      top: 0,
      left: 0,
      backgroundColor: 'rgba(0,0,0,0.85)',
      zIndex: 10,
    },
  }

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MobileDatePicker
          label="予約日"
          inputFormat="MM/DD"
          value={props.date}
          onOpen={openfunc}
          onClose={() => setOpenDatePicker(false)}
          open={openDatePicker}
          onChange={(e) => handleChange(e)}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{
                input: {
                  width: '60px',
                  background: '#282828',
                  fontSize: '23px',
                },
              }}
            />
          )}
        />
      </LocalizationProvider>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <div className="flex flex-col text-center items-center text-xl font-body">
          <div>
            今日
            <span className="font-bold text-cinekoya-yellow-1">ではない</span>
            日付が選択されました。
            <br />
            処理をつづけますか？
          </div>

          <button
            className="block w-1/2 mt-3 py-1 rounded-xl bg-cinekoya"
            onClick={() => setIsModalOpen(false)}
          >
            続ける
          </button>
          <button
            className="block w-1/2 mt-3 py-1 rounded-xl bg-yt-atom"
            onClick={() => {
              props.setDate(dayjs().format('YYYY-MM-DD'))
              setIsModalOpen(false)
            }}
          >
            戻る
          </button>
        </div>
      </Modal>
    </>
  )
}
