import React from 'react'
import rentalService from '../../../../assets/Service.jpg'

export const RentalSpace = () => {
  return (
    <>
      <div className="title-red-2 text-center font-hp">
        <div>
          貸切シアター・
          <br />
          リモートワーク
        </div>
      </div>
      <a href="https://cinekoya.com/service/">
        <div className="cursor-pointer hover:opacity-80">
          <img src={rentalService} />
        </div>
      </a>
    </>
  )
}
