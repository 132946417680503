import { useContext, useEffect } from 'react'
import { ReserveBaseParamsContext } from '../../../hooks/ReserveBaseParamsContext'
import { Reservation } from '../../Templates/Reservation'
import { TicketBreakdownContainer } from '../../Organism/Reservation/Container/TicketBreakdown.container'
import { NonMovieFeeCheckerContainer } from '../../Organism/Reservation/Container/NonMovieFeeChecker.container'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

export const ArrangeTicketPage = () => {
  const { reserveBaseParams } = useContext(ReserveBaseParamsContext)

  const navigate = useNavigate()

  useEffect(() => {
    if (reserveBaseParams.order === -1) {
      navigate('/schedule')
      return
    }
    axios.get('check_tentative_reservation').then((result) => {
      if (!result.data.is_reserved) {
        navigate('/reservation/session_expired')
      }
    })
  }, [])

  return (
    <>
      <Reservation>
        {Boolean(reserveBaseParams.is_cinema) && <TicketBreakdownContainer />}
        {!reserveBaseParams.is_cinema && <NonMovieFeeCheckerContainer />}
      </Reservation>
    </>
  )
}
