import React, { useEffect, useState } from 'react'
import { AddTelReservation } from '../Presentational/AddTelReservation'
import axios from 'axios'

interface MovieInfo {
  movie_id: number
  is_cinema: boolean
  title: string
}

interface Reservations {
  date: string
  order: number
  movie_id: number
  seats: number
  name: string
  phone: string
  memo: string
}

interface Props {
  isAddModalOpen: boolean
  closeAddModal: () => void
  closeModal: () => void
  date: string
  order: number
  movieInfo: MovieInfo
  getSeats: () => void
  getSeatsInfo: () => void
}
export const AddTelReservationContainer = (props: Props) => {
  const defaultReservations = {
    date: props.date,
    order: props.order,
    movie_id: props.movieInfo.movie_id,
    seats: 1,
    name: '',
    phone: '',
    memo: '',
  }
  const [reservations, setReservations] = useState<Reservations>({
    date: props.date,
    order: props.order,
    movie_id: props.movieInfo.movie_id,
    seats: 1,
    name: '',
    phone: '',
    memo: '',
  })

  useEffect(() => {
    setReservations({
      ...reservations,
      date: props.date,
      order: props.order,
      movie_id: props.movieInfo.movie_id,
    })
  }, [props.date, props.order, props.movieInfo.movie_id])

  const [isEmptyName, setIsEmptyName] = useState<boolean>(false)

  const handleSubmit = async () => {
    if (reservations.name === '') {
      setIsEmptyName(true)
      return
    }

    const formData = new FormData()
    formData.append('reservations', JSON.stringify(reservations))
    await axios.post('register_tel_reservation', formData)
    props.getSeats()
    props.getSeatsInfo()
    setReservations(defaultReservations)
    props.closeAddModal()
  }
  return (
    <>
      <AddTelReservation
        isAddModalOpen={props.isAddModalOpen}
        closeAddModal={props.closeAddModal}
        closeModal={props.closeModal}
        date={props.date}
        order={props.order}
        movieInfo={props.movieInfo}
        handleSubmit={handleSubmit}
        reservations={reservations}
        setReservations={setReservations}
        isEmptyName={isEmptyName}
      />
    </>
  )
}
