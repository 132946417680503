import React, { useEffect, useState } from 'react'
import ErrorIcon from '@mui/icons-material/Error'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { ThreeDots } from 'react-loader-spinner'
interface FormData {
  name: string
  email: string
}

interface Errors {
  email: boolean
  empty: boolean
}
interface Props {
  form: FormData
  errors: Errors
  setForm: (e: FormData) => void
  handleSubmit: () => void
  isMailSent: boolean
  isLoading: boolean
}
export const MailMagazine = (props: Props) => {
  const [isAnyError, setIsAnyError] = useState<boolean>(false)
  useEffect(() => {
    setIsAnyError(props.errors.email || props.errors.empty)
  }, [props.errors])

  return (
    <>
      <div className="title-red-2 text-center font-hp">
        <div>
          メールマガジン
          <br />
          シネコヤ通信
        </div>
      </div>
      <div className="text-cinekoya  font-hp pt-1 pb-3 px-1 ">
        毎月1日に、シネコヤの上映情報、映画関連情報などをお届けしております。ご希望の方は、下記にご入力の上、「参加」ボタンを押してください。
      </div>
      <div className="text-cinekoya  font-hp px-2">
        <div>お名前</div>
        <div className="mb-2">
          <input
            type="text"
            className={`w-full border-1 border-cinekoya`}
            maxLength={50}
            value={props.form.name}
            onChange={(e) =>
              props.setForm({ ...props.form, name: e.target.value })
            }
          />
        </div>
        <div>メールアドレス</div>
        <div>
          <input
            type="text"
            className="w-full border-1 border-cinekoya"
            value={props.form.email}
            maxLength={50}
            onChange={(e) =>
              props.setForm({ ...props.form, email: e.target.value })
            }
          />
        </div>
      </div>
      <div className="flex justify-center pt-3 font-body">
        {isAnyError && (
          <div className="w-full bg-cinekoya-yellow-1 text-yt-component  px-2 py-2 rounded-xl shadow-md">
            {props.errors.email === true && (
              <p>
                <ErrorIcon />
                &nbsp; メールアドレスが適切ではありません
              </p>
            )}
            {props.errors.empty === true && (
              <p>
                <ErrorIcon />
                &nbsp; 未入力の項目があります
              </p>
            )}
          </div>
        )}
      </div>
      <div className="flex justify-center mt-3 mb-3">
        {props.isMailSent && (
          <div className="bg-cinekoya-hover rounded-xl py-2 px-2 text-white">
            <CheckCircleOutlineIcon />
            ありがとうございます。ご登録内容は送信されました。
          </div>
        )}
        {!props.isMailSent && (
          <button
            className={`w-1/2 py-2.5 text-center font-hp bg-cinekoya text-white`}
            onClick={props.handleSubmit}
          >
            {!props.isLoading && '  参加'}
            {props.isLoading && (
              <ThreeDots
                height="25"
                width="25"
                radius="9"
                color="#ffffff"
                ariaLabel="three-dots-loading"
                wrapperStyle={{ justifyContent: 'center' }}
                visible={true}
              />
            )}
          </button>
        )}
      </div>
    </>
  )
}
