import { useEffect, useState } from 'react'
import { Post } from '../types/Post.'

interface Props {
  tables: Post[]
  date: string
}
export const useGetDayTable = (props: Props) => {
  const [dayTables, setDayTables] = useState<Post[]>([])

  useEffect(() => {
    const day_tables = props.tables.filter((item) => {
      if (item.start_day <= props.date && item.end_day >= props.date) {
        return item
      }
    })

    setDayTables(day_tables)
  }, [props.tables])

  return dayTables
}
