import React, { useState } from 'react'
import { useGetLimit } from '../../../../hooks/useGetLimit'
import { Modal_SetLimit } from './Modal_SetLimit'

interface ScheduleLimits {
  id: number
  start_day: string
  end_day: string
  order: number
  seat_limits: number
}

interface ParticleLimits {
  id: number
  date: string
  order: number
  limit: number
}

interface Props {
  date: string
  orderNum: number
  particleLimits: ParticleLimits[]
  scheduleLimits: ScheduleLimits[]
  commonLimit: number
  isForcedLimit: boolean
  getReservationLimit: () => void
  setOnmouseDate: (e: string) => void
  baseDate: string
}
export const CellParticleLimit = (props: Props) => {
  let isPastDate: boolean

  if (props.date < props.baseDate) {
    isPastDate = true
  } else {
    isPastDate = false
  }

  const { particleLimit, scheduleLimit } = useGetLimit({
    date: props.date,
    orderNum: props.orderNum,
    particleLimits: props.particleLimits,
    scheduleLimits: props.scheduleLimits,
  })

  const [modalIsOpen, setModalIsOpen] = useState(false)

  const openModalForLimit = () => {
    setModalIsOpen(true)
    props.getReservationLimit()
  }
  const closeModalForLimit = () => {
    setModalIsOpen(false)
  }

  return (
    <>
      <td
        className={` ${
          isPastDate
            ? 'cursor-not-allowed bg-yt-bg'
            : 'cursor-pointer bg-yt-component'
        } text-center justify-center border-b-2 border-b-yt-atom font-bold h-10 pb-1 transition-color  duration-200`}
        onMouseEnter={() => props.setOnmouseDate(props.date)}
        onClick={() => {
          console.log(particleLimit?.limit)
        }}
      >
        {particleLimit?.limit !== null && (
          <div
            className={`
          ${!isPastDate && 'cursor-pointer hover:bg-yt-atom hover:text-white'}
          border-2 border-cinekoya-yellow-2 text-cinekoya-yellow-2  w-16 h-9  rounded-full inline-block`}
            onClick={openModalForLimit}
          >
            {particleLimit?.limit}
          </div>
        )}

        {particleLimit?.limit === null && scheduleLimit !== null && (
          <div
            className={`
          ${!isPastDate && 'cursor-pointer hover:bg-yt-atom  hover:text-white'}
          border-2 border-yt-atom text-yt-atom w-16 h-9 rounded-full inline-block`}
            onClick={openModalForLimit}
          >
            {scheduleLimit}
          </div>
        )}

        {particleLimit?.limit === null && scheduleLimit === null && (
          <div
            className={` w-16 h-9  rounded-full ${
              !isPastDate && 'mt-2 hover:bg-yt-atom inline-block '
            }`}
            onClick={openModalForLimit}
          ></div>
        )}
      </td>

      <Modal_SetLimit
        modalIsOpen={modalIsOpen}
        closeModalForLimit={closeModalForLimit}
        orderNum={props.orderNum}
        date={props.date}
        particleLimit={particleLimit}
        scheduleLimit={scheduleLimit}
        commonLimit={props.commonLimit}
        isForcedLimit={props.isForcedLimit}
        getReservationLimit={props.getReservationLimit}
      />
    </>
  )
}
