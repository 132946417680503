import React from 'react'
import Modal from 'react-modal'
import useWindowSize from '../../../../hooks/useWindowSize'
import CloseIcon from '@mui/icons-material/Close'
import { DiscountInfo } from '../../../../types/Reservation/DiscountInfo'

interface Props {
  modalIsOpen: boolean
  setModalIsOpen: (e: boolean) => void
  setPrevGeneralNumber: (e: number) => void
  setDiscountInfo: (e: DiscountInfo) => void
  setSwitchChecked: (e: boolean) => void
}
export const Modal_ResetRepeatDiscount = (props: Props) => {
  const { width } = useWindowSize()

  const isMobile = width <= 700 // 768pxをスマホとPCの切り替えポイントとする

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '100%',
      maxWidth: '610px',
      height: isMobile ? '40vh' : '30vh',
      borderRadius: '5%',
      background: 'rgba(255,255,255,0.9)',
      padding: '10px',
      border: 'solid 3px #A94337',
      transition: 'height 0.5s ease-out',
    },
    overlay: {
      top: 0,
      left: 0,
      backgroundColor: 'rgba(0,0,0,0.5)',
      zIndex: 10,
    },
  }

  return (
    <Modal
      isOpen={props.modalIsOpen}
      style={customStyles}
      contentLabel="Example Modal"
      ariaHideApp={false}
      onRequestClose={() => props.setModalIsOpen(false)}
    >
      <div className="w-full text-right pr-1 text-cinekoya">
        <CloseIcon
          onClick={() => props.setModalIsOpen(false)}
          sx={{ cursor: 'pointer' }}
        />
      </div>
      <div className="w-full text-yt-atom font-bold opacity-90 mb-2 px-2 py-3 text-center text-lg sm:text-xl">
        一般料金の割引がリセットされます。
        <br />
        よろしいですか？
      </div>

      <div className="w-full text-center  font-body font-bold">
        <button
          className="w-1/2 text-white py-2 bg-yt-atom rounded-2xl hover:opacity-80 coursor-pointer"
          onClick={() => {
            props.setDiscountInfo({
              email: '',
              coupon_code: '',
              repeat_general_number: 0,
            })
            props.setSwitchChecked(false)
            props.setModalIsOpen(false)
            props.setPrevGeneralNumber(-1)
          }}
        >
          リセットする
        </button>
      </div>
      <div className="w-full text-center mt-3 font-body font-bold">
        <button
          className="w-1/2 text-white py-2 bg-cinekoya-sub-b rounded-2xl hover:opacity-80 coursor-pointer"
          onClick={() => props.setModalIsOpen(false)}
        >
          キャンセル
        </button>
      </div>
    </Modal>
  )
}
