import React from 'react'
import { StaticPageCreatorContainer } from '../Organism/StaticPageCreator/Container/StaticPageCreator.container'
import { DashboardTop } from '../Templates/DashboardTop'

export const StaticPageCreatorPage = () => {
  return (
    <DashboardTop>
      <StaticPageCreatorContainer />
    </DashboardTop>
  )
}
