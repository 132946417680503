import React from 'react'
import { CampaignPageCreatorContainer } from '../Organism/CampaignPageCreator/Container/CampaignPageCreator.container'
import { DashboardTop } from '../Templates/DashboardTop'

export const CampaignCreatorPage = () => {
  return (
    <DashboardTop>
      <CampaignPageCreatorContainer />
    </DashboardTop>
  )
}
