import React, { SyntheticEvent, useContext, useState } from 'react'
import axios from 'axios'
import { AuthContext } from '../../hooks/AuthContext'

type Login = {
  auth: boolean
}

const Login = () => {
  const { isAuth, setIsAuth } = useContext(AuthContext)

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const submit = async (e: SyntheticEvent) => {
    e.preventDefault()

    await axios.get('../sanctum/csrf-cookie').then(() => {
      axios
        .post('login', {
          email,
          password,
        })
        .then(() => {
          setIsAuth(true)
        })
        .catch((error) => console.log(error))
    })
  }

  return (
    <>
      <form className="form-signin" onSubmit={submit}>
        <div className="text-center">
          <input
            type="email"
            className="form-control"
            placeholder="Email"
            required
            onChange={(e) => setEmail(e.target.value)}
          />

          <input
            type="password"
            className="form-control"
            placeholder="Password"
            required
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>

        <div className="text-center">
          <button
            className="bg-yt-atom hover:bg-yt-atom-hover text-center rounded-2xl py-1 px-5 text-white"
            type="submit"
          >
            login
          </button>
        </div>
      </form>

      <div></div>
    </>
  )
}

export default Login
