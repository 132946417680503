/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ChangeEvent, useState } from 'react'
import EditIcon from '@mui/icons-material/Edit'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import Modal from 'react-modal'
import axios from 'axios'

type CellParticleLimit = {
  id: number | null
  limit: number | null
}

type Props = {
  newLimit: number
  setNewLimit: (e: number) => void
  particleLimit: CellParticleLimit | undefined
  getReservationLimit: () => void
  closeModalForLimit: () => void
}

export const HasParticleLimit = (props: Props) => {
  const [editMode, setEditMode] = useState<boolean>(false)

  const update = () => {
    const particleId = props.particleLimit?.id
    axios
      .put('limit_particle', {
        id: particleId,
        limit: props.newLimit,
      })
      .then(() => {
        props.getReservationLimit()
        setEditMode(false)
        props.closeModalForLimit()
      })
      .catch((error) => console.log(error))
  }

  const deleteLimit = () => {
    const particleId = props.particleLimit?.id
    axios
      .delete('limit_particle', {
        params: {
          id: particleId,
        },
      })
      .then(() => {
        props.getReservationLimit()
        props.setNewLimit(0)
        setEditMode(false)
        closeModal()
        props.closeModalForLimit()
      })
      .catch((error) => console.log(error))
  }

  const editLimit = () => {
    if (props.particleLimit?.limit) {
      props.setNewLimit(props.particleLimit?.limit)
    }

    setEditMode(true)
  }

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '300px',
      borderRadius: '5%',
    },
    overlay: {
      top: 0,
      left: 0,
      backgroundColor: 'rgba(0,0,0,0.85)',
    },
  }

  const [modalIsOpen, setIsOpen] = useState(false)

  const openModal = () => {
    setIsOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
  }

  return (
    <>
      {!editMode && (
        <>
          <div className="flex flex-row items-center justify-center">
            <div className="basis-4/5 text-xl pl-4">
              現在の上限設定：<b>{props.particleLimit?.limit}席</b>
            </div>
            <div className="basis-1/5 text-right">
              <EditIcon
                sx={{ cursor: 'pointer', marginRight: '5px' }}
                fontSize="medium"
                onClick={editLimit}
              />

              <DeleteRoundedIcon
                sx={{ cursor: 'pointer' }}
                onClick={openModal}
                fontSize="medium"
              />
            </div>
          </div>
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
            ariaHideApp={false}
          >
            <div className="text-center font-body">
              <div className="text-xl font-bold text-stone-600">
                上限設定を解除しますか？
              </div>
              <div className=" mt-4 text-lg">
                <button
                  className="w-36 p-1 text-white bg-cinekoya-sub-c rounded-full drop-shadow-md hover:bg-cinekoya-sub-a"
                  onClick={deleteLimit}
                >
                  解除
                </button>
              </div>
              <div className="mt-3 text-sm text-stone-400">
                <button onClick={closeModal}>キャンセル</button>
              </div>
            </div>
          </Modal>
        </>
      )}

      {editMode && (
        <>
          <div className=" w-100 text-center text-sm text-yt-text-gray">
            現在の上限設定：<b>{props.particleLimit?.limit}席</b>
          </div>
          <div className="flex flex-row mt-2">
            <div className="basis-1/5"></div>
            <div className="basis-3/5 text-center">
              <span className="font-body font-bold text-lg align-bottom">
                上限の設定&nbsp;
              </span>
              <input
                type="number"
                className=" w-32 h-10  ml-1 text-center text-xl font-bold border-solid  border-2 border-stone-400 focus:outline-none focus:ring-2 focus:ring-cinekoya-sub-c focus:border-transparent text-black"
                value={props.newLimit}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  props.setNewLimit(Number(e.target.value))
                }
              />
              &nbsp;
              <span className="font-body font-bol text-lg align-bottom">
                席
              </span>
            </div>
            <div className="basis-1/5 text-right">
              <div
                className="cursor-pointer inline-block align-middle pt-0.5 rounded-full bg-stone-400 text-center"
                style={{ width: '30px', height: '30px' }}
              >
                <KeyboardArrowUpIcon
                  fontSize="medium"
                  onClick={() => setEditMode(false)}
                />
              </div>
            </div>
          </div>
          <div className="w-100 text-center clear-both">
            <button
              className="mt-3 w-40 py-0.5 text-xl text-center text-white inline-block bg-cinekoya-sub-c font-body rounded-full shadow-md cursor-pointer hover:bg-cinekoya-sub-a "
              onClick={() => {
                update()
              }}
            >
              更新
            </button>
          </div>
        </>
      )}
    </>
  )
}
