import React from 'react'

interface Props {
  newSeats: number
  setNewSeats: (e: number) => void
}
export const Counter = (props: Props) => {
  const countDown = () => {
    if (props.newSeats - 1 === 0) {
      props.setNewSeats(props.newSeats - 2)
      return
    }
    props.setNewSeats(props.newSeats - 1)
  }

  const countUp = () => {
    if (props.newSeats + 1 === 0) {
      props.setNewSeats(props.newSeats + 2)
      return
    }
    props.setNewSeats(props.newSeats + 1)
  }

  return (
    <div className="flex flex-row justify-center mt-5">
      <div className="basis-1/3 text-right">
        <button
          className="h-16 w-16 rounded-l-2xl bg-cinekoya hover:bg-cinekoya-hover  text-white font-bold text-3xl"
          onClick={countDown}
        >
          －
        </button>
      </div>
      <div className="basis-1/3 text-center">
        <div className="inline-block h-16 w-36 border-1 border-yt-atom-hover align-bottom rounded-2xl pt-3 text-white bg-yt-component ">
          <span className="align-bottom text-2xl font-bold">
            {props.newSeats}名
          </span>
        </div>
      </div>
      <div className="basis-1/3 text-left">
        <button
          className="h-16 w-16 rounded-r-2xl bg-cinekoya hover:bg-cinekoya-hover text-white font-bold text-3xl"
          onClick={countUp}
        >
          +
        </button>
      </div>
    </div>
  )
}
