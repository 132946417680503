import React, { ChangeEvent, useContext, useState } from 'react'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import SearchIcon from '@mui/icons-material/Search'
import axios from 'axios'
import Modal from 'react-modal'
import CloseIcon from '@mui/icons-material/Close'
import { PostParamsContext } from '../../../../hooks/postParamsContext'
import { ButtonBorderBlue } from '../../../Atoms/ButtonBorderBlue'
import { Post } from '../../../../types/Post.'

interface Props {
  setIsReflectMovieinfo: (e: boolean) => void
  setIsEditableReflected: (e: boolean) => void
  movieList: Post[]
}

export const ReflectMovieinfo = (props: Props) => {
  const { postParams, setPostParams } = useContext(PostParamsContext)

  const defaultMovieList = props.movieList

  const [movieList, setMovieList] = useState<Post[]>(props.movieList)
  const [searchValue, setSearchValue] = useState<string>('')

  const [modalIsOpen, setModalIsOpen] = useState(false)

  const openModal = () => {
    setModalIsOpen(true)
    //スケジュールに登録されていない作品をクリーンアップ
    axios.post('cleanup_unrelated_movies')
  }
  const closeModal = () => {
    console.log(postParams.id)
    setModalIsOpen(false)
  }

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '100%',
      maxWidth: '700px',
      height: '80vh',
      border: '1px solid #3090A0 ',
      borderRadius: '1%',
      background: 'rgba(28,28,28,0.7)',
      color: 'white',
      fontFamily: 'Roboto, sans-serif',
      zIndex: 20,
    },
    overlay: {
      top: 0,
      left: 0,
      backgroundColor: 'rgba(0,0,0,0.85)',
      zIndex: 20,
    },
  }

  const reflect = (movie: Post, isUsedAsOrigin: boolean) => {
    setPostParams({
      ...postParams,
      cinema_id: isUsedAsOrigin ? movie.id : 0,
      is_cinema: Boolean(movie.is_cinema),
      non_cinema_reservable: Boolean(movie.non_cinema_reservable),
      title: movie.title,
      detail: movie.detail,
      event_price_1: movie.event_price_1,
      event_price_2: movie.event_price_2,
      event_price_3: movie.event_price_3,
      event_price_4: movie.event_price_4,
      ep_1_label: movie.ep_1_label,
      ep_2_label: movie.ep_2_label,
      ep_3_label: movie.ep_3_label,
      ep_4_label: movie.ep_4_label,
      image: movie.image,
      trailer_url: movie.trailer_url,
    })
    props.setIsReflectMovieinfo(true)
    closeModal()
    props.setIsEditableReflected(false)
  }

  const searchHandler = (value: string) => {
    setSearchValue(value)

    const searchResult = defaultMovieList.filter((item) => {
      if (item.title.includes(value)) {
        return item
      }
    })

    setMovieList(searchResult)
  }

  return (
    <>
      <div className="w-full text-right pr-2">
        <ButtonBorderBlue
          onClick={() => {
            openModal()
          }}
        >
          <ArrowDownwardIcon />
          登録済の作品情報を利用する
        </ButtonBorderBlue>
      </div>

      <Modal
        isOpen={modalIsOpen}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
        onRequestClose={closeModal}
      >
        <div className="text-right pr-2 mb-3 text-stone-300">
          <CloseIcon
            fontSize="medium"
            sx={{ cursor: 'pointer' }}
            onClick={closeModal}
          />
        </div>
        <div className="flex flex-row mb-3 pl-1 pr-2">
          <div className=" basis-1/5 bg-cinekoya-sub-c h-10  text-center pt-1 rounded-l-xl">
            <SearchIcon fontSize="large" />
          </div>
          <div className="basis-4/5 h-10">
            <input
              className="text-stone-600 px-2 py-1 rounded-r-xl h-full w-full"
              type="text"
              value={searchValue}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                searchHandler(e.target.value)
              }
            />
          </div>
        </div>
        <div className="h-3/5 overflow-y-scroll scrollbar border-1 border-yt-atom">
          {movieList.map((movie, index) => (
            <React.Fragment key={index}>
              <div className="flex flex-row py-2 px-2">
                <div className="basis-1/2">{movie.title}</div>
                <div className="basis-1/2 text-right">
                  <button
                    className="w-[130px] inline-block px-2 py-1 bg-cinekoya hover:bg-cinekoya-hover rounded-xl text-sm"
                    onClick={() => reflect(movie, true)}
                  >
                    利用
                  </button>
                  &nbsp;
                  <button
                    className="w-[130px] inline-block px-2 py-1 bg-cinekoya-sub-c hover:bg-cinekoya-sub-d rounded-xl text-sm"
                    onClick={() => reflect(movie, false)}
                  >
                    素材のみコピー
                  </button>
                </div>
              </div>
              <hr />
            </React.Fragment>
          ))}
        </div>
        <div className=" text-sm  text-yt-text-gray px-4">
          <br />
          <span className="text-white font-bold">利用する:</span>
          「１つの作品を複数のスケジュールで使い回す」形です。作品情報を編集すると、この作品を利用しているすべてのスケジュールに反映されます。
          <br />
          <span className="text-white font-bold">素材のみコピー:</span>
          「別の作品としてコピーする」形です。テンプレとして利用したい場合などに使って下さい。
          <br />
          <br />
          ※どこにもスケジュール化されていない作品データは自動的に削除しており、上記リストにも表示されません（テストや誤作成など）。一度でもどこかにスケジュール登録されている作品は、上記リストに表示されます。
        </div>
      </Modal>
    </>
  )
}
