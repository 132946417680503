import React, { useContext, useEffect, useState } from 'react'
import './App.css'
import { Navigate, Route, Routes } from 'react-router-dom'
import Login from './components/pages/Login'
import axios from 'axios'
import { AuthContext } from './hooks/AuthContext'
import { Schedule } from './components/pages/schedule'
import PhotosUpload from './components/pages/phptosUpload'
import ScrollTest from './components/pages/scrolltest'
import { CreatePage } from './components/pages/CreatePage'
import { CommonSettingPage } from './components/pages/CommonSettingPage'
import { NotificationEventPage } from './components/pages/NotificationEventPage'
import { TopPage } from './components/pages/TopPage'
import { CinemaDetailPage } from './components/pages/CinemaDetailPage'
import { ArrangeTicketPage } from './components/pages/Reservation/ArrangeTicketPage'
import { EventDisplayOptionPage } from './components/pages/EventDisplayOptionPage'
import { VisitorManagementPage } from './components/pages/VisitorManagementPage'
import { AggregateSalesPage } from './components/pages/AggregateSalesPage'
import { SelectSeatPage } from './components/pages/Reservation/SelectSeatPage'
import { PersonalInfoPage } from './components/pages/Reservation/PersonalInfoPage'
import { ConfirmPaymentPage } from './components/pages/Reservation/ConfirmPaymentPage'
import { PaymentSuccessPage } from './components/pages/Reservation/PaymentSuccessPage'
import { SessionExpiredPage } from './components/pages/Reservation/SessionExpiredPage'
import { NotificationTopPage } from './components/pages/NotificationTopPage'
import { CampaignCreatorPage } from './components/pages/CampaignCreatorPage'
import { CampaignPage } from './components/pages/CampaignPage'
import { PreviewCampaignPage } from './components/pages/PreviewCampaignPage'
import { StaticPageCreatorPage } from './components/pages/StaticPageCreatorPage'
import { PreviewStaticPage } from './components/pages/PreviewStaticPage'
import { StaticPage } from './components/pages/StaticPage'
import { PreviewCinemaDetailPage } from './components/pages/PreviewCinemaDetailPage'
import { NonCinemaDetailPage } from './components/pages/NonCinemaDetailPage'
import { PastEventsPage } from './components/pages/PastEventsPage'

const AppRouter = () => {
  // const [login,setLogin] = useState(false);

  const { isAuth, setIsAuth } = useContext(AuthContext)
  const [photos, setPhotos] = useState<File[]>([])

  useEffect(() => {
    axios
      .get('user')
      .then(() => {
        setIsAuth(true)
      })
      .catch(() => {
        setIsAuth(false)
      })
  }, [isAuth])

  return (
    <Routes>
      <Route path="/login" element={isAuth ? <Navigate to="/" /> : <Login />} />

      {/*client*/}
      <Route path="/" element={isAuth ? <TopPage /> : <Login />} />

      <Route path="/schedule" element={<Schedule />} />

      <Route
        path="/cinema/:cinema_id"
        element={isAuth ? <CinemaDetailPage /> : <Login />}
      />

      <Route
        path="/non_cinema/:event_id"
        element={isAuth ? <NonCinemaDetailPage /> : <Login />}
      />

      <Route
        path="/campaign/:campaign_id"
        element={isAuth ? <CampaignPage /> : <Login />}
      />

      <Route
        path="/past_events"
        element={isAuth ? <PastEventsPage /> : <Login />}
      />
      <Route
        path="/static/:path_name"
        element={isAuth ? <StaticPage /> : <Login />}
      />

      {/* reservation */}
      <Route
        path="/reservation/select_seat"
        element={isAuth ? <SelectSeatPage /> : <Login />}
      />
      <Route
        path="/reservation/arrange_ticket"
        element={isAuth ? <ArrangeTicketPage /> : <Login />}
      />
      <Route
        path="/reservation/personal_info"
        element={isAuth ? <PersonalInfoPage /> : <Login />}
      />
      <Route
        path="/reservation/confirm_payment"
        element={isAuth ? <ConfirmPaymentPage /> : <Login />}
      />

      <Route
        path="/reservation/payment_success/:hasGeneralTicket"
        element={isAuth ? <PaymentSuccessPage /> : <Login />}
      />

      <Route
        path="reservation/session_expired"
        element={isAuth ? <SessionExpiredPage /> : <Login />}
      />

      {/* dashboard */}

      <Route path="/dashboard" element={isAuth ? <CreatePage /> : <Login />} />

      <Route
        path="/dashboard/visitor_management"
        element={isAuth ? <VisitorManagementPage /> : <Login />}
      />

      <Route
        path="/dashboard/aggregate_sales"
        element={isAuth ? <AggregateSalesPage /> : <Login />}
      />

      <Route
        path="/dashboard/notification_event"
        element={isAuth ? <NotificationEventPage /> : <Login />}
      />

      <Route
        path="/dashboard/notification_top"
        element={isAuth ? <NotificationTopPage /> : <Login />}
      />

      <Route
        path="/dashboard/campaign_creator"
        element={isAuth ? <CampaignCreatorPage /> : <Login />}
      />

      <Route
        path="/dashboard/staticpage_creator"
        element={isAuth ? <StaticPageCreatorPage /> : <Login />}
      />

      <Route
        path="/dashboard/preview_campaign"
        element={isAuth ? <PreviewCampaignPage /> : <Login />}
      />

      <Route
        path="/dashboard/preview_staticpage"
        element={isAuth ? <PreviewStaticPage /> : <Login />}
      />

      <Route
        path="/dashboard/preview_cinema_detail"
        element={isAuth ? <PreviewCinemaDetailPage /> : <Login />}
      />

      <Route
        path="/dashboard/event_display_option"
        element={isAuth ? <EventDisplayOptionPage /> : <Login />}
      />

      <Route
        path="/dashboard/common_setting"
        element={isAuth ? <CommonSettingPage /> : <Login />}
      />

      <Route path="/scrolltest" element={<ScrollTest />} />
      <Route
        path="/phptoupload"
        element={
          <PhotosUpload name="photos" photos={photos} setPhotos={setPhotos} />
        }
      />
    </Routes>
  )
}

export default AppRouter
