import { useContext, useEffect } from 'react'
import { ReserveBaseParamsContext } from '../../../hooks/ReserveBaseParamsContext'
import { ReservationHeader } from '../../Molecules/ReservationHeader'
import { PaymentSuccess } from '../../Organism/Reservation/Presentational/PaymentSuccess'
import { useParams } from 'react-router-dom'
import { DefaultReserveBaseParams } from '../../../const/DefaultReserveBaseParams'

export const PaymentSuccessPage = () => {
  const { setReserveBaseParams } = useContext(ReserveBaseParamsContext)

  const { hasGeneralTicket } = useParams<{ hasGeneralTicket: string }>()

  useEffect(() => {
    setReserveBaseParams(DefaultReserveBaseParams)
    console.log(hasGeneralTicket)
  }, [])
  return (
    <>
      <div className="flex justify-center">
        <div className="w-full md:w-5/6">
          <ReservationHeader />
        </div>
      </div>
      <PaymentSuccess
        hasGeneralTicket={hasGeneralTicket === 'has_general' ? true : false}
      />
    </>
  )
}
