import React, { useState } from 'react'
import { HasParticleLimit } from './HasParticleLimit'
import { NoParticleLimit } from './NoParticleLimit'
import CloseIcon from '@mui/icons-material/Close'
import Modal from 'react-modal'
import dayjs from 'dayjs'

interface CellParticleLimit {
  id: number | null
  limit: number | null
}

interface Props {
  modalIsOpen: boolean
  closeModalForLimit: () => void
  orderNum: number
  date: string
  particleLimit: CellParticleLimit
  scheduleLimit: number | null
  commonLimit: number
  isForcedLimit: boolean
  getReservationLimit: () => void
}

export const Modal_SetLimit = (props: Props) => {
  const [newLimit, setNewLimit] = useState<number>(0)

  const customStyles = {
    content: {
      top: '40%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      paddingBottom: '5%',
      transform: 'translate(-50%, -50%)',
      width: '100%',
      maxWidth: '400px',
      height: 'auto',
      border: '1px solid #3090A0 ',
      borderRadius: '1%',
      background: 'rgba(28,28,28,0.7)',
      color: 'white',
      fontFamily: 'Roboto, sans-serif',
    },
    overlay: {
      top: 0,
      left: 0,
      backgroundColor: 'rgba(0,0,0,0.85)',
    },
  }

  return (
    <Modal
      isOpen={props.modalIsOpen}
      style={customStyles}
      contentLabel="Example Modal"
      ariaHideApp={false}
      onRequestClose={props.closeModalForLimit}
    >
      <div className="flex flex-row justify-center text-base md:text-lg font-bold mb-3">
        <div className="basis-1/5" />
        <div className="basis-3/5 text-center">
          {' '}
          {dayjs(props.date).format('YYYY年M月D日')}
          <br />
          {props.orderNum}コマ目
        </div>
        <div className="basis-1/5 text-right pr-2 ">
          <span className="text-yt-atom hover:text-white">
            <CloseIcon
              sx={{ cursor: 'pointer' }}
              onClick={props.closeModalForLimit}
            />
          </span>
        </div>
      </div>

      <div className="flex justify-center mb-5">
        <div className="text-center text-white text-lg md:text-xl">
          {props.isForcedLimit && (
            <>
              <span className="text-cinekoya-yellow-2  font-bold">
                共通設定
              </span>
              の上限を
              <span className="text-cinekoya-yellow-2">強制適用</span>中&nbsp;
              {'('}
              <b>{props.commonLimit}</b>
              {'席)'}
            </>
          )}

          {props.particleLimit?.limit !== null && !props.isForcedLimit && (
            <>
              <span className="text-cinekoya-yellow-2 font-bold">コマ単位</span>
              の上限を適用中&nbsp;{'('}
              <b>{props.particleLimit?.limit}</b>
              {'席)'}
            </>
          )}

          {props.particleLimit?.limit === null &&
            props.scheduleLimit !== null &&
            !props.isForcedLimit && (
              <>
                <span className="text-cinekoya-yellow-2  font-bold">
                  作品単位
                </span>
                の上限を適用中&nbsp;{'('}
                <b>{props.scheduleLimit}</b>
                {'席)'}
              </>
            )}

          {props.particleLimit?.limit === null &&
            props.scheduleLimit === null &&
            !props.isForcedLimit && (
              <>
                <span className="text-cinekoya-yellow-2  font-bold">
                  共通設定
                </span>
                の上限を適用中&nbsp;{'('}
                <b>{props.commonLimit}</b>
                {'席)'}
              </>
            )}
        </div>
      </div>
      {!props.isForcedLimit && (
        <>
          {' '}
          <hr className="mt-2 mb-3" />
          <div className=" text-white">
            {props.particleLimit?.limit !== null ? (
              <HasParticleLimit
                newLimit={newLimit}
                setNewLimit={setNewLimit}
                particleLimit={props.particleLimit}
                getReservationLimit={props.getReservationLimit}
                closeModalForLimit={props.closeModalForLimit}
              />
            ) : (
              <NoParticleLimit
                order={props.orderNum}
                targetDay={props.date}
                newLimit={newLimit}
                setNewLimit={setNewLimit}
                getReservationLimit={props.getReservationLimit}
                closeModalForLimit={props.closeModalForLimit}
              />
            )}
          </div>
        </>
      )}
    </Modal>
  )
}
