import axios from 'axios'
import { useEffect, useState } from 'react'
import EditIcon from '@mui/icons-material/Edit'
import CloseIcon from '@mui/icons-material/Close'
import dayjs from 'dayjs'

export const WeekSetting = () => {
  const today = dayjs()

  const days = ['mon', 'tue', 'wed', 'thr', 'fri', 'sat', 'sun']
  const [currentWeek, setCurrentWeek] = useState<Record<string, boolean>>({})
  const [newWeek, setNewWeek] = useState<Record<string, boolean>>({})

  const dayLabels: Record<string, string> = {
    mon: '月',
    tue: '火',
    wed: '水',
    thr: '木',
    fri: '金',
    sat: '土',
    sun: '日',
  }

  useEffect(() => {
    axios
      .get('common_setting', { params: { today: today.format('YYYY-MM-DD') } })
      .then((response) => setCurrentWeek(response.data.weeks))
      .catch((error) => console.log(error))
  }, [])

  const [editWeek, setEditWeek] = useState(false)

  const classNames = {
    defaultSetWeek:
      'px-1.5 lg:px-2.5 cursor-pointer bg-cinekoya-sub-c border-1',
    defaultUnsetWeek: 'px-1.5 lg:px-2.5 cursor-pointer bg-yt-bg  border-1',
    selectedWeek:
      'px-1.5 lg:px-2.5 cursor-pointer bg-white bg-opacity-80  text-black border-1',
    unselectedWeek:
      'px-1.5 lg:px-2.5 cursor-pointer bg-yt-bg text-white  border-1',
  }

  const saveWeek = () => {
    axios
      .put('common_setting_week', newWeek)
      .then((response) => {
        setCurrentWeek(response.data)
        setEditWeek(false)
      })
      .catch((error) => console.log(error))
  }

  const toggleEditWeek = () => {
    setEditWeek(!editWeek)
    setNewWeek(currentWeek)
  }

  return (
    <>
      <div className="flex flex-row pt-2 text-xl">
        <div className="basis-3/4 text-center flex justify-center">
          <div className="inline-block text-center">曜日</div>
          <table
            className={`inline-block ml-3 ${
              editWeek
                ? 'border-1 border-yt-atom text-black'
                : 'bg-yt-atom text-white border-1 border-yt-atom'
            }`}
          >
            <thead>
              <tr>
                {days.map((day) => (
                  <th
                    key={day}
                    className={
                      editWeek
                        ? newWeek[day]
                          ? classNames.selectedWeek
                          : classNames.unselectedWeek
                        : currentWeek[day]
                        ? classNames.defaultSetWeek
                        : classNames.defaultUnsetWeek
                    }
                    onClick={() =>
                      editWeek
                        ? setNewWeek({ ...newWeek, [day]: !newWeek[day] })
                        : null
                    }
                  >
                    {dayLabels[day]}
                  </th>
                ))}
              </tr>
            </thead>
          </table>
          {editWeek && (
            <button
              className="text-center inline-block  rounded-2xl ml-3 px-2 py-1 text-sm bg-yt-atom hover:bg-yt-atom-hover cursor-pointer"
              onClick={saveWeek}
            >
              保存
            </button>
          )}
        </div>{' '}
        <div className="basis-1/4 text-right pr-3 cursor-pointer">
          {editWeek ? (
            <CloseIcon fontSize="large" onClick={toggleEditWeek} />
          ) : (
            <EditIcon fontSize="large" onClick={toggleEditWeek} />
          )}
        </div>
      </div>
    </>
  )
}
