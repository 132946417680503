import React from 'react'
import { Reservation } from '../../../../types/Reservation/Reservation'

interface Props {
  reservation: Reservation
}
export const CustomerDetailWeb = (props: Props) => {
  const index = [
    ['フリガナ', props.reservation.kana],
    ['予約番号', props.reservation.reservation_number],
    ['ファンクラブ番号', props.reservation.fanclub_code.replace(/\//g, ', ')],
    ['メンバーズ番号', props.reservation.members_code.replace(/\//g, ', ')],
    ['メールアドレス', props.reservation.email],
    ['電話番号', props.reservation.phone],
    ['STRIPE ID', props.reservation.stripe_id],
    ['備考', props.reservation.memo],
  ]
  return (
    <div className="grid grid-cols-4 grid-rows-2 px-3">
      {index.map(([title, content]) => (
        <>
          <div
            key={title}
            className="col-span-1 row-span-1 border-1 border-yt-atom pl-2 py-1"
          >
            {title}
          </div>

          <div className="col-span-3 row-span-1  border-1 border-yt-atom px-1 py-1">
            {content}
          </div>
        </>
      ))}
    </div>
  )
}
