import React from 'react'
import { TextField } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import dayjs from 'dayjs'
import { ChangeEvent } from 'react'
import { CinemaOnly } from '../../../../types/CInemaOnly'
import { TicketSales } from '../../../../types/Reservation/TicketSales'
import { Bars } from 'react-loader-spinner'

type PaidType = 'prepaid' | 'non_prepaid' | 'all'

interface Props {
  startDay: string
  setStartDay: (e: string) => void
  endDay: string
  setEndDay: (e: string) => void
  cinemaList: CinemaOnly[]
  setMovieId: (e: number) => void
  isMovieUnit: boolean
  setIsMovieUnit: (e: boolean) => void
  displayDailySales: TicketSales[]
  displayTicketSales: TicketSales | undefined
  arrayDate: string[]
  paidType: PaidType
  setPaidType: (e: PaidType) => void
  isLoading: boolean
}
export const AggregateSales = (props: Props) => {
  const arrayTicketType = [
    ['一般', 'general'],
    ['ファンクラブ', 'fanclub'],
    ['ファンクラブ同伴', 'fanclub_companion'],
    ['メンバーズ', 'members'],
    ['メンバーズ同伴', 'members_companion'],
    ['ユース', 'youth'],
    ['小中高生', 'under_youth'],
    ['幼児', 'infant'],
    ['２本目割引', 'second'],
  ]

  return (
    <>
      <div className="flex flex-row justify-center text-white mt-2">
        <div className="basis-1/6 pl-2  text-2xl font-body font-bold">
          売上表
        </div>
        <div className="basis-4/6 text-center text-lg ">
          <button
            className={`w-1/4 rounded-l-3xl px-3 py-1 hover:bg-opacity-75  ${
              props.isMovieUnit ? 'bg-cinekoya-sub-c' : 'bg-yt-atom'
            }`}
            onClick={() => props.setIsMovieUnit(true)}
          >
            作品単位
          </button>
          <button
            className={`w-1/4 rounded-r-3xl px-3 py-1 hover:bg-opacity-75 ml-2 ${
              !props.isMovieUnit ? 'bg-cinekoya-sub-c' : 'bg-yt-atom'
            }`}
            onClick={() => {
              props.setIsMovieUnit(false)
              props.setMovieId(-1)
            }}
          >
            日にち単位
          </button>
        </div>
        <div className="basis-1/6" />
      </div>
      <div className="w-full px-2 py-3">
        <hr className="bg-yt-atom opacity-100" style={{ height: '2px' }} />
      </div>
      <div className="flex flex-row justify-center text-white mb-5 mt-3">
        <div className="basis-2/6 pl-5">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileDatePicker
              label="開始日"
              inputFormat="MM/DD"
              value={props.startDay}
              onChange={(e) => props.setStartDay(dayjs(e).format('YYYY-MM-DD'))}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{
                    input: {
                      width: '70px',
                      height: '20px',
                      background: '#282828',
                      fontSize: '20px',
                    },
                  }}
                />
              )}
            />
          </LocalizationProvider>
          <div className="inline-block px-3 py-2">～</div>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileDatePicker
              label="終了日"
              inputFormat="MM/DD"
              value={props.endDay}
              onChange={(e) => props.setEndDay(dayjs(e).format('YYYY-MM-DD'))}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{
                    input: {
                      width: '70px',
                      height: '20px',
                      background: '#282828',
                      fontSize: '20px',
                    },
                  }}
                />
              )}
            />
          </LocalizationProvider>
        </div>
        <div className="basis-4/6 pr-3">
          {props.isMovieUnit && (
            <select
              className="w-full px-2 py-2 rounded-lg text-lg text-white font-title cursor-pointer bg-yt-component border-4 border-cinekoya-sub-c focus:ring-cinekoya-sub-c"
              onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                props.setMovieId(Number(e.target.value))
              }
            >
              {props.cinemaList.map((cinema) => (
                <option key={cinema.id} value={cinema.id}>
                  {cinema.title}
                </option>
              ))}
            </select>
          )}
        </div>
      </div>
      <div className="flex flex-row text-white">
        <div className="basis-1/6 text-right pl-3">
          <table className="w-full ">
            <thead className=" border-b-4 border-transparent">
              <tr className="h-9">
                <th></th>
              </tr>
            </thead>
            <tbody className="text-white border-r-2 border-yt-atom">
              {arrayTicketType.map(([title, _]) => (
                <tr key={title} className="pr-1">
                  <th className="pr-2">{title}</th>
                </tr>
              ))}
              <tr className="border-t-2 border-transparent text-cinekoya-yellow-2">
                <th className="pr-1">売上枚数</th>
              </tr>
              <tr className="text-cinekoya-yellow-2">
                <th className="pr-1">売上金額</th>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="w-4/6 overflow-x-scroll">
          {!props.isLoading && (
            <table className="text-white text-center">
              <thead className=" border-b-4 border-cinekoya-sub-a">
                <tr className="h-9">
                  {props.displayDailySales.map((sale) => (
                    <th key={sale.date} className="px-2 text-center">
                      {dayjs(sale.date).format('M/D')}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="text-white">
                {arrayTicketType.map(([title, key]) => (
                  <tr key={title}>
                    {props.displayDailySales.map((sale) => (
                      <td key={sale.date}>{sale[key as keyof TicketSales]}</td>
                    ))}
                  </tr>
                ))}
                <tr className="border-t-2 border-yt-atom-hover text-cinekoya-yellow-2">
                  {props.displayDailySales.map((sale) => (
                    <td key={sale.date}>{sale.total_quantity}</td>
                  ))}
                </tr>
                <tr className="text-cinekoya-yellow-2">
                  {props.displayDailySales.map((sale) => (
                    <td key={sale.date}>{sale.total_amount}</td>
                  ))}
                </tr>
              </tbody>
            </table>
          )}
          {props.isLoading && (
            <Bars
              height="60"
              width="60"
              color="#00B8CC"
              ariaLabel="bars-loading"
              wrapperStyle={{ justifyContent: 'center', paddingTop: '120px' }}
              wrapperClass=""
              visible={true}
            />
          )}
        </div>
        <div className="basis-1/6 ">
          <table className="w-1/2 text-center">
            <thead className=" border-b-4 border-transparent">
              <tr className="h-9">
                <th>合計</th>
              </tr>
            </thead>
            <tbody className="text-white border-l-2 border-yt-atom">
              {arrayTicketType.map(([_, type]) => (
                <tr key={type}>
                  <td>
                    {props.displayTicketSales?.[type as keyof TicketSales]}
                  </td>
                </tr>
              ))}
              <tr className="border-t-2 border-yt-atom-hover text-cinekoya-yellow-2">
                <td>{props.displayTicketSales?.total_quantity}</td>
              </tr>
              <tr className="text-cinekoya-yellow-2">
                <td>{props.displayTicketSales?.total_amount}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="flex flex-row justify-center py-4 font-body">
        <div className="basis-3/5 rounded-full bg-yt-atom py-0.5 px-3 text-white shadow-md">
          <div className="flex flex-row gap-2">
            <button
              className={` basis-1/3 rounded-full py-1 ${
                props.paidType === 'all'
                  ? 'bg-cinekoya-sub-c hover:opacity-80 shadow-md'
                  : 'bg-yt-component hover:opacity-75'
              }`}
              onClick={() => props.setPaidType('all')}
            >
              全売上
            </button>
            <button
              className={`basis-1/3 rounded-full  py-1  ${
                props.paidType === 'non_prepaid'
                  ? 'bg-cinekoya-sub-c hover:opacity-80 shadow-md'
                  : 'bg-yt-component hover:opacity-75'
              }`}
              onClick={() => props.setPaidType('non_prepaid')}
            >
              当日決済のみ
            </button>
            <button
              className={` basis-1/3 rounded-full  py-1  ${
                props.paidType === 'prepaid'
                  ? 'bg-cinekoya-sub-c hover:opacity-80 shadow-md'
                  : 'bg-yt-component hover:opacity-75'
              }`}
              onClick={() => props.setPaidType('prepaid')}
            >
              WEB予約のみ
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
