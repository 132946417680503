import dayjs from 'dayjs'
import React, { useContext } from 'react'
import { ReserveBaseParamsContext } from '../../../../hooks/ReserveBaseParamsContext'
import { UrlContext } from '../../../../hooks/UrlContext'
import { useLocation } from 'react-router-dom'
import { RowBreakdown } from './RowBreakdown'

export const ReservationSummary = () => {
  const { imageURL } = useContext(UrlContext)
  const { reserveBaseParams } = useContext(ReserveBaseParamsContext)
  const location = useLocation()
  const path = location.pathname

  return (
    <>
      <div className="flex justify-center font-body mt-4">
        <div className="w-full md:w-[700px] rounded-xl grid grid-cols-7 grid-rows-2 py-3 px-2 md:px-4   bg-stone-100 text-cinekoya shadow-md">
          <div className="col-span-2 sm:col-span-1 row-span-2">
            <img
              src={imageURL + reserveBaseParams.movie?.image}
              className="m-auto block cursor-pointer max-w-[90px] md:max-w-[80px]"
              style={{ border: 'solid 1px #8F8F8F' }}
            />
          </div>
          <div className="col-span-4 sm:col-span-5 row-span-1 text-lg  md:text-xl sm:text-2xl pl-2  font-title text-left">
            {reserveBaseParams.movie?.alternative_name === null
              ? reserveBaseParams.movie?.title
              : reserveBaseParams.movie?.alternative_name}
          </div>
          <div className="col-span-4 sm:col-span-5 row-span-1 pl-1 md:pl-5 pt-3 text-center">
            <span className="text-2xl  sm:text-3xl font-bold">
              {dayjs(reserveBaseParams.date).format('MM/DD(dd)')}&nbsp;
            </span>

            <span className="text-xl sm:text-3xl  font-bold">
              {reserveBaseParams.movie?.start_hour}:
              {reserveBaseParams.movie?.start_minutes}
            </span>
            <span className="text-base sm:text-xl">
              ～{reserveBaseParams.movie?.end_hour}:
              {reserveBaseParams.movie?.end_minutes}
            </span>
          </div>
          <div className="col-span-1   row-span-2 "></div>
        </div>
      </div>

      {(path === '/reservation/personal_info' ||
        path === '/reservation/confirm_payment') && (
        <>
          <div className="block w-full  md:w-[700px] h-auto border-x-2 border-t-2 border-stone-200 border-b-0 rounded-t-lg  py-2 m-auto  font-body mt-3 text-zinc-600">
            {path === '/reservation/confirm_payment' && (
              <>
                <div className="pl-4 text-lg font-bold ">
                  {reserveBaseParams.name}様
                </div>
                <div className="pl-4 text-sm">
                  電話番号：{reserveBaseParams.phone}
                </div>
                <div className="pl-4 mb-2 text-sm">
                  Eメール：{reserveBaseParams.email}
                </div>
                <hr className="mb-2" />
              </>
            )}
            {Boolean(reserveBaseParams.is_cinema) && (
              <>
                {' '}
                {reserveBaseParams.sold_ticket_types.map((ticket_type) => (
                  <RowBreakdown
                    key={ticket_type.id}
                    label={ticket_type.name}
                    unitPrice={ticket_type.unitPrice}
                    num={ticket_type.ticketNum}
                    totalPrice={ticket_type.totalPrice}
                  />
                ))}
              </>
            )}
            {!reserveBaseParams.is_cinema && (
              <div className="w-full items-center md:text-lg py-1">
                {reserveBaseParams.breakdown.split('/').map((item, index) => (
                  <div key={index} className="flex flex-row text-center">
                    {!item.includes('0枚') && (
                      <>
                        <div className="basis-3/4">
                          {item.split(':')[0]}&nbsp;¥&nbsp;
                          {item.split(':')[1].toLocaleString()}&nbsp;×
                          <span className="  text-cinekoya ">
                            {item.split(':')[2]}
                          </span>
                        </div>
                        <div className="basis-1/4 text-cinekoya text-center font-bold">
                          ¥
                          {(
                            Number(item.split(':')[1]) *
                            Number(item.split(':')[2].replace('枚', ''))
                          ).toLocaleString()}
                        </div>
                      </>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="flex w-full  md:w-[700px] border-4 border-stone-200 rounded-b-lg py-2 px-3 m-auto bg-stone-100 font-body  ">
            <div className="  w-1/2 text-center text-sm md:text-xl font-bold">
              チケット合計&emsp;
              <span className="text-cinekoya">{reserveBaseParams.seat}枚</span>
            </div>
            <div className="  w-1/2 text-right text-3xl text-cinekoya font-bold">
              ¥&nbsp;{reserveBaseParams.price.toLocaleString()}
            </div>
          </div>
        </>
      )}
    </>
  )
}
