import axios from 'axios'
import React, { useState } from 'react'
import Modal from 'react-modal'
import { Post } from '../../../../types/Post.'

import { RotatingLines } from 'react-loader-spinner'
import { DeleteScheduleButton } from '../Presentational/DeleteScheduleButton'

interface Props {
  saveDisabled: boolean
  closeModal: () => void
  getLatest: () => void
  cntReservation: number
  selectedCinemaSchedule: Post | null
}

export const DeleteSchedule = (props: Props) => {
  const customStyles = {
    content: {
      top: '40%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '100%',
      maxWidth: '400px',
      height: '20vh',
      border: '1px solid #3090A0 ',
      borderRadius: '1%',
      background: 'rgba(28,28,28,0.7)',
      color: 'white',
      fontFamily: 'Roboto, sans-serif',
    },
    overlay: {
      top: 0,
      left: 0,
      backgroundColor: 'rgba(0,0,0,0.85)',
    },
  }

  const [modalIsOpen, setModalIsOpen] = useState(false)

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const openModal = () => {
    setModalIsOpen(true)
  }
  const closeModal = () => {
    setModalIsOpen(false)
  }

  const beforeDeleteSchedule = async () => {
    await axios
      .post('count_reservation', {
        schedule: JSON.stringify(props.selectedCinemaSchedule),
      })
      .then((response) => {
        const cntReservations = response.data.cnt_reservation
        cntReservations > 0 && openModal()
        cntReservations === 0 && deleteSchedule()
      })
  }

  const deleteSchedule = () => {
    setIsLoading(true)
    new Promise((resolve) => {
      const formData = new FormData()
      const paramsJson = JSON.stringify(props.selectedCinemaSchedule)
      formData.append('params', paramsJson)

      resolve(formData)
    })
      .then((formData) => {
        const config = { headers: { 'content-type': 'multipart/form-data' } }
        axios
          .post('delete_schedule', formData, config)
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          .then((response) => {
            setIsLoading(false)
            props.closeModal()
          })
      })
      .then(() => props.getLatest())
  }

  return (
    <>
      <DeleteScheduleButton
        onClick={() => beforeDeleteSchedule()}
        isPreDelete={true}
      >
        {isLoading && (
          <div className="flex justify-center">
            <RotatingLines
              strokeColor="#00B8CC"
              strokeWidth="5"
              animationDuration="0.75"
              width="30"
              visible={true}
            />
          </div>
        )}

        {!isLoading && 'スケジュールを削除'}
      </DeleteScheduleButton>

      <Modal
        isOpen={modalIsOpen}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
        onRequestClose={closeModal}
      >
        <div className="text-lg  mb-4">
          この作品には本日以降すでに予約が入っています。本当に削除しますか？
        </div>
        <div>
          <DeleteScheduleButton
            onClick={() => deleteSchedule()}
            isPreDelete={false}
          >
            削除する
          </DeleteScheduleButton>
        </div>
      </Modal>
    </>
  )
}
