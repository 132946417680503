interface Props {
  value: number
  countDown: () => void
  countUp: () => void
  unit: string
  height: number
  widthInput: number
  paddingTop: number
}
export const CountNonCinemaTicket = (props: Props) => {
  return (
    <div className="flex flex-row h-10 justify-center ">
      <div className="basis-full sm:basis-96   text-center">
        <button
          className={`h-${
            props.height
          } w-${16} rounded-l-2xl bg-cinekoya hover:bg-cinekoya-hover  text-white font-bold text-3xl`}
          onClick={props.countDown}
        >
          －
        </button>
        &emsp;
        <div
          className={`inline-block h-${props.height} w-${props.widthInput} border-1 border-yt-atom-hover align-bottom rounded-2xl pt-${props.paddingTop}`}
        >
          <span className="align-bottom text-xl font-bold">
            {props.value}
            {props.unit}
          </span>
        </div>
        &emsp;
        <button
          className={`h-${
            props.height
          } w-${16} rounded-r-2xl bg-cinekoya hover:bg-cinekoya-hover text-white font-bold text-3xl`}
          onClick={props.countUp}
        >
          +
        </button>
      </div>
    </div>
  )
}
