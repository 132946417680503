import axios from 'axios'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { Post } from '../../types/Post.'
import { CreateTable } from '../Organism/CreateTable/Presentational/CreateTable'
import { DashboardTop } from '../Templates/DashboardTop'

interface ParticleLimits {
  id: number
  date: string
  order: number
  limit: number
}

interface ScheduleLimits {
  id: number
  start_day: string
  end_day: string
  order: number
  seat_limits: number
}

interface ArrayDate {
  date: string
  isClose: boolean
}

export const CreatePage = () => {
  //予約数上限に係わる変数群
  const [particleLimits, setParticleLimits] = useState<ParticleLimits[]>([])
  const [scheduleLimits, setScheduleLimits] = useState<ScheduleLimits[]>([])
  const [commonLimit, setCommonLimit] = useState<number>(0)
  const [isForcedLimit, setIsForcedLimit] = useState<boolean>(false)

  const [displaySetLimit, setDisplaySetLimit] = useState<boolean>(false)
  const [movieList, setMovieList] = useState<Post[]>([])
  //日付配列
  const [arrayDate, setArrayDate] = useState<string[]>([])
  const [arrayDateAttribute, setArrayDateAttribute] = useState<ArrayDate[]>([])

  const [schedules, setSchedules] = useState<Post[]>([])

  const [baseDate, setBaseDate] = useState<string>(dayjs().format('YYYY-MM-DD'))

  const getLatest = () => {
    axios
      .get('get_schedule', {
        params: {
          today: baseDate,
        },
      })
      .then((response) => {
        const diff = response.data.difference

        const array = []
        for (let i = -diff; i < 50; i++) {
          array.push(dayjs(baseDate).add(i, 'd').format('YYYY-MM-DD'))
        }

        const formData = new FormData()
        const paramsJson = JSON.stringify(array)
        formData.append('array_date', paramsJson)
        axios.post('get_array_date_attribute', formData).then((response) => {
          setArrayDateAttribute(response.data)
        })

        setArrayDate(array)
        setSchedules(response.data.schedules)
      })
    axios.get('get_movielist').then((response) => {
      setMovieList(response.data)
    })
    getReservationLimit()
  }

  const getReservationLimit = () => {
    axios
      .get('get_limit', {
        params: {
          today: baseDate,
        },
      })
      .then((response) => {
        setParticleLimits(response.data.particle_limits)
        setScheduleLimits(response.data.schedule_limits)
        setCommonLimit(response.data.common_limit)
        setIsForcedLimit(Boolean(response.data.forced_limit))
      })
  }

  useEffect(() => {
    //初期値の取得
    getLatest()
    getReservationLimit()
  }, [baseDate])

  return (
    <DashboardTop>
      <CreateTable
        commonLimit={commonLimit}
        setCommonLimit={setCommonLimit}
        particleLimits={particleLimits}
        scheduleLimits={scheduleLimits}
        displaySetLimit={displaySetLimit}
        setDisplaySetLimit={setDisplaySetLimit}
        isForcedLimit={isForcedLimit}
        setIsForcedLimit={setIsForcedLimit}
        arrayDateAttribute={arrayDateAttribute}
        arrayDate={arrayDate}
        schedules={schedules}
        getLatest={getLatest}
        getReservationLimit={getReservationLimit}
        baseDate={baseDate}
        setBaseDate={setBaseDate}
        movieList={movieList}
      />
    </DashboardTop>
  )
}
