import axios from 'axios'
import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ReserveBaseParamsContext } from '../../../../hooks/ReserveBaseParamsContext'
import { ConfirmSession } from '../Presentational/ConfirmSession'

interface Props {
  reservableNum: number
  setReservableNum: (e: number) => void
  getReservableNumber: () => void
}
export const ConfirmSessionContainer = (props: Props) => {
  const navigate = useNavigate()
  const { reserveBaseParams, setReserveBaseParams } = useContext(
    ReserveBaseParamsContext
  )
  const [confirmModalIsOpen, setConfirmModalIsOpen] = useState<boolean>(false)
  const [soldoutModalIsOpen, setSoldoutModalIsOpen] = useState<boolean>(false)
  const [isPastTime, setIsPastTime] = useState<boolean>(false)
  const [limitTime, setLimitTime] = useState<number>(15)
  const [previousSeet, setPreviousSeat] = useState(0)

  const createTentativeReservation = async () => {
    await axios.get('../sanctum/csrf-cookie').then(() => {
      axios
        .post('create_tentative_reservation', {
          seat: reserveBaseParams.seat,
          date: reserveBaseParams.date,
          order: reserveBaseParams.order,
        })
        .then((response) => {
          if (response.data.has_tentative_reservation === true) {
            setConfirmModalIsOpen(true)
            setPreviousSeat(response.data.previous_seat)
            return
          }

          //再度予約可能残数を確かめる。
          axios
            .get('get_reservable_number', {
              params: {
                date: reserveBaseParams.date,
                order: reserveBaseParams.order,
              },
            })
            .then((response) => {
              if (reserveBaseParams.seat > response.data.reservable_num) {
                props.setReservableNum(response.data.reservable_num)
                setSoldoutModalIsOpen(true)
                setConfirmModalIsOpen(false)
                setLimitTime(response.data.limit_time)
                if (response.data.is_past_time === true) {
                  setIsPastTime(true)
                }
                return
              }

              navigate('../reservation/arrange_ticket')
            })
        })
        .catch((error) => console.log(error))
    })
  }

  const updateTentativeReservation = async () => {
    await axios
      .post('update_tentative_reservation', {
        seat: reserveBaseParams.seat,
        date: reserveBaseParams.date,
        order: reserveBaseParams.order,
      })
      .then(() => {
        //再度予約可能残数を確かめる。
        axios
          .get('get_reservable_number', {
            params: {
              date: reserveBaseParams.date,
              order: reserveBaseParams.order,
            },
          })
          .then((response) => {
            if (reserveBaseParams.seat > response.data.reservable_num) {
              props.setReservableNum(response.data.reservable_num)
              setSoldoutModalIsOpen(true)
              setConfirmModalIsOpen(false)
              return
            }

            navigate('../reservation/arrange_ticket')
          })
      })
      .catch((error) => console.log(error))
  }

  const returnPreviousProcess = () => {
    setReserveBaseParams({ ...reserveBaseParams, seat: previousSeet })
    navigate('../reservation/arrange_ticket')
  }

  return (
    <ConfirmSession
      reservableNum={props.reservableNum}
      confirmModalIsOpen={confirmModalIsOpen}
      setConfirmModalIsOpen={setConfirmModalIsOpen}
      soldoutModalIsOpen={soldoutModalIsOpen}
      setSoldoutModalIsOpen={setSoldoutModalIsOpen}
      isPastTime={isPastTime}
      limitTime={limitTime}
      createTentativeReservation={createTentativeReservation}
      updateTentativeReservation={updateTentativeReservation}
      returnPreviousProcess={returnPreviousProcess}
      previousSeat={previousSeet}
    />
  )
}
