import dayjs from 'dayjs'
import { Post } from '../../../types/Post.'
import { CinemaCard } from './CinemaCard'
import MyLoader from './MyLoader'
import MovieIcon from '@mui/icons-material/Movie'
import { useGetDayTable } from '../../../hooks/useGetDayTable'

interface ReservableProperty {
  date: string
  order: number
  isReservable: boolean
  ReservableNumber: number
}

interface ArrayDateAttribute {
  date: string
  isClose: boolean
}

interface Props {
  dateAttribute: ArrayDateAttribute
  tables: Post[]
  reservableProperty: ReservableProperty[]
  isLoading: boolean
}
export const DateCard = (props: Props) => {
  const dayTables = useGetDayTable({
    tables: props.tables,
    date: props.dateAttribute.date,
  })

  return (
    <div id={props.dateAttribute.date} className="pt-3">
      <div className="text-center  mt-3 py-2">
        <div className="inline-block px-5 pb-1  text-zinc-200 font-bold shadow-md sm:shadow-md border-4 border-zinc-50 bg-zinc-700 rounded-full">
          <span className="text-lg md:text-2xl align-middle font-number  ">
            {dayjs(props.dateAttribute.date).format('M')}
          </span>
          <span className="text-xl md:text-2xl align-middle font-number">
            {'/' + dayjs(props.dateAttribute.date).format('D')}
          </span>
          <span className="text-lg md:text-xl align-bottom font-body">
            {'(' + dayjs(props.dateAttribute.date).format('dd') + ')'}
          </span>
        </div>
      </div>
      {dayTables &&
        !props.dateAttribute.isClose &&
        dayTables.map((table, index) => (
          <CinemaCard
            key={index}
            date={props.dateAttribute.date}
            table={table}
            reservableProperty={props.reservableProperty}
          />
        ))}
      {dayTables && props.dateAttribute.isClose && (
        <div className="flex justify-center">
          <div className="w-3/4 py-1  text-center bg-cinekoya-hover text-stone-200 font-bold rounded-xl text-md md:text-xl font-body border-1 shadow-md">
            休館日
          </div>
        </div>
      )}
      {props.isLoading && (
        <div className="flex text-center justify-center">
          <MyLoader />
        </div>
      )}
      {!props.isLoading &&
        dayTables.length === 0 &&
        !props.dateAttribute.isClose && (
          <div className="flex justify-center px-2 md:px-0 mt-0">
            <div className="w-full md:w-3/4 py-20 rounded-xl bg-cinekoya-bg shadow-md">
              <div className="text-center text-xl md:text-3xl font-title py-2 text-white">
                <MovieIcon />
                &nbsp;
                <span className="align-middle">作品準備中</span>
              </div>
              <div className="text-center text-base font-body text-stone-600">
                Comming soon...
              </div>
            </div>
          </div>
        )}
    </div>
  )
}
