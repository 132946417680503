import { useContext, useEffect } from 'react'
import { ReserveBaseParamsContext } from '../../../hooks/ReserveBaseParamsContext'
import { Reservation } from '../../Templates/Reservation'
import { PersonalInfoContainer } from '../../Organism/Reservation/Container/PersonalInfo.container'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

export const PersonalInfoPage = () => {
  const { reserveBaseParams } = useContext(ReserveBaseParamsContext)

  const navigate = useNavigate()
  useEffect(() => {
    if (reserveBaseParams.order === -1) {
      navigate('/schedule')
      return
    }
    axios.get('check_tentative_reservation').then((result) => {
      if (!result.data.is_reserved) {
        navigate('/reservation/session_expired')
      }
    })
  }, [])

  return (
    <>
      <Reservation>
        <PersonalInfoContainer />
      </Reservation>
    </>
  )
}
