import React, { useEffect } from 'react'
import { useContext, useState } from 'react'
import { ReserveBaseParamsContext } from '../../../../hooks/ReserveBaseParamsContext'
import { useNavigate } from 'react-router-dom'
import { PersonalInfo } from '../Presentational/PersonalInfo'

interface MembersCode {
  index: number
  code: string
}

interface ErrorPersonalInfo {
  emailConfirmation: boolean
  empty: boolean
  emailCharacter: boolean
}

export const PersonalInfoContainer = () => {
  const navigate = useNavigate()

  const { reserveBaseParams, setReserveBaseParams } = useContext(
    ReserveBaseParamsContext
  )

  const defaultErrorPersonalInfo = {
    emailConfirmation: false,
    empty: false,
    emailCharacter: false,
  }
  const [errorPersonalInfo, setErrorPersonalInfo] = useState<ErrorPersonalInfo>(
    defaultErrorPersonalInfo
  )

  const [errorFanclubCode, setErrorFanclubCode] = useState<boolean>(false)
  const [errorMembersCode, setErrorMembersCode] = useState<boolean>(false)
  const [errorDigit, setErrorDigit] = useState<boolean>(false)

  const [arrayFanclubCode, setArrayFanclubCode] = useState<MembersCode[]>([])
  const [arrayMembersCode, setArrayMembersCode] = useState<MembersCode[]>([])

  const [emailConfirmation, setEmailConfirmation] = useState<string>('')

  useEffect(() => {
    setReserveBaseParams({
      ...reserveBaseParams,
      name: '',
      kana: '',
      phone: '',
      email: '',
    })
    setEmailConfirmation('')
  }, [])

  const judgeDigit = () => {
    const judgeDigitArray = [...arrayFanclubCode, ...arrayMembersCode].map(
      (item) => {
        return !(
          item.code.length === 4 &&
          /^[a-zA-Z]/.test(item.code) && //先頭にアルファベットを含む
          !/[a-zA-Z]{2,}/.test(item.code) && //連続したアルファベットを含まない
          !/[a-zA-Z][^a-zA-Z]*[a-zA-Z]/.test(item.code) && //アルファベットによる挟み込みを含まない
          item.code.match(/[1-9]/)
        )
      }
    )
    const judgeDigit = judgeDigitArray.some((item) => item)
    if (judgeDigit) {
      setErrorDigit(judgeDigit)
      return true
    }
  }

  const escape = (e: string) => {
    const escapedValue = String(e)
      .replace(/&/g, '&amp;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#39;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')

    return escapedValue
  }

  const validatePersonalInfo = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    // ^: 文字列の先頭
    // [^\s@]+: 空白文字と@以外の1文字以上の連続した文字列
    // @: @文字
    // [^\s@]+: 空白文字と@以外の1文字以上の連続した文字列
    // .: ピリオド文字（.）（エスケープ文字）
    // [^\s@]+: 空白文字と@以外の1文字以上の連続した文字列
    // $: 文字列の末尾
    //文字列が、空白文字で区切られた2つ以上の文字列（ユーザー名とドメイン名）が、@で結ばれていること、そして、最後にピリオドと2つ以上の文字列（トップレベルドメイン）が続くことを示す

    if (
      reserveBaseParams.name === '' ||
      reserveBaseParams.kana === '' ||
      reserveBaseParams.phone === '' ||
      reserveBaseParams.email === '' ||
      emailConfirmation === ''
    ) {
      setErrorPersonalInfo({ ...errorPersonalInfo, empty: true })
      return false
    }

    if (!emailRegex.test(reserveBaseParams.email)) {
      setErrorPersonalInfo({ ...errorPersonalInfo, emailCharacter: true })
      return false
    }

    if (reserveBaseParams.email !== emailConfirmation) {
      setErrorPersonalInfo({ ...errorPersonalInfo, emailConfirmation: true })
      return false
    }
    return true
  }

  const validateMembersInfo = () => {
    if (judgeDigit()) return false

    const fanclubCodes = arrayFanclubCode.map((fc) => fc.code).join('/')
    const membersCodes = arrayMembersCode.map((mc) => mc.code).join('/')

    if (fanclubCodes.includes('c')) {
      setErrorFanclubCode(true)
      return false
    }

    if (membersCodes.includes('a')) {
      setErrorMembersCode(true)
      return false
    }
    setReserveBaseParams({
      ...reserveBaseParams,
      fanclub_code: fanclubCodes,
      members_code: membersCodes,
    })
    return true
  }

  const handleSubmit = () => {
    setErrorDigit(false)
    setErrorFanclubCode(false)
    setErrorMembersCode(false)
    setErrorPersonalInfo(defaultErrorPersonalInfo)

    if (validatePersonalInfo() && validateMembersInfo()) {
      setReserveBaseParams({
        ...reserveBaseParams,
        name: escape(reserveBaseParams.name),
        kana: escape(reserveBaseParams.kana),
        phone: escape(reserveBaseParams.phone),
        email: escape(reserveBaseParams.email),
      })

      navigate('../reservation/confirm_payment')
    }
  }

  return (
    <PersonalInfo
      arrayFanclubCode={arrayFanclubCode}
      setArrayFanclubCode={setArrayFanclubCode}
      arrayMembersCode={arrayMembersCode}
      setArrayMembersCode={setArrayMembersCode}
      errorFanclubCode={errorFanclubCode}
      errorMembersCode={errorMembersCode}
      errorDigit={errorDigit}
      errorPersonalInfo={errorPersonalInfo}
      emailConfirmation={emailConfirmation}
      setEmailConfirmation={setEmailConfirmation}
      handleSubmit={handleSubmit}
    />
  )
}
