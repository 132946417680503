import React, { useState } from 'react'
import { SideButton } from '../../../Atoms/SideButton'
import Modal from 'react-modal'
import { modalStyle_logout } from '../../../../style/modalStyle_logout'
import LogoutIcon from '@mui/icons-material/Logout'
interface Props {
  logout: () => void
  isMenuShrinked: boolean
}
export const Logout = (props: Props) => {
  const [modalIsOpen, setIsOpen] = useState(false)

  const openModal = () => {
    setIsOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
  }
  return (
    <>
      <button
        className={`w-full font-hiragino rounded-full text-base py-2 px-3  hover:bg-yt-atom-hover  
          ${
            props.isMenuShrinked
              ? 'text-center bg-black text-yt-atom '
              : 'text-left bg-yt-component text-white'
          }
        `}
        onClick={openModal}
      >
        <LogoutIcon />
        {!props.isMenuShrinked && <>&nbsp;ログアウト</>}
      </button>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={modalStyle_logout}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <div className="text-center font-body">
          <div className="text-lg  text-stone-600">ログアウトしますか？</div>
          <div className=" mt-4 text-lg">
            <button
              className="w-36 p-1 text-white bg-cinekoya-sub-c rounded-full drop-shadow-md hover:bg-cinekoya-sub-a"
              onClick={props.logout}
            >
              ログアウト
            </button>
          </div>
          <div className="mt-3 text-sm text-stone-400">
            <button onClick={closeModal}>キャンセル</button>
          </div>
        </div>
      </Modal>
    </>
  )
}
