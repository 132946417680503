import React, { useEffect, useState } from 'react'
import { TopNotification } from '../Presentational/TopNotification'
import axios from 'axios'

export const TopNotificationContainer = () => {
  const [editingValue, setEditingValue] = useState<string>('')
  const [editorMode, setEditorMode] = useState<'live' | 'edit' | 'preview'>(
    'edit'
  )

  const [isEdit, setIsEdit] = useState<boolean>(false)

  const [notification, setNotification] = useState<string | null>(null)

  useEffect(() => {
    axios.get('get_notification_top').then((res) => {
      setNotification(res.data.content)
      setEditingValue(res.data.content !== null ? res.data.content : '')
    })
  }, [])

  const updateNotificationTop = async () => {
    if (!editingValue) {
      return
    }

    await axios
      .put('update_notification_top', {
        content: editingValue,
      })
      .then((res) => {
        setNotification(res.data.content)
        setEditingValue(res.data.content !== null ? res.data.content : '')
        setEditorMode('edit')
        setIsEdit(false)
      })
  }

  const deleteNotificationTop = async () => {
    await axios.put('clear_notification_top').then((res) => {
      setNotification(res.data.content)
      setEditingValue(res.data.content !== null ? res.data.content : '')
      setEditorMode('edit')
      setIsEdit(false)
    })
  }

  return (
    <TopNotification
      isEdit={isEdit}
      setIsEdit={setIsEdit}
      editorMode={editorMode}
      setEditorMode={setEditorMode}
      updateNotificationTop={updateNotificationTop}
      deleteNotificationTop={deleteNotificationTop}
      editingValue={editingValue}
      setEditingValue={setEditingValue}
      notification={notification}
    />
  )
}
