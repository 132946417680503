import React from 'react'
import Modal from 'react-modal'
import { ButtonGray } from '../../../Atoms/ButtonGray'

interface Props {
  modalIsOpen: boolean
  closeModal: () => void
  targetText: string
}
export const Modal_AlertDuplicated = (props: Props) => {
  const customStyles = {
    content: {
      top: '40%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '100%',
      maxWidth: '300px',
      height: '17vh',
      border: '1px solid #3090A0 ',
      borderRadius: '3%',
      background: 'rgba(28,28,28,0.7)',
      color: 'white',
      fontFamily: 'Roboto, sans-serif',
    },
    overlay: {
      top: 0,
      left: 0,
      backgroundColor: 'rgba(0,0,0,0.85)',
      zIndex: 15,
    },
  }

  return (
    <Modal
      isOpen={props.modalIsOpen}
      style={customStyles}
      contentLabel="Example Modal"
      ariaHideApp={false}
      onRequestClose={props.closeModal}
    >
      <div className="text-center">
        <div className="text-lg  mb-4 text-left">
          選択した{props.targetText}は、他の作品と期間が重複しています。
        </div>
        <ButtonGray
          onClick={props.closeModal}
          label={props.targetText + '選択に戻る'}
        />
      </div>
    </Modal>
  )
}
