import React, { ChangeEvent, useContext } from 'react'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import FormControl from '@mui/material/FormControl'
import { PostParamsContext } from '../../../../hooks/postParamsContext'

export const SetLimit = () => {
  const { postParams, setPostParams } = useContext(PostParamsContext)

  const handleLimit = (e: ChangeEvent<HTMLInputElement>) => {
    const selectLimit = e.target.value
    if (selectLimit === 'set' && postParams.seat_limits === null) {
      setPostParams({ ...postParams, seat_limits: 0 })
    }
    if (selectLimit === 'default') {
      setPostParams({ ...postParams, seat_limits: null })
    }
  }
  return (
    <>
      <div className="font-body text-lg text-cinekoya-yellow-2">
        予約上限設定
      </div>
      <FormControl>
        <RadioGroup
          row
          aria-labelledby="demo-radio-buttons-group-label"
          value={postParams.seat_limits !== null ? 'set' : 'default'}
          name="radio-buttons-group"
          onChange={handleLimit}
        >
          <FormControlLabel
            value="default"
            control={<Radio />}
            label="共通設定を適用"
          />
          <FormControlLabel
            value="set"
            control={<Radio />}
            label="作品単位で上限設定"
          />
        </RadioGroup>
      </FormControl>
    </>
  )
}
