import { ReactNode, useState } from 'react'

import { ThemeProvider, createTheme } from '@mui/material'
import { MenuShrinked } from '../Molecules/MenuShrinked'

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
})

interface Props {
  children: ReactNode
}
export const DashboardTop = (props: Props) => {
  const [isMenuShrinked, setIsMenuShrinked] = useState<boolean>(true)

  return (
    <>
      <ThemeProvider theme={darkTheme}>
        <div
          className={`
          ${'flex flex-row bg-yt-bg min-h-screen'}

        `}
        >
          <div className=" basis-1/12 bg-black ">
            <MenuShrinked
              isMenuShrinked={isMenuShrinked}
              setIsMenuShrinked={setIsMenuShrinked}
            />
          </div>

          <div className="basis-11/12 mt-3 md:mt-10 pl-3 overflow-hidden pr-2 md:pr-5">
            {props.children}
          </div>
        </div>
      </ThemeProvider>
    </>
  )
}
