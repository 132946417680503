import React, { ChangeEvent, useContext } from 'react'
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import Modal from 'react-modal'
import { modalStyle_create } from '../../../../style/modalStyle_create'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import EditIcon from '@mui/icons-material/Edit'
import { SaveButton } from '../../../Atoms/SaveButton'
import QuillEditor from '../../../Molecules/QuillEditor'
import { CampaignParams } from '../../../../types/CampaignPrams'
import { MainImageCandidates } from '../../../../types/MainImageCandidates'
import { UrlContext } from '../../../../hooks/UrlContext'

interface Props {
  baseDate: string
  setBaseDate: React.Dispatch<React.SetStateAction<string>>
  createCampaign: (e: boolean) => void
  updateCampaign: (e: boolean) => void
  deleteCampaign: () => void
  isModalOpen: boolean
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  postParams: CampaignParams
  setPostParams: React.Dispatch<React.SetStateAction<CampaignParams>>
  handleEditorChange: (content: string) => void
  getMainImageCandidates: () => void
  saveDisabled: boolean
  campaigns: CampaignParams[]
  isEditmode: boolean
  setIsEditmode: React.Dispatch<React.SetStateAction<boolean>>
  mainImageCandidates: MainImageCandidates[]
  setMainImageCandidates: React.Dispatch<
    React.SetStateAction<MainImageCandidates[]>
  >
  closeModal: () => void
}
export const CampaignPageCreator = (props: Props) => {
  const { imageURL } = useContext(UrlContext)

  const openPreview = (campaign: CampaignParams) => {
    localStorage.setItem('preview_campaign_params', JSON.stringify(campaign))
    window.open('/dashboard/preview_campaign', '_blank')
    //ストレージの削除はcloseModal上に記述
  }

  return (
    <>
      <div className="flex flex-row justify-center text-white mt-2">
        <div className="basis-2/6 pl-2 text-2xl font-body font-bold pb-2">
          キャンペーンページ
        </div>
        <div className="basis-4/6 text-right pr-2">
          <div className="inline-block  bg-cinekoya-sub-c rounded-xl py-1 px-4 text-left shadow-lg">
            <TipsAndUpdatesIcon />
            &nbsp;
            基準日以降に表示するキャンペーンページの作成・編集ができます。キャンペーンページはHPのトップの「上映中」または「イベント」に表示されます。スケジュールには表示されません。
          </div>
        </div>
      </div>
      <div className="flex flex-row text-white mt-5">
        <div className="basis-1/3" />
        <div className="basis-1/3 text-left">
          基準日：
          <input
            type="date"
            className="w-1/2 h-10 rounded-xl border-2 border-yt-atom text-center text-white bg-yt-component cursor-pointer hover:bg-yt-atom hover:text-yt-component"
            value={props.baseDate}
            onChange={(e) => props.setBaseDate(e.target.value)}
          />
        </div>
        <div className="basis-1/3 text-cinekoya-sub-a text-center hover:text-cinekoya-sub-d">
          <AddCircleIcon
            fontSize="large"
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              props.setIsModalOpen(true)
            }}
          />
        </div>
      </div>

      {props.campaigns.map((campaign, index) => (
        <div key={index} className="flex justify-center font-body">
          <div className="flex flex-row text-white mt-3 w-5/6 border-1 border-cinekoya-sub-c mb-2 py-2 rounded-xl bg-yt-component">
            <div className="basis-1/6 flex items-center px-2">
              <div className="w-2/3 bg-yt-atom rounded-md py-2 text-center text-sm">
                @&nbsp;
                {Boolean(campaign.display_event) && 'イベント'}
                {Boolean(campaign.display_movie) && '上映中'}
              </div>
            </div>
            <div className="basis-4/6 text-left ">
              <div className="text-2xl font-body font-bold pb-2">
                <span className="font-title">{campaign.title}</span>
                {!campaign.display_public && (
                  <span className="text-cinekoya-yellow-2">（非公開）</span>
                )}
              </div>
              <div className="text-lg font-body font-bold pb-2">
                <span className="text-yt-atom-hover">表示期間：</span>
                {campaign.start_date} 〜 {campaign.end_date}
              </div>
            </div>
            <div className="basis-1/6 ">
              <div className="text-center">
                <button
                  className="w-2/3 bg-cinekoya-sub-c text-white py-0.5 rounded-xl text-sm hover:opacity-100 cursor-pointer bg-opacity-80"
                  onClick={() => openPreview(campaign)}
                >
                  プレビュー
                </button>
              </div>
              <div className="text-center pt-3 ">
                <span className="text-cinekoya-yellow-2 hover:text-cinekoya-sub-d">
                  <EditIcon
                    fontSize="large"
                    sx={{ cursor: 'pointer' }}
                    onClick={() => {
                      props.setIsEditmode(true)
                      props.setIsModalOpen(true)
                      props.setPostParams(campaign)
                    }}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      ))}

      <Modal
        isOpen={props.isModalOpen}
        style={modalStyle_create}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <div className="flex flex-row">
          <div className="basis-1/2  text-xl text-cinekoya-yellow-2 bg-yt-atom bg-opacity-70 rounded-xl pl-3 py-1 mb-3  shadow-md">
            ページの新規作成
          </div>

          <div className="basis-1/2 text-right">
            <button
              className="w-1/3 bg-cinekoya-sub-a text-white py-0.5 rounded-xl text-base hover:opacity-100 cursor-pointer bg-opacity-80"
              onClick={() => openPreview(props.postParams)}
            >
              プレビュー
            </button>
            &emsp;
            <HighlightOffIcon
              fontSize="large"
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                props.closeModal()
              }}
            />
          </div>
        </div>
        <div className=" font-body text-lg text-cinekoya-yellow-2">
          タイトル
        </div>

        <div className="mb-3 ">
          <input
            className="w-full text-black border-solid  border-1 border-yt-atom focus:outline-none focus:ring-2 focus:ring-yt-atom-hover focus:border-transparent"
            value={props.postParams.title}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              props.setPostParams({
                ...props.postParams,
                title: e.target.value,
              })
            }}
          />
        </div>
        <div className=" font-body text-lg text-cinekoya-yellow-2">本文</div>

        <div className="w-full bg-white text-black mt-1 overflow-hidden">
          <QuillEditor
            allowImageInsertion={true}
            value={props.postParams.content}
            onChange={props.handleEditorChange}
            onImageUploadComplete={props.getMainImageCandidates}
          />
        </div>

        <div className=" font-body text-lg text-cinekoya-yellow-2 mt-3 mb-1">
          メイン画像
        </div>
        <div className="text-sm text-white pl-2">
          ※エディタ内で画像を挿入すると下に表示されます。
          <br />
          ※複数ある場合は選択した画像がトップページに表示されます。
        </div>

        <div className="grid grid-cols-5 gap-2">
          {props.mainImageCandidates.map((image, index) => (
            <div key={index} className="col-span-1">
              <div>
                <img
                  src={imageURL + image.url}
                  className={`w-32 h-32 object-cover rounded-lg cursor-pointer
                    ${
                      props.postParams.main_image === image.url
                        ? 'ring-4 ring-cinekoya-sub-a'
                        : 'opacity-40'
                    }
                  
                  `}
                  onClick={() => {
                    props.setPostParams({
                      ...props.postParams,
                      main_image: image.url,
                    })
                  }}
                />
              </div>
            </div>
          ))}
        </div>

        <div className=" font-body text-lg text-cinekoya-yellow-2 mt-5">
          公開期間
        </div>
        <div className="flex flex-row mt-2 pl-3">
          開始日&nbsp;
          <input
            type="date"
            className=" text-black border-solid  border-1 border-yt-atom focus:outline-none focus:ring-2 focus:ring-yt-atom-hover focus:border-transparent cursor-pointer"
            value={props.postParams.start_date}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              props.setPostParams({
                ...props.postParams,
                start_date: e.target.value,
              })
            }}
          />
          ～ 終了日&nbsp;
          <input
            type="date"
            className=" text-black border-solid  border-1 border-yt-atom focus:outline-none focus:ring-2 focus:ring-yt-atom-hover focus:border-transparent cursor-pointer"
            value={props.postParams.end_date}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              props.setPostParams({
                ...props.postParams,
                end_date: e.target.value,
              })
            }}
          />
        </div>

        <div className=" font-body text-lg text-cinekoya-yellow-2 mt-3">
          表示場所
        </div>
        <div className="flex flex-row mt-2 pl-3">
          <div className="flex flex-row items-center">
            <input
              type="radio"
              id="event"
              name="display"
              value="event"
              checked={props.postParams.display_event}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                props.setPostParams({
                  ...props.postParams,
                  display_event: e.target.checked,
                  display_movie: !e.target.checked,
                })
              }}
              className="form-radio h-5 w-5 cursor-pointer"
            />
            <label htmlFor="event" className="ml-2 mt-1 cursor-pointer">
              イベント
            </label>
          </div>
          <div className="flex flex-row  ml-5 items-center">
            <input
              type="radio"
              id="movie"
              name="display"
              value="movie"
              className="form-radio h-5 w-5 cursor-pointer"
              checked={props.postParams.display_movie}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                props.setPostParams({
                  ...props.postParams,
                  display_movie: e.target.checked,
                  display_event: !e.target.checked,
                })
              }}
            />
            <label htmlFor="movie" className="ml-2 mt-1 cursor-pointer">
              上映中
            </label>
          </div>
        </div>

        {!props.isEditmode && (
          <>
            <SaveButton
              saveDisabled={props.saveDisabled}
              onClick={() => props.createCampaign(true)}
              isPublic={true}
            >
              登録
            </SaveButton>

            <SaveButton
              saveDisabled={props.saveDisabled}
              onClick={() => props.createCampaign(false)}
              isPublic={false}
            >
              下書き保存
            </SaveButton>
          </>
        )}
        {props.isEditmode && (
          <>
            <SaveButton
              saveDisabled={props.saveDisabled}
              onClick={() => props.updateCampaign(true)}
              isPublic={true}
            >
              更新
            </SaveButton>

            <SaveButton
              saveDisabled={props.saveDisabled}
              onClick={() => props.updateCampaign(false)}
              isPublic={false}
            >
              下書きとして更新
            </SaveButton>

            <div className="text-center mt-4 mb-10 ">
              <button
                className={`
            
                text-stone-300 hover:text-white
               
             bg-yt-component  hover:bg-yt-atom-hover  w-64 rounded-full py-1 shadow-md`}
                onClick={() => props.deleteCampaign()}
              >
                削除
              </button>
            </div>
          </>
        )}
      </Modal>
    </>
  )
}
