import React, { ChangeEvent, useContext } from 'react'
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import Modal from 'react-modal'
import { modalStyle_create } from '../../../../style/modalStyle_create'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import EditIcon from '@mui/icons-material/Edit'
import { SaveButton } from '../../../Atoms/SaveButton'
import QuillEditor from '../../../Molecules/QuillEditor'
import { StaticPageParams } from '../../../../types/StaticPageParams'
import { MainImageCandidates } from '../../../../types/MainImageCandidates'
import { UrlContext } from '../../../../hooks/UrlContext'
import LinkIcon from '@mui/icons-material/Link'

interface Props {
  createStaticPage: (e: boolean) => void
  updateStaticPage: (e: boolean) => void
  deleteStaticPage: () => void
  isModalOpen: boolean
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  defaultObject: StaticPageParams
  postParams: StaticPageParams
  setPostParams: React.Dispatch<React.SetStateAction<StaticPageParams>>
  handleEditorChange: (content: string) => void
  getMainImageCandidates: () => void
  saveDisabled: boolean
  staticPages: StaticPageParams[]
  isEditmode: boolean
  setIsEditmode: React.Dispatch<React.SetStateAction<boolean>>
  mainImageCandidates: MainImageCandidates[]
  setMainImageCandidates: React.Dispatch<
    React.SetStateAction<MainImageCandidates[]>
  >
  closeModal: () => void
}
export const StaticPageCreator = (props: Props) => {
  const { imageURL } = useContext(UrlContext)

  const openPreview = (staticPage: StaticPageParams) => {
    localStorage.setItem(
      'preview_staticpage_params',
      JSON.stringify(staticPage)
    )
    window.open('/dashboard/preview_staticpage', '_blank')
    //ストレージの削除はcloseModal上に記述
  }

  return (
    <>
      <div className="flex flex-row justify-center text-white mt-2">
        <div className="basis-2/6 pl-2 text-2xl font-body font-bold pb-2">
          固定ページ
        </div>
        <div className="basis-4/6 text-right pr-2">
          <div className="inline-block  bg-cinekoya-sub-c rounded-xl py-1 px-4 text-left shadow-lg">
            <TipsAndUpdatesIcon />
            &nbsp;「シネコヤの過ごし方」や「パン・ドリンクメニュー」等の固定ページを編集できます。また、臨時ページをここで作成し、そのリンクを「お知らせ」やSNS等に貼りつけるという使い方もできます。
          </div>
        </div>
      </div>
      <div className="flex flex-row text-white mt-3">
        <div className="basis-2/3" />
        <div className="basis-1/3 text-cinekoya-sub-a text-center hover:text-cinekoya-sub-d">
          <AddCircleIcon
            fontSize="large"
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              props.setIsModalOpen(true)
              props.setPostParams(props.defaultObject)
              //なぜかCloseModalの初期化が効かないので、ここでも初期化
            }}
          />
        </div>
      </div>

      {props.staticPages.map((staticPage, index) => (
        <div key={index} className="flex justify-center font-body">
          <div className="flex flex-row text-white mt-3 w-5/6 border-1 border-cinekoya-sub-c mb-2 py-2 rounded-xl bg-yt-component">
            <div className="basis-5/6 text-left pl-5">
              <div className="text-2xl font-body font-bold pb-2">
                <span className="font-title">{staticPage.page_name}</span>
                {!staticPage.display_public && (
                  <span className="text-cinekoya-yellow-2">（非公開）</span>
                )}
              </div>
              <div className="text-sm">
                <LinkIcon fontSize="small" />
                &nbsp;
                <a
                  href={`https://cinekoya.com/static/${staticPage.path_name}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  https://cinekoya.com/static/{staticPage.path_name}
                </a>
              </div>
            </div>
            <div className="basis-1/6 ">
              <div className="text-center">
                <button
                  className="w-2/3 bg-cinekoya-sub-c text-white py-0.5 rounded-xl text-sm hover:opacity-100 cursor-pointer bg-opacity-80"
                  onClick={() => openPreview(staticPage)}
                >
                  プレビュー
                </button>
              </div>
              <div className="text-center pt-3 ">
                <span className="text-cinekoya-yellow-2 hover:text-cinekoya-sub-d ">
                  <EditIcon
                    fontSize="large"
                    sx={{ cursor: 'pointer' }}
                    onClick={() => {
                      props.setIsEditmode(true)
                      props.setIsModalOpen(true)
                      props.setPostParams(staticPage)
                    }}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      ))}

      <Modal
        isOpen={props.isModalOpen}
        style={modalStyle_create}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <div className="flex flex-row">
          <div className="basis-1/2  text-xl text-cinekoya-yellow-2 bg-yt-atom bg-opacity-70 rounded-xl pl-3 py-1 mb-3  shadow-md">
            ページの新規作成
          </div>
          <div className="basis-1/2 text-right">
            <button
              className="w-1/3 bg-cinekoya-sub-a text-white py-0.5 rounded-xl text-base hover:opacity-100 cursor-pointer bg-opacity-80"
              onClick={() => openPreview(props.postParams)}
            >
              プレビュー
            </button>
            &emsp;
            <HighlightOffIcon
              fontSize="large"
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                props.closeModal()
              }}
            />
          </div>
        </div>
        <div className=" font-body text-lg text-cinekoya-yellow-2">
          ページ名&nbsp;
          <span className="text-sm text-white">
            ※ ページ上部のタイトルとして表示されます。
          </span>
        </div>

        <div className="mb-3 ">
          <input
            className="w-full text-black border-solid  border-1 border-yt-atom focus:outline-none focus:ring-2 focus:ring-yt-atom-hover focus:border-transparent px-2 py-1"
            placeholder="例）フードメニュー"
            value={props.postParams.page_name}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              props.setPostParams({
                ...props.postParams,
                page_name: e.target.value,
              })
            }}
          />
        </div>

        <div className=" font-body text-lg text-cinekoya-yellow-2">
          ページのパス
          <br />
          <span className="text-sm text-white">
            ※ この投稿を表示させるURLの末尾を指定します。
            &quot;https://cinekoya.com/static/●●&quot;の「●●」の部分です。
            <br />
            ※例えば&quot;http://cinekoya.com/food&quot;にこの投稿を表示させたい場合は&nbsp;food&nbsp;と入力します。
          </span>
        </div>

        <div className="mb-3 ">
          <input
            className="w-full text-black border-solid  border-1 border-yt-atom focus:outline-none focus:ring-2 focus:ring-yt-atom-hover focus:border-transparent px-2 py-1"
            placeholder="例）food, about など"
            value={props.postParams.path_name}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              props.setPostParams({
                ...props.postParams,
                path_name: e.target.value,
              })
            }}
          />
        </div>
        <div className=" font-body text-lg text-cinekoya-yellow-2">本文</div>

        <div className="w-full bg-white text-black mt-1 overflow-hidden staticpage_editor">
          <QuillEditor
            allowImageInsertion={true}
            value={props.postParams.content}
            onChange={props.handleEditorChange}
            onImageUploadComplete={props.getMainImageCandidates}
          />
        </div>

        <div className=" font-body text-lg text-cinekoya-yellow-2 mt-3 mb-1">
          メイン画像
        </div>
        <div className="text-sm text-white pl-2">
          ※エディタ内で画像を挿入すると下に表示されます。
          <br />
          ※複数ある場合は選択した画像がトップページに表示されます。
        </div>

        <div className="grid grid-cols-5 gap-2 mb-5">
          {props.mainImageCandidates.map((image, index) => (
            <div key={index} className="col-span-1">
              <div>
                <img
                  src={imageURL + image.url}
                  className={`w-32 h-32 object-cover rounded-lg cursor-pointer
                    ${
                      props.postParams.main_image === image.url
                        ? 'ring-4 ring-cinekoya-sub-a'
                        : 'opacity-40'
                    }
                  
                  `}
                  onClick={() => {
                    props.setPostParams({
                      ...props.postParams,
                      main_image: image.url,
                    })
                  }}
                />
              </div>
            </div>
          ))}
        </div>

        {!props.isEditmode && (
          <>
            <SaveButton
              saveDisabled={props.saveDisabled}
              onClick={() => props.createStaticPage(true)}
              isPublic={true}
            >
              登録
            </SaveButton>

            <SaveButton
              saveDisabled={props.saveDisabled}
              onClick={() => props.createStaticPage(false)}
              isPublic={false}
            >
              下書き保存
            </SaveButton>
          </>
        )}
        {props.isEditmode && (
          <>
            <SaveButton
              saveDisabled={props.saveDisabled}
              onClick={() => props.updateStaticPage(true)}
              isPublic={true}
            >
              更新
            </SaveButton>

            <SaveButton
              saveDisabled={props.saveDisabled}
              onClick={() => props.updateStaticPage(false)}
              isPublic={false}
            >
              下書きとして更新
            </SaveButton>

            <div className="text-center mt-4 mb-10 ">
              <button
                className={`
            
                text-stone-300 hover:text-white
               
             bg-yt-component  hover:bg-yt-atom-hover  w-64 rounded-full py-1 shadow-md`}
                onClick={() => props.deleteStaticPage()}
              >
                削除
              </button>
            </div>
          </>
        )}
      </Modal>
    </>
  )
}
