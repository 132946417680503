import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ReserveBaseParamsContext } from '../../../../hooks/ReserveBaseParamsContext'
import { NonMovieFeeChecker } from '../Presentational/NonMovieFeeChecker'

interface CustomPrice {
  event_price_1: number
  event_price_2: number
  event_price_3: number
  event_price_4: number
  ep_1_label: string
  ep_2_label: string
  ep_3_label: string
  ep_4_label: string
}
export const NonMovieFeeCheckerContainer = () => {
  const navigate = useNavigate()
  const { reserveBaseParams, setReserveBaseParams } = useContext(
    ReserveBaseParamsContext
  )
  const [breakDown, setBreakDown] = useState<string>('')
  const [totalPrice, setTotalPrice] = useState<number>(0)
  const [totalTicketNum, setTotalTicketNum] = useState<number>(0)
  const [countNotEnough, setCountNotEnough] = useState<boolean>(false)
  const [customPrice, setCustomPrice] = useState<CustomPrice>({} as CustomPrice)
  const backToSeat = () => {
    navigate('../reservation/select_seat')
    setReserveBaseParams({ ...reserveBaseParams, seat: 1 })
  }

  const nextToCreditinfo = () => {
    if (reserveBaseParams.seat - totalTicketNum > 0) {
      setCountNotEnough(true)
      return
    }
    setCountNotEnough(false)
    setReserveBaseParams({
      ...reserveBaseParams,
      breakdown: breakDown,
      price: totalPrice,
    })
    navigate('../reservation/personal_info')
  }

  useEffect(() => {
    axios
      .get('custom_price', {
        params: {
          movie_id: reserveBaseParams.movie_id,
        },
      })
      .then((response) => {
        setCustomPrice(response.data)
        skipArrangeTicket(response.data)
      })
  }, [])

  const skipArrangeTicket = (data: CustomPrice) => {
    const custom_price = data
    if (custom_price.event_price_2 === 0) {
      const TOTALPRICE = custom_price.event_price_1 * reserveBaseParams.seat
      const BREAKDOWN = `${custom_price.ep_1_label}:${custom_price.event_price_1}:${reserveBaseParams.seat}枚`
      setReserveBaseParams({
        ...reserveBaseParams,
        breakdown: BREAKDOWN,
        price: TOTALPRICE,
      })
      navigate('../reservation/personal_info')
    }
  }

  return (
    <NonMovieFeeChecker
      totalPrice={totalPrice}
      setTotalPrice={setTotalPrice}
      setBreakDown={setBreakDown}
      backToSeat={backToSeat}
      nextToCreditinfo={nextToCreditinfo}
      customPrice={customPrice}
      countNotEnough={countNotEnough}
      totalTicketNum={totalTicketNum}
      setTotalTicketNum={setTotalTicketNum}
    />
  )
}
