import React, { ReactNode } from 'react'
import { useLocation } from 'react-router-dom'

interface Props {
  children: ReactNode
  label: string
}
export const MenuButton = (props: Props) => {
  const location = useLocation()

  return (
    <button
      className={`w-full font-hiragino rounded-full  text-left text-base py-2 px-3  text-white cursor-pointer shadow-xl
        ${
          location.pathname === props.label
            ? 'bg-yt-atom-hover'
            : 'bg-yt-component hover-bg-yt-atom-hover'
        }
    
    `}
    >
      {props.children}
    </button>
  )
}
