import React from 'react'
import Map from '../../../../assets/map_2019.png'

export const Access = () => {
  return (
    <>
      <div className="title-red-2 text-center font-hp">
        <div>アクセス</div>
      </div>
      <div className="font-hp text-cinekoya text-lg">
        神奈川県藤沢市鵠沼海岸3-4-6
        <br />
        TEL：0466-33-5393
        <br />
        鵠沼海岸駅から徒歩3分くらいです。
      </div>
      <div>
        <img src={Map} />
      </div>
      <div>
        <p className="text-lg text-cinekoya">
          <a
            href="https://www.google.com/maps?q=%E8%97%A4%E6%B2%A2%E5%B8%82%E9%B5%A0%E6%B2%BC%E6%B5%B7%E5%B2%B83-4-6&ion=1&espv=2&bav=on.2,or.r_cp.&bvm=bv.151325232,d.dGc&biw=1439&bih=732&dpr=1&um=1&ie=UTF-8&sa=X&ved=0ahUKEwj1hPP85PrSAhXKv7wKHYhmCc4Q_AUIBigB"
            target="_blank"
            rel="noreferrer"
          >
            ▶GoogleMapはこちら
          </a>
        </p>
      </div>
    </>
  )
}
