import React, { useContext } from 'react'
import { UrlContext } from '../../../../hooks/UrlContext'
import { Movie } from '../../../../types/Movie'
import { CampaignParams } from '../../../../types/CampaignPrams'
import dayjs from 'dayjs'

interface Props {
  nonCinemaEvents: Movie[]
  eventCampaigns: CampaignParams[]
}
export const NonCinemaEvent = (props: Props) => {
  const { imageURL } = useContext(UrlContext)

  return (
    <>
      <div className="title-red-2 text-center font-hp">
        <div>イベント</div>
      </div>

      <div className="flex flex-col">
        {props.eventCampaigns.map((campaign) => (
          <div key={campaign.id}>
            <a href={`/campaign/${campaign.id}`}>
              <div
                className="square hover:opacity-80"
                style={{
                  backgroundImage: `url(${
                    imageURL + encodeURIComponent(campaign.main_image)
                  })`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                }}
              >
                <div className="monthly-title-red">
                  <p className="movie-title tri">
                    <span className="font-body">
                      {dayjs(campaign.start_date).format('M/D(dd)')} &emsp;
                    </span>
                    <b> {campaign.title}</b>
                  </p>
                </div>
              </div>
            </a>
          </div>
        ))}

        {props.nonCinemaEvents.map((event) => (
          <div key={event.id}>
            <a href={`/non_cinema/${event.id}`}>
              <div
                className="square hover:opacity-80"
                style={{
                  backgroundImage: `url(${
                    imageURL + encodeURIComponent(event.image)
                  })`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                }}
              >
                <div className="monthly-title-blue">
                  <p className="movie-title tri">
                    <span className="font-body">
                      {dayjs(event.start_day).format('M/D(dd)')} &emsp;
                    </span>
                    <b>{event.title}</b>
                  </p>
                </div>
              </div>
            </a>
          </div>
        ))}
      </div>
    </>
  )
}
