import { ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'
import { NextStepButton } from '../../../Atoms/NextStepButton'
import { PrevStepButton } from '../../../Atoms/PrevStepButton'
import FeedbackIcon from '@mui/icons-material/Feedback'
import visaLogo from '../../../../assets/credit_logo_visa.svg'
import masterCardLogo from '../../../../assets/credit_logo_mastercard.svg'
import americanExpressLogo from '../../../../assets/credit_logo_amex.svg'
import dinersClubLogo from '../../../../assets/credit_logo_diners.svg'
import jcbLogo from '../../../../assets/credit_logo_jcb.svg'

interface Props {
  error: string | null
  processing: boolean
  disabled: boolean
  children: ReactNode
  handleSubmit: () => void
}

export const PayCreditcard = (props: Props) => {
  const navigate = useNavigate()
  return (
    <>
      <div className="flex flex-col items-center mt-5 mb-3 text-base text-black px-1">
        <div className="w-full md:w-[700px] text-center bg-cinekoya-sub-c bg-opacity-80  pt-2 pb-4 px-3 rounded-2xl shadow-md">
          <div className="flex flex-row mb-1">
            <div className="basis-1/2 font-body text-white text-lg font-bold text-left">
              クレジットカード情報を入力
            </div>
            <div className="basis-1/2 md:w-[700px] ">
              <img
                src={dinersClubLogo}
                className="float-right mr-1"
                width={40}
              />
              <img
                src={americanExpressLogo}
                className="mr-2 float-right"
                width={40}
              />
              <img src={jcbLogo} className=" float-right" width={40} />{' '}
              <img src={masterCardLogo} className="float-right" width={40} />{' '}
              <img src={visaLogo} className=" float-right" width={40} />
            </div>
          </div>

          {props.children}
        </div>

        {/* Show any error that happens when processing the payment */}
        {props.error && (
          <div className="card-error text-lg mt-2 text-cinekoya" role="alert">
            <FeedbackIcon />
            {props.error}
          </div>
        )}
      </div>

      <div className="flex flex-row mt-10 mb-3 justify-center text-center">
        <NextStepButton
          onClick={() => props.handleSubmit()}
          disabled={props.processing || props.disabled}
          bgColor="cinekoya"
        >
          <span id="button-text">
            {props.processing ? (
              <div className="spinner" id="spinner"></div>
            ) : (
              '決済を確定する'
            )}
          </span>
        </NextStepButton>
      </div>

      <div className="w-full text-center ">
        <PrevStepButton
          onClick={() => navigate('../reservation/personal_info')}
        >
          戻る
        </PrevStepButton>
      </div>
    </>
  )
}
