import React from 'react'

interface SeatsInfo {
  visited_non_reserved: number
  visited_web: number
  not_visited_web: number
  visited_tel: number
  not_visited_tel: number
  total_visited_reserved_number: number
  total_not_visited_reserved_seats: number
  capacity: number
  seats_left: number
}

interface Props {
  seatsInfo: SeatsInfo
}
export const AggregateSeatsLeft = (props: Props) => {
  return (
    <>
      <div
        className={`grid grid-cols-7  gap-3   rounded-xl px-5 py-4 clear-both ${
          props.seatsInfo.seats_left > 0
            ? 'bg-stone-200 text-yt-component'
            : 'bg-cinekoya text-white'
        }`}
      >
        <div className="col-span-3 text-right text-3xl">残席数</div>
        <div className={`col-span-1  text-3xl text-center `}>
          <b> {props.seatsInfo.seats_left}</b>
          &nbsp;席
        </div>
        <div className="col-span-3 " />
        <div className="col-span-3 text-2xl text-right">（</div>
        <div className="col-span-1 text-2xl  text-right pr-3">
          {Number(props.seatsInfo.seats_left) +
            Number(props.seatsInfo.total_not_visited_reserved_seats)}
          席
        </div>
        <div className="col-span-3 text-2xl">
          <span className="text-lg">※未来店の予約者を入れた場合</span>）
        </div>
        <div className="col-span-7 ">
          <hr style={{ backgroundColor: '#282828' }} />
        </div>
        <div className="col-span-3 text-right">定員数：</div>
        <div className="col-span-1 text-right">
          {props.seatsInfo.capacity}席
        </div>
        <div className="col-span-3" />

        <div className="col-span-3 text-right">来店済予約者：</div>
        <div className="col-span-1 text-right">
          {props.seatsInfo.total_visited_reserved_number}席
        </div>
        <div className="col-span-3">
          &nbsp;（web: &nbsp;{props.seatsInfo.visited_web} 席&nbsp;+&nbsp;
          電話:&nbsp;
          {props.seatsInfo.visited_tel} 席）
        </div>

        <div className="col-span-3 text-right">未来店予約者：</div>
        <div className="col-span-1 text-right">
          {props.seatsInfo.total_not_visited_reserved_seats}席
        </div>
        <div className="col-span-3">
          &nbsp;（web: &nbsp;{props.seatsInfo.not_visited_web} 席&nbsp;+&nbsp;
          電話:&nbsp;
          {props.seatsInfo.not_visited_tel} 席）
        </div>

        <div className="col-span-3 text-right">当日来客：</div>
        <div className="col-span-1 text-right">
          {props.seatsInfo.visited_non_reserved}席
        </div>
        <div className="col-span-3" />
      </div>
    </>
  )
}
