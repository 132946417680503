import React, { useContext, useEffect, useState } from 'react'
import EditIcon from '@mui/icons-material/Edit'
import { useBgColor } from '../../../../hooks/Create/useBgColor'
import { useJudgeEdge } from '../../../../hooks/Create/useJudgeEdge'
import { useJudgeOccupied } from '../../../../hooks/Create/useJudgeOccupied'
import { useMatchedSchedule } from '../../../../hooks/Create/useMatchedSchedule'
import { useSelectDate } from '../../../../hooks/Create/useSelectDate'
import { useSelectOrder } from '../../../../hooks/Create/useSelectOrder'
import { PostParamsContext } from '../../../../hooks/postParamsContext'
import { Post } from '../../../../types/Post.'

interface Props {
  date: string
  orderNum: number
  selectedOrder: number
  selectedDate: string
  selectedDateSecond: string
  selectedCinemaSchedule: Post | undefined | null
  firstClicked: boolean
  schedules: Post[]
  setSelectedOrder: (e: number) => void
  setSelectedDate: (e: string) => void
  setOnmouseDate: (e: string) => void
  setSelectedDateSecond: (e: string) => void
  setSelectedCinemaSchedule: (e: Post | null) => void
  defaultPostParams: Post
  setFirstClicked: (e: boolean) => void
  firstClick: (e: string) => void
  secondClick: (e: string, f: Post | null) => void
  baseDate: string
}

export const TD = (props: Props) => {
  const { postParams, setPostParams } = useContext(PostParamsContext)
  let pastDate: boolean

  if (props.date < props.baseDate) {
    pastDate = true
  } else {
    pastDate = false
  }

  const [inRange, setInRange] = useState<boolean>(false)

  const { matchedCinemaSchedule, isDraft, dateDiff } = useMatchedSchedule({
    date: props.date,
    orderNum: props.orderNum,
    schedules: props.schedules,
    defaultPostParams: props.defaultPostParams,
  })

  const orderSelectType = useSelectOrder({
    orderNum: props.orderNum,
    selectedOrder: props.selectedOrder,
  })

  const dateSelectType = useSelectDate({
    date: props.date,
    selectedDate: props.selectedDate,
    selectedDateSecond: props.selectedDateSecond,
  })

  const isOccupied = useJudgeOccupied({
    date: props.date,
    orderNum: props.orderNum,
    schedules: props.schedules,
  })

  const { judgeStart, judgeEnd } = useJudgeEdge({
    date: props.date,
    orderNum: props.orderNum,
    schedules: props.schedules,
  })

  const bgColor = useBgColor({
    isOccupied: isOccupied,
    orderSelectType: orderSelectType,
    dateSelectType: dateSelectType,
    isDraft: isDraft,
    inRange: inRange,
    pastDate: pastDate,
  })

  //ホバー時の処理
  const onMouseHandler = () => {
    props.setSelectedCinemaSchedule(matchedCinemaSchedule)
    props.setOnmouseDate(props.date)
  }

  //↓このuseEffect:hoverしているセルが
  //登録済スケジュールの範囲内にいるかどうかの判定
  useEffect(() => {
    if (
      props.selectedCinemaSchedule &&
      props.orderNum === props.selectedCinemaSchedule.order &&
      props.date >= props.selectedCinemaSchedule?.start_day &&
      props.date <= props.selectedCinemaSchedule?.end_day
    ) {
      setInRange(true)
    } else {
      setInRange(false)
    }
  }, [props.selectedCinemaSchedule])

  const ResetOrderAndDate = () => {
    props.setSelectedOrder(0)
    props.setSelectedDate('')
    props.setSelectedDateSecond('')
    props.setFirstClicked(false)
  }

  const clickHandler = () => {
    props.setSelectedOrder(props.orderNum)
    setPostParams({ ...postParams, order: props.orderNum })

    if (!props.firstClicked) {
      props.setSelectedDate(props.date)
    } else {
      props.setSelectedDateSecond(props.date)
    }

    //modalの開閉処理
    if (!isOccupied && !pastDate) {
      if (orderSelectType === 'selected' && props.firstClicked) {
        props.secondClick(props.date, matchedCinemaSchedule)
      } else if (orderSelectType === 'selected' && !props.firstClicked) {
        props.firstClick(props.date)
      } else if (orderSelectType === 'default') {
        props.firstClick(props.date)
      } else if (orderSelectType === 'unselected') {
        ResetOrderAndDate()
      }
    } else if (isOccupied) {
      if (orderSelectType === 'unselected') {
        ResetOrderAndDate()
      } else {
        if (props.firstClicked) {
          ResetOrderAndDate()
        } else {
          props.secondClick(props.date, matchedCinemaSchedule)
        }
      }
    }
  }

  return (
    <td
      onClick={clickHandler}
      onMouseEnter={onMouseHandler}
      className={`
      ${bgColor}
      ${judgeStart && dateDiff > 1 && 'rounded-l-xl pl-3'}
      ${judgeStart && dateDiff <= 1 && 'rounded-l-xl pl-1'}
      ${judgeEnd ? 'rounded-r-xl' : ''}
      overflow-visible whitespace-nowrap py-1 transition-color  duration-200`}
    >
      {judgeStart ? (
        <>
          {dateDiff > 0 && (
            <>
              {dateDiff === 1 && (
                <span className="text-sm font-title">
                  {' '}
                  {matchedCinemaSchedule?.alternative_name === null
                    ? matchedCinemaSchedule?.title.substring(0, 6)
                    : matchedCinemaSchedule?.alternative_name.substring(0, 6)}
                  …
                </span>
              )}
              {dateDiff > 1 && (
                <span className="text-base font-title">
                  {matchedCinemaSchedule?.alternative_name === null
                    ? matchedCinemaSchedule?.title
                    : matchedCinemaSchedule?.alternative_name}
                </span>
              )}
              &nbsp;
              <EditIcon fontSize="small" />
              <br />
              {matchedCinemaSchedule?.time_unsettled ? (
                <span className="text-sm text-stone-300">時間未定</span>
              ) : (
                <>
                  <span className="text-sm text-stone-300">
                    {matchedCinemaSchedule?.start_hour}:
                    {matchedCinemaSchedule?.start_minutes}～
                    {matchedCinemaSchedule?.end_hour}:
                    {matchedCinemaSchedule?.end_minutes}
                  </span>
                </>
              )}
            </>
          )}

          {dateDiff === 0 && (
            <>
              <span className="text-sm font-title">
                {matchedCinemaSchedule?.alternative_name === null
                  ? matchedCinemaSchedule?.title.substring(0, 6)
                  : matchedCinemaSchedule?.alternative_name.substring(0, 6)}
                <br />
                {matchedCinemaSchedule?.alternative_name === null
                  ? matchedCinemaSchedule?.title.substring(7, 10)
                  : matchedCinemaSchedule?.alternative_name.substring(7, 10)}
                …
              </span>
              <EditIcon fontSize="small" />
              &nbsp;
              <br />
              {matchedCinemaSchedule?.time_unsettled ? (
                <span className="text-sm text-stone-300">時間未定</span>
              ) : (
                <>
                  <span className="text-sm text-stone-300">
                    {matchedCinemaSchedule?.start_hour}:
                    {matchedCinemaSchedule?.start_minutes}～
                  </span>
                </>
              )}
            </>
          )}
        </>
      ) : null}
      &nbsp;
      {judgeStart && isDraft && (
        <>
          <br />
          <div className="inline-block px-1 py-0.5  text-cinekoya-sub-a font-bold rounded-lg text-sm">
            下書き
          </div>
        </>
      )}
    </td>
  )
}
