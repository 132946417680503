import TextField from '@mui/material/TextField'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import axios from 'axios'
import { Dayjs } from 'dayjs'
import React, { useContext, useState } from 'react'
import { PostParamsContext } from '../../../../hooks/postParamsContext'
import { Post } from '../../../../types/Post.'
import { Modal_AlertDuplicated } from './Modal_AlertDuplicated'

interface Props {
  selectedOrder: number
  selectedCinemaSchedule: Post | null | undefined
}

export const InputStartDay = (props: Props) => {
  const { postParams, setPostParams } = useContext(PostParamsContext)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [openDatePicker, setOpenDatePicker] = useState<boolean>(false)

  const validateStartday = async (startday: Dayjs | null) => {
    await axios
      .post('validate_startday', {
        start_day: startday?.format('YYYY-MM-DD'),
        end_day: postParams.end_day,
        order: props.selectedOrder,
      })
      .then((response) => {
        const isDuplicated = response.data
        if (isDuplicated && !props.selectedCinemaSchedule) {
          setPostParams(postParams)
          setOpenDatePicker(false)
          setModalIsOpen(true)
          return
        }
        if (isDuplicated && props.selectedCinemaSchedule) {
          setPostParams({
            ...postParams,
            start_day: props.selectedCinemaSchedule.start_day,
          })
          setOpenDatePicker(false)
          setModalIsOpen(true)
          return
        }

        setPostParams({
          ...postParams,
          start_day: String(startday?.format('YYYY-MM-DD')),
        })
      })
  }

  const openfunc = () => {
    setOpenDatePicker(true)
  }

  const closeModal = () => {
    setModalIsOpen(false)
    setOpenDatePicker(true)
  }

  return (
    <>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        sx={{
          width: 500,
        }}
      >
        <MobileDatePicker
          label="開始日"
          inputFormat="MM/DD"
          value={postParams.start_day}
          onOpen={openfunc}
          onClose={() => setOpenDatePicker(false)}
          open={openDatePicker}
          onChange={validateStartday}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{
                input: { width: '100px', background: '#282828' },
              }}
            />
          )}
        />
      </LocalizationProvider>

      <Modal_AlertDuplicated
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        targetText="開始日"
      />
    </>
  )
}
