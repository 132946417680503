import React from 'react'
import { CountDiscount } from './CountDiscount'
import { DiscountInfo } from '../../../../types/Reservation/DiscountInfo'

interface Props {
  fanclubNum: number
  setfanclubNum: (e: number) => void
  membersNum: number
  setMembersNum: (e: number) => void
  youthNum: number
  setYouthNum: (e: number) => void
  underYouthNum: number
  setUnderYouthNum: (e: number) => void
  infantNum: number
  setInfantNum: (e: number) => void
  countRemainder: number
  prevGeneralNumber: number
  setPrevGeneralNumber: (e: number) => void
  discountInfo: DiscountInfo
  setDiscountInfo: (e: DiscountInfo) => void
  setSwitchChecked: (e: boolean) => void
}

export const InputPeopleBreakdown = (props: Props) => {
  const constArrayCounter = {
    countRemainder: props.countRemainder,
    countMinLimit: 0,
    unit: '名',
    height: 9,
    widthInput: 32,
    paddingTop: 1,
  }
  const arrayCounter = [
    {
      ...constArrayCounter,
      label: 'ファンクラブ会員',
      amount: props.fanclubNum,
      setAmount: props.setfanclubNum,
    },
    {
      ...constArrayCounter,
      label: 'メンバーズ会員',
      amount: props.membersNum,
      setAmount: props.setMembersNum,
    },
    {
      ...constArrayCounter,
      label: 'ユース（18歳～22歳）',
      amount: props.youthNum,
      setAmount: props.setYouthNum,
    },
    {
      ...constArrayCounter,
      label: '小中高生',
      amount: props.underYouthNum,
      setAmount: props.setUnderYouthNum,
    },
    {
      ...constArrayCounter,
      label: '幼児',
      amount: props.infantNum,
      setAmount: props.setInfantNum,
    },
  ]
  return (
    <>
      {arrayCounter.map((counter, index) => (
        <React.Fragment key={index}>
          <div className="w-full text-center text-lg mb-0 mt-2 text-stone-700">
            {counter.label}
          </div>
          <CountDiscount
            amount={counter.amount}
            setAmount={counter.setAmount}
            countRemainder={counter.countRemainder}
            countMinLimit={counter.countMinLimit}
            unit={counter.unit}
            height={counter.height}
            widthInput={counter.widthInput}
            paddingTop={counter.paddingTop}
            prevGeneralNumber={props.prevGeneralNumber}
            setPrevGeneralNumber={props.setPrevGeneralNumber}
            discountInfo={props.discountInfo}
            setDiscountInfo={props.setDiscountInfo}
            setSwitchChecked={props.setSwitchChecked}
          />
        </React.Fragment>
      ))}
    </>
  )
}
