import axios from 'axios'
import dayjs from 'dayjs'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { UrlContext } from '../../hooks/UrlContext'
import { CinemaDetail } from '../Organism/CinemaDetail/CinemaDetail'
import { EventTemplate } from '../Templates/EventTemplate'

interface Notification {
  movie_id: number
  content: string
}

interface Detail {
  title: string
  detail: string
  image: string
  trailer_url: string
}

interface Schedule {
  id: number
  movie_id: number
  start_day: string
  end_day: string
  start_hour: string
  start_minutes: string
  end_hour: string
  end_minutes: string
}

export const CinemaDetailPage = () => {
  const { cinema_id } = useParams<{ cinema_id: string }>()

  const { imageURL } = useContext(UrlContext)

  const defaultDetail = {
    title: '',
    detail: '',
    image: '',
    trailer_url: '',
  }

  const [detail, setDetail] = useState<Detail>(defaultDetail)

  const [notification, setNotification] = useState<Notification | null>(null)

  const [schedule, setSchedule] = useState<Schedule[] | null>([])

  //MDEditor.Markdownのaタグのcolorを変更(直接指定できないため)
  const aTag = document.getElementsByTagName('a')
  for (let i = 0; i < aTag.length; ++i) {
    aTag[i].style.color = '#A94337'
  }

  const now = dayjs()
  useEffect(() => {
    axios
      .get('cinema_detail', {
        params: {
          date: now?.format('YYYY-MM-DD'),
          cinema_id: cinema_id,
        },
      })
      .then((response) => {
        setDetail(response.data.cinema_detail)
        setNotification(response.data.notification)
        setSchedule(response.data.schedules)
      })
      .catch((error) => console.log(error))
  }, [])

  return (
    <EventTemplate>
      <CinemaDetail
        detail={detail}
        schedule={schedule}
        notification={notification}
        is_cinema={true}
      >
        <img src={imageURL + detail?.image} className="m-auto" />
      </CinemaDetail>
    </EventTemplate>
  )
}
