import dayjs from 'dayjs'
import React from 'react'
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded'
function App() {
  const [selected, setSelected] = React.useState([])

  const now = dayjs()
  const arrayDate = [now]

  for (let i = 1; i < 20; i++) {
    arrayDate.push(now.add(i, 'd'))
  }

  const isItemSelected = (id: any) => !!selected.find((el) => el === id)

  const handleClick = (id: any) => {
    const itemSelected = isItemSelected(id)

    setSelected((currentSelected) =>
      itemSelected
        ? currentSelected.filter((el) => el !== id)
        : currentSelected.concat(id)
    )
  }

  return (
    <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
      {arrayDate.map((id, index) => (
        <Card
          itemId={String(id)} // NOTE: itemId is required for track items
          title={String(id)}
          key={index}
          selected={isItemSelected(id)}
        />
      ))}
    </ScrollMenu>
  )
}

function LeftArrow() {
  const { isFirstItemVisible, scrollPrev } = React.useContext(VisibilityContext)

  return (
    <button disabled={isFirstItemVisible} onClick={() => scrollPrev()}>
      <ArrowBackIosNewRoundedIcon />
    </button>
  )
}

function RightArrow() {
  const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext)

  return (
    <button disabled={isLastItemVisible} onClick={() => scrollNext()}>
      <ArrowForwardIosRoundedIcon />
    </button>
  )
}

type CardProps = {
  selected: boolean
  title: string
  itemId: string
}
const Card = (props: CardProps) => {
  const visibility = React.useContext(VisibilityContext)

  return (
    <div
      style={{
        width: '160px',
      }}
      tabIndex={0}
    >
      {props.title}
    </div>
  )
}

export default App
