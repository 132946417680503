import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { ReserveBaseParamsContext } from '../../../../hooks/ReserveBaseParamsContext'
import { NextStepButton } from '../../../Atoms/NextStepButton'
import { Post } from '../../../../types/Post.'

interface Result {
  id: number
  name: string
  ticketNum: number
  unitPrice: number
  totalPrice: number
}

interface ReservableProperty {
  date: string
  order: number
  isReservable: boolean
  ReservableNumber: number
}

interface Props {
  aggregateResults: Result[]
  grandTotalPrice: number
  tables: Post[]
  reservableProperty: ReservableProperty[]
}
export const SubmitTicketBreakdonw = (props: Props) => {
  const navigate = useNavigate()
  const { reserveBaseParams, setReserveBaseParams } = useContext(
    ReserveBaseParamsContext
  )

  const nextToPersonalInfo = () => {
    const ticketSales = props.aggregateResults.filter(
      (result) => result.ticketNum > 0
    )
    const ticketBreakdownString = ticketSales.map((item) =>
      item.ticketNum !== 0
        ? item.name + '(' + item.unitPrice + '):' + item.ticketNum + '枚'
        : null
    )

    const breakdown = ticketBreakdownString.join('/')

    setReserveBaseParams({
      ...reserveBaseParams,
      breakdown: breakdown,
      price: props.grandTotalPrice,
      sold_ticket_types: ticketSales,
    })

    navigate('../reservation/personal_info')
  }

  return (
    <>
      <NextStepButton
        onClick={nextToPersonalInfo}
        bgColor={'cinekoya'}
        disabled={false}
      >
        次へ
      </NextStepButton>
    </>
  )
}
