import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { ReserveBaseParamsContext } from '../../../../hooks/ReserveBaseParamsContext'
import { Post } from '../../../../types/Post.'
import { SubmitTicketBreakdonw } from '../Presentational/SubmitTicketBreakdonw'

interface Result {
  id: number
  name: string
  ticketNum: number
  unitPrice: number
  totalPrice: number
}

interface ReservableProperty {
  date: string
  order: number
  isReservable: boolean
  ReservableNumber: number
}

interface Props {
  aggregateResults: Result[]
  grandTotalPrice: number
}

export const SubmitTicketBreakdownContainer = (props: Props) => {
  const { reserveBaseParams } = useContext(ReserveBaseParamsContext)
  const [tables, setTables] = useState<Post[]>([])

  const [reservableProperty, setReservableProperty] = useState<
    ReservableProperty[]
  >([])

  useEffect(() => {
    getArrayDateinfo()
  }, [])

  const getArrayDateinfo = async () => {
    const array_date: string[] = [reserveBaseParams.date]

    const formData = new FormData()
    formData.append('array_date', JSON.stringify(array_date))

    await axios
      .get('get_tables', {
        params: {
          firstDay: reserveBaseParams.date,
          lastDay: reserveBaseParams.date,
        },
      })
      .then((response) => {
        setTables(response.data)
      })

    await axios.post('get_reservable_property', formData).then((response) => {
      setReservableProperty(response.data)
    })
  }

  return (
    <SubmitTicketBreakdonw
      aggregateResults={props.aggregateResults}
      grandTotalPrice={props.grandTotalPrice}
      tables={tables}
      reservableProperty={reservableProperty}
    />
  )
}
