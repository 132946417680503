import React, { useContext, useEffect, useState } from 'react'
import { Post } from '../types/Post.'
import { ReserveBaseParamsContext } from './ReserveBaseParamsContext'

interface ReservableProperty {
  date: string
  order: number
  isReservable: boolean
  ReservableNumber: number
}

interface Props {
  table: Post
  reservableProperty: ReservableProperty[]
  date: string
}

export const useReservable = (props: Props) => {
  const [isReservable, setIsReservable] = useState<boolean>(false)

  useEffect(() => {
    const properties = props.reservableProperty.map((item) => {
      if (props.table.order === item.order && props.date === item.date) {
        return item.isReservable
      } else {
        return null
      }
    })

    const matched_property = properties.find((item) => item !== null)

    if (matched_property) {
      setIsReservable(matched_property)
    }
  }, [props.reservableProperty])

  return isReservable
}
