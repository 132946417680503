import React, { useState } from 'react'
import Modal from 'react-modal'
import PhoneIcon from '@mui/icons-material/Phone'
import CancelSharpIcon from '@mui/icons-material/CancelSharp'
export const CallToBookButton = () => {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false)
  const openModal = () => {
    setModalIsOpen(true)
  }

  const closeModal = () => {
    setModalIsOpen(false)
  }

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '100%',
      maxWidth: '520px',
      height: '75vh',
      maxHeight: '500px',
      borderRadius: '1%',
      background: 'rgba(28,28,28,0.8)',
    },
    overlay: {
      top: 0,
      left: 0,
      backgroundColor: 'rgba(0,0,0,0.65)',
      zIndex: 10,
    },
  }

  return (
    <>
      <button
        className="w-3/4 sm:w-1/3 py-0.5 sm:py-1 text-sm text-center text-white inline-block  font-body rounded-full shadow-md cursor-pointer border-1  bg-cinekoya-sub-b  hover:bg-cinekoya"
        onClick={openModal}
      >
        &nbsp;電話予約のみ
      </button>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Example Modal"
      >
        <div className="flex flex-col px-2 justify-center items-center font-body text-white">
          <div className="text-center text-yt-atom-hover">
            <CancelSharpIcon
              fontSize="large"
              sx={{ cursor: 'pointer' }}
              onClick={closeModal}
            />
          </div>
          <div className="w-full md:w-4/5 text-center mt-3 py-1 px-2 bg-cinekoya-hover  bg-opacity-85 text-2xl  rounded-t-2xl shadow-lg">
            <PhoneIcon />
            <span className="ml-2 align-middle font-bold">電話予約</span>
          </div>

          <div className="w-full md:w-4/5 mt-3  mb-2 px-1 text-lg">
            お電話でご予約の場合は、上映時間の
            <b className="text-cinekoya-yellow-2">１５分前まで</b>
            のご来店をお願いしております。
            <b className="text-cinekoya-yellow-2">１５分前まで</b>
            にご来店がない場合は、ご予約がキャンセルとなりますのでご注意ください。
          </div>
          <div className="w-full md:w-4/5 text-center mt-3 py-3 px-2 bg-cinekoya-hover  bg-opacity-85 rounded-b-2xl border-1 border-yt-atom">
            <div className="text-xl mb-2">◆電話対応時間◆</div>
            <div>11:00～閉店時間まで</div>
            <div>（月火定休日）</div>
            <div>
              <b>0466&nbsp;-&nbsp;33&nbsp;-&nbsp;3393</b>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}
