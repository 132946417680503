import React, { ReactNode } from 'react'
import ratIcon from '../../../assets/icon_rat.svg'
import scheduleIcon from '../../../assets/schedule_icon.svg'
// import MDEditor from '@uiw/react-md-editor'
import dayjs from 'dayjs'
import ja from 'dayjs/locale/ja'

dayjs.locale(ja)

interface Detail {
  title: string
  detail: string
  image: string
  trailer_url: string
}

interface Schedule {
  id: number
  movie_id: number
  start_day: string
  end_day: string
  start_hour: string
  start_minutes: string
  end_hour: string
  end_minutes: string
}

interface Notification {
  movie_id: number
  content: string
}

interface Props {
  detail: Detail
  schedule: Schedule[] | null | undefined
  notification: Notification | null | undefined
  children: ReactNode
  is_cinema: boolean
}
export const CinemaDetail = (props: Props) => {
  return (
    <>
      <div className="title-red-2 text-center mt-5">
        <div>
          {props.is_cinema ? '上映中' : 'イベント'}
          <img className="rat" src={ratIcon} width={40} />
        </div>
      </div>
      <div className="flex flex-row justify-center">
        <div className="basis-4/5 title-white-red">{props.detail?.title}</div>
      </div>
      <div className="flex justify-center  mb-3">
        <div className="basis-full md:basis-4/5 ">
          <div className="flex flex-row justify-center">
            {props.is_cinema && (
              <div className="basis-1/4 sm:basis-1/3 pl-2 sm:pl-0">
                {' '}
                <img src={scheduleIcon} className="ml-auto" />
              </div>
            )}
            <div
              className={`basis-3/4 sm:basis-2/3 text-cinekoya text-left
            `}
            >
              {props.schedule &&
                props.schedule.map((sch) => (
                  <React.Fragment key={sch.id}>
                    <div className="pt-1">
                      &emsp;
                      <span className="align-middle">
                        {dayjs(sch.start_day).format('M/D(dd)')}
                        {sch.start_day !== sch.end_day && (
                          <>~{dayjs(sch.end_day).format('M/D(dd)')}</>
                        )}
                        &emsp;
                        {sch.start_hour}:{sch.start_minutes}-{sch.end_hour}:
                        {sch.end_minutes}
                      </span>
                    </div>
                  </React.Fragment>
                ))}
              {!props.schedule && (
                <div className="pt-1 text-yt-atom opacity-80">
                  &emsp;※※ここに上映期間が入ります※※
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {props.notification !== null && (
        <div className="flex justify-center  mb-3">
          <div className="basis-full md:basis-1/2 px-3  md:px-0 text-cinekoya">
            {props.notification?.content}
          </div>
        </div>
      )}
      <div className="flex justify-center text-center mb-3">
        <div className="basis-full px-3 md:basis-4/5 md:px-0">
          {props.children}
        </div>
      </div>
      <div
        className={`flex flex-row justify-center
        ${props.detail?.trailer_url ? '' : 'mb-5'}
      `}
      >
        <div
          className="basis-full sm:basis-4/5 px-2 sm:px-0"
          dangerouslySetInnerHTML={{ __html: props.detail?.detail }}
        >
          {/* <MDEditor.Markdown
            source={props.detail?.detail}
            style={{ color: 'gray' }}
          /> */}
        </div>
      </div>
      {props.detail?.trailer_url && (
        <>
          <div className="flex justify-center mt-5 mb-5">
            <div className="title-sideb">TRAILER</div>
          </div>

          <div className="flex justify-center font-body mt-4">
            <div className=" iframe-content ">
              <iframe
                src={`https://www.youtube.com/embed/${props.detail?.trailer_url.replace(
                  'https://youtu.be',
                  ''
                )}`}
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </>
      )}
    </>
  )
}
