import React, { useState } from 'react'
import EditIcon from '@mui/icons-material/Edit'
import NotificationsIcon from '@mui/icons-material/Notifications'
import NotificationAddIcon from '@mui/icons-material/NotificationAdd'
import { Link } from 'react-router-dom'
import FactCheckIcon from '@mui/icons-material/FactCheck'
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight'
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import Modal from 'react-modal'
import { Menu } from './Menu'
import SettingsIcon from '@mui/icons-material/Settings'
import EventRepeatIcon from '@mui/icons-material/EventRepeat'
import BarChartIcon from '@mui/icons-material/BarChart'
import PushPinIcon from '@mui/icons-material/PushPin'
import { MenuButtonShrinked } from '../Atoms/MenuButtonShrinked'
import { LogoutContainer } from '../Organism/Logout/Container/Logout.container'
interface Props {
  isMenuShrinked: boolean
  setIsMenuShrinked: (e: boolean) => void
}

const Horizon = () => {
  return (
    <div className="px-2 my-4">
      <hr className="bg-cinekoya-sub-c opacity-100" style={{ height: '1px' }} />
    </div>
  )
}

export const MenuShrinked = (props: Props) => {
  const [modalIsOpen, setIsOpen] = useState(false)

  const customStyles = {
    content: {
      top: '50%',
      left: '0%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(0%, -50%)',
      width: '300px',
      height: '100%',
      // background: '#f5f5f4',
      background: '#1F1F1F',
      border: 'none',
      zIndex: 5,
      paddingTop: '8px',
    },
    overlay: {
      top: '0px',
      left: 0,
      backgroundColor: 'rgba(0,0,0,0.40)',
      zIndex: 5,
    },
  }

  const openModal = () => {
    setIsOpen(true)
    props.setIsMenuShrinked(false)
  }

  const closeModal = () => {
    setIsOpen(false)
    props.setIsMenuShrinked(true)
  }
  return (
    <>
      <div className="w-full text-cinekoya-sub-a hover:text-cinekoya-sub-d mt-2 text-center">
        <KeyboardDoubleArrowRightIcon
          fontSize="large"
          onClick={openModal}
          className="cursor-pointer"
        />
      </div>

      <Horizon />

      <div className="text-center px-1 mt-4 mb-3">
        <Link to={'/dashboard/visitor_management'}>
          <MenuButtonShrinked label={'/dashboard/visitor_management'}>
            <FactCheckIcon />
          </MenuButtonShrinked>
        </Link>
      </div>

      <Horizon />

      <div className="text-center px-1 mb-3">
        <Link to={'/dashboard/aggregate_sales'}>
          <MenuButtonShrinked label={'/dashboard/aggreagete_sales'}>
            <BarChartIcon />
          </MenuButtonShrinked>
        </Link>
      </div>

      <Horizon />

      <div className="text-center px-1 mb-2 mt-4">
        <Link to={'/dashboard'}>
          <MenuButtonShrinked label={'/dashboard'}>
            <EditIcon />
          </MenuButtonShrinked>
        </Link>
      </div>

      <div className="text-center px-1 mb-4 mt-3">
        <Link to={'/dashboard/event_display_option'}>
          <MenuButtonShrinked label={'/dashboard/event_display_option'}>
            <EventRepeatIcon />
          </MenuButtonShrinked>
        </Link>
      </div>

      <div className="text-center px-1 mb-4 mt-3">
        <Link to={'/dashboard/notification_event'}>
          <MenuButtonShrinked label={'/dashboard/notification'}>
            <NotificationsIcon />
          </MenuButtonShrinked>
        </Link>
      </div>

      <div className="text-center px-1 mb-4 mt-3">
        <Link to={'/dashboard/campaign_creator'}>
          <MenuButtonShrinked label={'/dashboard/campaign_creator'}>
            <NotificationAddIcon />
          </MenuButtonShrinked>
        </Link>
      </div>

      <div className="text-center px-1 mb-4 mt-3">
        <Link to={'/dashboard/staticpage_creator'}>
          <MenuButtonShrinked label={'/dashboard/staticpage_creator'}>
            <PushPinIcon />
          </MenuButtonShrinked>
        </Link>
      </div>

      <Horizon />
      <div className="text-center px-1 mb-4 mt-3">
        <Link to={'/dashboard/common_setting'}>
          <MenuButtonShrinked label={'/dashboard/common_setting'}>
            <SettingsIcon />
          </MenuButtonShrinked>
        </Link>
      </div>

      <div className="text-right px-1 mb-20">
        <LogoutContainer isMenuShrinked={props.isMenuShrinked} />
      </div>
      <div className="text-right px-1">
        <a href="/" target="_blank">
          <button className="w-full font-hiragino rounded-full text-center text-base py-2 px-3 bg-black hover:bg-yt-atom-hover text-cinekoya-sub-a ">
            <OpenInNewIcon fontSize="large" />
          </button>
        </a>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Example Modal"
      >
        <div className="w-full text-white">
          <div className="pl-2 text-cinekoya-sub-a hover:text-cinekoya-sub-d">
            <KeyboardDoubleArrowLeftIcon
              fontSize="large"
              onClick={closeModal}
              className="cursor-pointer"
            />
          </div>

          <Menu isMenuShrinked={props.isMenuShrinked} />
        </div>
      </Modal>
    </>
  )
}
