import React from 'react'

export const modalStyle_create = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: '780px',
    height: '98vh',
    border: '1px solid #3090A0 ',
    borderRadius: '1%',
    background: 'rgba(28,28,28,0.55)',
    color: 'white',
    fontFamily: 'Roboto, sans-serif',
  },
  overlay: {
    top: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0,0.55)',
    zIndex: 10,
  },
}
