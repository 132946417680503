import React, { useContext } from 'react'
import { ReserveBaseParamsContext } from '../../../../hooks/ReserveBaseParamsContext'

interface Props {
  unit: string
  countRemainder: number
  countMinLimit: number
  height: number
  widthInput: number
  paddingTop: number
}
export const CountSeat = (props: Props) => {
  const { reserveBaseParams, setReserveBaseParams } = useContext(
    ReserveBaseParamsContext
  )

  const countDown = () => {
    if (reserveBaseParams.seat - 1 < props.countMinLimit) {
      setReserveBaseParams(reserveBaseParams)
    } else {
      setReserveBaseParams({
        ...reserveBaseParams,
        seat: reserveBaseParams.seat - 1,
      })
    }
  }

  const countUp = () => {
    if (props.countRemainder <= 0) {
      setReserveBaseParams(reserveBaseParams)
    } else {
      setReserveBaseParams({
        ...reserveBaseParams,
        seat: reserveBaseParams.seat + 1,
      })
    }
  }

  return (
    <div className="flex flex-row h-10 justify-center ">
      <div className="basis-full sm:basis-96   text-center">
        <button
          className={`h-${
            props.height
          } w-${16} rounded-l-2xl bg-cinekoya hover:bg-cinekoya-hover  text-white font-bold text-3xl`}
          onClick={countDown}
        >
          －
        </button>
        &emsp;
        <div
          className={`inline-block h-${props.height} w-${props.widthInput} border-1 border-yt-atom-hover align-bottom rounded-2xl pt-${props.paddingTop}`}
        >
          <span className="align-bottom text-xl font-bold">
            {reserveBaseParams.seat}
            {props.unit}
          </span>
        </div>
        &emsp;
        <button
          className={`h-${
            props.height
          } w-${16} rounded-r-2xl bg-cinekoya hover:bg-cinekoya-hover text-white font-bold text-3xl`}
          onClick={countUp}
        >
          +
        </button>
      </div>
    </div>
  )
}
