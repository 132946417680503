import React, { useEffect, useState } from 'react'
import { Post } from '../../types/Post.'

interface Suggestion {
  altenative_name: string
}
interface Props {
  schedules: Post[]
}

export const useSuggestAlternativeName = (props: Props) => {
  const [suggestAlternativeName, setSuggestAlternativeName] = useState<Post[]>(
    []
  )

  useEffect(() => {
    const suggestions = props.schedules.filter((item) => {
      if (item.alternative_name !== null) {
        return item
      }
    })
    setSuggestAlternativeName(suggestions)
  }, [props.schedules])

  return suggestAlternativeName
}
