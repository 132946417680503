import { Dayjs } from 'dayjs'
import { ChangeEvent } from 'react'
import { ThreeCircles } from 'react-loader-spinner'
import { CinemaOnly } from '../../../../types/CInemaOnly'
import { CountNonReservedVisitorContainer } from '../Container/CountNonReservedVisitor.container'
import { CountTelReservedVisitorContainer } from '../Container/CountTelReservedVisitor.container'
import { CountWebReservedVisitorContainer } from '../Container/CountWebReservedVisitor.container'
import { AggregateSeatsLeft } from './AggregateSeatsLeft'
import NotificationsIcon from '@mui/icons-material/Notifications'
import { DatePicker } from './DatePicker'

interface SeatsInfo {
  visited_non_reserved: number
  visited_web: number
  not_visited_web: number
  visited_tel: number
  not_visited_tel: number
  total_visited_reserved_number: number
  total_not_visited_reserved_seats: number
  capacity: number
  seats_left: number
}

interface MovieInfo {
  movie_id: number
  is_cinema: boolean
  title: string
}

interface Props {
  date: string
  setDate: (e: string) => void
  order: number
  setOrder: (e: number) => void
  movieInfo: MovieInfo
  setMovieInfo: (e: MovieInfo) => void
  cinemaList: CinemaOnly[]
  getInitialList: (e: Dayjs | null) => void
  getSeatsInfo: () => void
  seatsInfo: SeatsInfo
  isLoadingSeatsInfo: boolean
}
export const VisitorManagement = (props: Props) => {
  const areaStyle = 'basis-1/3 border-2 rounded-xl py-2 text-center '

  const buttonStyle =
    'w-3/4  px-2 py-1 rounded-xl font-body font-bold border-2 border-white hover:opacity-90 text-2xl'

  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const movie = props.cinemaList.find(
      (item) => item.order === Number(e.target.value)
    )

    if (movie) {
      props.setMovieInfo({
        movie_id: movie.id,
        is_cinema: movie.is_cinema,
        title: movie.title,
      })
    }

    props.setOrder(Number(e.target.value))
  }

  return (
    <>
      <div className="flex flex-row justify-center text-white mb-5 mt-2">
        <div className="basis-1/6 pl-2 text-2xl font-body font-bold">
          来客簿
        </div>
        <div className="basis-4/6 pl-10">
          <DatePicker date={props.date} setDate={props.setDate} />
          &emsp;
          <select
            className="w-2/3 px-2 py-3 rounded-lg text-lg text-white font-title cursor-pointer bg-yt-component border-4 border-cinekoya-sub-c focus:ring-cinekoya-sub-c"
            onChange={handleChange}
          >
            {props.cinemaList.map((cinema) => (
              <option key={cinema.id} value={cinema.order}>
                {cinema.alternative_name === null
                  ? cinema.title
                  : cinema.alternative_name}
              </option>
            ))}
          </select>
        </div>

        <div className="basis-1/6" />
      </div>

      <div className="mb-2">
        <hr className="bg-cinekoya-sub-c" style={{ height: '3px' }} />
      </div>

      <div className="flex flex-row gap-2">
        <CountNonReservedVisitorContainer
          areaStyle={areaStyle}
          buttonStyle={buttonStyle}
          date={props.date}
          order={props.order}
          movieInfo={props.movieInfo}
          getSeatsInfo={props.getSeatsInfo}
          seatsInfo={props.seatsInfo}
        />

        <CountWebReservedVisitorContainer
          areaStyle={areaStyle}
          buttonStyle={buttonStyle}
          date={props.date}
          order={props.order}
          getSeatsInfo={props.getSeatsInfo}
        />
        <CountTelReservedVisitorContainer
          areaStyle={areaStyle}
          buttonStyle={buttonStyle}
          date={props.date}
          order={props.order}
          movieInfo={props.movieInfo}
          getSeatsInfo={props.getSeatsInfo}
        />
      </div>
      <div className="flex flex-col justify-center mt-5 px-5 font-body ">
        {!props.isLoadingSeatsInfo && props.seatsInfo.seats_left <= 0 && (
          <div className="text-center font-body font-bold text-2xl text-cinekoya-yellow-1">
            <NotificationsIcon />
            &nbsp;定員に達しました！
          </div>
        )}
        {!props.isLoadingSeatsInfo && (
          <AggregateSeatsLeft seatsInfo={props.seatsInfo} />
        )}
        {props.isLoadingSeatsInfo && (
          <div className="grid grid-cols-7  bg-stone-200 text-yt-component rounded-xl px-5 py-4 ">
            <div className="col-span-7">
              <ThreeCircles
                height="100"
                width="100"
                color=""
                wrapperStyle={{ justifyContent: 'center' }}
                wrapperClass=""
                visible={true}
                ariaLabel="three-circles-rotating"
                outerCircleColor="#A94337"
                innerCircleColor="#3090A0"
                middleCircleColor="#E4F551"
              />
            </div>
          </div>
        )}
      </div>
    </>
  )
}
