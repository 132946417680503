import React, { useEffect, useState } from 'react'

interface Props {
  date: string
  selectedDate: string
  selectedDateSecond: string
}
export const useSelectDate = (props: Props) => {
  const [dateSelectType, setDateSelectType] = useState<string>('')
  //default,selected,unselected

  useEffect(() => {
    if (
      props.date === props.selectedDate ||
      props.date === props.selectedDateSecond
    ) {
      setDateSelectType('selected')
    } else {
      setDateSelectType('unselected')
    }
  }, [props])

  return dateSelectType
}
