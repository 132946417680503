import React, { ChangeEvent, useContext, useState } from 'react'
import { InputStartTime } from './InputStartTime'
import { InputEndTime } from './InputEndTime'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import { Radio } from '@mui/material'
import { Post } from '../../../../types/Post.'
import { PostParamsContext } from '../../../../hooks/postParamsContext'

interface Props {
  selectedCinemaSchedule: Post | null
}

export const ScreeningTime = (props: Props) => {
  const { postParams, setPostParams } = useContext(PostParamsContext)

  const [radioValue, setRadioValue] = useState<string>(
    postParams.time_unsettled ? 'unsettled' : 'input'
  )

  const radioScreenTime = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === 'unsettled') {
      setPostParams({
        ...postParams,
        start_hour: '',
        start_minutes: '',
        end_hour: '',
        end_minutes: '',
        time_unsettled: true,
      })
      setRadioValue('unsettled')
    }
    if (e.target.value === 'input') {
      setPostParams({
        ...postParams,

        time_unsettled: false,
      })
      setRadioValue('input')
    }
  }

  return (
    <>
      <div className="font-body text-lg text-cinekoya-yellow-2">上映時間</div>
      <FormControl>
        <RadioGroup
          row
          aria-labelledby="demo-radio-buttons-group-label"
          value={radioValue}
          name="radio-buttons-group"
          onChange={radioScreenTime}
        >
          <FormControlLabel value={'input'} control={<Radio />} label="入力" />
          <FormControlLabel
            value={'unsettled'}
            control={<Radio />}
            label="未確定"
          />
        </RadioGroup>
      </FormControl>

      {!postParams.time_unsettled && (
        <div className="flex mt-4 items-center">
          <InputStartTime
            selectedCinemaSchedule={props.selectedCinemaSchedule}
          />
          <div className="inline-block m-2">～</div>
          <InputEndTime selectedCinemaSchedule={props.selectedCinemaSchedule} />
        </div>
      )}
    </>
  )
}
