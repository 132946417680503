import dayjs, { Dayjs } from 'dayjs'
import ja from 'dayjs/locale/ja'
import axios from 'axios'
import { useContext, useEffect, useState } from 'react'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import { DateBar } from '../Organism/Schedule/DateBar'
import { ReserveBaseParamsContext } from '../../hooks/ReserveBaseParamsContext'
import { ReservationHeader } from '../Molecules/ReservationHeader'
import { DateCard } from '../Organism/Schedule/DateCard'
import { Post } from '../../types/Post.'
import { FooterSnsIcon } from '../Molecules/FooterSnsIcon'

dayjs.locale(ja)

interface ArrayDate {
  date: string
  isClose: boolean
}

interface ReservableProperty {
  date: string
  order: number
  isReservable: boolean
  ReservableNumber: number
}

export const Schedule = () => {
  const now = dayjs()
  const { reserveBaseParams, setReserveBaseParams } = useContext(
    ReserveBaseParamsContext
  )

  const [arrayDateAttribute, setArrayDateAttribute] = useState<ArrayDate[]>([])

  const [closeDays, setCloseDays] = useState<string[]>([])
  const [difference, setDifference] = useState<number>(0)

  const [tables, setTables] = useState<Post[]>([])
  const [reservableProperty, setReservableProperty] = useState<
    ReservableProperty[]
  >([])

  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    ;(async () => {
      await axios
        .get('judge_firstday', {
          params: {
            date: now.format('YYYY-MM-DD'),
          },
        })
        .then((response) => {
          setCloseDays(response.data.closedays)
          setDifference(response.data.difference)
          const diff = response.data.difference

          //今日と今日以降の休館日でない日との日時差分を取得
          //左端の表示日時は今日以降の休館日ではない日なのでdiffを加算する

          setReserveBaseParams({
            ...reserveBaseParams,
            date: now.add(diff, 'd').format('YYYY-MM-DD'),
          })

          axios.get('get_schedule_period').then((response) => {
            const period = response.data
            getArrayDateinfo(now.add(diff, 'd'), period)
          })
        })
    })()
  }, [])

  useEffect(() => {
    setReserveBaseParams({
      ...reserveBaseParams,
      date: now.add(difference, 'd').format('YYYY-MM-DD'),
    })
  }, [difference])

  const getArrayDateinfo = async (firstday: Dayjs, period: number) => {
    const array_date: string[] = []

    for (let i = 0; i < period; i++) {
      array_date.push(firstday.add(i, 'd').format('YYYY-MM-DD'))
    }

    const formData = new FormData()
    const paramsJson = JSON.stringify(array_date)
    formData.append('array_date', paramsJson)

    await axios
      .post('get_array_date_attribute', formData)
      .then((response) => {
        setArrayDateAttribute(response.data)
      })
      .catch((error) => console.log(error))

    await axios
      .get('get_tables', {
        params: {
          firstDay: firstday.format('YYYY-MM-DD'),
          lastDay: firstday.add(period - 1, 'd').format('YYYY-MM-DD'),
        },
      })
      .then((response) => {
        setTables(response.data)
      })
      .catch((error) => console.log(error))

    await axios.post('get_reservable_property', formData).then((response) => {
      setReservableProperty(response.data)
      setIsLoading(false)
    })
  }

  return (
    <>
      <div className="flex justify-center">
        <div className="w-full md:w-2/3 mb-10">
          <ReservationHeader />
          <div className=" text-center text-2xl font-title mt-3 mb-1 text-cinekoya bg-white">
            <div className=" inline-block align-top">
              <CalendarMonthIcon />
            </div>
            <div className="inline-block pl-2 pt-1 align-bottom ">
              上映スケジュール
            </div>
          </div>

          <DateBar
            arrayDateAttribute={arrayDateAttribute}
            closeDays={closeDays}
            difference={difference}
          />
          {arrayDateAttribute?.map((dateAttribute, index) => (
            <DateCard
              key={index}
              dateAttribute={dateAttribute}
              tables={tables}
              reservableProperty={reservableProperty}
              isLoading={isLoading}
            />
          ))}
        </div>
      </div>
      <div className="w-full py-5 text-center text-cinekoya">
        <FooterSnsIcon />
        <div className="mt-[-30px]">&copy; cinekoya</div>
      </div>
    </>
  )
}
