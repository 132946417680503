import React, { useState } from 'react'
import { MailMagazine } from '../Presentational/MailMagazine'
import axios from 'axios'

interface FormData {
  name: string
  email: string
}

interface Error {
  email: boolean
  empty: boolean
}

export const MailMagazineContainer = () => {
  const [form, setForm] = useState<FormData>({
    name: '',
    email: '',
  })

  const [isMailSent, setIsMailSent] = useState<boolean>(false)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const initialErrors = {
    email: false,
    empty: false,
  }

  const [errors, setErrors] = useState<Error>(initialErrors)

  const isInvalidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return !emailRegex.test(email)
  }

  const isEmpty = () => {
    if (form.name === '' || form.email === '') {
      return true
    } else {
      return false
    }
  }

  const escape = (e: string) => {
    const escapedValue = String(e)
      .replace(/&/g, '&amp;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#39;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')

    return escapedValue
  }
  const handleSubmit = async () => {
    setIsLoading(true)
    //初期化
    setErrors(initialErrors)

    //setErrorsは非同期的に実行されるので、一度初期値のオブジェクトを生成し、それを利用する。直接setErrors({email:false,empty:false})としても以下に続く処理では、更新前の値が使用されてしまう。

    if (isEmpty()) {
      setErrors({ ...initialErrors, empty: true })
      //...errorsとすると初期化前の値が適用されてしまう
      setIsLoading(false)
      return
    }

    if (isInvalidEmail(form.email)) {
      setErrors({ ...initialErrors, email: true })
      setIsLoading(false)
      return
    }

    await axios
      .post('mailmagazine_confirmation', {
        name: escape(form.name),
        email: escape(form.email),
      })
      .then(() => {
        setIsLoading(false)
        setIsMailSent(true)
      })
  }
  return (
    <MailMagazine
      form={form}
      errors={errors}
      setForm={setForm}
      handleSubmit={handleSubmit}
      isMailSent={isMailSent}
      isLoading={isLoading}
    />
  )
}
