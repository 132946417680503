import React, { ChangeEvent, useContext, useState } from 'react'
import { DiscountInfo } from '../../../../types/Reservation/DiscountInfo'
import { ReserveBaseParamsContext } from '../../../../hooks/ReserveBaseParamsContext'

interface Props {
  prevGeneralNumber: number
  discountInfo: DiscountInfo
  setDiscountInfo: (e: DiscountInfo) => void
  setModalIsOpen: (e: boolean) => void
  handleCancel: () => void
}
export const InputRepeatGeneral = (props: Props) => {
  const [repeatNum, setRepeatNum] = useState<number>(0)
  const { reserveBaseParams } = useContext(ReserveBaseParamsContext)
  const handleChangeRepeatGeneral = (e: ChangeEvent<HTMLInputElement>) => {
    const targetNum = Number(e.target.value)

    if (targetNum > props.prevGeneralNumber) {
      setRepeatNum(repeatNum)
      return
    }
    setRepeatNum(targetNum)
  }

  const handleClickRepeatGeneral = () => {
    props.setDiscountInfo({
      ...props.discountInfo,
      repeat_general_number: repeatNum,
    })

    props.setModalIsOpen(false)
  }

  return (
    <>
      {' '}
      <div className="flex justify-center mt-3 mb-5">
        <div className="w-full md:w-3/4 text-xl text-stone-600 font-bold px-2">
          <b>別作品のご予約</b>では
          <b>
            「一般料金」のお客様が
            <u>{props.prevGeneralNumber}名</u>
          </b>
          いらっしゃいます。
          <u>この{props.prevGeneralNumber}名様のうち 今回もご予約される方</u>
          の人数を入力して下さい。
        </div>
      </div>
      <div className=" text-center text-xl mb-3 font-bold">
        <input
          className="w-[70px] rounded-lg border-zinc-700 border-1 py-1 px-2 text-center bg-cinekoya-yellow-2 bg-opacity-10"
          value={repeatNum}
          type="number"
          autoComplete="off"
          min="0"
          max={reserveBaseParams.generalNum}
          onChange={handleChangeRepeatGeneral}
        />
        &nbsp;名
      </div>
      <div className="text-center">
        <button
          className="w-full md:w-3/4 py-2 bg-cinekoya bg-opacity-90 disabled:bg-cinekoya-sub-b disabled:bg-opacity-60 text-white shadow-md rounded-2xl hover:bg-opacity-80 text-xl border-white border-4"
          disabled={repeatNum === 0}
          onClick={handleClickRepeatGeneral}
        >
          割引の適用
        </button>
      </div>
      <div className="w-full text-center">
        <button
          className="w-1/2 py-1 mt-3 bg-yt-atom hover:bg-opacity-80 text-white rounded-xl cursor-pointer"
          onClick={props.handleCancel}
        >
          キャンセル
        </button>
      </div>
    </>
  )
}
