import { useEffect, useState } from 'react'

interface ScheduleLimits {
  id: number
  start_day: string
  end_day: string
  order: number
  seat_limits: number
}

interface ParticleLimits {
  id: number
  date: string
  order: number
  limit: number
}

type CellParticleLimit = {
  id: number | null
  limit: number | null
}

interface Props {
  date: string
  orderNum: number
  particleLimits: ParticleLimits[]
  scheduleLimits: ScheduleLimits[]
}
export const useGetLimit = (props: Props) => {
  const [particleLimit, setParticleLimit] = useState<CellParticleLimit>({
    id: null,
    limit: null,
  })
  const [scheduleLimit, setScheduleLimit] = useState<number | null>(null)

  useEffect(() => {
    const arrayParticleLimit = props.particleLimits?.map((item) => {
      if (item.date === props.date && item.order === props.orderNum) {
        return item
      }
      return null
    })

    const ParticleLimit = arrayParticleLimit.find((items) => items !== null)

    if (ParticleLimit) {
      setParticleLimit({
        id: ParticleLimit.id,
        limit: ParticleLimit.limit,
      })
    }

    //削除をしてレコードがなくなった場合の初期化処理
    if (!ParticleLimit) {
      setParticleLimit({
        id: null,
        limit: null,
      })
    }

    const arrayScheduleLimit = props.scheduleLimits?.map((item) => {
      if (
        item.start_day <= props.date &&
        props.date <= item.end_day &&
        item.order === props.orderNum
      ) {
        return item
      }
      return null
    })

    const schedule_limit = arrayScheduleLimit.find((item) => item !== null)

    if (schedule_limit) {
      setScheduleLimit(schedule_limit?.seat_limits)
    }

    //削除してレコードがなくなった場合の初期化処理
    if (!schedule_limit) {
      setScheduleLimit(null)
    }
  }, [props.particleLimits, props.scheduleLimits])

  return { particleLimit, scheduleLimit }
}
