import React from 'react'
import Modal from 'react-modal'
import { modalStyle_visitorManagement } from '../../../../style/modalStylev_visitorManagement'

interface SeatsInfo {
  visited_non_reserved: number
  visited_web: number
  not_visited_web: number
  visited_tel: number
  not_visited_tel: number
  total_visited_reserved_number: number
  total_not_visited_reserved_seats: number
  capacity: number
  seats_left: number
}

interface Props {
  isAlertModalOpen: boolean
  setIsAlertModalOpen: (e: boolean) => void
  setIsModalOpen: (e: boolean) => void
  seatsInfo: SeatsInfo
}
export const SoldOutModal = (props: Props) => {
  return (
    <Modal
      isOpen={props.isAlertModalOpen}
      onRequestClose={() => props.setIsAlertModalOpen(false)}
      style={modalStyle_visitorManagement}
      contentLabel="Example Modal"
      ariaHideApp={false}
    >
      <div className="w-full text-center pt-3 mb-3 text-2xl text-cinekoya-yellow-1 font-body font-bold">
        すでに定員数に達しています。
        <br />
        当日来客の追加に進みますか？
      </div>
      <div className="grid grid-cols-7  gap-3 bg-cinekoya bg-opacity-85 text-white  rounded-xl px-5 py-4 mb-5">
        <div className="col-span-3 text-right text-3xl">残席数</div>
        <div className="col-span-1  text-3xl text-center">
          <b> {props.seatsInfo.seats_left}</b>
          &nbsp;席
        </div>
        <div className="col-span-3 " />
        <div className="col-span-3 text-2xl text-right">（</div>
        <div className="col-span-1 text-2xl  text-right pr-3">
          {Number(props.seatsInfo.seats_left) +
            Number(props.seatsInfo.total_not_visited_reserved_seats)}
          席
        </div>
        <div className="col-span-3 text-2xl">
          <span className="text-base">※未来店の予約者を入れた場合</span>
          &nbsp;）
        </div>
      </div>
      <div className="w-full text-center mt-3 text-white font-body">
        <button
          className="w-1/3 py-2  bg-zinc-700 hover:opacity-85 text-white text-lg rounded-xl"
          onClick={() => {
            props.setIsAlertModalOpen(false)
            props.setIsModalOpen(true)
          }}
        >
          進む
        </button>
      </div>
      <div className="w-full text-center mt-3 text-white font-body">
        <button
          className="w-1/3 py-2 bg-cinekoya hover:opacity-85 text-white text-lg rounded-xl"
          onClick={() => props.setIsAlertModalOpen(false)}
        >
          キャンセル
        </button>
      </div>
    </Modal>
  )
}
