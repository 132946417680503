import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates'
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { ReactNode } from 'react'

interface Props {
  children: ReactNode
}
export const Notification = (props: Props) => {
  const location = useLocation()
  const path = location.pathname

  return (
    <>
      <div className="flex flex-row justify-center text-white mt-2">
        <div className="basis-1/6 pl-2 text-2xl font-body font-bold pb-2">
          お知らせ
        </div>
        <div className="basis-5/6 text-right pr-2">
          <div className="inline-block  bg-cinekoya-sub-c rounded-xl py-1 px-4 text-left shadow-lg">
            <TipsAndUpdatesIcon />
            &nbsp;
            {path === '/dashboard/notification_event' &&
              '基準日以降に予定されている映画・イベントの詳細ページに表示するお知らせを作成・編集できます。'}
            {path === '/dashboard/notification_top' &&
              'ホームページのトップに表示するお知らせを作成・編集できます。'}
          </div>
        </div>
      </div>
      <div className="basis-4/6 text-center text-lg text-white font-body mt-5 mb-3 ">
        <Link
          className={`inline-block w-1/4 rounded-l-3xl px-3 py-1 hover:bg-opacity-75 hover:text-white hover:no-underline ${
            path === '/dashboard/notification_event'
              ? 'bg-cinekoya-sub-c'
              : 'bg-yt-atom'
          }`}
          to={'/dashboard/notification_event'}
        >
          作品ページ
        </Link>
        <Link
          className={`inline-block w-1/4 rounded-r-3xl px-3 py-1 hover:bg-opacity-75 ml-2 hover:text-white hover:no-underline ${
            path === '/dashboard/notification_top'
              ? 'bg-cinekoya-sub-c'
              : 'bg-yt-atom'
          }`}
          to={'/dashboard/notification_top'}
        >
          トップページ
        </Link>
      </div>
      {props.children}
    </>
  )
}
