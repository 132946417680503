import React, { ReactNode } from 'react'

interface Props {
  areaStyle: string
  buttonStyle: string
  openModal: () => void
  totalVisited: number
  totalReservedSeats: number
  children: ReactNode
  borderColor: string
  buttonColor: string
}
export const ReservationKindsBlock = (props: Props) => {
  return (
    <div className={`${props.areaStyle} ${props.borderColor}`}>
      <div className="flex flex-col font-body text-white">
        <div>
          <button
            className={`${props.buttonStyle} ${props.buttonColor}`}
            onClick={props.openModal}
          >
            {props.children}
          </button>
        </div>
        <div className=" mt-3">
          現在の来店合計：{props.totalVisited}&nbsp;
          <span className="text-yt-text-gray">
            /&nbsp;
            {props.totalReservedSeats}
          </span>
        </div>
      </div>
    </div>
  )
}
