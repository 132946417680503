import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Reservation } from '../../../../types/Reservation/Reservation'
import { CountTelReservedVisitor } from '../Presentational/CountTelReservedVisitor'

interface TicketType {
  id: number
  name: string
  unit_price: number
}

interface MovieInfo {
  movie_id: number
  is_cinema: boolean
  title: string
}

interface Props {
  areaStyle: string
  buttonStyle: string
  date: string
  order: number
  movieInfo: MovieInfo
  getSeatsInfo: () => void
}
export const CountTelReservedVisitorContainer = (props: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isEditableVisitedNum, setIsEditableVIsitedNum] =
    useState<boolean>(false)

  const [totalReservedSeats, setTotalReservedSeats] = useState<number>(0)

  const [totalVisited, setTotalVisited] = useState<number>(0)
  const [visitedReservations, setVisitedReservations] = useState<Reservation[]>(
    []
  )
  const [notVisitedReservations, setNotVisitedReservations] = useState<
    Reservation[]
  >([])

  const [visitedNum, setVisitedNum] = useState<number>(0)

  const [selectedReservationId, setSelectedReservationId] = useState<
    number | null
  >(null)

  const [ticketTypes, setTicketTypes] = useState<TicketType[]>([])
  const [soldTicketTypes, setSoldTicketTypes] = useState<TicketType[]>([])
  const [isTicketTypeEmpty, setIsTicketTypeEmpty] = useState<boolean>(false)

  useEffect(() => {
    getSeats()
  }, [props.date, props.order])

  const getSeats = () => {
    axios
      .get('get_reserved_visitor_tel', {
        params: {
          date: props.date,
          order: props.order,
        },
      })
      .then((response) => {
        setTotalReservedSeats(response.data.total_reserved_seats)
        setTotalVisited(response.data.total_visited_number)
        setVisitedReservations(response.data.visited_reservations)
        setNotVisitedReservations(response.data.not_visited_reservations)
      })
  }

  useEffect(() => {
    axios.get('ticket_types').then((response) => {
      setTicketTypes(response.data)
    })
  }, [])

  const registerHasVisited = async () => {
    setIsLoading(true)

    if (
      props.movieInfo.is_cinema &&
      soldTicketTypes.find((item) => item.name === '券種を選択')
    ) {
      setIsTicketTypeEmpty(true)
      setIsLoading(false)
      return
    }

    const formData = new FormData()

    formData.append('reservation_id', String(selectedReservationId))
    formData.append('visited_number', String(visitedNum))
    formData.append('sold_ticket_types', JSON.stringify(soldTicketTypes))

    const response = await axios.post('register_has_visited_tel', formData)
    if (props.movieInfo.is_cinema) {
      formData.append('date', props.date)
      formData.append('order', String(props.order))
      formData.append('movie_id', String(props.movieInfo.movie_id))
      formData.append('management_id', response.data)

      await axios.post('register_sales_tel', formData)
    }

    setIsLoading(false)
    setSelectedReservationId(null)
    getSeats()
    props.getSeatsInfo()
  }

  const updateVisitedNum = async () => {
    setIsLoading(true)

    if (
      props.movieInfo.is_cinema &&
      soldTicketTypes.find((item) => item.name === '券種を選択')
    ) {
      setIsTicketTypeEmpty(true)
      setIsLoading(false)
      return
    }

    const formData = new FormData()

    formData.append('reservation_id', String(selectedReservationId))
    formData.append('visited_number', String(visitedNum))
    formData.append('sold_ticket_types', JSON.stringify(soldTicketTypes))

    const response = await axios.post('update_visited_number_tel', formData)

    if (props.movieInfo.is_cinema) {
      formData.append('date', props.date)
      formData.append('order', String(props.order))
      formData.append('movie_id', String(props.movieInfo.movie_id))
      formData.append('management_id', response.data)

      await axios.post('update_sales_tel', formData)
    }

    setIsLoading(false)
    setSelectedReservationId(null)
    getSeats()
    setIsEditableVIsitedNum(false)
    props.getSeatsInfo()
  }

  const cancelHasVisited = () => {
    setIsLoading(true)
    axios
      .put('cancel_has_visited_tel', {
        reservation_id: selectedReservationId,
      })
      .then((response) => {
        const management_id = response.data
        axios
          .post('delete_sales_tel', {
            management_id: management_id,
          })
          .then(() => {
            setIsLoading(false)
            setSelectedReservationId(null)
            getSeats()
            setIsEditableVIsitedNum(false)
            props.getSeatsInfo()
          })
      })
  }

  return (
    <>
      <CountTelReservedVisitor
        areaStyle={props.areaStyle}
        buttonStyle={props.buttonStyle}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        totalReservedSeats={totalReservedSeats}
        totalVisited={totalVisited}
        visitedReservations={visitedReservations}
        notVisitedReservations={notVisitedReservations}
        selectedReservationId={selectedReservationId}
        setSelectedReservationId={setSelectedReservationId}
        visitedNum={visitedNum}
        setVisitedNum={setVisitedNum}
        registerHasVisited={registerHasVisited}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        isEditableVisitedNum={isEditableVisitedNum}
        setIsEditableVisitedNum={setIsEditableVIsitedNum}
        updateVisitedNum={updateVisitedNum}
        cancelHasVisited={cancelHasVisited}
        ticketTypes={ticketTypes}
        soldTicketTypes={soldTicketTypes}
        setSoldTicketTypes={setSoldTicketTypes}
        isTicketTypeEmpty={isTicketTypeEmpty}
        setIsTicketTypeEmpty={setIsTicketTypeEmpty}
        movieInfo={props.movieInfo}
        date={props.date}
        order={props.order}
        getSeats={getSeats}
        getSeatsInfo={props.getSeatsInfo}
      />
    </>
  )
}
