import React from 'react'

export const Fanclub = () => {
  return (
    <div className="pb-5">
      <div className="title-red-2 text-center font-hp">
        <div>シネコヤ・ファンクラブ</div>
      </div>
      <div className="text-cinekoya  font-hp pt-1 pb-3 px-1">
        手づくりの小冊子が毎月届く！シネコヤオリジナル「月刊シネコヤ（小冊子）」{' '}
      </div>
      <div className="text-lg text-center">
        <a
          className="monthly-btn tri-red text-center font-hp "
          href="http://cinekoya.com/event/6873/"
        >
          ファンクラブ
          <br />
          ご案内はこちら
        </a>
      </div>
    </div>
  )
}
