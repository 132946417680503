import React, { useEffect, useState } from 'react'

interface Props {
  orderNum: number
  selectedOrder: number
}

export const useSelectOrder = (props: Props) => {
  const [orderSelectType, setOrderSelectType] = useState<string>('')

  useEffect(() => {
    //classNameのbg分岐用 コマ順序の選択状況
    if (props.orderNum === props.selectedOrder) {
      setOrderSelectType('selected')
    } else if (props.selectedOrder !== 0) {
      setOrderSelectType('unselected')
    } else if (props.selectedOrder === 0) {
      setOrderSelectType('default')
    }
  }, [props])

  return orderSelectType
}
