import React, { ChangeEvent, useContext, useEffect, useState } from 'react'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import { Radio } from '@mui/material'
import { PostParamsContext } from '../../../../hooks/postParamsContext'
import { Post } from '../../../../types/Post.'
import { useSuggestAlternativeName } from '../../../../hooks/Create/useSuggestAlternativeName'

interface Props {
  schedules: Post[]
}

export const AlternativeName = (props: Props) => {
  const { postParams, setPostParams } = useContext(PostParamsContext)

  const [hasAlternativeName, setHasAlternativeName] = useState<boolean>(
    postParams.alternative_name !== null ? true : false
  )

  const [radioValue, setRadioValue] = useState<string>(
    postParams.alternative_name !== null ? 'create' : 'default'
  )

  const [isExpandSuggest, setIsExpandSuggest] = useState<boolean>(false)
  const [searchResults, setSearchResults] = useState<Post[]>([])

  const suggestAlternativeName = useSuggestAlternativeName({
    schedules: props.schedules,
  })

  const radioAlternativeName = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === 'create') {
      setHasAlternativeName(true)
      setRadioValue('create')
    }
    if (e.target.value === 'default') {
      setHasAlternativeName(false)
      setRadioValue('default')
      if (postParams.alternative_name !== null) {
        setPostParams(postParams)
      } else {
        setPostParams({ ...postParams, alternative_name: null })
      }

      setIsExpandSuggest(false)
    }
  }

  const searchHandler = (value: string) => {
    const search_result = suggestAlternativeName.filter((item) => {
      if (item.alternative_name?.includes(value)) {
        return item
      }
    })

    setSearchResults(search_result)
  }

  return (
    <div className="mb-5">
      <div className="font-body text-lg text-cinekoya-yellow-2">
        表示タイトル
      </div>
      <FormControl>
        <RadioGroup
          row
          aria-labelledby="demo-radio-buttons-group-label"
          value={radioValue}
          name="demo-radio-buttons-group"
          onChange={radioAlternativeName}
        >
          <FormControlLabel
            value={'default'}
            control={<Radio />}
            label="作品名を表示"
          />
          <FormControlLabel
            value={'create'}
            control={<Radio />}
            label="別名を表示"
          />
        </RadioGroup>
      </FormControl>

      {hasAlternativeName && (
        <div className="mb-3">
          <div>
            <input
              className="w-full text-black border-solid  border-1 border-yt-atom focus:outline-none focus:ring-2 focus:ring-yt-atom-hover focus:border-transparent"
              value={postParams.alternative_name?.toString()}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                searchHandler(e.target.value)
                setPostParams({
                  ...postParams,
                  alternative_name: e.target.value,
                })
              }}
              onClick={() => setIsExpandSuggest(true)}
            />
          </div>

          <div className="w-full h-0 overflow-visible ">
            {isExpandSuggest && (
              <label className="w-full h-24 overflow-scroll bg-white  text-black rounded-b-lg mt-1 border-x-2 border-b-2 border-yt-atom z-[100] relative">
                {searchResults.map((item, index) => (
                  <div
                    key={index}
                    className="cursor-pointer hover:bg-cinekoya-sub-c hover:text-white"
                    onClick={() => {
                      setPostParams({
                        ...postParams,
                        alternative_name: item.alternative_name,
                      })
                      setIsExpandSuggest(false)
                    }}
                  >
                    {item.alternative_name}
                  </div>
                ))}
              </label>
            )}
          </div>
        </div>
      )}
    </div>
  )
}
