import TextField from '@mui/material/TextField'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import axios from 'axios'
import { Dayjs } from 'dayjs'
import { useContext, useState } from 'react'
import { PostParamsContext } from '../../../../hooks/postParamsContext'
import { Post } from '../../../../types/Post.'
import { Modal_AlertDuplicated } from './Modal_AlertDuplicated'

interface Props {
  selectedOrder: number
  selectedCinemaSchedule: Post | null | undefined
}

export const InputEndDay = (props: Props) => {
  const { postParams, setPostParams } = useContext(PostParamsContext)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [openDatePicker, setOpenDatePicker] = useState<boolean>(false)

  const validateEndday = async (endday: Dayjs | null) => {
    await axios
      .post('validate_endday', {
        start_day: postParams.start_day,
        end_day: endday?.format('YYYY-MM-DD'),
        order: props.selectedOrder,
      })
      .then((response) => {
        const isDuplicated = response.data
        if (isDuplicated && !props.selectedCinemaSchedule) {
          setPostParams(postParams)
          setOpenDatePicker(false)
          setModalIsOpen(true)
          return
        }
        if (isDuplicated && props.selectedCinemaSchedule) {
          setPostParams({
            ...postParams,
            end_day: props.selectedCinemaSchedule.end_day,
          })
          setOpenDatePicker(false)
          setModalIsOpen(true)
          return
        }

        setPostParams({
          ...postParams,
          end_day: String(endday?.format('YYYY-MM-DD')),
        })
      })
  }

  const openfunc = () => {
    setOpenDatePicker(true)
  }

  const closeModal = () => {
    setModalIsOpen(false)
    setOpenDatePicker(true)
  }

  return (
    <>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        sx={{
          width: 500,
        }}
      >
        <MobileDatePicker
          label="終了日"
          inputFormat="MM/DD"
          value={postParams.end_day}
          onOpen={openfunc}
          onClose={() => setOpenDatePicker(false)}
          open={openDatePicker}
          onChange={validateEndday}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{
                input: { width: '100px', background: '#282828' },
              }}
            />
          )}
        />
      </LocalizationProvider>

      <Modal_AlertDuplicated
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        targetText="終了日"
      />
    </>
  )
}
