import React from 'react'
import { DashboardTop } from '../Templates/DashboardTop'
import { EventDisplayOptionContainer } from '../Organism/EventDisplayOption/Container/EventDisplayOption.container'

export const EventDisplayOptionPage = () => {
  return (
    <DashboardTop>
      <EventDisplayOptionContainer />
    </DashboardTop>
  )
}
