import axios from 'axios'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { VisitorManagement } from '../Organism/VisitorManagement/Presentational/VisitorManagement'

import { DashboardTop } from '../Templates/DashboardTop'
import { CinemaOnly } from '../../types/CInemaOnly'

interface SeatsInfo {
  visited_non_reserved: number
  visited_web: number
  not_visited_web: number
  visited_tel: number
  not_visited_tel: number
  total_visited_reserved_number: number
  total_not_visited_reserved_seats: number
  capacity: number
  seats_left: number
}

interface MovieInfo {
  movie_id: number
  is_cinema: boolean
  title: string
}

export const VisitorManagementPage = () => {
  const today = dayjs()
  const [date, setDate] = useState<string>(today.format('YYYY-MM-DD'))
  const [order, setOrder] = useState<number>(1)
  const [movieInfo, setMovieInfo] = useState<MovieInfo>({
    movie_id: -1,
    is_cinema: true,
    title: '',
  })

  const [cinemaList, setCinemaList] = useState<CinemaOnly[]>([])

  const [isLoadingSeatsInfo, setIsLoadingSeatsInfo] = useState<boolean>(false)
  const [seatsInfo, setSeatsInfo] = useState<SeatsInfo>({
    visited_non_reserved: 0,
    visited_web: 0,
    not_visited_web: 0,
    visited_tel: 0,
    not_visited_tel: 0,
    total_visited_reserved_number: 0,
    total_not_visited_reserved_seats: 0,
    capacity: 0,
    seats_left: 1, //初期画面で残席0以下の警告赤画面がちらつくので0とはせず1にしておく
  })

  const getInitialList = () => {
    setIsLoadingSeatsInfo(true)
    if (date === null) {
      return
    }

    axios
      .get('get_cinema_list', {
        params: {
          date: date,
        },
      })
      .then((response) => {
        setCinemaList(response.data.cinema_list)
        setOrder(response.data.initial_order)
        setMovieInfo({
          movie_id: response.data.initial_movie_id,
          is_cinema: response.data.initial_is_cinema,
          title: response.data.initial_title,
        })
        setIsLoadingSeatsInfo(false)
      })
  }

  const getSeatsInfo = async () => {
    setIsLoadingSeatsInfo(true)
    const response = await axios.get('get_seats_info', {
      params: {
        date: date,
        order: order,
      },
    })

    setSeatsInfo(response.data)
    setIsLoadingSeatsInfo(false)
  }

  useEffect(() => {
    getInitialList()
  }, [date])

  useEffect(() => {
    getSeatsInfo()
  }, [date, order])

  return (
    <DashboardTop>
      <VisitorManagement
        date={date}
        setDate={setDate}
        order={order}
        setOrder={setOrder}
        movieInfo={movieInfo}
        setMovieInfo={setMovieInfo}
        cinemaList={cinemaList}
        getInitialList={getInitialList}
        seatsInfo={seatsInfo}
        getSeatsInfo={getSeatsInfo}
        isLoadingSeatsInfo={isLoadingSeatsInfo}
      />
    </DashboardTop>
  )
}
