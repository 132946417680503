import axios from 'axios'
import React, { useContext, useState } from 'react'
import Modal from 'react-modal'
import { PostParamsContext } from '../../../../hooks/postParamsContext'
import { ImageFile } from '../../../../types/ImageFile'
import { Post } from '../../../../types/Post.'
import { SaveButton } from '../../../Atoms/SaveButton'

interface Props {
  imageFile: ImageFile
  saveDisabled: boolean
  closeModal: () => void
  getLatest: () => void
  cntReservation: number
  selectedCinemaSchedule: Post | null
}

export const UpdateSchedule = (props: Props) => {
  const { postParams } = useContext(PostParamsContext)
  const customStyles = {
    content: {
      top: '40%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '100%',
      maxWidth: '400px',
      height: '20vh',
      border: '1px solid #3090A0 ',
      borderRadius: '1%',
      background: 'rgba(28,28,28,0.7)',
      color: 'white',
      fontFamily: 'Roboto, sans-serif',
    },
    overlay: {
      top: 0,
      left: 0,
      backgroundColor: 'rgba(0,0,0,0.85)',
    },
  }

  const [modalIsOpen, setModalIsOpen] = useState(false)

  const openModal = () => {
    setModalIsOpen(true)
  }
  const closeModal = () => {
    setModalIsOpen(false)
  }

  const beforeUpdateSchedule = async (isPublic: boolean) => {
    await axios
      .post('count_reservation', {
        schedule: JSON.stringify(props.selectedCinemaSchedule),
      })
      .then((response) => {
        const cntReservations = response.data.cnt_reservation
        cntReservations > 0 && openModal()
        cntReservations === 0 && updateSchedule(isPublic)
      })
  }

  const updateSchedule = (isPublic: boolean) => {
    // const detailEscaped = postParams.detail
    //   ?.replace(/&/g, '&lt;')
    //   .replace(/</g, '&lt;')
    //   .replace(/>/g, '&gt;')
    //   .replace(/"/g, '&quot;')
    //   .replace(/'/g, '&#x27;')

    new Promise((resolve) => {
      const params = {
        ...postParams,
        display_public: isPublic,
      }

      const formData = new FormData()
      if (props.imageFile.file !== null) {
        const image: File = props.imageFile.file

        formData.append('image', image, props.imageFile.fileName)
        //第3引数はファイル名。ここで画像ファイル名を指定しないと、すべて”Blob”という名前で保存される。拡張子も何もないので開くこともできない。また同じ名前なので延々と上書きされる。
      }
      const paramsJson = JSON.stringify(params)
      formData.append('params', paramsJson)

      resolve(formData)
    }).then((formData) => {
      const config = { headers: { 'content-type': 'multipart/form-data' } }
      axios
        .post('update_schedule', formData, config)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .then((response) => {
          props.closeModal()
          props.getLatest()
        })
    })
  }

  return (
    <>
      <SaveButton
        saveDisabled={props.saveDisabled}
        onClick={() => updateSchedule(true)}
        isPublic={true}
      >
        更新
      </SaveButton>

      <SaveButton
        saveDisabled={props.saveDisabled}
        onClick={() => beforeUpdateSchedule(false)}
        isPublic={false}
      >
        非公開で更新
      </SaveButton>

      <Modal
        isOpen={modalIsOpen}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
        onRequestClose={closeModal}
      >
        <div className="text-lg  mb-4">
          この作品には本日以降すでに予約が入っています。非公開にしますか？
        </div>
        <div>
          <SaveButton
            saveDisabled={props.saveDisabled}
            onClick={() => updateSchedule(false)}
            isPublic={false}
          >
            非公開にする
          </SaveButton>
        </div>
      </Modal>
    </>
  )
}
