import React, { ReactNode } from 'react'

interface Props {
  onClick: () => void
  children: ReactNode
}
export const PrevStepButton = (props: Props) => {
  return (
    <button
      className="h-12 w-5/6 sm:w-96  rounded-2xl shadow-sm bg-yt-atom hover:bg-yt-atom-hover text-white font-bold text-xl"
      onClick={props.onClick}
    >
      {props.children}
    </button>
  )
}
