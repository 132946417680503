import React, { useEffect, useState } from 'react'
import { CampaignPageCreator } from '../Presentational/CampaignPageCreator'
import axios from 'axios'
import dayjs from 'dayjs'
import { CampaignParams } from '../../../../types/CampaignPrams'
import { MainImageCandidates } from '../../../../types/MainImageCandidates'

export const CampaignPageCreatorContainer = () => {
  const [baseDate, setBaseDate] = React.useState<string>(
    dayjs().format('YYYY-MM-DD')
  )
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false)

  const [isEditmode, setIsEditmode] = useState<boolean>(false)
  const [saveDisabled, setSaveDisabled] = useState<boolean>(false)
  const [mainImageCandidates, setMainImageCandidates] = useState<
    MainImageCandidates[]
  >([])
  const defaultObject: CampaignParams = {
    id: -1,
    title: '',
    start_date: '',
    end_date: '',
    content: '',
    display_movie: false,
    display_event: true,
    display_public: true,
    main_image: '',
  }
  const [campaigns, setCampaigns] = useState<CampaignParams[]>([])

  const [postParams, setPostParams] = useState<CampaignParams>(defaultObject)

  useEffect(() => {
    getCampaigns()
  }, [baseDate])

  useEffect(() => {
    if (postParams.id !== -1) {
      getMainImageCandidates()
    }
  }, [postParams.id])

  useEffect(() => {
    //blank check
    if (
      postParams.title === '' ||
      postParams.start_date === '' ||
      postParams.end_date === '' ||
      postParams.content === ''
    ) {
      setSaveDisabled(true)
    } else {
      setSaveDisabled(false)
    }

    //previewのためにローカルストレージに保存
    localStorage.setItem('preview_campaign_params', JSON.stringify(postParams))
  }, [postParams])

  const handleEditorChange = (content: string) => {
    setPostParams({ ...postParams, content: content })
  }

  const getMainImageCandidates = async () => {
    const response = await axios.get('campaign_main_image_candidates', {
      params: { campaign_id: postParams.id },
    })

    setMainImageCandidates(response.data)
  }

  const getCampaigns = () => {
    axios
      .get('get_campaign_list', {
        params: {
          base_date: baseDate,
        },
      })
      .then((response) => {
        setCampaigns(response.data)
        setPostParams(defaultObject)
      })
  }

  const createCampaign = (isPublic: boolean) => {
    const params = {
      ...postParams,
      display_public: isPublic,
    }

    const paramsJson = JSON.stringify(params)
    const formData = new FormData()
    formData.append('params', paramsJson)
    axios.post('create_campaign', formData).then(() => {
      closeModal()
      getCampaigns()
    })
  }

  const updateCampaign = (isPublic: boolean) => {
    const params = {
      ...postParams,
      display_public: isPublic,
    }

    const paramsJson = JSON.stringify(params)
    const formData = new FormData()
    formData.append('params', paramsJson)
    axios.post('update_campaign', formData).then(() => {
      closeModal()
      getCampaigns()
    })
  }

  const deleteCampaign = () => {
    const paramsJson = JSON.stringify(postParams)
    const formData = new FormData()
    formData.append('params', paramsJson)
    axios.post('delete_campaign', formData).then(() => {
      closeModal()
      getCampaigns()
    })
  }

  const deleteUnuseImages = () => {
    axios.delete('delete_unused_images').then(() => {
      setMainImageCandidates([])
    })
  }

  const closeModal = () => {
    setIsModalOpen(false)
    setSaveDisabled(true)
    setPostParams(defaultObject)
    setIsEditmode(false)
    deleteUnuseImages()

    //previewのためにローカルストレージも削除
    localStorage.removeItem('preview_campaign_params')
  }

  return (
    <>
      <CampaignPageCreator
        baseDate={baseDate}
        setBaseDate={setBaseDate}
        createCampaign={createCampaign}
        updateCampaign={updateCampaign}
        deleteCampaign={deleteCampaign}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        postParams={postParams}
        setPostParams={setPostParams}
        handleEditorChange={handleEditorChange}
        getMainImageCandidates={getMainImageCandidates}
        saveDisabled={saveDisabled}
        campaigns={campaigns}
        isEditmode={isEditmode}
        setIsEditmode={setIsEditmode}
        mainImageCandidates={mainImageCandidates}
        setMainImageCandidates={setMainImageCandidates}
        closeModal={closeModal}
      />
    </>
  )
}
