import React, { ChangeEvent, useContext } from 'react'
import { Movie } from '../../../../types/Movie'
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates'
import { UrlContext } from '../../../../hooks/UrlContext'
import EditIcon from '@mui/icons-material/Edit'
import CloseIcon from '@mui/icons-material/Close'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import SaveIcon from '@mui/icons-material/Save'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import { ThreeDots } from 'react-loader-spinner'
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded'
interface Props {
  nowShowingMovies: Movie[]
  commingSoonMovies: Movie[]
  forcedNowShowingMovies: Movie[]
  pastDisplayPeriod: number
  newPeriod: number
  setNewPeriod: (e: number) => void
  updatePastDisplayPeriod: () => void
  deleteForcedNowShowing: (e: number) => void
  createForcedNowShowing: (e: number) => void
  isEditMode: boolean
  setIsEditMode: (e: boolean) => void
  isLoading: boolean
}

export const EventDisplayOption = (props: Props) => {
  const { imageURL } = useContext(UrlContext)
  const [isDescriptionOpen, setIsDescriptionOpen] =
    React.useState<boolean>(false)

  return (
    <>
      <div className="flex flex-row justify-center text-white mt-2">
        <div className="basis-1/6 pl-2 text-2xl font-body font-bold pb-2">
          トップ表示
        </div>
        <div className="basis-5/6 text-right pr-2">
          <div className="inline-block w-3/4 bg-cinekoya-sub-c rounded-xl py-1 px-4 text-left shadow-lg">
            <TipsAndUpdatesIcon />
            &nbsp;
            トップページの「上映中」「近日公開」の表示の仕方を設定できます。
          </div>
        </div>
      </div>

      <div className="flex flex-row justify-end mt-3   ">
        <div className="basis-3/5 px-3 py-1 pb-2 border-2 border-yt-atom bc-yt-component rounded-xl mr-2 text-white font-body">
          <div className="flex flex-row justify-end  mb-1 h-7">
            <div className="basis-3/6 py-1">
              <span className=" font-bold text-yt-text-gray">
                「上映中」に含めるインターバル日数：&nbsp;
              </span>
            </div>
            <div className="basis-2/6 text-center">
              {props.isEditMode && (
                <input
                  className=" w-[55px] text-black py-1 px-1 text-lg text-center"
                  type="number"
                  value={props.newPeriod}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    props.setNewPeriod(Number(e.target.value))
                  }
                />
              )}
              {!props.isEditMode && (
                <div className="w-[55px] inline-block py-1 px-1 text-center text-xl text-cinekoya-sub-a font-bold">
                  {props.pastDisplayPeriod}
                </div>
              )}
              <span> 日&nbsp;</span>
              {props.isEditMode && (
                <div className="inline-block pb-2  text-yt-atom-hover">
                  <Tooltip title="更新する">
                    <IconButton>
                      <SaveIcon
                        onClick={props.updatePastDisplayPeriod}
                        sx={{ color: '#00B8CC' }}
                      />
                    </IconButton>
                  </Tooltip>
                  <CloseIcon
                    onClick={() => props.setIsEditMode(false)}
                    sx={{ cursor: 'pointer' }}
                  />
                </div>
              )}
              {!props.isEditMode && (
                <div className="inline-block text-cinekoya-sub-a">
                  <Tooltip title="変更">
                    <IconButton>
                      <EditIcon
                        onClick={() => props.setIsEditMode(true)}
                        sx={{ cursor: 'pointer', color: '#00B8CC' }}
                      />
                    </IconButton>
                  </Tooltip>
                </div>
              )}
            </div>
            <div className="basis-1/6 text-right pr-2 py-1 text-yt-text-gray">
              {!isDescriptionOpen && (
                <HelpOutlineIcon
                  sx={{ cursor: 'pointer' }}
                  onClick={() => setIsDescriptionOpen(!isDescriptionOpen)}
                />
              )}

              {isDescriptionOpen && (
                <ExpandLessRoundedIcon
                  sx={{ cursor: 'pointer' }}
                  onClick={() => setIsDescriptionOpen(!isDescriptionOpen)}
                />
              )}
            </div>
          </div>
          {isDescriptionOpen && (
            <div className=" rounded-xl px-2 py-2 text-sm border-2 border-cinekoya-sub-c text-yt-text-gray">
              この日数は、作品を期間を空けて再上映する予定のとき、それを「上映中」とするか「近日上映」とするかを判定するための日数です。前回の上映終了日を含むようにインターバル日数を設定すると、再上映の上映開始日が明日以降だとしても「上映中」と判定されます。逆に含めないように設定すると「近日上映」と判定されます。
            </div>
          )}
        </div>
      </div>
      <div className="font-body font-bold text-white text-xl mt-1 pl-2">
        上映中（自動）
      </div>
      <div className="grid grid-cols-10  gap-3 px-3 py-2 border-2 border-yt-atom bc-yt-component rounded-xl mr-2 text-white font-body">
        {props.nowShowingMovies.map((movie) => (
          <div key={movie.id} className="col-span-1 ">
            <div>
              <a href={`/cinema/${movie.id}`} target="_blank" rel="noreferrer">
                <img
                  src={imageURL + movie.image}
                  className="w-32 h-32 object-cover rounded-lg hover:opacity-80 cursor-pointer"
                />
              </a>
            </div>
            <div className="text-yt-text-gray font-title text-sm">
              {movie.title.substring(0, 12)}
              {movie.title.length > 12 ? '…' : null}
            </div>
          </div>
        ))}
      </div>

      <div className="flex flex-row font-body text-white mt-3 pl-2 text-xl  font-bold">
        上映中<span className="text-cinekoya-yellow-2 ">（強制）</span>
      </div>
      <div className="grid grid-cols-10 gap-4 px-3 py-2 border-2 border-yt-atom bc-yt-component rounded-xl mr-2">
        {props.isLoading && (
          <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#E4F551"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            visible={true}
          />
        )}
        {!props.isLoading && props.forcedNowShowingMovies.length === 0 && (
          <div className=" col-span-10 text-yt-text-gray text-lg">
            現在適用中の作品はありません
          </div>
        )}
        {!props.isLoading &&
          props.forcedNowShowingMovies.length > 0 &&
          props.forcedNowShowingMovies.map((movie) => (
            <div key={movie.id} className="col-span-1 ">
              <div>
                <a
                  href={`/cinema/${movie.id}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={imageURL + movie.image}
                    className="w-32 h-32 object-cover rounded-lg  hover:opacity-80 cursor-pointer"
                  />
                </a>
              </div>
              <div className="text-yt-text-gray font-title text-sm mb-1  h-12">
                {movie.title.substring(0, 12)}
                {movie.title.length > 12 ? '…' : null}
              </div>
              <div>
                <button
                  className="w-full bg-cinekoya-sub-c hover:bg-cinekoya-sub-a text-white py-1 rounded-2xl border-1 text-sm cursor-pointer font-body"
                  onClick={() => props.deleteForcedNowShowing(movie.id)}
                >
                  戻す
                </button>
              </div>
            </div>
          ))}
        <div className="col-span-10">
          <div className="w-full bg-yt-component text-yt-text-gray rounded-xl py-2 px-2 text-sm font-body">
            上映日を迎えると自動的に「上映日（自動）」に移ります。
          </div>
        </div>
      </div>

      <div className="flex flex-row font-body text-white mt-3 pl-2 text-xl  font-bold">
        近日上映（自動）
      </div>

      <div className="grid grid-cols-10 gap-4 px-3 py-2 border-2 border-yt-atom bc-yt-component rounded-xl mr-2">
        {props.isLoading && (
          <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#606060"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            visible={true}
          />
        )}
        {!props.isLoading &&
          props.commingSoonMovies.map((movie) => (
            <div key={movie.id} className="col-span-1 ">
              <div>
                <a
                  href={`/cinema/${movie.id}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={imageURL + movie.image}
                    className="w-32 h-32 object-cover rounded-lg  hover:opacity-80 cursor-pointer"
                  />
                </a>
              </div>
              <div className="text-yt-text-gray font-title text-sm h-12">
                {movie.title.substring(0, 12)}
                {movie.title.length > 12 ? '…' : null}
              </div>
              <div>
                <button
                  className="w-full bg-cinekoya-sub-c hover:bg-cinekoya-sub-a text-white py-1
                   rounded-2xl border-1 text-sm cursor-pointer font-body"
                  onClick={() => props.createForcedNowShowing(movie.id)}
                >
                  上映中へ
                </button>
              </div>
            </div>
          ))}
        <div className="col-span-10">
          <div className="w-full bg-yt-component text-yt-text-gray rounded-xl py-2 px-2 text-sm font-body">
            &nbsp;「その他の設定」の「スケジュール表示期間」で設定した期間までに新たに上映開始日を迎える作品が表示されます。つまり
            <a
              className="text-cinekoya-sub-a underline"
              href="/schedule"
              target="_balank"
            >
              スケジュールページ
            </a>
            と同じ範囲です。
          </div>
        </div>
      </div>
    </>
  )
}
