import React, { useEffect } from 'react'
import { EventTemplate } from '../Templates/EventTemplate'
import { StaticPageBody } from '../Organism/StaticPageBody/StaticPageBody'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { StaticPageParams } from '../../types/StaticPageParams'

export const StaticPage = () => {
  const { path_name } = useParams<{ path_name: string }>()
  const [staticPage, setStaticPage] = React.useState({} as StaticPageParams)

  useEffect(() => {
    getContent()
  }, [])

  const getContent = () => {
    axios
      .get('get_staticpage', {
        params: {
          path_name: path_name,
        },
      })
      .then((response) => {
        setStaticPage(response.data)
      })
  }
  return (
    <EventTemplate>
      {staticPage.display_public ? (
        <StaticPageBody staticPage={staticPage} />
      ) : (
        <div className="w-full mt-[200px]  mb-[200px] text-center text-xl font-body  font-bold text-cinekoya text-opacity-70">
          ページが存在しません。
        </div>
      )}
    </EventTemplate>
  )
}
