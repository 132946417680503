import React from 'react'
import twitterIcon from '../../assets/twitter_icon.svg'
import facebookIcon from '../../assets/facebook_icon.svg'
import instaIcon from '../../assets/insta_icon.svg'
import lineIcon from '../../assets/line_icon.svg'
import noteIcon from '../../assets/note_icon.svg'

export const FooterSnsIcon = () => {
  const style = 'mb-2 w-9 md:w-13 inline  mr-2'
  return (
    // <div className="md:fixed md:bottom-1 md:right-20">
    <div className="flex justify-center mb-5">
      <div className={`${style}`}>
        <a href="https://twitter.com/cinekoya" target="_blank" rel="noreferrer">
          <img src={twitterIcon} alt="twitter" />
        </a>
      </div>
      <div className={`${style}`}>
        <a
          href="https://www.facebook.com/cinekoya/"
          target="_blank"
          rel="noreferrer"
        >
          <img src={facebookIcon} alt="facebook" />
        </a>
      </div>
      <div className={`${style}`}>
        <a
          href="https://www.instagram.com/cinekoya/"
          target="_blank"
          rel="noreferrer"
        >
          <img src={instaIcon} alt="instagram" />
        </a>
      </div>
      <div className={`${style}`}>
        <a
          href="https://line.me/R/ti/p/%40efu5635f"
          target="_blank"
          rel="noreferrer"
        >
          <img src={lineIcon} alt="line" />
        </a>
      </div>
      <div className={`${style}`}>
        <a href="https://note.com/cinekoya" target="_blank" rel="noreferrer">
          <img src={noteIcon} alt="line" />
        </a>
      </div>
    </div>
    // </div>
  )
}
