import React from 'react'
import { Link } from 'react-router-dom'
import LogoSmall from '../../assets/logo_small.svg'
export const HomeHeader = () => {
  const linkButton =
    'col-span-1  rounded-md bg-cinekoya text-white site-font py-1  text-center hover:bg-cinekoya-sub-b cursor-pointer md:inline-block md:mr-1 md:min-w-[80px] md:px-3 md:text-[12px]'
  return (
    <header className="w-full mt-5">
      <div className="flex flex-col md:flex-row  items-center">
        <div className="w-full  md:basis-1/4 md:text-right">
          <img src={LogoSmall} width={'200px'} style={{ margin: 'auto' }} />
        </div>
        <div className="w-full md:basis-3/4">
          <div className="md:flex grid grid-cols-2 gap-2 text-sm px-3">
            <Link to="/">
              <div className={linkButton}>ホーム</div>
            </Link>
            <a href="https://cinekoya.com/about/">
              <div className={linkButton}>シネコヤの過ごし方</div>
            </a>
            <Link to="/schedule">
              <div className={linkButton}>スケジュール</div>
            </Link>
            <a href="https://cinekoya.com/support/">
              <div className={linkButton}>サポーター</div>
            </a>
            <a href="https://cinekoya.com/news/6873/">
              <div className={linkButton}>シネコヤの料金</div>
            </a>
            <a href="/#access">
              <div className={linkButton}>アクセス</div>
            </a>
          </div>
          <div></div>
        </div>
      </div>
    </header>
  )
}
