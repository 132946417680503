import React from 'react'

interface Props {
  label: string
  onClick: () => void
}
export const ButtonGray = (props: Props) => {
  return (
    <button
      className="bg-yt-atom py-2 px-4 rounded-xl border-1 hover:bg-yt-atom-hover"
      onClick={props.onClick}
    >
      {props.label}
    </button>
  )
}
