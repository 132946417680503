import { useEffect, useState } from 'react'

type Props = {
  file: File | null
}

export const ImagePreview = (props: Props) => {
  const [url, setUrl] = useState<string>('')
  const isLoading = props.file && !url

  useEffect(() => {
    if (!props.file) {
      return
    }

    let reader: FileReader | null = new FileReader()
    reader.onloadend = () => {
      const res = reader?.result
      if (res && typeof res === 'string') {
        setUrl(res)
      }
    }
    reader.readAsDataURL(props.file)

    return () => {
      reader = null
    }
  }, [props.file])

  return props.file ? (
    isLoading ? (
      <div>ない</div>
    ) : (
      <img
        src={url}
        alt={props.file.name}
        {...props}
        className="w-1/2 inline-block ring-4 ring-cinekoya-sub-c"
      />
    )
  ) : null
}
