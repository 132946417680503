import React from 'react'
import { CampaignParams } from '../../../types/CampaignPrams'

interface Props {
  campaign: CampaignParams
}
export const Campaign = (props: Props) => {
  return (
    <>
      <div className="grid grid-cols-0"></div>
      <div>
        <div className="flex flex-row justify-center mt-5">
          <div className="basis-4/5 title-white-red">
            {props.campaign.title}
          </div>
        </div>

        <div className="flex flex-row justify-center mb-5">
          <div
            className="basis-full sm:basis-4/5 px-2 sm:px-0 font-hp text-cinekoya"
            dangerouslySetInnerHTML={{ __html: props.campaign.content }}
          ></div>
        </div>
      </div>
    </>
  )
}
