import dayjs from 'dayjs'
import { useContext, useState } from 'react'
import axios from 'axios'
import { CellParticleLimit } from './CellParticleLimit'
import { PostParamsContext } from '../../../../hooks/postParamsContext'
import { Post } from '../../../../types/Post.'
import React from 'react'
import { SetCommonLimitContainer } from '../Container/SetCommonLimit.container'
import { Thead } from './Thead'
import { Modal_Create } from '../../Create/Presentational/Modal_Create'
import { TD } from './TD'
import { SelectBaseDate } from './SelectBaseDate'

interface ParticleLimits {
  id: number
  date: string
  order: number
  limit: number
}

interface ScheduleLimits {
  id: number
  start_day: string
  end_day: string
  order: number
  seat_limits: number
}

interface ArrayDate {
  date: string
  isClose: boolean
}

interface Image {
  file: File | null
  fileName: string
}

interface Props {
  commonLimit: number
  setCommonLimit: (e: number) => void
  particleLimits: ParticleLimits[]
  scheduleLimits: ScheduleLimits[]
  displaySetLimit: boolean
  setDisplaySetLimit: (e: boolean) => void
  isForcedLimit: boolean
  setIsForcedLimit: (e: boolean) => void
  arrayDateAttribute: ArrayDate[]
  arrayDate: string[]
  schedules: Post[]
  getLatest: () => void
  getReservationLimit: () => void
  baseDate: string
  setBaseDate: (e: string) => void
  movieList: Post[]
}

export const CreateTable = (props: Props) => {
  const { postParams, setPostParams } = useContext(PostParamsContext)
  const arrayOrderNum = [1, 2, 3, 4, 5, 6]

  const [firstClicked, setFirstClicked] = useState<boolean>(false)

  const [selectedOrder, setSelectedOrder] = useState<number>(0)
  const [selectedDate, setSelectedDate] = useState<string>('')
  const [selectedDateSecond, setSelectedDateSecond] = useState<string>('')
  const [onmouseDate, setOnmounseDate] = useState<string>('')

  const [isReflectMovieinfo, setIsReflectMovieinfo] = useState<boolean>(false)

  const [cntReservation, setCntReservation] = useState<number>(0)

  const [imageFile, setImageFile] = useState<Image>({
    file: null,
    fileName: '',
  })
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const defaultPostParams: Post = {
    id: -1, //これはschedulesのid
    cinema_id: -1,
    is_cinema: true,
    title: '',
    detail: '',
    event_price_1: 0,
    event_price_2: 0,
    event_price_3: 0,
    event_price_4: 0,
    ep_1_label: '',
    ep_2_label: '',
    ep_3_label: '',
    ep_4_label: '',
    image: '',
    trailer_url: '',
    order: 1,
    start_day: '',
    end_day: '',
    display_public: true,
    start_hour: '',
    start_minutes: '',
    end_hour: '',
    end_minutes: '',
    time_unsettled: false,
    non_cinema_reservable: false,
    seat_limits: null,
    alternative_name: null,
  }

  const [selectedCinemaSchedule, setSelectedCinemaSchedule] =
    useState<Post | null>(null)

  const isCloseDay = (targetDate: string) => {
    const foundDate = props.arrayDateAttribute.find(
      (dateObj) => dateObj.date === targetDate
    )

    // 対象日付が見つかった場合、その日付の isClose を返す
    if (foundDate) {
      return foundDate.isClose
    }
    return null
  }

  const firstClick = (date: string) => {
    if (isCloseDay(date)) {
      //休館日は選択できない
      setSelectedOrder(0)
      setSelectedDate('')
      return
    }

    setFirstClicked(true)
    if (!selectedCinemaSchedule) {
      setPostParams({
        ...postParams,
        start_day: date,
        order: selectedOrder,
      })
    }
  }

  const secondClick = async (date: string, matchedSchedule: Post | null) => {
    if (!matchedSchedule) {
      if (isCloseDay(date)) {
        //休館日は選択できない
        setSelectedDateSecond('')
        return
      }

      if (dayjs(postParams.start_day).isBefore(dayjs(date))) {
        setPostParams({
          ...postParams,
          end_day: date,
          order: selectedOrder,
        })
      } else {
        setPostParams({
          ...postParams,
          start_day: date,
          end_day: postParams.start_day,
          order: selectedOrder,
        })
      }
    }

    if (matchedSchedule) {
      setPostParams(matchedSchedule)
      setSelectedCinemaSchedule(matchedSchedule)
      const response = await axios.post('count_reservation', {
        schedule: JSON.stringify(matchedSchedule),
      })

      setCntReservation(response.data.cnt_reservation)
    }
    setModalIsOpen(true)
  }

  const closeModal = () => {
    setModalIsOpen(false)
    setFirstClicked(false)
    setPostParams(defaultPostParams)
    setImageFile({ file: null, fileName: '' })
    setSelectedOrder(0)
    setSelectedDate('')
    setSelectedDateSecond('')
    setSelectedCinemaSchedule(null)
    setIsReflectMovieinfo(false)
  }

  return (
    <>
      <div className="flex flex-row justify-center text-white mb-2 mt-2">
        <div className="basis-1/6 pl-2  text-2xl font-body font-bold">
          スケジュール
        </div>

        <div className="basis-4/6 text-center">
          {!props.displaySetLimit && (
            <SelectBaseDate
              baseDate={props.baseDate}
              setBaseDate={props.setBaseDate}
            />
          )}
          {props.displaySetLimit && (
            <SetCommonLimitContainer
              commonLimit={props.commonLimit}
              setCommonLimit={props.setCommonLimit}
              isForcedLimit={props.isForcedLimit}
              setIsForcedLimit={props.setIsForcedLimit}
            />
          )}
        </div>
        <div className="basis-1/6 text-right">
          <button
            className="w-full bg-yt-atom py-1 px-3 text-sm rounded-lg text-center text-white cursor-pointer border-1 hover:bg-yt-atom-hover"
            onClick={() => props.setDisplaySetLimit(!props.displaySetLimit)}
          >
            {props.displaySetLimit ? '予約上限を非表示' : '予約上限の設定'}
          </button>
        </div>
      </div>
      <div className=" w-full overflow-x-scroll text-white pr-5 ">
        {/* table-layoutを指定しないとtdが文字に合わせて伸びる */}
        <table className="w-[3700px] table-fixed scheduleTable">
          <Thead
            arrayDateAttribute={props.arrayDateAttribute}
            onmouseDate={onmouseDate}
          />
          <tbody>
            {arrayOrderNum.map((orderNum) => (
              <React.Fragment key={orderNum}>
                <tr className=" h-20">
                  {props.arrayDate.map((date, index) => (
                    <TD
                      key={index}
                      date={date}
                      orderNum={orderNum}
                      schedules={props.schedules}
                      selectedOrder={selectedOrder}
                      setSelectedOrder={setSelectedOrder}
                      selectedDate={selectedDate}
                      setSelectedDate={setSelectedDate}
                      selectedDateSecond={selectedDateSecond}
                      setSelectedDateSecond={setSelectedDateSecond}
                      selectedCinemaSchedule={selectedCinemaSchedule}
                      setSelectedCinemaSchedule={setSelectedCinemaSchedule}
                      defaultPostParams={defaultPostParams}
                      setOnmouseDate={setOnmounseDate}
                      firstClicked={firstClicked}
                      setFirstClicked={setFirstClicked}
                      firstClick={firstClick}
                      secondClick={secondClick}
                      baseDate={props.baseDate}
                    />
                  ))}
                </tr>
                <tr
                  className={`${
                    props.displaySetLimit ? ' visible' : 'hidden'
                  } `}
                >
                  {props.arrayDate.map((date) => (
                    <CellParticleLimit
                      key={date}
                      date={date}
                      orderNum={orderNum}
                      particleLimits={props.particleLimits}
                      scheduleLimits={props.scheduleLimits}
                      commonLimit={props.commonLimit}
                      isForcedLimit={props.isForcedLimit}
                      getReservationLimit={props.getReservationLimit}
                      setOnmouseDate={setOnmounseDate}
                      baseDate={props.baseDate}
                    />
                  ))}
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
      <Modal_Create
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        selectedCinemaSchedule={selectedCinemaSchedule}
        selectedOrder={selectedOrder}
        cntReservation={cntReservation}
        isReflectMovieinfo={isReflectMovieinfo}
        setIsReflectMovieinfo={setIsReflectMovieinfo}
        imageFile={imageFile}
        setImageFile={setImageFile}
        getLatest={props.getLatest}
        schedules={props.schedules}
        movieList={props.movieList}
      />
    </>
  )
}
