import React from 'react'
import EditIcon from '@mui/icons-material/Edit'
import { LogoutContainer } from '../Organism/Logout/Container/Logout.container'
import NotificationsIcon from '@mui/icons-material/Notifications'
import NotificationAddIcon from '@mui/icons-material/NotificationAdd'
import { Link } from 'react-router-dom'
import FactCheckIcon from '@mui/icons-material/FactCheck'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { MenuButton } from '../Atoms/MenuButton'
import SettingsIcon from '@mui/icons-material/Settings'
import EventRepeatIcon from '@mui/icons-material/EventRepeat'
import BarChartIcon from '@mui/icons-material/BarChart'
// import CampaignIcon from '@mui/icons-material/Campaign'
import PushPinIcon from '@mui/icons-material/PushPin'
interface Props {
  isMenuShrinked: boolean
}

const Horizon = () => {
  return (
    <div className="px-2 my-4">
      <hr className="bg-cinekoya-sub-c opacity-100" style={{ height: '1px' }} />
    </div>
  )
}

export const Menu = (props: Props) => {
  return (
    <>
      <Horizon />

      <div className="text-right px-1 mt-4 mb-3">
        <Link to={'/dashboard/visitor_management'}>
          <MenuButton label={'/dashboard/visitor_management'}>
            <FactCheckIcon />
            &nbsp;来客簿
          </MenuButton>
        </Link>
      </div>

      <Horizon />

      <div className="text-right px-1 mb-3">
        <Link to={'/dashboard/aggregete_sales'}>
          <MenuButton label={'/dashboard/aggregete_sales'}>
            <BarChartIcon />
            &nbsp;売上表
          </MenuButton>
        </Link>
      </div>

      <Horizon />

      <div className="text-center px-1 mb-2 mt-3">
        <Link to={'/dashboard'}>
          <MenuButton label={'/dashboard'}>
            <EditIcon />
            &nbsp;作品スケジュール
          </MenuButton>
        </Link>
      </div>

      <div className="text-left px-1 mb-4 mt-3">
        <Link to={'/dashboard/event_display_option'}>
          <MenuButton label={'/dashboard/event_display_option'}>
            <EventRepeatIcon />
            &nbsp;作品表示
          </MenuButton>
        </Link>
      </div>

      <div className="text-left px-1 mb-4 mt-3">
        <Link to={'/dashboard/notification'}>
          <MenuButton label={'/dashboard/notification_event'}>
            <NotificationsIcon />
            &nbsp;お知らせ
          </MenuButton>
        </Link>
      </div>

      <div className="text-left px-1 mb-4 mt-3">
        <Link to={'/dashboard/create_campaign'}>
          <MenuButton label={'/dashboard/campaign_creator'}>
            <NotificationAddIcon />
            &nbsp;キャンペーンページ
          </MenuButton>
        </Link>
      </div>

      <div className="text-left px-1 mb-4 mt-3">
        <Link to={'/dashboard/staticpage_campaign'}>
          <MenuButton label={'/dashboard/staticpage_creator'}>
            <PushPinIcon />
            &nbsp;固定ページ
          </MenuButton>
        </Link>
      </div>

      <Horizon />
      <div className="text-left px-1 mb-4 mt-3">
        <Link to={'/dashboard/common_setting'}>
          <MenuButton label={'/dashboard/common_setting'}>
            <SettingsIcon />
            &nbsp;その他設定
          </MenuButton>
        </Link>
      </div>

      <div className="text-right px-1 mb-20">
        <LogoutContainer isMenuShrinked={props.isMenuShrinked} />
      </div>
      <div className="text-right px-1">
        <a href="/" target="_blank">
          <button className="w-full font-hiragino rounded-full text-left text-base py-2 px-3 bg-cinekoya-sub-c hover:bg-cinekoya-sub-a  text-white  shadow-xl">
            <OpenInNewIcon />
            &emsp;ホームページ
          </button>
        </a>
      </div>
    </>
  )
}
